import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Popover,
  PopoverBody,
  Button,
} from "reactstrap";
import TooltipReference from "../../common/tooltip";
import { sliceText } from "../../common/sliceText";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faExclamationTriangle,
  faEye,
  faList,
  faExternalLinkAlt,
  faReply,
  faCopy
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "../../common/alert";
import phone from "../../assets/img/phonesymbol.png";
import { Link } from "react-router-dom";
import MapLoader from "../../common/MapLoader";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

interface TemplateCardProps {
  onClick(): void;
  template: any;
  handleTemplateActions(action: string, rowData: any): void;
  cardSelect: string;
  index: number;
  readOnly: boolean;
  delTemplate(id: string, name: string): void;
  cardBody(): void;
  tab?: string;
  isInvalidAccess: boolean;
  communityList?:any;
}

class TemplateCard extends React.Component<TemplateCardProps, any> {

  state = {
    popoverOpen: false,
    popoverTarget: null,
  };


  static defaultProps = {
    handleTemplateActions: () => { },
    onClick: () => { },
    cardSelect: "",
    index: "",
    delTemplate: () => { },
    readOnly: false,
    cardBody: () => { },
  };

  extractVariableName = (text: string) => {
    const regex = /{{(.*?)}}/g;
    const matches = text.match(regex);
    if (matches) {
      return matches.map((match: string) => match.replace(/{{|}}/g, ""));
    }
    return [];
  };

  copyTemplateId = (id: string) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        toast("success", "Template ID Copied");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  wordsExtractedFromSymbols = (symbol: string, text: string) => {
    var regex;
    if (symbol === "*") {
      regex = /\*(.*?)\*/g;
    } else if (symbol === "_") {
      regex = /_([^_]+)_/g;
    } else {
      regex = /~([^~]+)~/g;
    }
    const extractedWords = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      extractedWords.push(match[1]);
    }
    return extractedWords;
  };

  renderMessage = (values: any, text: string) => {
    let renderedText = text;
    if (values?.example?.body_text?.length) {
      const variableNames = this.extractVariableName(text);
      variableNames.forEach((variableName, index) => {
        const replacement = `<span class="sampleVarColor">${values.example.body_text[0][index]}</span>`;
        renderedText = renderedText.replace(`{{${variableName}}}`, replacement);
      });
    }
    if (renderedText?.includes("*")) {
      const boldWords = this.wordsExtractedFromSymbols("*", renderedText);
      boldWords.forEach((b) => {
        const replaceAsBold = `<span class="fontWeight">${b}</span>`;
        renderedText = renderedText.replace(`*${b}*`, replaceAsBold);
      });
    }
    if (renderedText?.includes("_")) {
      const italicWords = this.wordsExtractedFromSymbols("_", renderedText);
      italicWords.forEach((i) => {
        const replaceAsItalic = `<i>${i}</i>`;
        renderedText = renderedText.replace(`_${i}_`, replaceAsItalic);
      });
    }
    if (renderedText?.includes("~")) {
      const tildeWords = this.wordsExtractedFromSymbols("~", renderedText);
      tildeWords.forEach((t) => {
        const replaceAsTilde = `<span class="strikeThrough">${t}</span>`;
        renderedText = renderedText.replace(`~${t}~`, replaceAsTilde);
      });
    }
    return (
      <div
        style={{ whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={{ __html: renderedText }}
      />
    );
  };

  handleMapClick = (lat: any, long: any) => {
    const url = `https://www.google.co.in/maps/place/${lat},${long}`;
    window.open(url, "_blank");
  };

  render(): React.ReactNode {
    return (
      <Card
        className={`cardStyle ${Number(this.props.cardSelect) == this.props.index
            ? "cardStyleActive"
            : ""
          }`}
        onClick={this.props.onClick}
      >
        <CardHeader className="cardHeader p-2">
          {this.props.readOnly || this.props.tab === "default" ? null : (
            <span
              className="float-right ml-3 templateActions"
              id={`popover_${this.props.template.id}`}
              onClick={(event) => {
                this.setState({
                  popoverOpen: true,
                  popoverTarget: event.currentTarget,
                });
              }}
              tabIndex={0}
            >
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="iconsColor"
              />
            </span>
          )}
          {this.props.tab === "default" ? (
            <Button
              className="float-right useBtn mb-1"
              disabled={this.props.isInvalidAccess}
              onClick={() => {
                this.props.handleTemplateActions(
                  "default",
                  this.props.template
                );
              }}
            >
              Use
            </Button>
          ) : this.props.readOnly ? null : (
            <>
              <span className="float-right eyeicon">
                <TooltipReference
                  placement="bottom"
                  content="View"
                  tooltipId={`viewTemplate-${this.props.template.id}`}
                >
                  <FontAwesomeIcon
                    onClick={() =>
                      this.props.handleTemplateActions(
                        "view",
                        this.props.template
                      )
                    }
                    icon={faEye}
                    className="text-muted h6"
                  />
                </TooltipReference>
              </span>
              <Popover
                isOpen={
                  this.state.popoverOpen &&
                  this.state.popoverTarget ===
                  document.getElementById(`popover_${this.props.template.id}`)
                }
                target={`popover_${this.props.template.id}`}
                toggle={() =>
                  this.setState({ popoverOpen: false, popoverTarget: null })
                }
                placement="right"
                className="templatePopover mt-3"
                trigger="legacy"
              >
                <PopoverBody>
                  <div
                    className={`seperationLine p-2 templateTabs ${this.props.isInvalidAccess ? "disabledState" : ""} `}
                    onClick={() => {
                      this.props.handleTemplateActions(
                        "duplicate",
                        this.props.template
                      );
                    }}
                  >
                    Duplicate
                  </div>
                  <div
                    className="seperationLine p-2 templateTabs"
                    onClick={() => {
                      this.props.handleTemplateActions(
                        "view",
                        this.props.template
                      );
                    }}
                  >
                    View
                  </div>
                  {this.props.template.templateStatus === 'APPROVED' &&(
                  <div
                    className="seperationLine p-2 templateTabs"
                    // onClick={}
                  >
                      <Link
                                  to={`${process.env.PUBLIC_URL}/whatsappTemplate/${this.props.template.id}/${this.props.template.name}`}
                                  className=""
                                  style={{color:'black'}}
                                >
                                  Analytics
                                </Link>        
                  </div>   
                                )}           
                {this.props.template.templateStatus === 'DRAFT' || this.props.template.templateStatus === 'REJECTED' || this.props.template.templateStatus === 'ERROR'?
                   ((!this.props.template?.components?.some((component: any) => component?.type === 'CAROUSEL')) &&(
                    <div
                      className={`seperationLine p-2 templateTabs ${this.props.isInvalidAccess ? "disabledState" : ""} `}
                      onClick={() => {
                        this.props.handleTemplateActions(
                          "edit",
                          this.props.template
                        );
                      }}
                    >
                      Edit
                    </div>
                  )) : ''}
                  {this.props.template?.templateStatus!=="DELETED" &&(
                  <div
                    className={`${this.props.template.templateStatus === "DRAFT"
                        ? "seperationLine"
                        : "seperationLine p-2 templateTabs"
                      } p-2 templateTabs ${this.props.isInvalidAccess ? "disabledState" : ""} `}
                    onClick={() => {
                      this.setState({ popoverOpen: false, popoverTarget: null });
                      this.props.delTemplate(
                        this.props.template.id,
                        this.props.template.name
                      );
                    }}
                  >
                    Delete
                  </div>)}
                  {this.props.template.templateStatus !== "REJECTED" && (
                    <div
                      className="p-2 templateTabs"
                      onClick={() =>
                        this.copyTemplateId(this.props.template.id)
                      }
                    >
                      Copy Template ID{" "}
                    </div>
                  )}

                  {/* {this.props.template.templateStatus !== 'APPROVED' && this.props.template.templateStatus !== 'REJECTED' ?
                                        <div className='p-2' onClick={() => { this.props.handleTemplateActions('publish', temp) }}>Publish</div> : ''} */}
                </PopoverBody>
              </Popover>
            </>
          )}

          <div>
            <span className="fontsize mr-1">
              {" "}
              <TooltipReference
                placement="bottom"
                content={`${this.props?.template?.showName} (${this.props?.template?.templateStatus})`}
                tooltipId={`listTempName-${this.props?.template?.id}`}
              >
                {sliceText(this.props?.template?.showName, 12)}
              </TooltipReference>
            </span>
            {this.props.template?.templateStatus === "REJECTED" ? (
              <TooltipReference
                placement="bottom"
                tooltipId={`reject-${this.props.template.id}`}
                content={this.props?.template?.rejected_reason}
              >
                <span
                  className="rejectedStatus"
                  id={this.props.template.templateStatus}
                >
                  {this.props.template.templateStatus}{" "}
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="rejectedStatus"
                    aria-hidden="true"
                  />
                </span>
              </TooltipReference>
            ): this.props.template?.templateStatus === "ERROR" ? (
              <TooltipReference
                placement="bottom"
                tooltipId={`reject-${this.props.template.id}`}
                content={this.props?.template?.rejected_reason}
              >
                <span
                  className="rejectedStatus"
                  id={this.props.template.rejected_reason}
                >
                  {this.props.template.templateStatus}{" "}
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="rejectedStatus"
                    aria-hidden="true"
                  />
                </span>
              </TooltipReference>
            ) : this.props.template.templateStatus === "APPROVED" ? (
              <span
                className="approvedStatus"
                id={this.props.template.templateStatus}
              >
                {this.props.template.templateStatus}{" "}
              </span>
            ) : this.props.template.templateStatus === "DRAFT" ? (
              <span
                className="draftStatus"
                id={this.props.template.templateStatus}
              >
                {this.props.template.templateStatus}
              </span>
            ) : this.props.template.templateStatus === "PENDING" ? (
              <span
                className="pendingStatus"
                id={this.props.template.templateStatus}
              >
                {this.props.template.templateStatus}
              </span>
            ) : this.props.template.templateStatus === "IN_APPEAL" ? (
              <span
                className="appealStatus"
                id={this.props.template.templateStatus}
              >
                {this.props.template.templateStatus}
              </span>
            ) : this.props.template.templateStatus === "PENDING_DELETION" ? (
              <span
                className="pendingdelStatus"
                id={this.props.template.templateStatus}
              >
                {this.props.template.templateStatus}
              </span>
            ) : this.props.template.templateStatus === "DELETED" ? (
              <span
                className="deletedStatus"
                id={this.props.template.templateStatus}
              >
                {this.props.template.templateStatus}
              </span>
            ) : this.props.template.templateStatus === "DISABLED" ? (
              <span
                className="disableStatus"
                id={this.props.template.templateStatus}
              >
                {this.props.template.templateStatus}
              </span>
            ) : this.props.template.templateStatus === "PAUSED" ? (
              <span
                className="pauseStatus"
                id={this.props.template.templateStatus}
              >
                {this.props.template.templateStatus}
              </span>
            ) : this.props.template.templateStatus === "LIMIT_EXCEEDED" ? (
              <span
                className="limitStatus"
                id={this.props.template.templateStatus}
              >
                {this.props.template.templateStatus}
              </span>
            ) : this.props.template.templateStatus === "SUBMITTED" ? (
              <span
                className="submitStatus"
                id={this.props.template.templateStatus}
              >
                SUBMITTED
              </span>
            ) : (
              ""
            )}
            {/* <Tooltip
                                  placement="top"
                                  isOpen={this.props.template.templateStatus}
                                  autohide={false}
                                  target={this.props.template.templateStatus}
                                  toggle={() => this.toggleTooltip(this.props.template.templateStatus)}
                                >
                                  {this.props.template.templateStatus}
                                </Tooltip> */}
          </div>
          <div className="iconsColor pt-1 textstyle">
            {this.props.template?.category?.toUpperCase()}
          </div>
        </CardHeader>
        <div className="seperationLine"></div>
        <CardBody onClick={this.props.cardBody} className="adjustHeight">
          {/* <div className="link-overlay rounded">
                              <div className='bg-white rounded-pill p-1' onClick={() => { this.props.handleTemplateActions('view', temp) }}>View Template</div>
                            </div> */}
          {this.props.template?.isCatalog && this.props.template?.buttonColumns && (
            <img
              src={this.props?.template?.buttonColumns[0]}
              className="headerImg"
              alt="oldimage"
            />
          )}
          {this.props.template.components?.map(
            (tempDet: any, tempind: number) => {
              if (tempDet.type === "HEADER") {
                if (tempDet.format === "TEXT") {
                  return (
                    <div className="fontWeight" key={tempind}>
                      {tempDet.text}
                    </div>
                  );
                } else if (tempDet.format === "IMAGE") {
                  if(this.props.communityList?.length > 0 && this.props.communityList[0]?.uploadMethod === "GoogleSheets"){
                    return (
                      <img
                        key={tempind}
                        src={this.props.template?.media?.url}
                        className="headerImg"
                        alt="oldimage"
                      />
                    );
                  }
                  else{
                    return (
                      <img
                        key={tempind}
                        src={tempDet?.example?.header_handle[0]}
                        className="headerImg"
                        alt="oldimage"
                      />
                    );
                  }
                } else if (tempDet.format === "VIDEO") {
                  return (
                    <video
                      key={tempind}
                      controls
                      src={tempDet?.example?.header_handle[0]}
                      className="headerImg"
                    />
                  );
                } else if (tempDet.format === "DOCUMENT") {
                  return (
                    <iframe
                      src={tempDet?.example?.header_handle[0]}
                      className="pdfSizeInPreview"
                    ></iframe>
                  );
                }else if (tempDet.format === 'LOCATION') {
                  return (
                    <MapLoader key={tempind}>
                      <div id="send-msg-map">
                        <GoogleMap
                          id="msg-map"
                          mapContainerStyle={{ width: "auto", height: "225px" }}
                          zoom={12}
                          center={{
                            lat: this.props.template?.headerColumns?.[0]?.latitude,
                            lng: this.props.template?.headerColumns?.[0]?.longitude,
                          }}
                          options={{
                            disableDefaultUI: true,  
                            zoomControl: false,   
                            mapTypeControl: false,  
                            streetViewControl: false, 
                            fullscreenControl: false, 
                          }}
                          // onClick={() =>
                          //   this.handleMapClick(
                          //     this.props.template?.headerColumns?.[0]?.latitude,
                          //     this.props.template?.headerColumns?.[0]?.longitude
                          //   )
                          // }
                        >
                          <Marker
                            position={{
                              lat: this.props.template?.headerColumns?.[0]?.latitude,
                              lng: this.props.template?.headerColumns?.[0]?.longitude,
                            }}
                          />
                        </GoogleMap>
                      </div>
                    </MapLoader>
                  );
                }
                
              }

              if (tempDet.type === "BODY" && this.props.template.category!=="AUTHENTICATION") {
                return (
                  <div className="pt-3" key={this.props.template.id}>
                    {this.renderMessage(tempDet, tempDet.text)}
                  </div>
                );
              }
              if(tempDet.type==="BODY" && this.props.template?.category==="AUTHENTICATION" && this.props?.template?.buttonColumns){
                return(
                  <div className="pt-3" key={this.props.template.id}>
                    {this.renderMessage(tempDet, this.props?.template?.buttonColumns[0])}
                  </div>
                )
              }
              if (tempDet.type === "FOOTER"&& this.props.template?.category!=="AUTHENTICATION") {
                return (
                  <div className="pt-2 textstyle1" key="footer">
                    <i>{tempDet.text}</i>
                  </div>
                );
              }
              if (
                tempDet.type === "FOOTER" &&
                this.props.template.category === "AUTHENTICATION" &&
                Array.isArray(this.props.template.buttonColumns) &&
                this.props.template.buttonColumns.length > 1
              ) {
                return (
                  <div className="pt-2 textstyle1" key="footer">
                    <i>{this.props.template.buttonColumns[1]}</i>
                  </div>
                );
              }
              if (tempDet.type === "BUTTONS" && !this.props.template?.components?.some((component:any) => component?.type === "LIMITED_TIME_OFFER")) {
                const numButtons = tempDet.buttons?.length;
                const renderedButtons = tempDet.buttons
                  ?.slice(0, 2)
                  .map((btn: any, innerindex: number) => (
                    <span
                      key={innerindex}
                      title={btn.text}
                      // className={`pt-2 mt-3 text-center txtbtn ${numButtons === 1 ? 'col-12' :
                      //     (innerindex === 2 ? 'col-12' : 'width')} ${numButtons === 3 && innerindex === 0 ? 'mr-1' :
                      //         numButtons === 2 && innerindex === 0 ? 'mr-1' : ''}`}
                      className={`pt-2 mt-3 text-center txtbtn col-12`}
                    >
                      {btn.type === 'otp' && btn.otp_type === 'copy_code' ?                      
                      <FontAwesomeIcon icon={faCopy} className="mr-1"/> 
                      :
                     <FontAwesomeIcon icon={faReply} className="mr-1"/> 
                      }
                     {numButtons === 1 && innerindex === 0
                        ? sliceText(btn.text, 20)
                        : numButtons === 3 && innerindex === 2
                          ? sliceText(btn.text, 20)
                          : sliceText(btn.text, 20)}
                    </span>
                  ));

                return (
                  <div className="replyBtnsColor ">
                    {renderedButtons}
                    {numButtons > 2 && (
                      <span className={`pt-2 mt-3 text-center txtbtn col-12`}>
                        <FontAwesomeIcon
                          icon={faList}
                          widths={80}
                          className="me-2"
                        ></FontAwesomeIcon>
                        Explore More
                      </span>
                    )}
                  </div>
                );
              }
              if(tempDet?.buttons?.[0]?.type === 'COPY_CODE'){
                return (
                  <>
                    {
                        <div className="replyBtnsColor ">
                          <div
                            className={`pt-2 mt-3 text-center txtbtn col-12`}
                          >
                           <FontAwesomeIcon icon={faCopy} className="me-2" />Copy offer code
                          </div>
                            <div
                            className={`pt-2 mt-3 text-center txtbtn col-12`}
                          >
                            <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1"/> {tempDet?.buttons?.[1]?.text?.length > 18? tempDet?.buttons?.[1]?.text.slice(0,17)+ '...' : tempDet?.buttons?.[1]?.text }
                          </div>
                        </div>
                      }
                    </>
                  )
              }
            }
          )}

{this.props.template?.category === 'MARKETING' && ((this.props.template?.components[1]?.type === 'CAROUSEL' && this.props.template?.components[1]?.type) || (this.props.template?.components[0]?.type === 'CAROUSEL' && this.props.template?.components[0]?.type))? 
     <div className={`carouselTempsCards p-1`}>
     <div className="d-flex">
      {this.props.template?.components[1]?.type === 'CAROUSEL' && this.props.template?.components[1]?.type? 
       (this.props.template?.components[1].cards?.map((e: any, ind: number) => (
         <div key={ind} className="templateRow">
          <div className="">
           <span>
            {e?.components[0]?.format === 'IMAGE'?
             <img src={e?.components[0]?.example?.header_handle[0]} className="" width={170} height={90} alt="Header" /> :
             e?.components[0]?.format === 'VIDEO'? <video  src={e?.components[0]?.example?.header_handle[0]}
                                                controls
                                                width={170}
                                                height={90}
                                            /> : ''}
           </span>
           {e?.components[1]?.text && <div title={e?.components[1]?.text}>{e?.components[1]?.text.length > 15 && e?.components[1]?.text ? e?.components[1]?.text?.slice(0,14) + '...' : e?.components[1]?.text}</div>}
           </div>
           <div className="pt-2">
             {e?.components[2]?.buttons?.map((ele: any, buttonIndex: number) => (
               <p key={buttonIndex} className="quickbtns m-1 text-center" title={ele?.text}>
                  {ele?.type === 'QUICK_REPLY'? <FontAwesomeIcon icon={faReply} className="mr-1"/> : ele?.type === 'PHONE_NUMBER'? <img src={phone} width={20} /> : ele?.type === 'URL'? <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1"/> : '' }  
                  {ele?.text?.length > 15? ele?.text?.slice(0,14)+'...' : ele?.text}</p>
             ))}
           </div>
         </div>
       ))) : 
       (this.props.template?.components[0].cards?.map((e: any, ind: number) => (
        <div key={ind} className="templateRow">
         <div className="">
          <span>
           {e?.components[0]?.format === 'IMAGE'?
            <img src={e?.components[0]?.example?.header_handle[0]} className="" width={170} height={90} alt="Header" /> :
            e?.components[0]?.format === 'VIDEO'? <video  src={e?.components[0]?.example?.header_handle[0]}
                                               controls
                                               width={170}
                                               height={90}
                                           /> : ''}
          </span>
          {e?.components[1]?.text && <div title={e?.components[1]?.text}>{e?.components[1]?.text.length > 15 && e?.components[1]?.text ? e?.components[1]?.text?.slice(0,14) + '...' : e?.components[1]?.text}</div>}
          </div>
          <div className="pt-2">
            {e?.components[2]?.buttons?.map((ele: any, buttonIndex: number) => (
              <p key={buttonIndex} className="quickbtns m-1 text-center" title={ele?.text}>
                 {ele?.type === 'QUICK_REPLY'? <FontAwesomeIcon icon={faReply} className="mr-1"/> : ele?.type === 'PHONE_NUMBER'? <img src={phone} width={20} /> : ele?.type === 'URL'? <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1"/> : '' }  
                 {ele?.text?.length > 15? ele?.text?.slice(0,14)+'...' : ele?.text}</p>
            ))}
          </div>
        </div>
      )))}
     </div>
   </div>
   : ''}
        </CardBody>
        <div className="seperationLine"></div>
        {this.props.readOnly ? null : (
          <CardFooter className="cardHeader p-3">
            <span
              className="fontWeight"
              title={moment(this.props.template.createdAt).format(
                "DD/MM/YY dddd"
              )}
            >
              {moment(this.props.template.createdAt).format("DD MMM")}
            </span>{" "}
            <div className="float-right iconsColor language1 rounded">
              {this.props.template.language}
            </div>
          </CardFooter>
        )}
      </Card>
    );
  }
}

export default TemplateCard;
