import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import "../../main.scss";
import {
  getConnection,
  updateConnection,
  enableWebHookCatcher,
  deleteWebHookWorkFlow,
} from "../../../../services/integrationService";
import { useSelector } from "react-redux";
import { cashFreeWorkFlowTypes } from "../../assets";
import WorkFlowHeader from "../../Component/WorkFlowHeader";
import WorkFlowForm from "../../Component/WorkFlowForm";
import { Col, Row } from "reactstrap";
import { IoIosInformationCircleOutline } from "react-icons/io";
import io from "socket.io-client";
import { socket } from "../../../../common/socketconfig";
import { uuidv4 } from "../../../../botFlows/utils/uuid";

interface WebHookProps {
  integrationUid?: string; // Make it optional if it can be undefined
  activeTab: string;
  connectionUid: string;
  callDb: boolean;
  setCallDb: React.Dispatch<React.SetStateAction<boolean>>;
  setWorkFlowEnable: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  isInvalidAccess: boolean;
}

interface FormValues {
  integrationName: string;
  webhookUrl: string;
  channelName: { value: string; label: string } | null;
  integrationVariables: any[];
  metaData: string;
}

interface ParsedMetaData {
  jsonResponse: Record<string, any>;
  headerResponse: Record<string, string>;
}

const validationSchema = Yup.object({
  integrationName: Yup.string().required("Integration Name is required"),
  webhookUrl: Yup.string().required("WebHook URL is required"),
  channelName: Yup.object().nullable().required("Channel Name is required"),
});

const WebHook: React.FC<WebHookProps> = (props) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    integrationName: "",
    webhookUrl: "",
    channelName: null,
    integrationVariables: [],
    metaData: "",
  });

  const [parsedMetaData, setParsedMetaData] = useState<ParsedMetaData | null>(
    null
  );

  const webHookSocket = socket;

  const [webhookUrlCopied, setWebhookUrlCopied] = useState(false);
  const [activeWorkflowId, setActiveWorkflowId] = useState<number | null>(null);
  const [isWorkFlow, setIsWorkFlow] = useState<any>([]);

  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );
  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const [webhookData, setWebhookData] = useState(null); // State to store incoming data from socket
  
  const initializeSocket = () => {
    const event = `capturedWebhookData/${businessUid}`;

    // Listen for the event
    webHookSocket.on(event, (receivedData: any) => {
      console.log('Received data:', receivedData);
      setWebhookData(receivedData); // Update the state with the received data
    });
  }

  useEffect(() => {
    if (props.integrationUid) {
      getConnection(props.integrationUid)
        .then((data: any) => {
          const updatedInitialValues = {
            integrationName:
              data.integrationConfigurationDTO.integrationName || "",
            webhookUrl:
              data.integrationConfigurationDTO.webhookData.webhookUrl || "",
            channelName: data.integrationConfigurationDTO.channelDto
              ? {
                  value: data.integrationConfigurationDTO.channelDto.uid,
                  label: data.integrationConfigurationDTO.channelDto.name,
                }
              : null,
            integrationVariables: data?.integrationVariables || [],
            metaData: JSON.parse(data.integrationConfigurationDTO.metaData.jsonResponse || '{}'),
          };
  
          setInitialValues(updatedInitialValues);
          props.setWorkFlowEnable(
            Object.keys(data.integrationConfigurationDTO?.channelDto).length >
              0 || false
          );

          try {
            // Parse the metaData JSON string
            const parsed = JSON.parse(
              data.integrationConfigurationDTO.metaData.jsonResponse
            );
            setParsedMetaData(parsed);
          } catch (error) {
            console.error("Error parsing metaData JSON:", error);
            setParsedMetaData(null);
          }
          setIsWorkFlow(data.workFlows);
        })
        .catch((error) => {
          console.error("Error fetching connection data:", error);
        });
    }
  }, [props.integrationUid, props.callDb]);

  useEffect(() => {
    if (props.callDb) {
      setTimeout(() => {
        props.setCallDb(false);
      }, 1000); // set callDb to false after 2 seconds
    }
  }, [props.callDb]);

  const handleCopy = (
    text: string,
    setCopied: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  };

  const enableWebHookCatch = async () => {
    try {
      const response = await enableWebHookCatcher(props.connectionUid);
      console.log(response, 'response--')
      if (response === "SUCCESS") {
        initializeSocket(); // Call function to initialize socket
        // props.setCallDb(true);
      }
    } catch (error) {
      console.error("Error enabling webhook catcher:", error);
    }
  };

  // const renderMetaDataSection = (
  //   title: string,
  //   data?: Record<string, any> | Array<Record<string, any>>
  // ) => {
  //   console.log(data, "datajjj");
  //   if (!data) return null;
  //   return (
  //     <div className="mb-4">
  //       <div className="metaData-title">{title}</div>
  //       <div className="d-flex mb-2">
  //         <div className="metaData-label me-3">Label</div>
  //         <div className="metaData-label">Value</div>
  //       </div>
  //       {Array.isArray(data)
  //         ? data.map((item, index) => (
  //             <React.Fragment key={index}>
  //               {Object.entries(item).map(([key, value]) => (
  //                 <div key={key} className="d-flex mb-2">
  //                   <div
  //                     className="metaData-key me-3"
  //                     style={{ color: "#6c757d" }}
  //                   >
  //                     {`${title}[${index}].${key}`}
  //                   </div>
  //                   <div className="metaData-key">
  //                     {typeof value === "string" || typeof value === "number"
  //                       ? value
  //                       : JSON.stringify(value)}
  //                   </div>
  //                 </div>
  //               ))}
  //             </React.Fragment>
  //           ))
  //         : Object.entries(data).map(([key, value]) => (
  //             <div key={key} className="d-flex mb-2">
  //               <div className="metaData-key me-3" style={{ color: "#6c757d" }}>
  //                 {key}
  //               </div>
  //               <div className="metaData-key">
  //                 {typeof value === "string" || typeof value === "number"
  //                   ? value
  //                   : JSON.stringify(value)}
  //               </div>
  //             </div>
  //           ))}
  //     </div>
  //   );
  // };

  const renderMetaDataSection = (
    title: string,
    data?: Record<string, any> | Array<Record<string, any>>
  ) => {
    if (!data) return null;

    const renderValue = (value: any, key: string, index?: number) => {
      if (Array.isArray(value)) {
        return (
          <div>
            {value.map((item, arrayIndex) => (
              <div key={`${key}-${arrayIndex}`} className="d-flex mb-2">
                <div className="metaData-key me-3" style={{ color: "#6c757d" }}>
                  {index !== undefined
                    ? `${title}[${index}].${key}[${arrayIndex}]`
                    : `${key}[${arrayIndex}]`}
                </div>
                <div className="metaData-key">
                  {typeof item === "string" || typeof item === "number"
                    ? item
                    : JSON.stringify(item)}
                </div>
              </div>
            ))}
          </div>
        );
      } else {
        return typeof value === "string" || typeof value === "number"
          ? value
          : JSON.stringify(value);
      }
    };

    return (
      <div className="mb-4">
        <div className="metaData-title">{title}</div>
        <div className="d-flex mb-2">
          <div className="metaData-label me-3">Label</div>
          <div className="metaData-label">Value</div>
        </div>
        {Array.isArray(data)
          ? data.map((item, index) => (
              <React.Fragment key={index}>
                {Object.entries(item).map(([key, value]) => (
                  <div key={key} className="d-flex mb-2">
                    <div
                      className="metaData-key me-3"
                      style={{ color: "#6c757d" }}
                    >
                      {`${title}[${index}].${key}`}
                    </div>
                    <div className="metaData-key">
                      {renderValue(value, key, index)}
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))
          : Object.entries(data).map(([key, value]) => (
              <div key={key} className="d-flex mb-2">
                <div className="metaData-key me-3" style={{ color: "#6c757d" }}>
                  {key}
                </div>
                <div className="metaData-key">{renderValue(value, key)}</div>
              </div>
            ))}
      </div>
    );
  };

  const handleSubmit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const data = {
      integrationConfigurationDTO: {
        channelDto: {
          uid: values.channelName?.value,
        },
        integrationName: values.integrationName,
      },
    };

    try {
      const response = await updateConnection(props.connectionUid, data);
      props.setCallDb(true);
      props.setActiveTab("Workflow");
      // formikHelpers.resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const channelOptions = channelData.map((channel: any) => ({
    value: channel.uid,
    label: channel.name,
  }));

  const findWorkFlowById = (id: number) => {
    const workFlowType = isWorkFlow.find((types: any) => types.id === id);

    // const workFlow = isWorkFlow.find(
    //   (wf: any) => wf.id === workFlowType?.id
    // );

    return workFlowType || null;
  };

  const handleDeleteWorkflow = async (id: string) => {
    const idToRemove = id;

    try {
      const response = await deleteWebHookWorkFlow(idToRemove);
      props.setCallDb(true);
    } catch (error) {
      console.error("Error deleting workFlow:", error);
    }
  };

  return (
    <>
      {props.activeTab === "Overview" && (
        <div className="shopifyOverview">
          <div className="headerOverview">
            BizMagnets helps you to Integrate your Official WhatsApp Business
            Account with Webhooks that will enable you to send automated
            notifications to your customers and trigger a specific bot flow.
          </div>

          <div>
            <h5 className="fw-bold mt-4 mb-4">What can I do?</h5>
          </div>

          <div className="body">
            <Row>
              <Col md={12}>
                <div className="d-flex align-items-center mb-2">
                  <div className="me-2">
                    <img
                      src="data:image/svg+xml,%3csvg%20width='33'%20height='33'%20viewBox='0%200%2033%2033'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20width='32.4524'%20height='32.4524'%20rx='16.2262'%20fill='%23DDE4FF'/%3e%3cpath%20d='M12.7812%2022.1875C9.64875%2022.1875%207.09375%2019.6325%207.09375%2016.5C7.09375%2013.3675%209.64875%2010.8125%2012.7812%2010.8125C13.14%2010.8125%2013.4375%2011.11%2013.4375%2011.4688C13.4375%2011.8275%2013.14%2012.125%2012.7812%2012.125C10.3663%2012.125%208.40625%2014.085%208.40625%2016.5C8.40625%2018.915%2010.3663%2020.875%2012.7812%2020.875C15.1962%2020.875%2017.1562%2018.915%2017.1562%2016.5C17.1562%2016.1413%2017.4537%2015.8438%2017.8125%2015.8438C18.1713%2015.8438%2018.4688%2016.1413%2018.4688%2016.5C18.4688%2019.6325%2015.9137%2022.1875%2012.7812%2022.1875Z'%20fill='%23171923'/%3e%3cpath%20d='M20%2022.4062C19.6413%2022.4062%2019.3438%2022.1088%2019.3438%2021.75C19.3438%2021.3912%2019.6413%2021.0938%2020%2021.0938C22.5288%2021.0938%2024.5938%2019.0288%2024.5938%2016.5C24.5938%2013.9712%2022.5288%2011.9062%2020%2011.9062C17.4712%2011.9062%2015.4062%2013.9712%2015.4062%2016.5C15.4062%2016.8587%2015.1087%2017.1562%2014.75%2017.1562C14.3913%2017.1562%2014.0938%2016.8587%2014.0938%2016.5C14.0938%2013.245%2016.745%2010.5938%2020%2010.5938C23.255%2010.5938%2025.9062%2013.245%2025.9062%2016.5C25.9062%2019.755%2023.255%2022.4062%2020%2022.4062Z'%20fill='%23171923'/%3e%3c/svg%3e"
                      alt=""
                    />
                  </div>
                  <div>Send customized template messages</div>
                </div>
              </Col>
              <Col md={6}>
                <div className="d-flex align-items-center mb-2">
                  <div className="me-2">
                    <img
                      src="data:image/svg+xml,%3csvg%20width='33'%20height='33'%20viewBox='0%200%2033%2033'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20width='32.3816'%20height='32.3816'%20rx='16.1908'%20fill='%23FFF8DD'/%3e%3cpath%20d='M16%2016.6253C13.3583%2016.6253%2011.2083%2014.4753%2011.2083%2011.8337C11.2083%209.19199%2013.3583%207.04199%2016%207.04199C18.6416%207.04199%2020.7916%209.19199%2020.7916%2011.8337C20.7916%2014.4753%2018.6416%2016.6253%2016%2016.6253ZM16%208.29199C14.05%208.29199%2012.4583%209.88366%2012.4583%2011.8337C12.4583%2013.7837%2014.05%2015.3753%2016%2015.3753C17.95%2015.3753%2019.5416%2013.7837%2019.5416%2011.8337C19.5416%209.88366%2017.95%208.29199%2016%208.29199Z'%20fill='%23292D32'/%3e%3cpath%20d='M8.8418%2024.9583C8.50013%2024.9583%208.2168%2024.675%208.2168%2024.3333C8.2168%2020.775%2011.7085%2017.875%2016.0001%2017.875C16.8418%2017.875%2017.6668%2017.9834%2018.4668%2018.2084C18.8001%2018.3%2018.9918%2018.6417%2018.9001%2018.975C18.8085%2019.3083%2018.4668%2019.5%2018.1335%2019.4084C17.4501%2019.2167%2016.7335%2019.125%2016.0001%2019.125C12.4001%2019.125%209.4668%2021.4583%209.4668%2024.3333C9.4668%2024.675%209.18346%2024.9583%208.8418%2024.9583Z'%20fill='%23292D32'/%3e%3ccircle%20cx='20.5'%20cy='20.5'%20r='3'%20fill='%23FF4A4A'%20stroke='white'/%3e%3c/svg%3e"
                      alt=""
                    />
                  </div>
                  <div>Trigger a specific bot flow built in BizMagnets</div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}

      {props.activeTab === "Configuration" && (
        <div className="shipRocket-configuration mb-3">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="integrationName">
                    Integration Name
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    name="integrationName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="integrationName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="channelName">
                    Channel Name
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </label>
                  <Select
                    options={channelOptions}
                    name="channelName"
                    value={values.channelName}
                    onChange={(option) => setFieldValue("channelName", option)}
                    className="channel-select"
                  />
                  <ErrorMessage
                    name="channelName"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="webhookUrl">WebHook URL</label>
                  <div className="d-flex">
                    <Field
                      type="text"
                      name="webhookUrl"
                      className="form-control"
                      disabled
                    />
                    <button
                      type="button"
                      className="copyButton"
                      onClick={() =>
                        handleCopy(
                          document.querySelector<HTMLInputElement>(
                            'input[name="webhookUrl"]'
                          )?.value || "",
                          setWebhookUrlCopied
                        )
                      }
                    >
                      <svg
                        className="me-2"
                        stroke="currentColor"
                        fill="none"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        focusable="false"
                        height="1.2em"
                        width="1.2em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                        ></path>
                      </svg>
                      {webhookUrlCopied ? "Copied" : "Copy"}
                    </button>
                  </div>
                  <ErrorMessage
                    name="webhookUrl"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="webHook-note">
                  <div className="me-2">
                    <IoIosInformationCircleOutline size={17} />
                  </div>
                  <div>
                    Copy the webhook URL and add it under the webhook section of
                    the application you're willing to integrate with. Once
                    you're done with adding the webhook URL, then do a test
                    submission/record in that application in order to capture
                    the webhook response here. Note that webhook URL is unique
                    for every workflow.
                  </div>
                </div>

                <div>
                  <button
                    type="button"
                    className={`${
                      initialValues.metaData &&
                      Object.keys(initialValues.metaData).length > 0
                        ? "btn-connected"
                        : "btn btn-primary"
                    }`}
                    // className="btn btn-primary"
                    onClick={enableWebHookCatch}
                  >
                    {initialValues.metaData &&
                    Object.keys(initialValues.metaData).length > 0 ? (
                      <>Re-capture webhook response</>
                    ) : (
                      <>Capture webhook response</>
                    )}
                  </button>
                </div>

                {/* Display Captured Data */}
                {parsedMetaData && (
                  <div className="mt-3">
                    <h5 className="mb-3">Response Received</h5>
                    {renderMetaDataSection(
                      "Header",
                      parsedMetaData?.jsonResponse?.headerResponse
                    )}
                    {renderMetaDataSection(
                      "Body",
                      parsedMetaData?.jsonResponse?.jsonResponse
                    )}

                    <div
                      className={`d-flex justify-content-center mt-3 mb-5${
                        props?.isInvalidAccess ? "notAllowed" : "pointer"
                      } `}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary d-flex justify-content-between"
                        disabled={props?.isInvalidAccess}
                      >
                        Update Configuration
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      )}

      {props.activeTab === "Workflow" && (
        <div>
          {activeWorkflowId ? (
            <WorkFlowForm
              id={activeWorkflowId}
              title={
                isWorkFlow?.find((wf: any) => wf.id === activeWorkflowId)
                  ?.workFlowType || ""
              }
              description={""}
              value={
                isWorkFlow?.find((wf: any) => wf.id === activeWorkflowId)
                  ?.workFlowType || ""
              }
              setActiveWorkflowId={setActiveWorkflowId}
              connectionUid={props.connectionUid}
              channelDtoUid={initialValues.channelName?.value}
              integrationName={initialValues.integrationName}
              integrationVariables={initialValues?.integrationVariables}
              workflowData={findWorkFlowById(activeWorkflowId)}
              setCallDb={props.setCallDb}
              isInvalidAccess={props.isInvalidAccess}
              parsedMetaData={parsedMetaData}
            />
          ) : (
            <>
              {isWorkFlow.map((el: any) => {
                return (
                  <WorkFlowHeader
                    key={el.id}
                    id={el.id}
                    title={el.workFlowType}
                    description={"send template messages through whatsapp"}
                    setActiveWorkflowId={setActiveWorkflowId}
                    workflowData={findWorkFlowById(el.id)}
                    connectionUid={props.connectionUid}
                    channelDtoUid={initialValues.channelName?.value}
                    integrationName={initialValues.integrationName}
                    setCallDb={props.setCallDb}
                    isInvalidAccess={props.isInvalidAccess}
                    handleDeleteWorkflow={handleDeleteWorkflow}
                  />
                );
              })}

              {isWorkFlow.length < 6 && (
                <div
                  className={`d-flex justify-content-center mt-3 mb-5${
                    props?.isInvalidAccess ? "notAllowed" : "pointer"
                  } `}
                >
                  <div>
                    <button
                      onClick={
                        props?.isInvalidAccess
                          ? () => {}
                          : () => {
                              setActiveWorkflowId(1);
                            }
                      }
                      className="btn btn-primary d-flex justify-content-between"
                      disabled={props?.isInvalidAccess}
                    >
                      Create New WorkFlow
                    </button>
                    <small className="d-flex justify-content-center">
                      Max.5 Workflows allowed
                    </small>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default WebHook;
