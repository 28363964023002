import axios from "../utils/axios";
import { showAlert, sweetalert, toastAlert, toast } from "../common/alert";
import { components } from "react-select";

export const getAllChannels = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get("channel/getAll?uid=oKYl5mrJdVhcUQi2NHVaxPSk6sA2")
      .then((res) => {
        resolve(res);
      })
      .catch((err: any) => {
        toast("error", err.response.data.message);
      });
  });
};

export const draftPublish = (
  countryCode: any,
  data: any,
  id: string,
  uid: any
): Promise<any> => {


  return new Promise((resolve, reject) => {
    var countrycode = countryCode?.replace(/\+/g, "");
    var tempData: any = {};
    tempData = {
      name: data.name,
      showName: data.name,
      language: data.language,
      category: data.category.toUpperCase() === 'LIMITED'? 'MARKETING' : data.category.toUpperCase() ,
      allow_category_change: data.categoryChange,
      channelId: uid,
      templateStatus: "DRAFTPUBLISH",
      id: id,
      countryCode: countryCode ? countryCode : "+91",
      components: [],
      bodyColumns: [],
      media: {},
      headerColumns: []

    };
    if (data.components[0]?.format) {
      if (data.components[0]?.format !== "none") {
        const headerComponent: any = {
          type: "HEADER",
          format: data.components[0]?.format.toUpperCase(),
        };
        if (data.components[0]?.format !== "text") {
          if(data.options === 'location'){
            const locationDetail = {
                          "latitude": data.latitude,
                          "longitude": data.longitude,
                          "name": data.locName,
                          "address": data.locAddress, 
                          "label": data.locationLabel,
                          "isSearch": data.locationOption === 'search'? true : false
                 }
              tempData.headerColumns = [locationDetail];
              headerComponent.example  = {};
  
          }else{
          const example = {
            header_handle: [],
          };
          headerComponent.example = example;
        }
        } else {
          headerComponent.text = data.components[0].format
            ? data.components[1].text
            : data.components[0].text;
        }
        tempData.components.splice(0, 0, headerComponent);
      }
    }
    const bodyComponent: any = {
      type: "BODY",
    };
    if (data.components[0]?.example?.header_handle) {
      if (data.components[1].example?.body_text.length) {
        let count = 1,
          text = data.components[1].text;
        const replacedText = text.replace(/{{(.*?)}}/g, () => `{{${count++}}}`);
        bodyComponent.text = replacedText;
        const example = {
          body_text: [
            data.bodyColumns?.map(
              (variable: { value: string; key: string }) => variable.value
            ),
          ],
        };
        bodyComponent.example = example;
        data.bodyColumns?.map(
          (sampleVar: { value: string; key: string }, ind: number) => {
            tempData.bodyColumns.push({ key: ind + 1, value: sampleVar.value });
          }
        );
      } else {
        bodyComponent.text = data.components[0].format
          ? data.components[1].text
          : data.components[0].text;
      }
    } else {
      if (data.components[0].example?.body_text.length) {
        let count = 1,
          text = data.components[0].text;
        const replacedText = text.replace(/{{(.*?)}}/g, () => `{{${count++}}}`);
        bodyComponent.text = replacedText;
        const example = {
          body_text: [
            data.bodyColumns?.map(
              (variable: { value: string; key: string }) => variable.value
            ),
          ],
        };
        bodyComponent.example = example;
        data.bodyColumns?.map(
          (sampleVar: { value: string; key: string }, ind: number) => {
            tempData.bodyColumns.push({ key: ind + 1, value: sampleVar.value });
          }
        );
      } else {
        bodyComponent.text = data.components[0].format
          ? data.components[1].text
          : data.components[0].text;
      }
    }

    if (data.components[0]?.format) {
      tempData.components.splice(
        data.components[0]?.format !== "none" ? 1 : 0,
        0,
        bodyComponent
      );
    } else {
      tempData.components.push(bodyComponent);
    }

    // if (data.category !== "MARKETING") {
      const footerComponent = {
        type: "FOOTER",
        text: data.components[0].format
          ? data.components[2].text
          : data.components[1].text,
      };
      if (footerComponent.text) {
        tempData?.components?.splice(data.components[0]?.format !== 'none' ? 2 : 1, 0, footerComponent);
      }
      // } 
    // else {
    //   const footerComponent = {
    //     type: "FOOTER",
    //     text: "Reply with 'STOP' to unsubscribe from marketing messages",
    //   };
      // footerComponent.text?
      // tempData.components.splice(data.components[0]?.format !== 'none' ? 2 : 1, 0, footerComponent) : '';
    // }

    if (data.components[data.components.length - 1]?.buttons?.length) {
      const buttonsComponent: any = {
        type: "BUTTONS",
      };
      const buttons: any = [];
      if (
        data.components[data.components.length - 1]?.buttons[0].type ===
        "PHONE_NUMBER" ||
        data.components[data.components.length - 1]?.buttons[0].type === "URL"
      ) {
        if (data.components[data.components.length - 1]?.buttons[1]) {
          if (
            data.components[data.components.length - 1]?.buttons[0].type ===
            "PHONE_NUMBER"
          ) {
            buttons.push({
              type: "PHONE_NUMBER",
              text: data.components[data.components.length - 1]?.buttons[0]
                .text,
              phone_number:
                data.components[data.components.length - 1]?.buttons[0]
                  .phone_number,
            });
          }
          if (
            data.components[data.components.length - 1]?.buttons[1].type ===
            "URL"
          ) {
            if (
              data.components[data.components.length - 1]?.buttons[1].example
            ) {
              const Example = [
                data.components[data.components.length - 1]?.buttons[1]
                  .example[0],
              ];
              buttons.push({
                example: Example,
                type: "URL",
                text: data.components[data.components.length - 1]?.buttons[1]
                  .text,
                url: data.components[data.components.length - 1]?.buttons[1]
                  .url,
              });
            } else {
              buttons.push({
                type: "URL",
                text: data.components[data.components.length - 1]?.buttons[1]
                  .text,
                url: data.components[data.components.length - 1]?.buttons[1]
                  .url,
              });
            }
          }
          buttonsComponent.buttons = buttons;
        } else {
          if (
            data.components[data.components.length - 1]?.buttons[0].type ===
            "PHONE_NUMBER"
          ) {
            buttons.push({
              type: "PHONE_NUMBER",
              text: data.components[data.components.length - 1]?.buttons[0]
                .text,
              phone_number:
                data.components[data.components.length - 1]?.buttons[0]
                  .phone_number,
            });
          }
          if (
            data.components[data.components.length - 1]?.buttons[0].type ===
            "URL"
          ) {
            if (
              data.components[data.components.length - 1]?.buttons[0].example
            ) {
              const Example = [
                data.components[data.components.length - 1]?.buttons[0]
                  .example[0],
              ];
              buttons.push({
                example: Example,
                type: "URL",
                text: data.components[data.components.length - 1]?.buttons[0]
                  .text,
                url: data.components[data.components.length - 1]?.buttons[0]
                  .url,
              });
            } else {
              buttons.push({
                type: "URL",
                text: data.components[data.components.length - 1]?.buttons[0]
                  .text,
                url: data.components[data.components.length - 1]?.buttons[0]
                  .url,
              });
            }
          }
          buttonsComponent.buttons = buttons;
        }
      } else {
        data.components[data.components.length - 1].buttons.map(
          (btns: { text: string; type: string }) => {
            buttons.push({ type: "QUICK_REPLY", text: btns.text });
          }
        );
        buttonsComponent.buttons = buttons;
      }
      tempData.components.splice(
        tempData.components.length,
        0,
        buttonsComponent
      );
    }
    // data.components[0].example.header_handle?
    // tempData.components[0].example.header_handle.push(data.components[0].example.header_handle[0]) : '';
    if (tempData) {

      axios
        .post(`template/${id}/draftPublish`, tempData)
        .then(() => {
          toast("success", "Template Edited Successfully");
          resolve({ success: true });
        })
        .catch((err) => {
          toast("error", err.response.data.message);
        });
    }
  });
};

export const createTemplate = (
  submitType: string,
  countryCode: any,
  data: any,
  type: string,
  id: string,
  uid: any,
  singleTempData: any,
  loaderFunc: any,
  carouselMedia: any,
  carouselData: any,
  variable: any,
  countryCodeArr: any
): Promise<any> => {
  return new Promise((resolve, reject) => {
    var countrycode = countryCode?.replace(/\+/g, "");
    var tempData: any = {};

    console.log("apiformat mediaaaaaaaaaaaaaaaaaaaaaa", carouselData)
    const buttonCount =
      10 -
      ((data?.phoneChecked && data.btnOptions.includes("action") ? 1 : 0) +
        (data?.webUrlChecked && data.btnOptions.includes("action") ? 1 : 0) +
        (data?.webUrlChecked1 && data.btnOptions.includes("action") ? 1 : 0));

    if (data.category === 'carousel') {
      if (variable) {
        var variableArr = variable.map((variables: any, index: number) => {
          if (variables?.length) {
            return {
              card: index,
              variable: variables.map((v: any) => v.value)
            };
          }
        });
      }
      tempData = {
        name: data.templateName,
        showName: data.templateName,
        language: data.language,
        category: 'MARKETING',
        channelId: uid,
        templateStatus: submitType === "DRAFT" ? "DRAFT" : "SUBMIT",
        carouselMedia: carouselMedia,
        components: [],
        allow_category_change: data.categoryChange,
        carouselColumns: variable && variable?.length > 0 ? variableArr : null,
        buttonColumns: countryCodeArr && countryCodeArr?.length ? countryCodeArr : null,
        headerColumns: []
      }
    } else {
      if (type == "edit") {
        if (submitType === "DRAFT") {
          console.log("one")
          tempData = {
            name: data.templateName,
            showName: data.templateName,
            language: data.language,
            category: data.category.toUpperCase() === 'LIMITED'? 'MARKETING' : data.category.toUpperCase(),
            allow_category_change: data.categoryChange,
            channelId: uid,
            templateStatus: "DRAFT",
            id: id,
            countryCode: countryCode ? countryCode : "+91",
            components: [],
            bodyColumns: [],
            buttonColumns: data?.catalogImageUrl && data?.category === "catalog_marketing" ? [data.catalogImageUrl] : [],
            sectionColumns: data.sections ? data?.sections : [],
            templateTracking: data.trackLinks,
            retailerId: data?.catalogDropdown ? data?.catalogDropdown : "",
            isCatalog: data?.category === "catalog_marketing" ? true : false,
            isCoupon: data?.category === "coupon_marketing" ? true : false,
            media: singleTempData.media,
            headerColumns: []
          };
        } else if (submitType === "SUBMIT") {
          console.log("two")
          tempData = {
            name: data.templateName,
            showName: data.templateName,
            language: data.language,
            category:
              data.category === "catalog_marketing" || data.category === "coupon_marketing" || data.category === 'limited'
                ? "MARKETING"
                : data.category.toUpperCase(),
            allow_category_change: data.categoryChange,
            channelId: uid,
            templateStatus: "DRAFTPUBLISH",
            id: id,
            countryCode: countryCode ? countryCode : "+91",
            components: [],
            bodyColumns: [],
            buttonColumns: data?.catalogImageUrl && data?.category === "catalog_marketing" ? [data.catalogImageUrl] : [],
            sectionColumns: data.sections ? data?.sections : [],
            templateTracking: data.trackLinks,
            retailerId: data?.catalogDropdown ? data?.catalogDropdown : "",
            isCatalog: data?.category === "catalog_marketing" ? true : false,
            isCoupon: data?.category === "coupon_marketing" ? true : false,
            media: singleTempData.media,
            headerColumns: []
          };
        } else {
          tempData = {
            name: data.templateName,
            showName: data.templateName,
            language: data.language,
            category:
              data.category === "catalog_marketing" || data.category === "coupon_marketing" || data.category === 'limited'
                ? "MARKETING"
                : data.category.toUpperCase(),
            allow_category_change: data.categoryChange,
            channelId: uid,
            templateStatus: submitType,
            id: id,
            countryCode: countryCode ? countryCode : "+91",
            components: [],
            bodyColumns: [],
            buttonColumns: data?.catalogImageUrl && data?.category === "catalog_marketing" ? [data.catalogImageUrl] : [],
            sectionColumns: data.sections ? data?.sections : [],
            templateTracking: data.trackLinks,
            retailerId: data?.catalogDropdown ? data?.catalogDropdown : "",
            isCatalog: data?.category === "catalog_marketing" ? true : false,
            isCoupon: data?.category === "coupon_marketing" ? true : false,
            media: singleTempData.media,
            headerColumns: []
          };
        }
      } else {
        tempData = {
          name: data.templateName,
          showName: data.templateName,
          language: data.language,
          message_send_ttl_seconds: data?.ttlseconds,
          category:
            data.category === "catalog_marketing" || data.category === "coupon_marketing" || data.category === 'limited'
              ? "MARKETING"
              : data.category.toUpperCase(),
          allow_category_change: data.categoryChange,
          channelId: uid,
          templateStatus: submitType === "DRAFT" ? "DRAFT" : "SUBMIT",
          countryCode: countryCode ? countryCode : "+91",
          components: [],
          bodyColumns: [],
          sectionColumns: data.sections ? data?.sections : [],
          buttonColumns: data?.catalogImageUrl && data?.category === "catalog_marketing" ? [data.catalogImageUrl] : [],
          retailerId: data?.catalogDropdown ? data?.catalogDropdown : "",
          isCatalog: data?.category === "catalog_marketing" ? true : false,
          isCoupon: data?.category === "coupon_marketing" ? true : false,
          templateTracking: data.trackLinks,
          media: singleTempData.media,
          headerColumns: []
        };
      }
    }
    // else  if (type == 'edit' && submitType== 'SUBMIT') {
    //     tempData = {
    //         "name": data.templateName,
    //         "language": data.language,
    //         "category": data.category.toUpperCase(),
    //         "allow_category_change": data.categoryChange,
    //         "channelId": data.channel,
    //         "templateStatus": "SUBMIT",
    //         "id": id,
    //         "components": [

    //         ],
    //         bodyColumns: []
    //     }
    // }

    if (data.category === 'carousel') {
      // const cards = carouselData.map((e: any, ind: number) => {
      //   // Initialize the buttons array
      //   let buttons = [];

      //   // Handle buttonOne
      //   if (data.buttonOne === 'quickReply') {
      //     buttons.push({
      //       type: "QUICK_REPLY",
      //       text: e.buttonOne,
      //     });
      //   } else if (data.buttonOne === 'mobileNumber') {
      //     buttons.push({
      //       type: "PHONE_NUMBER",
      //       text: e.phoneInput1,
      //       phone_number: e.phoneInput2
      //     });
      //   } else if (data.buttonOne === 'weburl') {
      //     buttons.push({
      //       type: "URL",
      //       text: e.websiteName,
      //       url: e.webUrl,
      //       example: [
      //         e.webUrl
      //       ]
      //     });
      //   }

      //   // Handle buttonTwo
      //   if (data.buttonTwo === 'quickReply') {
      //     buttons.push({
      //       type: "QUICK_REPLY",
      //       text: e.buttonTwo,
      //     });
      //   } else if (data.buttonTwo === 'mobileNumber') {
      //     buttons.push({
      //       type: "PHONE_NUMBER",
      //       text: e.phoneInputTwo1,
      //       phone_number: e.phoneInputTwo2
      //     });
      //   } else if (data.buttonTwo === 'weburl') {
      //     buttons.push({
      //       type: "URL",
      //       text: e.websiteNameTwo,
      //       url: e.webUrlTwo,
      //       example: [
      //         e.webUrlTwo
      //       ]
      //     });
      //   }

      //   return {
      //     components: [
      //       {
      //         type: "HEADER",
      //         format: data.carouselType === 'image carousel'? 'IMAGE' : 'VIDEO',
      //         example: {
      //           header_handle: [
      //             carouselMedia[ind]?.url
      //           ]
      //         }
      //       },
      //       {
      //         type: "BODY",
      //         text: e.carouselBody,
      //         example: {
      //           body_text: [
      //             [
      //               "15OFF", // Assuming static values; replace with dynamic if needed
      //               "15%"
      //             ]
      //           ]
      //         }
      //       },
      //       {
      //         type: "BUTTONS",
      //         buttons: buttons
      //       }
      //     ]
      //   };
      // });

      const carouselStructure = {
        type: "CAROUSEL",
        // cards: carouselData.map((item:any) => item?.card)
        cards: carouselData.map((item: any) => {
          const card = { ...item.card }; // Clone the card object to avoid mutating the original data

          // Find the BODY component
          const bodyComponent = card.components.find((component: any) => component.type === "BODY");

          if (bodyComponent && bodyComponent.text) {
            let count = 1;
            const text = bodyComponent.text;

            // Replace the {{variables}} with incrementing numbers
            const replacedText = text.replace(/{{(.*?)}}/g, () => ` {{${count++}}} `);

            // Update the text in the BODY component
            bodyComponent.text = replacedText;
          }

          return card; // Return the modified card
        })
      };

      // Initialize tempData if not already initialized
      if (!tempData) {
        tempData = {};
      }

      // Initialize tempData.cards if not already initialized
      if (!tempData.cards) {
        tempData.components = [];
      }

      // Push the carousel structure into tempData.cards
      tempData.components.push(carouselStructure);

      // console.log(JSON.stringify(carouselStructure, null, 2));
    }


    // if(data.category !== 'carousel'){
    if (data.options !== "none") {
      const headerComponent: any = {
        type: "HEADER",
        format: data.options.toUpperCase(),
      };
      if (data.options !== "text") {
        if(data.options === 'location'){
          const locationDetail = {
                        "latitude": data.latitude,
                        "longitude": data.longitude,
                        "name": data.locName,
                        "address": data.locAddress, 
                        "label": data.locationLabel,
                        "isSearch": data.locationOption === 'search'? true : false
            }
         tempData.headerColumns = [locationDetail];
         headerComponent.example  = {};
        }else{
        const example = {
          header_handle: [],
        };
        headerComponent.example = example;
      }
      } else {
        headerComponent.text = data.content;
      }
      tempData.components.splice(0, 0, headerComponent);
    }

    if (data.category === "catalog_marketing" && data.catalogOption === "multi-product") {
      console.log("true", data.catalogheader)
      const headerComponent: any = {
        type: "HEADER",
        format: "text",

      };
      headerComponent.text = data.catalogheader;
      tempData.components.splice(0, 0, headerComponent);
      console.log("dfds", tempData);

    }

    const bodyComponent: any = {
      type: "BODY",
    };
    if (data.addVariables.length) {
      let count = 1,
        text = data.messageBody;
      const replacedText = text.replace(/{{(.*?)}}/g, () => `{{${count++}}}`);
      bodyComponent.text = replacedText;
      const example = {
        body_text: [
          data.addVariables.map(
            (variable: { name: string; id: string; value: string }) =>
              variable.value
          ),
        ],
      };
      bodyComponent.example = example;
      data.addVariables.map(
        (
          sampleVar: { name: string; id: string; value: string },
          ind: number
        ) => {
          tempData?.bodyColumns?.push({ key: ind + 1, value: sampleVar.name });
        }
      );
    } else if (data.category !== "authentication") {
      bodyComponent.text = data.messageBody;
    }
    else if (data.category === "authentication") {
      bodyComponent.add_security_recommendation = data.securitydisclaimer
    }
    tempData.components.splice(
      data.options !== "none" || data?.catalogheader ? 1 : 0,
      0,
      bodyComponent
    );
    // if (data.category !== "marketing") {

      if (data.footerMsg && data.category !== "authentication") {
        const footerComponent = {
          type: "FOOTER",
          text: data.footerMsg,
        };
        tempData.components.splice(
          data.options !== "none" ? 2 : 1,
          0,
          footerComponent
        );
      }
      if (data.category === "authentication" && data?.expirationwarning) {
        const footerComponent = {
          type: "FOOTER",
          code_expiration_minutes: parseInt(data?.timeIndicator)
        };
        tempData.components.splice(
          1,
          0,
          footerComponent
        );
      }
    // } 
    // else {
    //   const footerComponent = {
    //     type: "FOOTER",
    //     text: "Reply with 'STOP' to unsubscribe from marketing messages",
    //   };
    //   tempData.components.splice(
    //     data.options !== "none" ? 2 : 1,
    //     0,
    //     footerComponent
    //   );
    // }
      if(data.category === 'limited') {
        const limit = {
          "type": "LIMITED_TIME_OFFER",
          "limited_time_offer": {
          "text": data.limitedText, 
          "has_expiration": true
          }
          }
          tempData.components.splice(
            2,
            0,
            limit
          );
      }

    if (
      (data.btnOptions.includes("action")) ||
      data.btnOptions.includes("quickReply") || data.category === 'limited' ||
      data.btnOptions.includes("flow") ||
      data?.category === "catalog_marketing" || data?.category === "coupon_marketing" || data.category === "authentication"
    ) {
      console.log("kmm")
      const buttonsComponent: any = {
        type: "BUTTONS",
      };
      const buttons: any = [];

      console.log("checking data", data.catalogOption);

      if (data.category === "authentication") {
        tempData.buttonColumns.push(data.messageBody);
      }
      if (data.category === "authentication" && data?.securitydisclaimer && data?.timeIndicator) {
        tempData.buttonColumns.splice(1, 0, data?.footerMsg);
      }

      if (data.category === "catalog_marketing" && data.catalogOption === "catalogue") {
        buttons.push({
          type: "CATALOG",
          text: "View catalog",
        });
        buttonsComponent.buttons = buttons;
      }
      if (data?.category === "authentication" && data?.authButton && data?.authtextButton) {
        buttons.push(
          {
            type: "otp",
            otp_type: "copy_code",
            text: data.authtextButton
          }
        )
        buttonsComponent.buttons = buttons;

      }
      if (data.category === "catalog_marketing" && data.catalogOption === "multi-product") {
        buttons.push({
          type: "MPM",
          text: "View items",
        });
        buttonsComponent.buttons = buttons;
      }
      if (data.category === "coupon_marketing") {
        buttons.push(
          {
            type: "COPY_CODE",
            text: "Copy offer code",
            example: [data?.couponValue]
          }
        );
        buttonsComponent.buttons = buttons;

      }

      if (data.btnOptions.includes("action")) {
        if (data.phoneChecked) {
          buttons.push({
            type: "PHONE_NUMBER",
            text: data.phoneInput1,
            phone_number: countrycode
              ? countrycode + data.phoneInput2
              : "+91" + data.phoneInput2,
          });
          tempData.buttonColumns.push("PHONE_NUMBER");
        }
        if (data.webUrlChecked) {
          if (data.webUrlDropdown === "dynamicUrl") {
            const Example = [data.variable];
            tempData.buttonColumns.push("URL1");
            buttons.push({
              example: Example,
              type: "URL",
              text: data.websiteName,
              url: data.webUrl,
            });
          } else {
            buttons.push({
              type: "URL",
              text: data.websiteName,
              url: data.webUrl,
            });
          }
        }
        if (data.webUrlChecked1) {
          if (data.webUrlDropdown1 === "dynamicUrl") {
            const Example = [data.variable1];
            tempData.buttonColumns.push("URL2");
            buttons.push({
              example: Example,
              type: "URL",
              text: data.websiteName1,
              url: data.webUrl1,
            });
          } else {
            buttons.push({
              type: "URL",
              text: data.websiteName1,
              url: data.webUrl1,
            });
          }
        }
        buttonsComponent.buttons = buttons;
      }
      if (data.btnOptions.includes("quickReply")) {
        data.replyButtons
          .slice(0, buttonCount)
          .map(
            (btns: {
              label: string;
              name: string;
              id: string;
              value: string;
            }) => {
              buttons.push({ type: "QUICK_REPLY", text: btns.value });
            }
          );
        buttonsComponent.buttons = buttons;
      }

      if (data.category === 'limited'){
        const buttons =  []

        if (data?.limitDropDown === 'staticUrl') {
          tempData.buttonColumns.push("URL1");
          console.log("limited temp")
          buttons.push({
            "type": "COPY_CODE",
            "example": [data.limitedCode]
          },{
          "type": "URL",
          "text": data?.limitedButton,
          "url": data?.limitedUrl,
        });
      } else {
        tempData.buttonColumns.push("URL1");
        tempData.buttonColumns.push("URL2");
          buttons.push({
            "type": "COPY_CODE",
            "example": [data.limitedCode]
          },{
            "type": "URL",
            "text": data?.limitedButton,
            "url": data?.limitedUrl,
            "example": [
              data?.limitVar
            ]
          });
      }
      buttonsComponent.buttons = buttons;
      }

      if (data.btnOptions.includes("flow")){
        const buttons =  {
          "type": "FLOW",
          "text": data?.flowText,
          "flow_id": data?.flowSelect?.value,
          "flow_action": "navigate", 
          "navigate_screen": data?.flowSelect?.screen 
          }
        buttonsComponent.buttons = [buttons];
      }

      tempData.components.splice(
        tempData.components.length,
        0,
        buttonsComponent
      );
    }
    // }

    if (data.options !== "text" && data.options !== "none" && data.options !==  'location') {
      if (data.file.image) {
        const apiUrl = `media/saveMessageMedia?uid=${uid}&name=${data.templateName}`;
        const formData = new FormData();
        formData.append("content", data.file.image);
        axios
          .post(apiUrl, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response: any) => {
            if (response.data) {
              tempData.components[0]?.example?.header_handle.push(
                response.data.url
              );
              tempData.media = response.data;
              if (type === "edit") {
                if(submitType === 'DRAFT'){
                axios
                  .patch(`template/${id}/draftEdit`, tempData)
                  .then(() => {
                    loaderFunc(false, "draft");
                    toast("success", "Template Edited Successfully");
                    resolve({ success: true });
                  })
                  .catch((err) => {
                    loaderFunc(false, "draft");
                    toast("error", err.response.data.message);
                  });
                } else if (submitType === "SUBMIT") {
                  axios
                    .post(`template/${id}/draftPublish`, tempData)
                    .then(() => {
                      loaderFunc(false, "draft");
                      toast("success", "Template Edited Successfully");
                      resolve({ success: true });
                    })
                    .catch((err) => {
                      loaderFunc(false, "draft");
                      toast("error", err.response.data.message);
                    });
                } else {
                  console.log("edit1", data);
                  axios
                    .patch(`template/${id}/edit`, tempData)
                    .then(() => {
                      loaderFunc(false, "approval");
                      toast("success", "Template Edited Successfully");
                      resolve({ success: true });
                    })
                    .catch((err) => {
                      loaderFunc(false, "approval");
                      toast("error", err.response.data.message);
                    });
                }
              } else {
                axios
                  .post("template/createTemplate", tempData)
                  .then(() => {
                    loaderFunc(false, "create");
                    toast("success", "Template Created successfully");
                    resolve({ success: true });
                  })
                  .catch((err) => {
                    loaderFunc(false, "create");
                    toast("error", err.response.data.message);
                  });
              }
            }
          })
          .catch((error) => {
            toast("error", error.response.data.message);
          });
      } else {
        tempData.components[0]?.example?.header_handle.push(data.file.imgUrl);
        if (type === "edit") {
          if (submitType === "DRAFT") {
            axios
              .patch(`template/${id}/draftEdit`, tempData)
              .then(() => {
                loaderFunc(false, "draft");
                toast("success", "Template Edited Successfully");
                resolve({ success: true });
              })
              .catch((err) => {
                loaderFunc(false, "draft");
                toast("error", err.response.data.message);
              });
          } else if (submitType === "SUBMIT") {
            axios
              .post(`template/${id}/draftPublish`, tempData)
              .then(() => {
                loaderFunc(false, "draft");
                toast("success", "Template Edited Successfully");
                resolve({ success: true });
              })
              .catch((err) => {
                loaderFunc(false, "draft");
                toast("error", err.response.data.message);
              });
          } else {
            console.log("edit1", data);
            axios
              .patch(`template/${id}/edit`, tempData)
              .then(() => {
                loaderFunc(false, "approval");
                toast("success", "Template Edited Successfully");
                resolve({ success: true });
              })
              .catch((err) => {
                loaderFunc(false, "approval");
                toast("error", err.response.data.message);
              });
          }
        } else {
          axios
            .post("template/createTemplate", tempData)
            .then(() => {
              loaderFunc(false, "create");
              toast("success", "Template Created successfully");
              resolve({ success: true });
            })
            .catch((err) => {
              loaderFunc(false, "create");
              toast("error", err.response.data.message);
            });
        }
      }
    } else {
      if (type === "edit") {
        if (submitType === "DRAFT") {
          axios
            .patch(`template/${id}/draftEdit`, tempData)
            .then(() => {
              loaderFunc(false, "draft");
              toast("success", "Template Edited Successfully");
              resolve({ success: true });
            })
            .catch((err) => {
              loaderFunc(false, "draft");
              toast("error", err.response.data.message);
            });
        } else if (submitType === "SUBMIT") {
          axios
            .post(`template/${id}/draftPublish`, tempData)
            .then(() => {
              loaderFunc(false, "submit");
              toast("success", "Template Edited Successfully");
              resolve({ success: true });
            })
            .catch((err) => {
              loaderFunc(false, "submit");
              toast("error", err.response.data.message);
            });
        } else {
          axios
            .patch(`template/${id}/edit`, tempData)
            .then(() => {
              loaderFunc(false, "approval");
              toast("success", "Template Edited Successfully");
              resolve({ success: true });
            })
            .catch((err) => {
              loaderFunc(false, "approval");
              toast("error", err.response.data.message);
            });
        }
      } else {
        axios
          .post("template/createTemplate", tempData)
          .then(() => {
            loaderFunc(false, "draft");
            toast("success", "Template Created successfully");
            resolve({ success: true });
          })
          .catch((err) => {
            loaderFunc(false, "draft");
            loaderFunc(false, "create");
            toast("error", err.response.data.message);
          });
      }
    }
  });
};

export const getAllTemplates = async (payload: { channelId: string, page: number, limit: number, search: string | null }) => {
  try {
    const templatedata = await axios.post('template/getAllTemplates', payload);
    return templatedata.data;
  }
  catch (err:any) {
    return err.response;
  }
};

export const syncTemplates = (uid: string, alert: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`template/syncTemplates?uid=${uid}`)
      .then((res) => {
        if (alert === "true") {
          resolve(res);
        }
      })
      .catch((err) => {
        if (alert === "true") {
          toast("error", err.response.data.message);
        }
      });
  });
};

export const deleteTemplate = (id: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    sweetalert(
      "warning",
      "Are you sure you want to delete?",
      () => {
        axios
          .delete(`template/${id}/delete`)
          .then((res) => {
            toast("error", "");
            resolve(res);
          })
          .catch((err) => {
            toastAlert("top-end", 'The template cannot be deleted as it is associated with other modules.', "error");
          });
      },
      () => { }
    );

    //     axios.delete(`template/${id}/delete?templateId=${id}`).then(res => {
    //         showAlert('error', '');
    //         resolve(res);
    //     }).catch(err => {
    //         showAlert('error', err.message);
    //     })
  });
};

export const saveMedia = async (
  wid_logo: any,
  wid_name: string,
  channelId: string
) => {
  try {
    //   const apiUrl = `media/saveMedia?uid=${channelId}&name=${wid_name}`;
    const apiUrl = `media/saveMessageMedia?uid=${channelId}&name=${wid_name}`;
    const formData = new FormData();
    formData.append("content", wid_logo);
    const response = await axios.post(apiUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    //  const response =   await axios.patch(`channel/${channelId}/widget`,widgetData,config)
    return response.data;
  } catch (error: any) {
    toast("error", error.message);
  }
};

export const defaultTemplates = (payload: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`template/getDefaultTemplates`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  });
};

// ---------------- Template Analytics --------------------//
export const getTemplateTrackingAnalytics = async (ids: any, payload: any) => {
  try {
    const { templateId, channelUid } = ids;
    const response = await axios.post(
      `template/${channelUid}/${templateId}/getTemplateTrackingAnalytics`, payload
    );
    return response.data;
  } catch (error: any) {

  }
};

export const getTemplateTrackingRecords = async (ids: any, date: any) => {
  try {
    const { templateId, channelUid } = ids;
    const response = await axios.post(
      `template/${channelUid}/${templateId}/getTemplateTrackingRecords`, { date }
    );
    return response.data;
  } catch (error: any) {

  }
};