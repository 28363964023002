import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {Button } from 'reactstrap';
import Select from "react-select";
import {setConfiguration, getConfiguration } from './apiService';
import { useSelector } from 'react-redux';
import SwitchButton from "../Contacts/SwitchButton";
import CommonPopover from '../../common/commonPopover';
import QuickReplies from '../../common/quickReplies';
import {InputGroup} from 'react-bootstrap';
import './autodesign.scss';
import * as Yup from 'yup';
interface SelectBtnOption {
    value: string;
    label: string;
  }

interface ChatInboxProps {
    isInvalidAccess: boolean;
}

const ChatInbox: React.FC<ChatInboxProps> = ({isInvalidAccess}) => {
  const [activeTab, setActiveTab] = useState<string | null>('working_hours');
  const [configureData, setConfigurationData] = useState<any>([]);
  const [workActive, setWorkActive] = useState<boolean>(false);
  const [welcomeActive, setWelcomeActive] = useState<boolean>(false);
  const [delayActive, setDelayActive] = useState<boolean>(false);
  const [followActive, setFollowActive] = useState<boolean>(false);
  const [emailActive, setEmailActive] = useState<boolean>(false);
  const [spamActive, setSpamActive] = useState<boolean>(false);
  const [options, setoptions] = useState<any>([]);
  const [quickPopoverOpen, setQuickPopoverOpen] = useState(false);
  const quickReplies = useSelector((state: any) => state.cartreducer.quickReplies);
  const profileDetail:any = useSelector ((state: any) => state.cartreducer.business);
  const [day, setDay] = useState<any>(); 
  const [timeArray, setTimeArray] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [timers, setTimers] = useState([{ id: 1 }]);
  const [truevalues, setTrueValue] = useState<string[]>([]);
  const [workMsg, setWorkMsg] = useState<any>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const days =[
    {value:'Monday' , label: 'Monday'},
    {value:'Tuesday' , label: 'Tuesday'},
    {value:'Wednesday' , label: 'Wednesday'},
    {value:'Thursday' , label: 'Thursday'},
    {value:'Friday' , label: 'Friday'},
    {value:'Saturday' , label: 'Saturday'},
    {value:'Sunday' , label: 'Sunday'}
  ]

  const toggleQuickPopover = () => {
    setQuickPopoverOpen(!quickPopoverOpen);
 };

const getQuickReply = (quickres: any, setFieldValue: (field: string, value: any)=>void) => {
  if(activeTab === 'working_hours'){
    setWorkMsg(quickres.name)
    setFieldValue('workReply', quickres.name);
  }else if(activeTab === 'welcoming_message'){
    setFieldValue('welcomeReply', quickres.name)
  }else if(activeTab === 'delayed_message'){
    setFieldValue('delayReply', quickres.name);
  }else if(activeTab === 'follow_up_message'){
    setFieldValue('followReply', quickres.name);
  }else if(activeTab === 'spam'){
    setFieldValue('spamReply', quickres.name);
  }
  setQuickPopoverOpen(false);
}

const quickpop =() =>{
  setQuickPopoverOpen(true);
}

let channel :any = useSelector ((state: any) => state.cartreducer.channelUid);
  const selectQuickReply: SelectBtnOption[] = options.map((item : any) => ({
    value: item.id.toString(),
    label: item.name
 }));

useEffect(()=> {
    if(channel.value){
        getConfiguration(channel.value)
        .then(data => {
          setConfigurationData(data.data);
          setTimeArray(data.data.timeArray)
          setCount(data.data.timeArray?.length);
          setWorkActive(data.data.workingTimeActive);
          setWelcomeActive(data.data.welcomeMessageActive);
          setDelayActive(data.data.autoReplyActive);
          setFollowActive(data.data.reEngagementActive);
          setEmailActive(data.data.emailActive);
          setSpamActive(data.data.spamActive);
          setWorkMsg(quickValue(data?.data?.workingTimeCannedResponseId));
        })
        .catch(error => {
            console.error('Error:', error);
        });
        }
},[channel])


useEffect(() => {
  const handleResize = () => setWindowWidth(window.innerWidth);
  window.addEventListener('resize', handleResize);

  // Cleanup function to remove the event listener
  return () => window.removeEventListener('resize', handleResize);
}, []);


let truevalue:any = [];
const tabshowing =()=>{
  if (configureData.workingTimeActive === true) {
    truevalue.push('Working Hours');
  } if (configureData.welcomeMessageActive === true) {
    truevalue.push('Welcome Message');
  } if (configureData.autoReplyActive === true) {
    truevalue.push('Delayed Response Message');
  }  if (configureData.reEngagementActive === true) {
    truevalue.push('Follow Up Message');
  } if (configureData.emailActive === true) {
    truevalue.push('Email');
  } if (configureData.spamActive === true) {
    truevalue.push('Spam');
  }
  setTrueValue(truevalue)
}
useEffect(()=> {
  tabshowing();
},[configureData])

 const quickValue = (id : any)=> {
 const value = quickReplies?.cannedReponseList?.find((item:any) => item.id === id);
   return value?.name;
}

const quickId = (name: any)=> {
 const value = quickReplies?.cannedReponseList?.find((item:any) => item.name === name);
  return value?.id;
}

const convertTime = (time: number, type: string) => {
    if (type === 'milliSec') {
     return time * 60000; 
    } else if (type === 'minute') {
      return time / 60000;
    } else {
      throw new Error('Invalid conversion type');
    }
  };

const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  const switchActive = (check: boolean) => {
    if(activeTab === 'working_hours'){
      setWorkActive(check)
    }else if(activeTab === 'welcoming_message'){
      setWelcomeActive(check)
    }else if(activeTab === 'delayed_message'){
      setDelayActive(check)
    }else if(activeTab === 'follow_up_message'){
      setFollowActive(check)
    }else if(activeTab === 'email'){
      setEmailActive(check)
    }else if(activeTab === 'spam'){
      setSpamActive(check)
    }
  };

  const handleSubmit = (values: any) => {
    if(activeTab === 'working_hours'){
    configureData.workingTimeActive = workActive;
    configureData.workingTimeCannedResponseId = quickId(values.workReply)
    configureData.timeArray = values.timeArray
    }else
    if(activeTab === 'welcoming_message'){
    configureData.welcomeMessageActive = welcomeActive;
    configureData.welcomeMessageCannedResponseId = quickId(values.welcomeReply);
  }else
  if(activeTab === 'delayed_message'){
    configureData.autoReplyActive = delayActive;
    configureData.autoReplyCannedResponseId = quickId(values.delayReply);
    configureData.autoReplyResponseTime = convertTime(values.delayMinute, 'milliSec');
  }else
  if(activeTab === 'follow_up_message'){
    configureData.reEngagementActive = followActive;
    configureData.reEngagementCannedResponseId = quickId(values.followReply);
    configureData.reEngagementResponseTime = convertTime(values.followMinute, 'milliSec');
  }else
  if(activeTab === 'email'){
    configureData.email = values.email;
    configureData.emailActive = emailActive;
  }else
 if(activeTab === 'spam'){
    configureData.spamActive = spamActive;
    configureData.spamCannedResponseId = quickId(values.spamReply);
    configureData.spamResponseTime = convertTime(values.spamMinute, 'milliSec');;
  }
   
  !isInvalidAccess && setConfiguration(channel.value, configureData)
        .then(data => {
            console.log('Response data:', data);
        })
        .catch(error => {
            console.error('Error:', error);
        });
   tabshowing();
  };

  var validationSchema;
  if(activeTab === 'working_hours'){
     validationSchema = Yup.object().shape({
      workReply: Yup.string().required('Message is required'),
      timeArray: Yup.array().of(
        Yup.object().shape({
          day: Yup.string().required('Select day'),
          fromTime: Yup.string().required('Enter start time'),
          toTime: Yup.string()
            .required('Enter end time')
            .test('is-valid-time', 'Invalid time', function (toTime) {
              const fromTime = this.parent.fromTime;
              if (fromTime && toTime) {
                return fromTime < toTime;
              }
              return true;
            }),
        })
      ).test('unique-times', 'Some times are the same', function (timeArray) {
        // Convert the timeArray into an array of JSON strings
        const timeEntriesJSON = timeArray?.map((timeEntry) => JSON.stringify(timeEntry));
        // Check for duplicates in the array of JSON strings
        const uniqueTimeEntries = new Set(timeEntriesJSON);
        return uniqueTimeEntries.size === timeArray?.length;
      }),
    });
    
}else if(activeTab === 'welcoming_message'){
  validationSchema = Yup.object().shape({
    welcomeReply: Yup.string()
    .required('Message is required'),
  });
}else if(activeTab === 'delayed_message'){
  validationSchema = Yup.object().shape({
    delayReply: Yup.string()
    .required('Message is required'),
  delayMinute: Yup.number()
    .required('Please enter the time period')
    .max(1414, 'Time period must be less than or equal to 1414')
    .min(1, 'Time period must be greater than 0'),
  });
}else if(activeTab === 'follow_up_message'){
  validationSchema = Yup.object().shape({
    followReply: Yup.string()
    .required('Message is required'),
  followMinute: Yup.number()
    .required('Please enter time period')
    .max(1414, 'Time period must be less than or equal to 1414')
    .min(1, 'Time period must be greater than 0'),
  });
}else if(activeTab === 'email'){
  validationSchema = Yup.object().shape({
    email: Yup.string()
    .required('Email is required')
    .email('Enter valid email'),
  });
}else if(activeTab === 'spam'){
  validationSchema = Yup.object().shape({
    spamReply: Yup.string()
    .required('Message is required'),
  spamMinute: Yup.number()
    .required('Please enter time period')
    .max(1414, 'Time period must be less than or equal to 1414'),
  });
}

// addtime
// const handleAddTimer = () => {
//   timeArray.push({day:'', fromTime:'', toTime:''})
//   setCount(timeArray?.length + 1);
//   setTimers([...timeArray, { id: timeArray?.length + 1 }]);
// };
const handleAddTimer = (setFieldValue: any) => {
  const newTimer = { day: '', fromTime: '', toTime: '' };
  if (timeArray === null) {
  setTimeArray([newTimer]);
  setCount(timeArray?.length + 1);
  setTimers([{ id: 1 }]);
  } else {
  setTimeArray([...timeArray, newTimer]);
  setCount(timeArray?.length + 1); 
  setTimers([...timeArray, { id: timeArray?.length + 1 }]);
  }
}

const handleDeleteTimer = (timerId: number) => {
  timeArray.splice(timerId,1)
  setTimeArray(timeArray)
  setCount(timeArray?.length)
};

const handleFieldChange = (index : any, fieldName: string, value: any) => {
  const updatedTimeArray = [...timeArray];
  updatedTimeArray[index] = {
    ...updatedTimeArray[index],
    [fieldName]: value,
  };
  setTimeArray(updatedTimeArray);
};

const disableFields = (tab:any) => {
if(tab === 'Working Hours'){
  setWorkActive(false);
  configureData.workingTimeActive = false;
}if(tab === 'Welcome Message'){
  setWelcomeActive(false);
  configureData.welcomeMessageActive = false;
}if(tab === 'Delayed Response Message'){
  setDelayActive(false);
  configureData.autoReplyActive = false;
}if(tab === 'Follow Up Message'){
  setFollowActive(false);
  configureData.reEngagementActive = false;
}if(tab === 'Email'){
  setEmailActive(false);
  configureData.emailActive = false;
}if(tab === 'Spam'){
  setSpamActive(false);
  configureData.spamActive = false;
}
  setConfiguration(channel.value, configureData)
    .then(data => {
      console.log('Response data:', data);
        })
     .catch(error => {
      console.error('Error:', error);
        });
   tabshowing();
}

return (
  <div>
   <h6 className='mt-4 labelcolor'>Chat Inbox Configuration</h6>
   <p className='infotext'>Apply these configurations when there is no assigned bot flow for the chat.</p>
      <div className="navbar mt-3 col-xl-8">
        <div
          className={`tab-nav-item ${activeTab === 'working_hours' ? 'active' : ''}`}
          onClick={() => handleTabClick('working_hours')}>Working Hours</div>
        <div
          className={`tab-nav-item ${activeTab === 'welcoming_message' ? 'active' : ''}`}
          onClick={() => handleTabClick('welcoming_message')}>Welcome Message</div>
        <div
          className={`tab-nav-item ${activeTab === 'delayed_message' ? 'active' : ''}`}
          onClick={() => handleTabClick('delayed_message')}>Delayed Response Message</div>
        <div
          className={`tab-nav-item ${activeTab === 'follow_up_message' ? 'active' : ''}`}
          onClick={() => handleTabClick('follow_up_message')}>Follow up Message</div>
        <div
          className={`tab-nav-item ${activeTab === 'email' ? 'active' : ''}`}
          onClick={() => handleTabClick('email')}>Email</div>
        {/* <div
          className={`tab-nav-item ${activeTab === 'spam' ? 'active' : ''}`}
          onClick={() => handleTabClick('spam')}>Spam</div> */}
      </div>
  { truevalues.map((e: any) => (
  <div className='tabshow' key={e}> <div onClick={()=>disableFields(e)}>{e}<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M15 5L5 15" stroke="#3B3E45" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5 5L15 15" stroke="#3B3E45" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  </div>
  </div>
  )) }

 <hr/>
<br/>
      <div className="content">
      <Formik
      initialValues={{
        delayMinute: convertTime(configureData.autoReplyResponseTime, 'minute'),
        followMinute: convertTime(configureData.reEngagementResponseTime, 'minute'),
        email: configureData.email,
        spamMinute: convertTime(configureData.spamResponseTime, 'minute'),
        workReply: workMsg? workMsg : '',
        welcomeReply: configureData.welcomeMessageCannedResponseId? quickValue(configureData.welcomeMessageCannedResponseId) : '',
        delayReply: configureData.autoReplyCannedResponseId? quickValue(configureData.autoReplyCannedResponseId) : '',
        followReply: configureData.reEngagementCannedResponseId? quickValue(configureData.reEngagementCannedResponseId) : '',
        spamReply: configureData.spamCannedResponseId? quickValue(configureData.spamCannedResponseId) : '',
        timeArray: timeArray
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      >

 {({ values, setFieldValue }) => (
  <Form>
 {activeTab === 'working_hours' && <div>
  <div className='d-flex'>
  <p className='labelstyle'>Working Hours</p>
  <span className='switchBtn'> <SwitchButton whatsappOptState={switchActive} checked={workActive} menuName={'automation'}/> </span>
  </div>
  <p className='text-bold'>Establish your designated working hours and configuration an out of office message.</p>
  <div className='mt-4 mb-3'>
  <label className='smalllabel ml-3'>Quick Replies</label>
  <CommonPopover isOpen={quickPopoverOpen}  target="quickRep" toggle={toggleQuickPopover} placement='bottom'
  component='conversation'>
  <QuickReplies component='conversation' getQuickReply={(quickres: any) => getQuickReply(quickres, setFieldValue)}></QuickReplies>
  </CommonPopover>
   <div className='d-flex'>
  <InputGroup>
  <InputGroup.Text className='iconBg cursor-pointer'>
     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 11" fill="none"
      onClick={() => quickpop} id='quickRep' className='ml-1'>
      <ellipse cx="5" cy="5.125" rx="5" ry="5.125" fill="#90939F" />
      <path d="M0.549238 10.1115H4.57701L1.93815 8.86483L1.3826 8.72632L0.688127 9.41889C0.132571 9.97294 0.364053 10.1115 0.549238 10.1115Z" fill="#90939F" stroke="#90939F" stroke-width="0.5" />
      <path d="M5.96819 2.07751L5.8293 2.21602L2.91263 5.1248C2.69041 5.45723 2.91263 5.54034 3.05152 5.54034H4.30152L3.60708 8.03359C3.49597 8.58764 3.74597 8.44913 3.88486 8.31061L6.94041 5.26332C7.16263 5.04169 7.033 4.89395 6.94041 4.84778H5.55152L6.24597 2.21602C6.24597 1.9944 6.06078 2.03133 5.96819 2.07751Z" fill="white" stroke="white" stroke-width="0.5" />
      </svg>
  </InputGroup.Text>
  <Field type='text' name='workReply' placeholder='select reply' className='form-control col-3'></Field>
  <button type='button' className='signUpBtn ml-5 mb-3 p-1 col-4 col-sm-3 col-xl-1 rounded' onClick={() => handleAddTimer(setFieldValue)}>Add Time</button>
  </InputGroup>
      </div>
  <ErrorMessage name="workReply" component="div" className="text-danger ml-3" />
     </div>
     
    {values.timeArray && values.timeArray.map((timer:any, index:any) => (
      <div key={timer.id} className='timer ml-3 mt-4 rounded' id="rowTime">
        <div  className='d-flex flex-column flex-sm-row'>
          <div className='col-5 cursor-pointer' id="daySelect">
          <Field name={`timeArray[${index}].day`}  value={timeArray[index].day}>
            {({ field, form }: any) => (
              <Select
              {...field}
              options={days} 
              id="daysSelect"
              className="mt-1 mr-4 cursor-pointer"  
              value={days.find(dayOption => dayOption.value === timer.day)}
              onChange={(event: any) => {
                form.setFieldValue(`days[${index}]`, event); 
                handleFieldChange(index, 'day', event.label);
              }}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9800 }),
                menu: base => ({
                  ...base,
                  width: window.innerWidth <= 580 ? 120 : (window.innerWidth <= 780 ? 120 : 160)
                })
              }}
            />
            )}
          </Field>
          <ErrorMessage name={`timeArray[${index}].day`} component="div" className="text-danger" />
          </div>
          <div className="cs-form mr-4 mt-1" id="timeSelect">
            <Field
              type="time"
              className="form-control cursor-pointer"
              name={`timeArray[${index}].fromTime`}
              value={timeArray[index].fromTime || ''} 
              onChange={(e: any) => {
              handleFieldChange(index, 'fromTime', e.target.value);
            }}
            />
          <ErrorMessage name={`timeArray[${index}].fromTime`} component="div" className="text-danger" />
          
          </div>
          <div className="cs-form mt-1" id="timeSelect">
            <Field
              type="time"
              className="form-control cursor-pointer"
              name={`timeArray[${index}].toTime`}
              value={timeArray[index].toTime }
              onChange={(e: any) => {
                handleFieldChange(index, 'toTime', e.target.value);
              }}
            />
            <ErrorMessage name={`timeArray[${index}].toTime`} component="div" className="text-danger" />
          </div>
          <div className='delete mt-2 bg-transparent' onClick={() => handleDeleteTimer(index)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
          <circle cx="10.5" cy="10.9199" r="10" fill="#29caaf"/>
          <path d="M4.5 10.9199H16.5" stroke="white" stroke-linecap="round"/>
          </svg>
          </div>
        </div>
      </div>
    ))}

      {values.timeArray?.length? '' : <div className="text-danger ml-3">Add atleast one time period</div>}
  </div>}

 {activeTab === 'welcoming_message' && <div>
  <div className='d-flex'>
  <p className='labelstyle'>Welcome Message</p>
  <span className='switchBtn'><SwitchButton whatsappOptState={switchActive} checked={welcomeActive} menuName={'automation'}/></span>
  </div> <p className='text-bold'>Greet your customers with automated message.</p>
  <div className='mt-4'>
  <label className='smalllabel ml-3'>Quick replies</label>
  <CommonPopover isOpen={quickPopoverOpen}  target="quickRep" toggle={toggleQuickPopover} placement='bottom'
    component='conversation'>
  <QuickReplies component='conversation' getQuickReply={(quickres: any) => getQuickReply(quickres, setFieldValue)}></QuickReplies>
  </CommonPopover>
  <InputGroup>
  <InputGroup.Text className='iconBg cursor-pointer'>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 11" fill="none"
      onClick={() => quickpop} id='quickRep' className='ml-1'>
      <ellipse cx="5" cy="5.125" rx="5" ry="5.125" fill="#90939F" />
      <path d="M0.549238 10.1115H4.57701L1.93815 8.86483L1.3826 8.72632L0.688127 9.41889C0.132571 9.97294 0.364053 10.1115 0.549238 10.1115Z" fill="#90939F" stroke="#90939F" stroke-width="0.5" />
      <path d="M5.96819 2.07751L5.8293 2.21602L2.91263 5.1248C2.69041 5.45723 2.91263 5.54034 3.05152 5.54034H4.30152L3.60708 8.03359C3.49597 8.58764 3.74597 8.44913 3.88486 8.31061L6.94041 5.26332C7.16263 5.04169 7.033 4.89395 6.94041 4.84778H5.55152L6.24597 2.21602C6.24597 1.9944 6.06078 2.03133 5.96819 2.07751Z" fill="white" stroke="white" stroke-width="0.5" />
    </svg>
  </InputGroup.Text>
  <Field type='text' name='welcomeReply' placeholder='select reply' className='form-control col-3'></Field>
  </InputGroup>
   <ErrorMessage name="welcomeReply" component="div" className="text-danger ml-3" />
  </div>    
  </div>}

  {activeTab === 'delayed_message' && <div>
    <div className='d-flex'>
    <p className='labelstyle' id="delayed">Delayed Response Message</p>
    <span className='switchBtn' id="switchButton"><SwitchButton whatsappOptState={switchActive} checked={delayActive} menuName={'automation'}/></span>
    </div>
    <p className='text-bold'>Configure quick replies when the user has not engaged for a period of time.</p>
    <div className='mt-4'>
    <label className='smalllabel ml-3'>Quick replies</label>
     <CommonPopover isOpen={quickPopoverOpen}  target="quickRep" toggle={toggleQuickPopover} placement='bottom'
      component='conversation'>
     <QuickReplies component='conversation' getQuickReply={(quickres: any) => getQuickReply(quickres, setFieldValue)}></QuickReplies>
      </CommonPopover>
      <InputGroup>
      <InputGroup.Text className='iconBg cursor-pointer'>
     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 11" fill="none"
      onClick={() => quickpop} id='quickRep' className='ml-1'>
      <ellipse cx="5" cy="5.125" rx="5" ry="5.125" fill="#90939F" />
      <path d="M0.549238 10.1115H4.57701L1.93815 8.86483L1.3826 8.72632L0.688127 9.41889C0.132571 9.97294 0.364053 10.1115 0.549238 10.1115Z" fill="#90939F" stroke="#90939F" stroke-width="0.5" />
      <path d="M5.96819 2.07751L5.8293 2.21602L2.91263 5.1248C2.69041 5.45723 2.91263 5.54034 3.05152 5.54034H4.30152L3.60708 8.03359C3.49597 8.58764 3.74597 8.44913 3.88486 8.31061L6.94041 5.26332C7.16263 5.04169 7.033 4.89395 6.94041 4.84778H5.55152L6.24597 2.21602C6.24597 1.9944 6.06078 2.03133 5.96819 2.07751Z" fill="white" stroke="white" stroke-width="0.5" />
      </svg>
      </InputGroup.Text>
     <Field type='text' name='delayReply' placeholder='select reply' className='form-control col-3'></Field>
      </InputGroup>
      <ErrorMessage name="delayReply" component="div" className="text-danger ml-3" />
          </div>
           <div className='mt-2 margin-bottom'>
           <label className='smalllabel ml-3'>Minutes</label>
    <Field type='text' name='delayMinute' className='form-control fieldwidth' placeholder="enter minutes" maxLength={4}
       onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
       const value = e.target.value.replace(/[^\d]/g, '');
       setFieldValue('delayMinute', value);
      }}></Field>        
    <ErrorMessage name="delayMinute" component="div" className="text-danger ml-3" />  
   </div>      
 </div>}

 {activeTab === 'follow_up_message' && <div>
  <div className='d-flex'>
  <p className='labelstyle'>Follow up Message</p>
  <span className='switchBtn'><SwitchButton whatsappOptState={switchActive} checked={followActive} menuName={'automation'} /></span>
   </div>
  <p className='text-bold'>Automate re-engagement message when user haven’t responded in while.</p>
   <div className='mt-4'>
  <label className='smalllabel ml-3'>Quick replies</label>
  <CommonPopover isOpen={quickPopoverOpen}  target="quickRep" toggle={toggleQuickPopover} placement='bottom'
   component='conversation'>
     <QuickReplies component='conversation' getQuickReply={(quickres: any) => getQuickReply(quickres, setFieldValue)}></QuickReplies>
      </CommonPopover>
      <InputGroup>
      <InputGroup.Text className='iconBg cursor-pointer'>
     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 11" fill="none"
      onClick={() => quickpop} id='quickRep' className='ml-1'>
      <ellipse cx="5" cy="5.125" rx="5" ry="5.125" fill="#90939F" />
      <path d="M0.549238 10.1115H4.57701L1.93815 8.86483L1.3826 8.72632L0.688127 9.41889C0.132571 9.97294 0.364053 10.1115 0.549238 10.1115Z" fill="#90939F" stroke="#90939F" stroke-width="0.5" />
      <path d="M5.96819 2.07751L5.8293 2.21602L2.91263 5.1248C2.69041 5.45723 2.91263 5.54034 3.05152 5.54034H4.30152L3.60708 8.03359C3.49597 8.58764 3.74597 8.44913 3.88486 8.31061L6.94041 5.26332C7.16263 5.04169 7.033 4.89395 6.94041 4.84778H5.55152L6.24597 2.21602C6.24597 1.9944 6.06078 2.03133 5.96819 2.07751Z" fill="white" stroke="white" stroke-width="0.5" />
      </svg>
      </InputGroup.Text>
     <Field type='text' name='followReply' placeholder='select reply' className='form-control col-3'></Field>
      </InputGroup>
      <ErrorMessage name="followReply" component="div" className="text-danger ml-3" />
      </div>
      <div className='mt-2 margin-bottom'>
      <label className='smalllabel ml-3'>Minutes</label>
   <Field type='text' name='followMinute' className='form-control fieldwidth' placeholder="enter minutes" maxLength={4}
     onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
     const value = e.target.value.replace(/[^\d]/g, '');
     setFieldValue('followMinute', value);
     }}></Field>     
  <ErrorMessage name="followMinute" component="div" className="text-danger ml-3" />
  </div>    
 </div>}

 {activeTab === 'email' && <div>
  <div className='d-flex'>
        <p className='labelstyle'>Email</p>
        <span className='switchBtn'><SwitchButton whatsappOptState={switchActive} checked={emailActive} menuName={'automation'}/></span>
  </div>
  <p className='text-bold'>System triggers email when message is failed in this channel.</p>
  <label className='smalllabel'>Email ID</label>
  <Field name='email' className='form-control col-3 emailcolor' placeholder='enter email'></Field>
  <ErrorMessage name="email" component="div" className="text-danger" />
</div>}

{activeTab === 'spam' && <div>
  <div className='d-flex'>
    <p className='labelstyle'>Mark as Spam</p>
    <span className='switchBtn'><SwitchButton whatsappOptState={switchActive} checked={spamActive} menuName={'automation'}/></span>
  </div>
    <p className='text-bold'>Automatically changes the status to spam to spam when contacts has not responded for long time.</p>
  <div className='mt-4'>
    <label className='smalllabel ml-3'>Quick replies</label>
    <CommonPopover isOpen={quickPopoverOpen}  target="quickRep" toggle={toggleQuickPopover} placement='bottom'
    component='conversation'>
    <QuickReplies component='conversation' getQuickReply={(quickres: any) => getQuickReply(quickres, setFieldValue)}></QuickReplies>
    </CommonPopover>
    <InputGroup>
    <InputGroup.Text className='iconBg cursor-pointer'>
     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 11" fill="none"
      onClick={() => quickpop} id='quickRep' className='ml-1'>
      <ellipse cx="5" cy="5.125" rx="5" ry="5.125" fill="#90939F" />
      <path d="M0.549238 10.1115H4.57701L1.93815 8.86483L1.3826 8.72632L0.688127 9.41889C0.132571 9.97294 0.364053 10.1115 0.549238 10.1115Z" fill="#90939F" stroke="#90939F" stroke-width="0.5" />
      <path d="M5.96819 2.07751L5.8293 2.21602L2.91263 5.1248C2.69041 5.45723 2.91263 5.54034 3.05152 5.54034H4.30152L3.60708 8.03359C3.49597 8.58764 3.74597 8.44913 3.88486 8.31061L6.94041 5.26332C7.16263 5.04169 7.033 4.89395 6.94041 4.84778H5.55152L6.24597 2.21602C6.24597 1.9944 6.06078 2.03133 5.96819 2.07751Z" fill="white" stroke="white" stroke-width="0.5" />
      </svg>
      </InputGroup.Text>
     <Field type='text' name='spamReply' placeholder='select reply' className='form-control col-3'></Field>
      </InputGroup>
      <ErrorMessage name="spamReply" component="div" className="text-danger ml-3" />
        </div>
         <div className='mt-2 margin-bottom'>
        <label className='smalllabel ml-3'>Minutes</label>
        <Field type='text' name='spamMinute' className='form-control fieldwidth' placeholder="enter minutes" maxLength={4}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value.replace(/[^\d]/g, '');
            setFieldValue('spamMinute', value);
            }}></Field>           
      <ErrorMessage name="spamMinute" component="div" className="text-danger ml-3" />
</div>     
</div>}
  <div className={`applyButton ${isInvalidAccess ? "notAllowed" : ""} `}>
  <Button type="submit" className={`signUpBtn ${windowWidth <= 800 && 'applyButton'} col-md-2 col-xl-1 ${window.innerWidth > 900 && activeTab ==='working_hours' ? 'offset-md-7 mb-3' : 'position'}`} disabled={profileDetail.role?.name === 'MEMBER' || !values.timeArray?.length || isInvalidAccess} >Apply</Button>
  </div>
  </Form>
   )}
 </Formik> 
 </div>
</div>
 );
};

export default ChatInbox;
