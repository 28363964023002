import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllIntegration } from "../../services/integrationService";
import {
  integrationTypes as initialIntegrationTypes,
  IntegrationType,
} from "./assets";
import "./main.scss";
import { Col, Row } from "reactstrap";
import { MdOutlineArrowOutward } from "react-icons/md";

// Define the type for the integration from the API response
interface Integration {
  title: string;
  imgSrc: string;
  name: string;
  id: number | null;
  uid: string;
  integrationType: string;
  connection: any | null;
  integrationName: string;
  connectionUid: string;
  integrationUid: string;
}

const Integration = () => {
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const filterOptions = [
    "All",
    "Payment",
    "CRM",
    "Logistics",
    "E-Commerce",
    "Automation",
  ];
  const [allIntegration, setAllIntegration] = useState<Integration[]>([]);
  const [integrationTypes, setIntegrationTypes] = useState<IntegrationType[]>(
    initialIntegrationTypes
  );
  const [selectedFilter, setSelectedFilter] = useState<string>("All");

  const navigate = useNavigate();
  const location = useLocation();
  const [isInvalidAccess, setIsInvalidAccess] = useState(false);
  const roleRules = useSelector((state: any) => state.cartreducer.roleRules);
  const [wooCommerceUid, setWooCommerceUid] = useState<string | null>(null);
  const filteredIntegration =
    selectedFilter === "All"
      ? integrationTypes
      : integrationTypes.filter((type) =>
          type.type
            ?.toLowerCase()
            .split(" || ")
            .includes(selectedFilter.toLowerCase())
        );

  const filteredIntegrations = filteredIntegration.filter(
    (item: any) =>
      !allIntegration.some((data: any) => item.name === data.integrationType)
  );

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
  };

  const handleIntegrationClick = (integration: {
    name: string;
    imgSrc: string;
    title: string;
    wooCommerceUid?: string | null;
  }) => {
    if(integration.name === 'Google Sheets'){
      window.open('https://docs.google.com/spreadsheets/d/1fsF-5pEb-kBw47KtWh7pJZvr4RskObOyWrjSh2_US_Y/copy', '_blank');      
    }
    else{
      navigate(`/integrations/${integration.name}`, {
        state: {
          imgSrc: integration.imgSrc,
          description: integration.title,
          isInvalidAccess: isInvalidAccess,
          wooCommerceUid: wooCommerceUid,
        },
      });
    }
  };

  const handleNewClick = (
    integrationUid: string,
    integration: {
      name: string;
      imgSrc: string;
      title: string;
      connectionUid: string;
      connectionName: string;
    }
  ) => {
    navigate(`/integrations/${integration.name}/edit/${integrationUid}`, {
      state: {
        imgSrc: integration.imgSrc,
        description: integration.title,
        connectionUid: integration.connectionUid,
        connectionName: integration.connectionName,
        isInvalidAccess: isInvalidAccess,
      },
    });
  };

  useEffect(() => {
    if (roleRules) {
      const isInvalidAccess =
        roleRules.canViewIntegrations === true &&
        roleRules.canManageIntegrations === false;
      setIsInvalidAccess(isInvalidAccess);
    }
  }, [roleRules]);

  useEffect(() => {
    getAllIntegration(businessId).then((res: any) => {
      setAllIntegration(res);

      const wooCommerceObject = res.find(
        (item: any) => item.integrationType === "wooCommerce"
      );

      if (wooCommerceObject) {
        setWooCommerceUid(wooCommerceObject.uid); // Store the UID in the state
      } else {
        setWooCommerceUid(null); // If not found, set the state to null
      }

      // Update the integrationTypes with connectionUid
      const updatedIntegrationTypes = initialIntegrationTypes.map((type) => {
        const matchingIntegration = res.find(
          (integration: any) =>
            integration.integrationType.toLowerCase() ===
            type.name.toLowerCase()
        );
        if (matchingIntegration) {
          return {
            ...type,
            connectionUid: matchingIntegration.connectionUid,
            integrationUid: matchingIntegration.uid,
            connectionName: matchingIntegration.integrationName,
          };
        }
        return type;
      });
      setIntegrationTypes(updatedIntegrationTypes);
    });
  }, [businessId, location.state?.update]);

  return (
    <div>
      {allIntegration.length === 0 ? (
        <></>
      ) : (
        <div className="mt-2 mb-3">
          <div className="ms-3 mb-3">
            <h5 className="fw-bold">My Integrations</h5>
          </div>
          <Row>
            {allIntegration.map((integration) => {
              const integrationTypeFormatted =
                integration.integrationType.charAt(0).toLowerCase() +
                integration.integrationType.slice(1);

              const matchedIntegration = integrationTypes.find(
                (type) => type.name === integrationTypeFormatted
              );


              if (matchedIntegration) {
                return (
                  <Col md={3} key={integration.uid} className="ps-4 pe-4">
                    <div
                      key={integration.uid}
                      className="matchedIntegration-card"
                    >
                      <img src={matchedIntegration.imgSrc} alt="" />
                      <p className="header">
                        {matchedIntegration.name === "sprocket"
                          ? "Ship Rocket"
                          : matchedIntegration.name === "zohoBooks"
                          ? "Zoho Books"
                          : matchedIntegration.name === "razorPay"
                          ? "Razorpay"
                          : matchedIntegration.name === "shopify"
                          ? "Shopify"
                          : matchedIntegration.name === "wooCommerceCart"
                          ? "CartFlow" 
                          : matchedIntegration.name === "webEngage"
                          ? "webEngage" 
                          : matchedIntegration.name === "stripe" 
                          ? "Stripe"
                          : matchedIntegration.name === "cashFree"
                          ? "Cash Free"
                          : matchedIntegration.name === "genericWebHook"
                          ? "Generic Webhook"
                          : matchedIntegration.name
                        }
                      </p>
                      <p className="body">{matchedIntegration.decription}</p>
                      <div
                        className="new-btn"
                        onClick={() =>
                          handleNewClick(
                            matchedIntegration.integrationUid || "",
                            {
                              name: matchedIntegration.name,
                              imgSrc: matchedIntegration.imgSrc,
                              title: matchedIntegration.title || "",
                              connectionUid:
                                matchedIntegration.connectionUid || "",
                              connectionName:
                                matchedIntegration.connectionName || "",
                            }
                          )
                        }
                      >
                        {matchedIntegration.connectionName}
                      </div>
                    </div>
                  </Col>
                );
              }

              return null;
            })}
          </Row>
        </div>
      )}
      <div>
        <h5 className="ms-3 mb-4 fw-bold">Available Integrations</h5>

        <div className="filter-options">
          {filterOptions.map((option) => (
            <button
              key={option}
              className={`option-result ${
                selectedFilter === option ? "selected" : ""
              }`}
              onClick={() => handleFilterChange(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
      <div>
        {/* complete integration list */}
        <Row className={`${!isInvalidAccess ? "pointer" : "notAllowed"}`}>
          {filteredIntegrations.map((integration) => (
            <Col
              md={3}
              key={integration.id}
              className={`ps-4 pe-4 ${
                !(
                  integration.name === "sprocket" ||
                  integration.name === "Pabbly" ||
                  integration.name === "Sangam CRM" ||
                  integration.name === "razorPay" ||
                  integration.name === "Google Sheets" ||
                  integration.name === "wooCommerce" ||
                  integration.name === "zohoBooks" ||
                  integration.name === "shopify" ||
                  integration.name === "Zapier" ||
                  integration.name === "wooCommerceCart" || 
                  integration.name === "webEngage" ||
                  integration.name === "stripe" ||
                  integration.name === "cashFree" ||
                  integration.name === "genericWebHook" ||
                  integration.name === "Calendly"
                ) || isInvalidAccess
                  ? "disabled"
                  : ""
              }`}
              onClick={() => {
                (integration.name === "sprocket" ||
                  integration.name === "zohoBooks" ||
                  integration.name === "shopify" ||
                  integration.name === "razorPay" ||
                  integration.name === "Google Sheets" ||
                  integration.name === "wooCommerce" ||
                  integration.name === "wooCommerceCart" ||
                  integration.name === "webEngage" ||
                  integration.name === "stripe" ||
                  integration.name === "cashFree" ||
                  integration.name === "genericWebHook" ||
                  integration.name === "Calendly"
                ) &&
                  handleIntegrationClick({
                    name: integration.name,
                    imgSrc: integration.imgSrc,
                    title: integration.title || "",
                    wooCommerceUid: wooCommerceUid,
                  });

                integration.name === "shopify" &&
                  localStorage.setItem(
                    "shopifyIntegrationData",
                    JSON.stringify({
                      imgSrc: integration.imgSrc,
                      description: integration.title,
                      isInvalidAccess: isInvalidAccess,
                    })
                  );

                integration.name === "Pabbly" &&
                  window.open("https://connect.pabbly.com/", "_blank");

                integration.name === "Sangam CRM" &&
                  window.open(
                    "https://sangamcrm.com/crm-integration/crm-whatsapp-integration/",
                    "_blank"
                  );

                integration.name === "Zapier" &&
                  window.open(
                    "https://zapier.com/developer/public-invite/201203/a26a17f3576d6536b9e03138b97f7062/",
                    "_blank"
                  );
              }}
              style={{
                cursor:
                  integration.name === "sprocket" ||
                  integration.name === "Pabbly" ||
                  integration.name === "Sangam CRM" ||
                  integration.name === "zohoBooks" ||
                  integration.name === "shopify" ||
                  integration.name === "razorPay" ||
                  integration.name === "Google Sheets" ||
                  integration.name === "wooCommerce" ||
                  integration.name === "Zapier" ||
                  integration.name === "wooCommerceCart"||
                  integration.name === "webEngage" ||
                  integration.name === "stripe" ||
                  integration.name === "cashFree" ||
                  integration.name === "genericWebHook" ||
                  integration.name === "Calendly"
                    ? "pointer"
                    : "default",
              }}
            >
              <div className="matchedIntegration-card">
                <img src={integration.imgSrc} alt="" />
                <div className="d-flex">
                  <p className="header">
                    {integration.name === "sprocket"
                      ? "Ship Rocket"
                      : integration.name === "zohoBooks"
                      ? "Zoho Books"
                      : integration.name === "razorPay"
                      ? "Razorpay"
                      : integration.name === "shopify"
                      ? "Shopify"
                      : integration.name === "wooCommerceCart"
                      ? "CartFlow"
                      : integration.name === "webEngage" 
                      ? "Web Engage" 
                      : integration.name === "stripe" 
                      ? "Stripe"
                      : integration.name === "cashFree" 
                      ? "Cash Free"
                      : integration.name === "genericWebHook"
                      ? "Generic Webhook"
                      : integration.name === "Calendly" ? 
                      "Calendly"
                      : integration.name
                    }
                  </p>
                  {(integration.name === "Pabbly" ||
                    integration.name === "Sangam CRM" ||
                    integration.name === "Zapier") && (
                    <div className="d-flex align-items-center ms-2">
                      <MdOutlineArrowOutward size={18} color="#3182ce" />
                    </div>
                  )}
                </div>
                <p className="body">{integration.decription}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Integration;
