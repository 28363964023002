import React, { useRef, useEffect, useState } from 'react';
import { CSSTransition } from "react-transition-group";
import styles from './editor.module.scss';
import SendTextEditor from './sendTextEditor';
import SendListEditor from './listEditor';
import SendReplyButtonEditor from './sendReplyButtons';
import DelayEditor from './delayEditor';
import SendMediaEditor from './sendMediaEditor';
import AskTextEditor from './askType/askText';
import AskNumberEditor from './askType/askNumber';
import AskEmailEditor from './askType/askEmail';
import AskDateEditor from './askType/askDate';
import AskUrlEditor from './askType/askUrl';
import AskFileEditor from './askType/askFile';
import AskLocationEditor from './askType/askLocation';
import AskKeywordEditor from './askType/askKeyword';
import SwitchConditionEditor from './switchCondition';
import SetVariableEditor from './setVariable';
import AssignConversationEditor from './assignConversation';
import ConditionEditor from './condition';
import JumpToEditor from './jumpTo';
import AskMoreMenu from './askType/askMore';
import ApiCallsEditor from './apiCalls';
import SendTemplate from './sendTemplate';
import UnassignConversationEditor from './actions/unassignConversation';
import ResolveConversationEditor from './actions/resolveConversation';
import UpdateConversationTagEditor from './actions/updateConversationTag';
import RemoveConversationTagEditor from './actions/RemoveConversationTagEditor'
import UpdateContactFieldsEditor from './actions/updateContactFields';
import UpdateConversationStatusEditor from './actions/updateConversationStatus';
import OpenAi from './openAi';
import ZohoCRM from './integration/zohoCRM';
import Flow from './flow';
import RazorPay from './integration/razorPay';
import AskLocationButtonEditor from './askType/askLocationButton';
import SendOrderDetails from './catalogue/sendOrderDetails';
import AskCtaUrlEditor from './askType/askCtaUrl';
import AskAddressEditor from './askType/askAddress';
import SendFlow from './sendFlow';
import TriggerSequenceEditor from './actions/triggerSequence';
import SendLocationEditor from './sendLocationEditor';
import AddNotesEditor from './actions/addNotes';
import SendProductEditor from './catalogue/sendProducts';
import SetMemberEditor from './actions/setMember';
import Shopify from './integration/shopify';
import TicketingEditor from './ticketing/Ticketing';
import GoogleSheets from './integration/googleSheets';
import AskCurrentLocationEditor from './askType/askCurrentLocation';
import AppointmentFlowEditor from './appointment/appointmentFlow';
import AppointmentConfirmationEditor from './appointment/apointmentConfirmation';
import CreateTicketEditor from './ticketing/createTicket';
import SangamCRM from './integration/sangamCRM';
import WooCommerce from './integration/wooCommerce';
import openAI from './integration/openAI';
import ZohoBigin from './integration/zohoBigin';
import ReplaceText from './replaceText';
import Zoom from './integration/zoom';
import Hubspot from './integration/hubspot';
import { isInRectangle } from 'recharts/types/shape/Rectangle';
import { useAppSelector } from '../../../hooks';


interface EditorProps {
    show: boolean;
    onHide: () => void;
    id: string;
    type: string;
    create: boolean;
    isInvalidAccess?: boolean;
}

const editorComponent: { [key: string]: React.FC<{ id: string, onClose: () => void, create: boolean }>; } = {
    'send_text': SendTextEditor,
    'send_list': SendListEditor,
    'send_reply_buttons': SendReplyButtonEditor,
    'delay': DelayEditor,
    'send_media': SendMediaEditor,
    'ask_location': AskLocationButtonEditor,
    'ask_type/ask_text': AskTextEditor,
    'ask_address': AskAddressEditor,
    'ask_type/ask_number': AskNumberEditor,
    'ask_type/ask_email': AskEmailEditor,
    'ask_type/ask_date': AskDateEditor,
    'ask_type/ask_url': AskUrlEditor,
    'ask_type/ask_file': AskFileEditor,
    'ask_type/ask_location': AskLocationEditor,
    'ask_CtaUrl': AskCtaUrlEditor,
    'ask_keyword_options': AskKeywordEditor,
    'switchCondition': SwitchConditionEditor,
    'set_variable': SetVariableEditor,
    'actions/assign_conversation': AssignConversationEditor,
    'actions/unAssign_converation': UnassignConversationEditor,
    'actions/resolve_conversation': ResolveConversationEditor,
    "actions/update_contactTags": UpdateConversationTagEditor,
    "actions/remove_contactTags" :RemoveConversationTagEditor,
    "actions/update_contact": UpdateContactFieldsEditor,
    "actions/update_conversationStatus": UpdateConversationStatusEditor,
    "actions/trigger_Sequence": TriggerSequenceEditor,
    "actions/set_member": SetMemberEditor, 
    "actions/notes": AddNotesEditor,
    'condition': ConditionEditor,
    'jump_to': JumpToEditor,
    'ask_type': AskMoreMenu,
    'api_call': ApiCallsEditor,
    'send_wa_template': SendTemplate,
    'send_wa_templateV2': SendTemplate,
    'open_ai': OpenAi,
    'integration/ZohoCRM': ZohoCRM,
    'integration/Shopify': Shopify,
    'flow': Flow,
    'send_Flow': SendFlow,
    'integration/RazorPay': RazorPay,
    'send_OrderDetails': SendOrderDetails,
    'send_location' : SendLocationEditor,
    'send_product': SendProductEditor,
    'ticketIt' : TicketingEditor,
    'integration/GoogleSheets': GoogleSheets,
    'ask_currentLocation': AskCurrentLocationEditor,
    'appointment_Flow': AppointmentFlowEditor,
    'appointment_confirmation': AppointmentConfirmationEditor,
    'create_Ticket': CreateTicketEditor,
    'integration/SangamCRM': SangamCRM,
    'integration/Woocommerce': WooCommerce, 
    'integration/OpenAI': openAI,
    'integration/ZohoBigin': ZohoBigin,
    'replaceText': ReplaceText,
    'integration/Zoom': Zoom,
    'integration/Hubspot': Hubspot
};

function Editor(props: EditorProps) {
    const offcanvasRef = useRef(null);
    const [isInvalid, setIsInvalidAccess] = useState<boolean>(false);
    const roleRules = useAppSelector((state: any) => state.meta.roleRules);
  
    useEffect(() => {  
      if(roleRules) {
        const isInvalidAccess = roleRules.canViewBots === true && roleRules.canManageBots === false;
        setIsInvalidAccess(isInvalidAccess);
      }
    }, [roleRules]);

    const handleEscKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            props.onHide();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleEscKeyPress);
        return () => {
            window.removeEventListener('keydown', handleEscKeyPress);
        };
    }, []);

    let ContentCmp: React.FC<{ id: string, onClose: () => void, create: boolean , isInvalidAccess: boolean}> | null = null;

    if (props.type in editorComponent) {
        ContentCmp = editorComponent[props.type];
    }
    return (
        <CSSTransition
            nodeRef={offcanvasRef}
            in={props.show}
            timeout={300}
            unmountOnExit
            mountOnEnter
            classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive
            }}
        >
            <>
                <div className={styles.backdrop} id="flowBuilder-Editor-Offcanvas" onClick={props.onHide}></div>
                <div
                    ref={offcanvasRef}
                    className={styles.offcanvas}
                    id="flowBuilder-Editor-Offcanvas-Offcanvas"
                >
                    <div className={styles.editor} id="flowBuilder-Editor">
                        {ContentCmp && <ContentCmp create={props.create} id={props.id} onClose={props.onHide} isInvalidAccess={isInvalid} />}
                    </div>
                </div>
            </>
        </CSSTransition>
    );
}

Editor.defaultProps = {
    create: false,
    isInvalidAccess: false
};

export default Editor;