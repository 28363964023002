import { useFormikContext,Field,ErrorMessage } from "formik";
import { Form } from "react-bootstrap";
import { useEffect, useRef, useState, createContext, useContext } from 'react';
import FlagInput from "../../../../common/flagInput";
import 'react-phone-number-input/style.css'
import "../../../../components/Contacts/contact.scss";
import ValidationSettings from "./askType/validationSettings"
import ReactSelect from "react-select";

import { ContactsGetAll } from "../../../../services/campaignService";
import axios from "../../../../utils/axios";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../hooks";


interface Con {
    value: string;
    label: string;
}


interface AdvancedSettingsProps {

};

const initialPayload = {
    errorMessage: "",
    attempt: 0,
    endFlow: true,
    contact: {
        uid: "",
        mobileNumber: "",
        countryCode:"",
        name:""
    }
};

const TemplateAdvancedSettings: React.FC<AdvancedSettingsProps> = () => {
    const { values,setFieldValue } = useFormikContext<
            typeof initialPayload>();
    const [contacts, setContacts] = useState<Con[]>([]);
    const [selectedContact, setSelectedContact] = useState<Con| null>(null);
    const currentPage = useRef(0); 
    const current = currentPage.current;
    const [isLoading, setIsLoading] = useState(false);
    const searchDebounceRef = useRef(null);

   const businessId= localStorage.getItem('businessId')
   const paginationLimit = useRef(15);
   const limit = paginationLimit.current;
   const [searchTerm, setSearchTerm] = useState<string | null>();
   const page = current > 0 ? current - 1 : 0;
   const [code, setCode] = useState("+91");
   const [countryList,setCountryList] = useState([]);
   const codePlusRem = code.slice(1);
   const initialViewMode = values.contact?.uid ? 'contact' : values.contact?.mobileNumber ? 'phone' : '';
   const [viewMode, setViewMode] = useState('contact'); 
   const numberMasking = useAppSelector((state: any) => state.meta.numberMasking);
   
   const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    setSearchTerm(searchTermCheck);
    currentPage.current = 0; 
    setContacts([]);     
};

  const codeChange = (e: string) => {
    setCode(e);
  };

  const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setFieldValue("contact.mobileNumber", value);
    setFieldValue("contact.countryCode", `${code.slice(1)}`);
    setFieldValue("contact.uid",null);
};

const handleSelectContactChange = (newValue: Con | null) => {
    if (newValue !== null) {
        const selectedOption = newValue as Con;
        console.log('see',selectedOption)
        setSelectedContact(selectedOption);
        setFieldValue('contact.uid', selectedOption?.value);
        setFieldValue('contact.name',selectedOption?.label)
        setFieldValue("contact.mobileNumber", null); 
        setFieldValue("contact.countryCode", null); 
    } else {
        setSelectedContact(null);
    }
};

const [isOpen, setOpen] = useState(false);
    
    const handleViewModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if (newValue === 'contact') {
            setViewMode('contact');
        }  
        if (newValue ==='phone'){
            setViewMode('phone')
        }
      };
      
    useEffect(() => {
        if (values.contact?.mobileNumber == null && values.contact?.uid == null) {
            setOpen(false);
        } 
        else {
            setOpen(true); 
        }
    }, [values.contact?.mobileNumber, values.contact?.uid]);


    useEffect(() => {
        if (values.contact?.uid) {
            const foundContact = contacts.find(contact => contact.value === values.contact.uid);
            if (foundContact) setSelectedContact(foundContact);
        }
    }, [contacts, values.contact?.uid]);

    useEffect(() => {
        const initialViewMode = values.contact?.uid ? 'contact' : (values.contact?.mobileNumber ? 'phone' : viewMode);
        setViewMode(initialViewMode);
    }, [values.contact]);
    
    const fetchCountries = async () => {
        try {
            const responseData = await axios.get('getCountries');
            const flagData = responseData.data;
            setCountryList(flagData);
        } catch (err) {
            console.error('Error fetching countries', err);
        }
    };
    useEffect(() => {
        if (values.contact?.uid) {
            const foundContact = contacts.find(contact => contact.value === values.contact.uid);
            if (foundContact) setSelectedContact(foundContact);
            else fetchSelectedContact(values.contact.uid);
        }
    }, [contacts, values.contact?.uid]);
    const fetchSelectedContact = async (uid: string) => {
        try {
            const response = await axios.get(`contact/${uid}`);
            const contactData = response.data;
            const selectedContactData = {
                value: contactData.uid,
                label: contactData.name,
            };
            setSelectedContact(selectedContactData);
        } catch (err) {
            console.error('Error fetching selected contact', err);
        }
    };
    
    const fetchData = async (searchTerm :any, page :any) => {
        setIsLoading(true);
        try {
            const payload = {
                uid: businessId,
                limit: limit,
                page: page,
                search: searchTerm,
            };
            const response = await ContactsGetAll(payload as any);
            const contactsData = response?.data?.list?.map((item :any) => ({
                value: item.uid,
                label: item.name,
            }));
            setContacts(prevContacts => [...new Set([...prevContacts, ...contactsData])]);
        } catch (err) {
            console.error('Error Fetching Data', err);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        currentPage.current = 0;   
        fetchData(searchTerm, currentPage.current); 
    }, [searchTerm]);
    
    const numberMask = localStorage.getItem('numberMask') === 'true' || numberMasking;

    
    return (
        <ValidationSettings
        initiallyOpen={isOpen}
        onCancel={() => {
            setFieldValue('contact',{
                uid:null,
                mobileNumber:null, })
         }}
            caption='Advanced Settings'
        > <Form.Group  style={{ display: 'flex',gap:'10px',   alignItems: 'center', justifyContent: 'start' }}>
        <Form.Check
            type="radio"
            label="Select Contact"
            name="viewMode"
            value="contact"
            checked={viewMode === 'contact'}
            onChange={handleViewModeChange}
            id="radio-contact"
        />
        <Form.Check
            type="radio"
            label="Add Contact"
            name="viewMode"
            value="phone"
            checked={viewMode === 'phone'}
            onChange={handleViewModeChange}
            id="radio-phone"
            disabled={numberMask ? true :false}
        />
    </Form.Group>
            {viewMode === 'contact' && (
             <Form.Group className='mb-3'>
                <Form.Label>Select Contact</Form.Label>
                <div>
                <ReactSelect
    menuPortalTarget={document.body}
    styles={{
        menuPortal: base => ({ ...base, zIndex: 9800 }),
        menu: base => ({ ...base, width: 360 })
    }}
    options={contacts}
    value={selectedContact}
    onChange={handleSelectContactChange}
    onMenuScrollToBottom={() => {
        if (!isLoading) {
            currentPage.current += 1; 
            fetchData(searchTerm, currentPage.current); 
        }
    }}
    onInputChange={(inputValue, actionMeta) => {
        if (actionMeta.action === 'input-change') {
            onSearchChange(inputValue);
    }}
}
    //isDisabled={disableSelectContact}
/>

                </div>
            </Form.Group>)}
            
            {viewMode === 'phone' && (
<Form.Group className='mb-3'>
    <Form.Label>WhatsApp Number</Form.Label>
    {/* <div className="input-group inputBorder">
        <PhoneInput
          
            value={values.contact?.mobileNumber}
            onChange={(value:any) => {
                const countryCode = value?.slice(0, 2); 
                const phoneNumberWithoutCode = value?.slice(2);

                setFieldValue('contact.mobileNumber', phoneNumberWithoutCode);
                setFieldValue('contact.countryCode', countryCode);
            }}
            inputClass="form-control"
        />
    </div> */}
         <div className="input-group inputBorder">
                              <div className="input-group-prepend  ms-1 me-0"  >
                                <FlagInput
                                 countryList={countryList}
                                  countryCode={code}
                                  codeChange={codeChange}
                                />
                              </div>
                              <Field
                                type="text"
                                name="contact.mobileNumber"
                                id="mobileNumber"
                                placeholder="Enter your mobile number"
                                className="form-control"
                                onChange={handlePhoneInputChange}
                              />
                            </div>
    <ErrorMessage
        name="contact.mobileNumber"
        component="div"
        className="text-danger pt-1"
    />
</Form.Group>)}
        </ValidationSettings>
    )
}
export default TemplateAdvancedSettings; 