import React, { useEffect, useState, useRef } from "react";
import "./sequence.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody } from "reactstrap";
import { Form, Field, ErrorMessage, useFormikContext } from "formik";
import { SelectFlowTimes, delayUnits, days} from "../../common/category";
import Select from "react-select";
import Modall from "../../common/modall";
import TemplateList from "../Template/templateList";
import { useData } from "../../contexts/tempContext";
import SingleTemplate from "../Template/singleTemplate";
import { useSequenceData } from "./SequenceContext";
import { deleteSequenceFlow } from "../../services/SequenceService";
import { confirmAlert, toast } from "./../../common/alert";
import { Button, Col, Label, Row, Alert } from "reactstrap";
import { faClock, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import TooltipReference from "../../common/tooltip";
import DaySelection from "./DaySelectionComponent";
import Accordion from "react-bootstrap/Accordion";
import { IoIosArrowRoundDown, IoMdEye } from "react-icons/io";
import { PiPlusCircleFill } from "react-icons/pi";
import { IoCopyOutline } from "react-icons/io5";
import { MdOutlineSwapVert, MdOutlineDelete } from "react-icons/md";
// import { CgMathPlus } from "react-icons/bs";
import { CgMathPlus } from "react-icons/cg";
import { FiEdit3 } from "react-icons/fi";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { HiInformationCircle } from "react-icons/hi2";
import { GoClock } from "react-icons/go";
import { CgDanger } from "react-icons/cg";
import { customStyles } from "../../common/react-select-style";
import { HiMiniArrowSmallRight } from "react-icons/hi2";


type Props = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  getFlowData: (flowData: any) => void;
  getOverallSequence: any;
  updateSequence: (sequenceId: any) => void;
  setIsBackAlert: (alert: boolean) => void;
  daysList: any;
  error: boolean;
  bottomRef: any;
  sequenceHeaderRef: any;
};

interface FormValues {
  delay: string;
  delayUnit: any;
  name: string;
}

const AddFlow = (props: Props) => {
  const {
    getOverallSequence,
    setIsBackAlert,
    error,
    bottomRef,
    sequenceHeaderRef,
  } = props;
  const FormikContext = useFormikContext();
  const { setFieldValue, errors } = FormikContext;

  // const flowArrowRef = useRef<HTMLDivElement>(null);

  let formErrors = Object.entries(errors)
    .map(([key, value]) => {
      return key === "sequenceFlowSteps" ? value : undefined;
    })
    .filter(Boolean);

  const changeFormErrors = Array.isArray(formErrors[0])
    ? formErrors[0].map((item: any) =>
        typeof item === "object" ? item !== null : false
      )
    : [];

  const formikValues = FormikContext?.values as any;
  const { sequenceFlowSteps } = formikValues;

  const [sequenceFlowStep, setsequenceFlowStep] =
    useState<any[]>(sequenceFlowSteps);

  const [isTemplatePick, IsTemplatePick] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [modalTempTitle, setmodalTempTitle] = useState("Sequence Template");
  const [name, settemplateData] = useState("");
  const [templateVar, setTemplateVar] = useState<any>([]);
  const [carouselVar, setCarouselVar] = useState<any>([]);
  const { data, setData } = useData();
// console.log("dat",data);

  // const urlIndices =  data?.cards?.length === 0 &&  data?.buttonColumns?.map((button: any, index: number) =>
  //     button && (button?.includes("URL1") || button?.includes("URL2")) ? index : null)?.filter((index: number | null) => index !== null);
  const urlIndices = (Array.isArray(data?.buttonColumns) && data?.cards?.length === 0)
  ? data?.buttonColumns?.map((button: any, index: number) => {
      if (typeof button === 'string' && button) {
        return (button?.includes("URL1") || button?.includes("URL2")) ? index : null;
      } else {
        console.error("Button is not a string:", button);
        return null;
      }
    })?.filter((index: number | null) => index !== null)
  : [];

  const templateVariables = (
    tempVar: any,
    showLink: boolean,
    templateVariables: any,
    groupedCarousel: any,
  ) => {
    // console.log("va",templateVariables);
    // console.log("grouped car", groupedCarousel);
    setCarouselVar(groupedCarousel);
    const initialTemplate = (
      templateVariables?.filter((variable: any) => variable.isCarousel === false)?.map((variable: any, index: number) => ({
        componentType: variable?.isButton ? "button" : "body",
        position: variable?.isButton? urlIndices?.shift() : index + 1,
        ownValue: variable?.Check,
        bodyText: variable?.value,
      })) || []
    );
  
    const template = [...initialTemplate]; 
    setTemplateVar(template);
    // console.log("checking",template);
    // console.log("checking template",data)
    
  };

  useEffect(()=>{
    if ( data?.components &&  (data?.components[1]?.type==="CAROUSEL" ||  data?.components[0]?.type==="CAROUSEL")) {
      const template:any = [];
      if(carouselVar){
        let exampleIndex = 0; 
        ((data?.components[1]?.cards || data?.components[1]?.cards))?.forEach((card: any, index: number) => {
            const bodyComponent = card.components.find((c: any) => c.type === "BODY");
            if (bodyComponent && bodyComponent.example && exampleIndex < carouselVar.length) {
                bodyComponent.example.body_text = [carouselVar[exampleIndex].values];
                exampleIndex++;
            }
        });
      }
      template.push({
        componentType: "carousel",
        carouselText: [data?.components && data?.components[1]?.type==="CAROUSEL"? data?.components[1] : data?.components[0]],
      });

      setTemplateVar((prevTemplate: any) => [...prevTemplate, ...template]);
    }
  },[data])



  const { SequenceData, setIsSequenceData } = useSequenceData();

  const [swapModal, setSwapModal] = useState(false);
  const [fromIndex, setFromIndex] = useState(0);
  const [swapIndex, setSwapIndex] = useState(0);
  const [heights, setHeights] = useState<number[]>([]);
  const [sumUptoIndex, setSumUptoIndex] = useState(0);
  const [addFlow, setAddFlow] = useState(false);
  const [cloneFlow, setCloneFlow] = useState(false);
  const [modalSize, setModalSize] = useState("modal-xxl");

  useEffect(() => {
    const divHeight: number[] = [];
    for (let i = 0; i < sequenceFlowSteps.length; i++) {
      const flowHeight = document.getElementById(`flow-${i}`);
      if (flowHeight) {
        divHeight.push(flowHeight.clientHeight);
      }
    }
    setHeights(divHeight);
  }, [addFlow, sequenceFlowStep]);

  const [swapOption, setSwapOtion] = useState([]);

  const handleSwap = (formIndex: number, flow: any) => {
    setFromIndex(formIndex);
    setSwapIndex(formIndex);

    const sequenceFlowStepsIndexArr = sequenceFlowSteps.map(
      (item: any, index: number) => index
    );
    const updatedSwapOption = sequenceFlowStepsIndexArr.filter(
      (item: any, index: number) => index !== formIndex
    );

    setSwapOtion(
      updatedSwapOption.map((el: any, index: any) => ({
        value: el,
        label: `${el + 1}`,
      }))
    );
  };

  const handleSwapObjects = (
    flowArr: any[],
    index1: number,
    index2: number
  ) => {
    if (index1 === index2) {
      return;
    }
    const temp = flowArr[index1];
    flowArr[index1] = flowArr[index2];
    flowArr[index2] = temp;

    return flowArr;
  };

  const handleMoveSequence = (
    flowArr: any,
    fromIndex: number,
    toIndex: number
  ) => {
    if (fromIndex === toIndex) {
      return setSwapModal(false);
    } else {
      setIsSequenceData((prevState: any) => {
        if (Array.isArray(prevState.flowData)) {
          const newSequenceFlowSteps = handleSwapObjects(
            flowArr,
            fromIndex,
            swapIndex
          );
          // If sequenceFlowSteps is managed by Formik, update it directly here
          if (setFieldValue) {
            setFieldValue("sequenceFlowSteps", newSequenceFlowSteps);
          }

          // Update the stepNumber properties of the objects in the updated array
          newSequenceFlowSteps?.forEach((obj: any, index: number) => {
            obj.stepNumber = index + 1;
          });

          // const updatedSwapFlowStep = [...newSequenceFlowSteps];
          setsequenceFlowStep(newSequenceFlowSteps || []);

          return { ...prevState, flowData: newSequenceFlowSteps };
        } else {
          return prevState;
        }
      });
    }

    setSwapModal(false);
  };

  const [activeIndex, setActiveIndex] = useState(
    Array(sequenceFlowSteps.length).fill(null)
  );

  const handleSelect = (selectedIndex: any) => {
    const newActiveIndex = activeIndex[selectedIndex]
      ? null
      : selectedIndex.toString();
    setActiveIndex(
      Array(sequenceFlowSteps.length)
        .fill(null)
        .map((_, index) => (index === selectedIndex ? newActiveIndex : _))
    );
  };

  const flowData = SequenceData?.flowData || [];

  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const prevFormikValues = usePrevious(formikValues);

  useEffect(() => {
    if (prevFormikValues && prevFormikValues !== formikValues) {
      setIsBackAlert(true);
    }
  }, [formikValues, prevFormikValues]);

  useEffect(() => {
    if (flowData?.length > 0) {
      props.getFlowData(flowData);
      setsequenceFlowStep(
        flowData.sort((a: any, b: any) => a.stepNumber - b.stepNumber)
      );

      if (setFieldValue) {
        setFieldValue("sequenceFlowSteps", flowData);
      }
    }
    if (flowData?.length === 0) {
      setIsSequenceData({ flowData: sequenceFlowStep });
    }
  }, [flowData]);

  useEffect(() => {
    if (getOverallSequence) {
      const { sequenceFlows } = getOverallSequence;
      setIsSequenceData({
        flowData: sequenceFlows,
      });
    }
  }, [getOverallSequence]);
console.log('temp',templateVar)
  useEffect(() => {
    settemplateData(data?.name);
    if (SequenceData?.flowData) {
      setIsSequenceData((prevState: any) => {
        const flowData = prevState?.flowData ? [...prevState.flowData] : [];

        const { id, name, components, headerColumns } = data;

      let templateText: any[] = [];
      // Process the components to extract templateText for OTP and COPY_CODE
      components?.forEach((component: any) => {
        const [firstButton] = component.buttons || [];
        if (
          component.type === "BUTTONS" &&
          firstButton?.type?.toUpperCase() === "OTP" &&
          firstButton?.otp_type?.toUpperCase() === "COPY_CODE"
        ) {
          templateText.push({
            componentType: 'button',
            ownValue: false,
            bodyText: firstButton.example,
            position: 0,
          });
        }
         if(component?.format === "LOCATION") {
          templateText.push({
            componentType: "header",
            ownValue: false,
            bodyText: headerColumns?.[0]? headerColumns?.[0]?.label : headerColumns?.label,
            position: 1,
            metaData: {
            latitude: headerColumns?.[0]? headerColumns?.[0]?.latitude : headerColumns?.latitude,
            longitude: headerColumns?.[0]? headerColumns?.[0]?.longitude : headerColumns?.longitude,
            name: headerColumns?.[0]? headerColumns?.[0]?.name : headerColumns?.name,
            address: headerColumns?.[0]? headerColumns?.[0]?.address : headerColumns?.address
            }
        })
        }
         if(component.type === "LIMITED_TIME_OFFER"){
          templateText.push({
            componentType: "limited_time_offer",
            ownValue: false,
            bodyText: component?.limited_time_offer?.example?.[0],
            position: 1
            })
        } 
    
      const quickReplyButtons = component?.buttons?.map((item: any, index: any) => ({
        componentType: "QUICK_REPLY",
        bodyText: item.example?.[0] && item.type === 'QUICK_REPLY'? item.example[0] : '',
        position: index
      }))
      .filter((button:any) => button.bodyText); 

      if (quickReplyButtons?.length > 0) {
      templateText.push(...quickReplyButtons);
      }
      });

      // Merge templateText with templateVar
      templateText = [...templateText, ...templateVar];

        const body_text = components && components[1]?.example?.body_text;
        // let templateText;
        //   if (Array.isArray(body_text)) {
        //     if (body_text.length > 0 && Array.isArray(body_text[0])) {
        //       // If body_text is an array of arrays, flatten it to a single-level array
        //       templateText = body_text.flat();
        //     } else {
        //       // If body_text is already a single-level array, use it directly
        //       templateText = body_text;
        //     }
        //   } else if (body_text) {
        //     // If body_text is not an array, but a singular value, wrap it in an array
        //     templateText = [body_text];
        //   } else {
        //     // Fallback to an empty array if body_text is undefined or null
        //     templateText = [];
        //   }
        if (currentIndex >= 0 && currentIndex < flowData?.length) {
          // Correctly updates an existing item in flowData
          flowData[currentIndex] = {
            ...flowData[currentIndex],
            name: name,
            templateText: templateText,
            templateId: id,
            template: data,
            media: data?.media ?? flowData[currentIndex]?.media,
            components: data?.components?.map((component: any, idx: number) =>
              idx === 0 && component.type === "HEADER"
                ? {
                    ...component,
                    example: {
                      ...component.example,
                      header_handle: data?.media
                        ? [data.media.url]
                        : component?.example?.header_handle,
                    },
                  }
                : component
            ),
          };
        } else {
          // Adds a new item to flowData
          flowData.push({
            name: name,
            templateText: templateText,
            templateId: id,
            template: data,
          });
        }

        if (setFieldValue) {
          setFieldValue("sequenceFlowSteps", flowData);
        }
        return {
          ...prevState,
          flowData: flowData,
        };
      });
    }
  }, [data, templateVar]);

  const nextFlowStart = (e: any) => {
    e.preventDefault();
    setAddFlow(true);
    setIsSequenceData((prevState: any) => {
      if (Array.isArray(prevState.flowData)) {
        const flowData = prevState?.flowData ? [...prevState.flowData] : [];

        const emptyDataSet = {
          stepNumber: sequenceFlowStep.length + 1,
          delay: "1",
          delayUnit: "minutes",
          name: "", //
          templateText: "",
          scheduledTimes: Array(props.daysList.length).fill(false),
          templateId: "",
          template: "",
          customTime: "Default",
          fromTime: "",
          toTime: "",
        };

        // Add the new item to flowData
        flowData.push(emptyDataSet);

        // If sequenceFlowSteps is managed by Formik, update it directly here
        if (setFieldValue) {
          setFieldValue("sequenceFlowSteps", flowData);
        }

        // Update the local sequenceFlowStep state as well
        const updatedSequenceFlowStep = [...flowData, emptyDataSet];
        setsequenceFlowStep(updatedSequenceFlowStep);

        // Return the new state
        return {
          ...prevState,
          flowData: flowData,
        };
      } else {
        return prevState;
      }
    });
  };

  const dropFlow = async (flowUid: string, index: number) => {
    if (flowUid) {
      confirmAlert(
        "warning",
        "Are you sure you want to delete this flow?",
        async () => {
          const response = await deleteSequenceFlow(flowUid);
          if (response) {
            const { sequenceUid } = getOverallSequence;
            props.updateSequence(sequenceUid);
          }
        },
        () => {}
      );
    } else {
      confirmAlert(
        "warning",
        "Are you sure you want to delete this flow?",
        async () => {
          if (sequenceFlowStep.length > 1) {
            // Filter out the item at the specified index
            const newSequenceFlowStep = sequenceFlowStep.filter(
              (item: any, idx: number) => idx !== index
            );
            setFieldValue("sequenceFlowSteps", newSequenceFlowStep);
            setsequenceFlowStep(newSequenceFlowStep);

            // Update IsSequenceData with the new array
            setIsSequenceData({ flowData: newSequenceFlowStep });
          }
        },
        () => {}
      );
    }
  };

  const headerDays = ["M", "T", "W", "T", "F", "S", "S"];

  const templateOpen = (index: number, values: any) => {
    setCurrentIndex(index);
    IsTemplatePick(true);
  };
  const templateClose = () => {
    IsTemplatePick(false);
    setModalSize('modal-xxl');
  };

  const updateItemInFlowData = (index: number, name: string, value: any) => {
    // Assuming 'index' is an actual index in the array
    const updatedFlowData = SequenceData.flowData.map(
      (item: any, idx: number) => {
        if (idx === index) {
          // const isCustomTimeFlow = value === 'Custom' ? item?.flowsdayTime :defaultTimeSequence;
          return {
            ...item,
            [name]: value, // Update the item with the new value for the provided name
            // scheduledTimes:isCustomTimeFlow,
            // customTime: isCustomTimeFlow  // Update customTime based on the condition
          };
        }
        return item; // Return other items unchanged
      }
    );

    // Update the context state
    setIsSequenceData({
      ...SequenceData,
      flowData: updatedFlowData,
    });
  };

  const addItemToFlowData = (name: any, value: any) => {
    setIsSequenceData((prevState: any) => {
      const flowData = prevState?.flowData || [];
      return {
        ...prevState,
        flowData: [...flowData, { [name]: value }], // Add new item to the array
      };
    });
  };
  const addChangeFlow = (
    name: string,
    value: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    index: number
  ) => {
    if (index != null && index >= 0 && index < SequenceData.flowData?.length) {
      // If index is valid, update the item at that index
      updateItemInFlowData(index, name, value);
    } else {
      addItemToFlowData(name, value);
    }
    setFieldValue(name, value);
  };


  useEffect(() => {
    if (cloneFlow) {
      let sequenceHeaderHeight = 0;
      if(sequenceHeaderRef.length > 0){
        sequenceHeaderHeight = sequenceHeaderRef[0].offsetHeight;
      }
      window.scrollTo({
        top: ((sequenceHeaderHeight as number) +
        sumUptoIndex) as number,
        behavior: "smooth",
      });
    }
  }, [sumUptoIndex]);

  useEffect(() => { //Add Flow Scroll
    if(addFlow){
      window.scrollTo({
        top: bottomRef.current?.offsetHeight,
        behavior: "smooth",
      });
    }
  },[sequenceFlowSteps.length])
    
  const handleClone = (e: any, index: number, flowData: any) => {
    const sumUptoIndex = heights
        .slice(0, index)
        .reduce((acc, val) => acc + val, 0);

    setCloneFlow(true);
    setSumUptoIndex(index === 0 ? heights[0] : sumUptoIndex)

    e.preventDefault();
    setIsSequenceData((prevState: any) => {
      if (Array.isArray(prevState.flowData)) {
        const flowData = [...prevState.flowData];
        const clonedFlowItem = {
          // stepNumber: flowData[index]?.stepNumber + 1,
          delay: flowData[index]?.delay,
          delayUnit: flowData[index]?.delayUnit,
          name: flowData[index]?.name, //
          templateText: flowData[index]?.templateText,
          scheduledTimes: flowData[index]?.scheduledTimes,
          templateId: flowData[index]?.template?.id,
          template: flowData[index]?.template,
          customTime: flowData[index]?.customTime,
          fromTime: flowData[index]?.fromTime,
          toTime: flowData[index]?.toTime,
        };

        flowData.splice(index + 1, 0, clonedFlowItem);
        // Update the stepNumber property of all the objects in the clonedFlowData array
        flowData.forEach((step, i) => {
          step.stepNumber = i + 1;
        });

        // Update the stepNumber property of the cloned object

        if (setFieldValue) {
          setFieldValue("sequenceFlowSteps", [...flowData]);
        }
        setsequenceFlowStep(flowData);

        return {
          ...prevState,
          flowData: flowData,
        };
      } else {
        return prevState;
      }
    });
  };

  return (
    <div className="sequenceFlows">
      {swapModal && (
        <>
          <Modal isOpen={swapModal} toggle={() => setSwapModal(!swapModal)}>
            <ModalHeader toggle={() => setSwapModal(!swapModal)}>
              Rearrange steps
            </ModalHeader>
            <ModalBody className="pt-1">
              <div className="row swap-modal">
                <div
                  className="col-12 mb-1 d-flex justify-content-center align-items-center"
                  style={{ background: "#fefcbf", borderRadius: "4px" }}
                >
                  <div className="me-2">
                    <HiInformationCircle color="#718096" />
                  </div>
                  <div>
                    <small>
                      After changing the order, Place check if the time delay is
                      as per your need
                    </small>
                  </div>
                </div>
                <div className="col-5 d-flex align-items-center">
                  Move sequence step to
                </div>
                <div className="col-3">
                  <Select
                    options={swapOption}
                    value={{
                      value: swapIndex,
                      label: `${swapIndex + 1}`,
                    }}
                    onChange={(e: any) => setSwapIndex(e.value)}
                    styles={customStyles}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => setSwapModal(!swapModal)}
                style={{
                  background: "none",
                  border: "none",
                  color: "#00b598",
                  fontSize: "small",
                }}
              >
                Cancel
              </Button>{" "}
              <Button
                style={{
                  color: "white",
                  background: "#00b598",
                  borderRadius: "8px",
                  fontSize: "small",
                }}
                onClick={() => {
                  handleMoveSequence(
                    SequenceData.flowData,
                    fromIndex,
                    swapIndex
                  );
                  toast(
                    "success",
                    `Moved Step from ${fromIndex + 1} to ${swapIndex + 1}`
                  );
                }}
              >
                Move
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}

      {sequenceFlowStep.map((flow, index) => {
        return (
          <div key={index} id={`flow-${index}`}>
            <Form key={index}>
              <div className="row flow-arrow">
                <div className="col-12">|</div>
                <div className="col-12">
                  <Label className="sequence-time">
                    <FontAwesomeIcon icon={faClock} />
                    &nbsp; Message Sequence: Step {index + 1} - After{" "}
                    {flowData[index]?.delay} {flowData[index]?.delayUnit}
                  </Label>
                </div>
                <div className="col-12">
                  <IoIosArrowRoundDown size={25} />
                </div>
              </div>

              {/* STart Flows */}
              <div className="addFlow-accordian row">
                <div className="col-12">
                  <Accordion
                    defaultActiveKey={activeIndex[index]}
                    onSelect={() => {
                      handleSelect(index);
                    }}
                    alwaysOpen
                  >
                    <Accordion.Item eventKey={index.toString()}>
                      <Accordion.Header
                        as="div"
                        className={
                          activeIndex[index] == index.toString() ? "active" : ""
                        }
                        id="accordion_header"
                      >
                        <div className="flow-accordian-header">
                          <div className="render">
                            <div>
                              {/* <Label className="m-0">{index + 1}&nbsp;</Label> */}
                              <Label className="m-0">
                                {flowData && flowData[index]?.template?.showName
                                  ? flowData[index]?.template?.showName
                                  : "Untitled Name"}
                              </Label>
                            </div>
                            <div className="d-flex flex-row">
                              <div>
                                <GoClock color="#a3a5a9" size={14} />
                                <small
                                  style={{
                                    fontSize: "smaller",
                                  }}
                                >
                                  {flowData[index]?.customTime === "Custom" ? (
                                    <> Custom Time |</>
                                  ) : (
                                    <> Any Time |</>
                                  )}
                                </small>
                              </div>

                              <div className="d-flex justify-content-center align-items-center">
                                {headerDays.map((el: any, _index: number) => {
                                  return (
                                    <small
                                      style={{
                                        fontSize: "x-small",
                                        color:
                                          flowData[index]?.customTime !=
                                          "Custom"
                                            ? "#2f855a"
                                            : sequenceFlowSteps[index]
                                                .scheduledTimes[_index]
                                            ? "#2f855a"
                                            : "black",
                                        border: "1px solid #E2E8F0",
                                        background:
                                          flowData[index]?.customTime !=
                                          "Custom"
                                            ? "#c6f6d5"
                                            : sequenceFlowSteps[index]
                                                .scheduledTimes[_index]
                                            ? "#c6f6d5"
                                            : "white",
                                        marginLeft: "6px",
                                        padding: "1px 6px",
                                        borderRadius: "3px",
                                      }}
                                    >
                                      {el}
                                    </small>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="operations">
                            <div className="form-err me-2">
                              {error &&
                                Array.isArray(changeFormErrors) &&
                                changeFormErrors[index] && (
                                  <div className="d-flex justify-content-center align-items-center">
                                    <div>
                                      <CgDanger />
                                    </div>
                                    <div className="err-text">
                                      Fields required
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className="clone ms-1">
                              <TooltipReference
                                placement="bottom"
                                content="Clone"
                                tooltipId={`copySequence${index}`}
                              >
                                <IoCopyOutline
                                  size={15}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    handleClone(e, index, flowData);
                                  }}
                                />
                              </TooltipReference>
                            </div>
                            {sequenceFlowSteps.length < 2 ? null : (
                              <>
                                <div className="swap ms-1">
                                  <TooltipReference
                                    placement="bottom"
                                    content="Swap"
                                    tooltipId={`swapSequence${index}`}
                                  >
                                    <MdOutlineSwapVert
                                      size={17}
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        setSwapModal(true);
                                        handleSwap(index, flow);
                                      }}
                                    />
                                  </TooltipReference>
                                </div>

                                <div className="delete-io ms-1">
                                  <TooltipReference
                                    placement="bottom"
                                    content="Delete"
                                    tooltipId={`deleteSequence${index}`}
                                  >
                                    <MdOutlineDelete
                                      size={17}
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        dropFlow(
                                          getOverallSequence &&
                                            flowData[index]?.uid,
                                          index
                                        );
                                      }}
                                    />
                                  </TooltipReference>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="ps-2 pe-2">
                        <>
                          <div className="addflow-form">
                            <div className="row">
                              <div className="col-8">
                                <div className="select-forms">
                                  <div className="row">
                                    <div className="col-2 send-after d-flex align-items-center pe-0">
                                      <Label className="ps-2">
                                        Send after
                                        <span className="redstar_required"></span>
                                      </Label>
                                    </div>
                                    <div className="col-3 send-after ps-5">
                                      <Field
                                        type="text"
                                        name={`sequenceFlowSteps[${index}].delay`}
                                        onChange={(event: any) => {
                                          let value =
                                            event.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            );
                                          value = value.replace(/^0+/, "");
                                          setFieldValue(
                                            `sequenceFlowSteps[${index}].delay`,
                                            value
                                          );
                                          addChangeFlow(
                                            `delay`,
                                            value,
                                            setFieldValue,
                                            index
                                          );
                                        }}
                                        // value={formikValues.sequenceFlowSteps[index]?.delay ?? ""}
                                        value={flowData[index]?.delay ?? ""}
                                        className="form-control "
                                      />
                                      <ErrorMessage
                                        name={`sequenceFlowSteps[${index}].delay`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                    <div className="col-3 send-after">
                                      <Field
                                        name={`sequenceFlowSteps[${index}].delayUnit`}
                                      >
                                        {({ field, form }: any) => (
                                          <Select
                                            {...field}
                                            styles={customStyles}
                                            options={delayUnits.map(
                                              (sequence: any) => ({
                                                label: sequence.label,
                                                value: sequence.value,
                                              })
                                            )}
                                            value={delayUnits.find(
                                              (delayUnit: any) =>
                                                delayUnit.value ===
                                                  flowData[index]?.delayUnit ?flowData[index]?.delayUnit:
                                                ""
                                              // formikValues.sequenceFlowSteps[index]?.delayUnit ?? ""
                                            )}
                                            className=""
                                            onChange={(selectedOption: any) => {
                                              setFieldValue(
                                                `sequenceFlowSteps[${index}].delayUnit`,
                                                selectedOption.value
                                              );
                                              addChangeFlow(
                                                `delayUnit`,
                                                selectedOption.value,
                                                form.setFieldValue,
                                                index
                                              );
                                            }}
                                          />
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name={`sequenceFlowSteps[${index}].delayUnit`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                    <div className="col-4"></div>
                                    <div className="col-3 send-message pe-0">
                                      <Label className="ps-2">
                                        Send Message
                                        <span className="redstar_required"></span>
                                      </Label>
                                    </div>
                                    <div className="col-3 send-message p-0">
                                      {isTemplatePick && (
                                        <>
                                          <Modall
                                            isOpen={isTemplatePick}
                                            onClose={templateClose}
                                            // size="xxl"
                                            size={modalSize}
                                            title={modalTempTitle}
                                          >
                                            <TemplateList
                                              component="SEQUENCE"
                                              templateModal={isTemplatePick}
                                              handleClose={templateClose}
                                              modalTitle={setmodalTempTitle}
                                              setSelectTemplateId={(templateId: string) => ""}
                                              templateVariables={
                                                templateVariables
                                              }
                                              setRemainderTemplateId={(templateId: string) => ""}
                                              setModalSize={setModalSize}
                                            />
                                          </Modall>
                                        </>
                                      )}
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          templateOpen(index, formikValues)
                                        }
                                        className="template-button"
                                        name={`sequenceFlowSteps[${index}].name`}
                                        style={{position: 'relative', left: '-0.55rem'}}
                                      >
                                        <div className="field-text">
                                          {flow.name === ""
                                            ? "Select Template"
                                            : "Change Template"}
                                        </div>
                                        <div className="icons ms-2">
                                          {flow.name === "" ? (
                                            <CgMathPlus />
                                          ) : (
                                            <FiEdit3 />
                                          )}
                                        </div>
                                      </Button>
                                      <ErrorMessage
                                        className="text-danger pt-1"
                                        name={`sequenceFlowSteps[${index}].name`}
                                        component="div"
                                      />
                                    </div>
                                    <div className="col-6"></div>
                                    <div className="col-12 ps-4">
                                      <label className="fw-bold">
                                        Custom Delivery Preference{" "}
                                      </label>
                                      <span className="redstar_required"></span>
                                    </div>
                                    <div className="col-12 delivery mb-2">
                                      <div className="row">
                                        {SelectFlowTimes.map(
                                          (select, innerIndex) => (
                                            <>
                                              <div
                                                className="col-3 ps-4"
                                                key={innerIndex}
                                              >
                                                <label
                                                  className="d-flex align-items-center"
                                                  htmlFor={`SelectTimesFlow-${index}-${select.value}`}
                                                >
                                                  <input
                                                    type="radio"
                                                    name={`sequenceFlowSteps[${index}].customTime`}
                                                    className="me-2"
                                                    value={
                                                      flowData[index]
                                                        ?.customTime ===
                                                      select.value
                                                        ? flowData[index]
                                                            ?.customTime
                                                        : select.value
                                                    }
                                                    id={`SelectTimesFlow-${index}-${select.value}`}
                                                    onChange={(e: any) => {
                                                      setFieldValue(
                                                        `sequenceFlowSteps[${index}].customTime`,
                                                        e.target.value
                                                      );
                                                      addChangeFlow(
                                                        `customTime`,
                                                        e.target.value,
                                                        setFieldValue,
                                                        index
                                                      );
                                                    }}
                                                    checked={
                                                      formikValues
                                                        .sequenceFlowSteps[
                                                        index
                                                      ]?.customTime ===
                                                      select.value
                                                    }
                                                  />
                                                  {select.label}
                                                  &nbsp;&nbsp;
                                                  {select.value === "Default" &&
                                                    formikValues
                                                      .sequenceFlowSteps[index]
                                                      ?.customTime ===
                                                      "Default" && (
                                                      <TooltipReference
                                                        placement="bottom"
                                                        content="Messages can be sent anytime between 12:00 am to 11:59 pm"
                                                        tooltipId="displayName"
                                                        icon={faInfoCircle}
                                                      ></TooltipReference>
                                                    )}
                                                </label>
                                              </div>
                                            </>
                                          )
                                        )}
                                      </div>
                                    </div>
                                    {flowData[index]?.customTime ===
                                      "Custom" && (
                                      <>
                                        <Row className="ms-2">
                                          <Col
                                            md="3"
                                            className="d-flex  align-items-center justify-content-center mb-3"
                                          >
                                            <label className="m-0">Days </label>{" "}
                                            <span className="redstar_required"></span>
                                          </Col>
                                          <Col
                                            md={8}
                                            style={{ lineHeight: "1.5" }}
                                            className="mb-3 ps-4"
                                            // className="d-flex justify-start-end align-items-center"
                                          >
                                            {sequenceFlowStep[index]
                                              .scheduledTimes && (
                                              <>
                                                <DaySelection
                                                  daysList={props.daysList}
                                                  field={{
                                                    name: `sequenceFlowSteps[${index}].scheduledTimes`,
                                                    value:
                                                      sequenceFlowSteps[index]
                                                        .scheduledTimes,
                                                  }}
                                                  scheduledTimes={
                                                    sequenceFlowSteps[index]
                                                      .scheduledTimes
                                                  }
                                                  addChangeFlow={addChangeFlow}
                                                  flowIndex={index}
                                                />
                                              </>
                                            )}
                                            <ErrorMessage
                                              className="text-danger pt-1"
                                              name={`sequenceFlowSteps[${index}].scheduledTimes`}
                                              component="div"
                                            />
                                          </Col>
                                        </Row>
                                        <Row className="ms-2">
                                          <Col
                                            md="3"
                                            className="d-flex align-items-left justify-content-center"
                                          >
                                            <label>
                                              Time{" "}
                                              <span className="redstar_required"></span>
                                            </label>
                                          </Col>
                                          <Col className="ps-4">
                                            <Row >
                                              <Col md={4}>
                                                <Field
                                                  type="time"
                                                  className="form-control cursor-pointer"
                                                  name={`sequenceFlowSteps[${index}].fromTime`}
                                                  value={
                                                    flowData[index]?.fromTime
                                                  }
                                                  onChange={(event: any) => {
                                                    setFieldValue(
                                                      `sequenceFlowSteps[${index}].fromTime`,
                                                      event.target.value
                                                    );
                                                    addChangeFlow(
                                                      `fromTime`,
                                                      event.target.value,
                                                      setFieldValue,
                                                      index
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  className="text-danger pt-1"
                                                  name={`sequenceFlowSteps[${index}].fromTime`}
                                                  component="div"
                                                />
                                              </Col>
                                              <Col md={1} 
                                              style={{ width: "3.8rem" }}
                                              >
                                              <HiMiniArrowSmallRight size={30} />
                                              </Col>
                                              <Col md={4}>
                                                <Field
                                                  type="time"
                                                  className="form-control cursor-pointer"
                                                  name={`sequenceFlowSteps[${index}].toTime`}
                                                  value={
                                                    flowData[index]?.toTime ??
                                                    ""
                                                  }
                                                  onChange={(event: any) => {
                                                    setFieldValue(
                                                      `sequenceFlowSteps[${index}].toTime`,
                                                      event.target.value
                                                    );
                                                    addChangeFlow(
                                                      `toTime`,
                                                      event.target.value,
                                                      setFieldValue,
                                                      index
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  className="text-danger pt-1"
                                                  name={`sequenceFlowSteps[${index}].toTime`}
                                                  component="div"
                                                />
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </>
                                    )}
                                  </div>
                                </div>                        
                                {flowData[index]?.template?.category === 'MARKETING' &&(
                                <div  className="col-9 waring-alert">
                                        <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                                        Only marketing opted-in contacts will receive this marketing message template.
                                    </div>
                              )}
                              </div>
                              {/* dislay Template */}
                              <div className="col-4 p-2">
                                {flowData && flowData[index]?.template && (
                                  <Col className="p-0">
                                    <div key={index}>
                                      <Card className="bizContentShow">
                                        <CardBody key={index} className="p-2">
                                          <SingleTemplate
                                            key={index}
                                            singleTempData={{
                                              ...flowData[index].template,
                                              media:
                                                flowData[index]?.media ??
                                                flowData[index].template.media,
                                              components: flowData[
                                                index
                                              ].template?.components?.map(
                                                (
                                                  component: any,
                                                  idx: number
                                                ) => {
                                                  if (
                                                    idx === 0 &&
                                                    component.type === "HEADER"
                                                  ) {
                                                    return {
                                                      ...component,
                                                      example: {
                                                        ...component.example,
                                                        header_handle: flowData[
                                                          index
                                                        ]?.media
                                                          ? [
                                                              flowData[index]
                                                                .media.url,
                                                            ]
                                                          : component?.example
                                                              ?.header_handle,
                                                      },
                                                    };
                                                  } else if (
                                                    // idx === 1 && 
                                                    component.type === "BODY"
                                                  ) {

                                                    return {
                                                      ...component,
                                                      example: {
                                                        ...component.example,
                                                        body_text:
                                                          flowData[index]
                                                            ?.templateText
                                                            ?.length > 0
                                                            ? flowData[
                                                                index
                                                              ]?.templateText.map(
                                                                (item: any) =>
                                                                  item?.bodyText
                                                              )
                                                            : component?.example
                                                                ?.body_text,
                                                      },
                                                    };
                                                  } else {
                                                    return component;
                                                  }
                                                }
                                              ),
                                            }}
                                            component="chatcam"
                                          />
                                        </CardBody>
                                      </Card>
                                    </div>
                                  </Col>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </Form>
          </div>
        );
      })}

      <div className="row add-squence-flow">
        <div className="col-12">|</div>
        <div className="col-12">
          <div className="add-flow-button">
            <TooltipReference
              placement="right"
              content="Add Steps"
              tooltipId="AddSteps"
            >
              <PiPlusCircleFill
                className="cursor-pointer"
                color="#00b598"
                size={40}
                onClick={(e: any) => nextFlowStart(e)}
              />
            </TooltipReference>
          </div>
        </div>
        <div className="col-12">
          <IoIosArrowRoundDown size={25} />
        </div>
      </div>
    </div>
  );
};

export default AddFlow;
