import React, { useMemo, useEffect, useState } from "react";
import { sliceText } from "../../common/sliceText";
import { renderMessage } from "../../common/commonfns";
import "./template.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList,
  faExternalLinkAlt, 
  faReply,
  faCopy} from "@fortawesome/free-solid-svg-icons";
import phone from "../../assets/img/phonesymbol.png";
import { FaRegCopy } from "react-icons/fa";
import MapLoader from "../../common/MapLoader";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

interface SingleTemplateProps {
  singleTempData: any;
  component: string;
  selectedConversation?: any;
  status?: number;
}

const SingleTemplate: React.FC<SingleTemplateProps> = ({
  singleTempData,
  component,
  status,
}) => {
  const [templateData, mapTemplateData] = useState<any>([]);
  
  
  useEffect(() => {
    if (component === "conversation") {
      mapTemplateData(singleTempData?.action);

    } else {

      mapTemplateData(singleTempData.components);
    }
  }, [singleTempData]);
  
  const handleMapClick = (lat: any, long: any) => {
    const url = `https://www.google.co.in/maps/place/${lat},${long}`;
    window.open(url, "_blank");
  };

  const formatTimeDifference = (futureTimestamp: number) => {
    const diff = futureTimestamp - Date.now();
  
    if (diff <= 0) {
      return "";
    }
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    
    if (diff >= oneDayInMilliseconds) {
      const futureDate = new Date(futureTimestamp);
      const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit' };
      return `Offer ends on ${futureDate.toLocaleDateString('en-US', options)}`;
    }
    const hours = Math.floor(diff / (1000 * 60 * 60)).toString().padStart(2, '0');
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
    const seconds = Math.floor((diff % (1000 * 60)) / 1000).toString().padStart(2, '0');
  
    return `Offer ends within ${hours}:${minutes}:${seconds}`;
  };
  
  


  return (
    <div>
      <h5>{singleTempData?.showName}</h5>
      <div
        className={`p-2 ${component === "chatcam"
          ? "selectBackground"
          : status === -1
            ? "failedMsgBg"
            : "convBackground"
          }`}
          style={{borderRadius: '10px 0px 10px 10px'}}
      >
        {singleTempData?.isCatalog && singleTempData?.buttonColumns && (
          <img
            src={singleTempData?.buttonColumns[0]}
            className="headerImg"
            alt="catalogImg"
          />
        )}

        {/* Render TEXT, HEADER, BODY, and FOOTER content */}
        {templateData?.map((tempDet: any, tempind: number) => {
          if (tempDet.type === "HEADER") {
            if (tempDet.format === "TEXT") {
              return (
                <div className="fontWeight" key={tempind}>
                  {tempDet.text}
                </div>
              );
            } else if (tempDet.format === "IMAGE") {
              return (
                <img
                  key={tempind}
                  src={tempDet?.example?.header_handle[0]}
                  className="headerImg"
                  alt="oldimage"
                />
              );
            } else if (tempDet.format === "VIDEO") {
              return (
                <video
                  key={tempind}
                  controls
                  src={tempDet?.example?.header_handle[0]}
                  className="headerImg"
                />
              );
            } else if (tempDet.format === "DOCUMENT") {
              return (
                <iframe
                  src={tempDet?.example?.header_handle[0]}
                  className="headerImg"
                ></iframe>
              );
            } else if (tempDet.format === "LOCATION") {
              const latitude = parseFloat(templateData?.[0]?.example?.headerLocation?.latitude);
              const longitude = parseFloat(templateData?.[0]?.example?.headerLocation?.longitude);
              
              if (!isNaN(latitude) && !isNaN(longitude)) {
                return (
                  <MapLoader key={tempind}>
                    <div id="send-msg-map">
                      <GoogleMap
                        id="msg-map"
                        mapContainerStyle={{ width: "auto", height: "220px" }}
                        zoom={12}
                        center={{ lat: latitude, lng: longitude }}
                        onClick={() => {
                          if (singleTempData?.action) {
                            handleMapClick(latitude, longitude);
                          }
                        }}
                      >
                        <Marker
                          position={{ lat: latitude, lng: longitude }}
                        />
                      </GoogleMap>
                    </div>
                  </MapLoader>
                );
              } else {
                // Handle invalid lat/lng here
                return <p key={tempind}>Invalid location coordinates.</p>;
              }
            
            }
          }

          if (tempDet.type === "BODY" && singleTempData.category!=="AUTHENTICATION") {
            return (
              <div className={`${singleTempData.category ? 'pt-3' : ''}`} key={tempind}>
                {renderMessage(tempDet, tempDet.text, "conversation", status)}
              </div>
            );
          } 
          
          if(templateData?.some((e:any) => e.type === 'LIMITED_TIME_OFFER')){
          if ((tempDet.limited_time_offer || tempDet.buttons) && ( tempDet.buttons?.[0]?.type?.toUpperCase() !== "OTP" &&
          tempDet.buttons?.[0]?.otp_type?.toUpperCase() !== "COPY_CODE")) {
            return (
              <div className="bg-white text-muted pl-1" key={tempind}>
                {tempDet?.limited_time_offer?.text && (
                  <p className="m-0 text-dark">{tempDet.limited_time_offer.text}</p>
                )}
                 {tempDet?.limited_time_offer?.example?.[0] && (
                  <p className="m-0">{formatTimeDifference(tempDet.limited_time_offer.example[0])}</p>
                )}
                {tempDet?.buttons?.[0]?.example?.[0] && (!singleTempData?.isCoupon) && tempDet?.buttons?.[0]?.type === 'COPY_CODE' && (
                  <p className="m-0">Code: {tempDet.buttons[0].example[0]}</p>
                )}
              </div>
            );
          }
        }
             
          // if (tempDet?.type === "BODY" && singleTempData?.category==="AUTHENTICATION") {
          //   const message = singleTempData?.buttonColumns && singleTempData?.buttonColumns[0]?.replace(/\n/g, "") ;
          //   return (
          //     <div className="pt-3" key={tempind}>
          //       {renderMessage(tempDet, message, "conversation", status)}
          //     </div>
          //   );
          // } 
          // ---------------AUTHENTICATION OTP & COPY CODE-------------------//
          if (
            tempDet?.type === "BUTTONS" &&
            Array.isArray(tempDet.buttons) && tempDet.buttons.length > 0 &&
            tempDet.buttons[0]?.type?.toUpperCase() === "OTP" &&
            tempDet.buttons[0]?.otp_type?.toUpperCase() === "COPY_CODE" && 
            component === 'conversation'
          ) {
            const security = Array.isArray(singleTempData?.action) &&
            singleTempData?.action[0]?.type === "BODY" &&
            singleTempData?.action[0]?.add_security_recommendation === true;
            
            const message = security 
              ? `${tempDet.buttons[0]?.example ?? tempDet.buttons[0]?.text} is your verification code.` 
              : `${tempDet.buttons[0]?.example ?? tempDet.buttons[0]?.text} is your verification code. For your security, do not share this code.`;
            
            return (
              <div className="" key={tempind}>
                {renderMessage(tempDet, message, "conversation")}
              </div>
            );
          }
          
          if (tempDet.type === "FOOTER") {
            return (
              <div 
                // className="pt-2 textstyle1"
                className={`${tempDet.text ? `pt-2 textstyle1` : ''}`}
                key={tempind}>
                <i>{tempDet.text}</i>
              </div>
            );
          }
          // if (tempDet.type === "BUTTONS" && tempDet.buttons[0]?.type==="otp" &&component!=="chatcam") {
          //   const security=singleTempData?.action&&singleTempData?.action[0]?.type==="BODY" && singleTempData?.action[0]?.add_security_recommendation===true;
          //   return (
          //     singleTempData.action && (
          //       <div className="" key={tempind}>
          //         <i>{tempDet.buttons[0].text} is your verification code</i>
          //         {security && (
          //           <div className="textstyle1">
          //             <i>For your security recommendations, do not share this code.</i>
          //           </div>
          //         )}
          //       </div>
          //     )
          //   );
          // }

          // ---------------AUTHENTICATION OTP & COPY CODE-------------------//
          
      if (
        tempDet.type === "BUTTONS" &&
        tempDet.buttons[0]?.type?.toUpperCase() === "OTP" &&
        component === "chatcam" &&
        tempDet.buttons[0]?.otp_type?.toUpperCase() === "COPY_CODE" 
        // &&
        // tempDet.buttons[0]?.text === "Copy"
      ) {
        const security = Array.isArray(singleTempData?.components) &&
        singleTempData?.components[0]?.type === "BODY" &&
        singleTempData?.components[0]?.add_security_recommendation === true;
        const sequenceText =  singleTempData?.components?.[0]?.example?.body_text?.[0] ?? '';
         const text = tempDet?.buttons?.[0]?.example ? tempDet?.buttons?.[0]?.example : sequenceText ? sequenceText : tempDet.buttons?.[0]?.text;
        const message = security 
        ? ` {${text}} is your verification code. For your security, do not share this code.` 
        : `{${text}} is your verification code.`;
        return (
            <div className="" key={tempind}>
              {message}
            </div>
        );
      }

          // if(tempDet.type==="BODY" && tempDet.add_security_recommendation){
          //   return(
          //     <div className="pt-2 textstyle1" key={tempind}>
          //       <i>For your security recommendation,do not share this code</i>
          //     </div>
          //   )
          // }
          return null; 
        })}
        {/* {singleTempData?.action && singleTempData?.action[2]?.type==="BUTTON" && singleTempData?.action[2]?.type==="OTP" &&(
            <div className="">
            <i>{singleTempData?.action[2]?.buttons[0]?.text} is your verification code</i>
          </div>
        ) } */}
  {component === 'conversation' && ((templateData[1]?.type === 'CAROUSEL' && templateData[1]?.type) ||  (templateData[0]?.type === 'CAROUSEL' && templateData[0]?.type))? 
   <div className={`singleCarousel col-11  p-1`}>
   <div className="d-flex">
     {(templateData[1]?.cards || templateData[0]?.cards)?.map((e: any, ind: number) => (
       <div key={ind} className="templateRow">
        <div className="">
         <span>
          {e?.components[0]?.format === 'IMAGE'?
           <img src={e?.components[0]?.example?.header_handle[0]} className="" width={170} height={90} alt="Header" /> :
           e?.components[0]?.format === 'VIDEO'? <video  src={e?.components[0]?.example?.header_handle[0]}
                                              controls
                                              width={170}
                                              height={90}
                                          /> : ''}
         </span>
         {e?.components[1]?.text && <div title={e?.components[1]?.text}>{e?.components[1]?.text.length > 15 && e?.components[1]?.text ? e?.components[1]?.text?.slice(0,14) + '...' : e?.components[1]?.text}</div>}
         </div>
         <div className="pt-2">
           {e?.components[2]?.buttons?.map((ele: any, buttonIndex: number) => (
             <p key={buttonIndex} className="quickbtns m-1 text-center" title={ele?.text}>
                {ele?.type === 'QUICK_REPLY'? '' : ele?.type === 'PHONE_NUMBER'? <img src={phone} width={20} /> : ele?.type === 'URL'? <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1"/> : '' }  
                {ele?.text?.length > 15? ele?.text?.slice(0,14)+'...' : ele?.text}</p>
           ))}
         </div>
       </div>
     ))}
   </div>
 </div> : ''}
{singleTempData?.category === 'MARKETING' && ((singleTempData?.components[1]?.type === 'CAROUSEL' && singleTempData?.components[1]?.type) || singleTempData?.components[0]?.type === 'CAROUSEL' && singleTempData?.components[0]?.type)? 
     <div className={`singleCarousel col-11  p-1`}>
     <div className="d-flex">
       {(singleTempData?.components[1].cards || singleTempData?.components[0].cards)?.map((e: any, ind: number) => (
         <div key={ind} className="templateRow">
          <div className="">
           <span>
            {e?.components[0]?.format === 'IMAGE'?
             <img src={e?.components[0]?.example?.header_handle[0]} className="" width={170} height={90} alt="Header" /> :
             e?.components[0]?.format === 'VIDEO'? <video  src={e?.components[0]?.example?.header_handle[0]}
                                                controls
                                                width={170}
                                                height={90}
                                            /> : ''}
           </span>
           {e?.components[1]?.text && <div title={e?.components[1]?.text}>{e?.components[1]?.text.length > 15 && e?.components[1]?.text ? e?.components[1]?.text?.slice(0,14) + '...' : e?.components[1]?.text}</div>}
           </div>
           <div className="pt-2">
             {e?.components[2]?.buttons?.map((ele: any, buttonIndex: number) => (
               <p key={buttonIndex} className="quickbtns m-1 text-center" title={ele?.text}>
                  {ele?.type === 'QUICK_REPLY'? '' : ele?.type === 'PHONE_NUMBER'? <img src={phone} width={20} /> : ele?.type === 'URL'? <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1"/> : '' }  
                  {ele?.text?.length > 15? ele?.text?.slice(0,14)+'...' : ele?.text}</p>
             ))}
           </div>
         </div>
       ))}
     </div>
   </div>
   : ''}
      </div>
      <div className="buttonsDiv">
        {templateData?.map((tempDet: any, tempind: number) => {
          const isFailed = status === -1;
          const buttonClassName = `pt-2 mt-1 text-center singleTempBtns ${component === "chatcam"
            ? "selectbuttons"
            : isFailed
              ? "failedMsgBg failedMsgBtns"
              : "convbuttons"
            } 
            ${component === "conversation" ? "convButton" : ""}
            ${tempDet?.buttons?.length === 1 ? "col-12 mb-2" : ""} 
            ${tempDet?.buttons?.length === 3 && tempind === 0 ? "mr-2" : ""} 
            ${tempDet?.buttons?.length === 3 && tempind === 2 ? "mb-1" : ""}`;
        
        
          if (tempDet.type === "BUTTONS" && tempDet?.buttons?.[0]?.type !== 'COPY_CODE') {
            const numButtons = tempDet?.buttons?.length;
            const renderedButtons = tempDet?.buttons
              ?.slice(0, 2)
              .map((btn: any, innerindex: number) => (
                <span
                  key={innerindex}
                  title={btn.text}
                  className={`pt-2 mt-1 text-center singleTempBtns ${component === "chatcam"
                    ? "selectbuttons"
                    : status == -1
                      ? "failedMsgBg failedMsgBtns"
                      : "convbuttons"
                    } 
                   ${component === "conversation" ? "convButton" : ""}
                  ${numButtons === 1
                      ? "col-12 mb-2"
                      : innerindex === 2
                        ? "col-12"
                        : "singleTempBtnWidth"
                    } ${numButtons === 3 && innerindex === 0
                      ? "mr-2"
                      : numButtons === 2 && innerindex === 0
                        ? "mr-2"
                        : ""
                    } 
                                                  ${numButtons === 3 &&
                      innerindex === 2
                      ? "mb-1"
                      : ""
                    }`}
                >
                  {btn?.type?.toUpperCase() === "OTP" &&
                  btn?.otp_type?.toUpperCase() === "COPY_CODE" ?
                  <FaRegCopy /> :
                 <FontAwesomeIcon icon={faReply} className="mr-1"/> } {numButtons === 1 && innerindex === 0
                    ? sliceText(btn.text, 30)
                    : numButtons === 3 && innerindex === 2
                      ? sliceText(btn.text, 30)
                      : sliceText(btn.text, 30)}

                  {/* {sliceText(btn.text, 14)} */}
                </span>
              ));
            return (
              <div
                className={
                  status === -1 ? "failedReplyBtnsColor" : "replyBtnsColor"
                }
                key={tempind}
              >
                {renderedButtons}
                {numButtons > 2 && (
                  <span
                    className={`pt-2 mt-1 text-center singleTempBtns ${component === "chatcam"
                      ? "selectbuttons"
                      : status == -1
                        ? "failedMsgBg failedMsgBtns"
                        : "convbuttons"
                      } singleTempBtnWidth`}
                  >
                    <FontAwesomeIcon
                      icon={faList}
                      widths={80}
                      className="me-2"
                    ></FontAwesomeIcon>
                    Explore More
                  </span>
                )}
              </div>
            );
          }
          if (tempDet?.buttons?.[0]?.type === 'COPY_CODE') {
            return (
              <div className="replyBtnsColor" key={tempind}>
                <div className={buttonClassName}>
                  <FontAwesomeIcon icon={faCopy} className="me-2" />Copy offer code
                </div>
                <div className={buttonClassName}>
                  <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1" />
                  {tempDet?.buttons?.[1]?.text?.length > 18
                    ? tempDet?.buttons?.[1]?.text.slice(0, 17) + '...'
                    : tempDet?.buttons?.[1]?.text}
                </div>
              </div>
            );
          }
          return null; // handle other cases or return null for unrecognized types
        })}
      </div>
    </div>
  );
};

export default SingleTemplate;
