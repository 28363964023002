import React, { useEffect, useState, useCallback } from 'react';
import { getTag, createTag, deleteTag } from './tagApi';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button } from 'reactstrap';
import './tag.scss';
import Modall from '../../common/modall';
import * as Yup from 'yup';
import moment from "moment";
import SearchInput from '../../common/searchComponent';
import {Row,Col} from 'reactstrap';
import BizTable from '../../common/BizTable';
import { sweetalert} from "../../common/alert";
import {dateSplit} from '../../common/commonfns';

interface RowDelete {
  id: number;
  name: string;
}

const Tag = () => {
  const [allData, setAllData] = useState([]);
  const [open, openModal] = useState<boolean>(false);
  const [delAlert, setDelete] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [rowDelete, setRowDelete] = useState<RowDelete>({ id: 0, name: "" });
  const businessId: any = useSelector((state: any) => state.cartreducer.business.business.uid);
  const business: any = useSelector((state: any) => state.cartreducer.business);
  const [key, setKey] = useState('');
  const [tagCount,setTagCount] = useState(0)
  const getAllData = async (search: string) => {
    if (businessId) {
      const payload = {
        "businessUid": businessId,
        "limit": 1000,
        "page": 0,
        "search": search?? null,
      }
      setIsLoading(true);
      try {
        const response = await getTag(payload, 'tags');
        const data = response.data;
        setAllData(data.tagsDto);
        setIsLoading(false);
      }
      catch (error) {
        console.error('Error:', error);
        setIsLoading(false);
      }
    }
  };

  const [isInvalidAccess, setIsInvalidAccess] = useState(false);
  const roleRules = useSelector((state: any) => state.cartreducer.roleRules);

  useEffect(() => {  
    if(roleRules) {
      const isInvalidAccess = roleRules.canViewTags === true && roleRules.canManageTags === false;
      setIsInvalidAccess(isInvalidAccess);
    }
  }, [roleRules]);


  const fetchData = useCallback(async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {
    if(businessId){
    const listPayload = {
      businessUid: businessId,
      page:  currentPage,
      limit: pageSize,
      search: searchTerm
    };

    const response = await getTag(listPayload, 'tags');
    if(response){
      const {tagsDto,count} = response.data;
      const countData = searchTerm ? tagsDto.length : count;
      setTagCount(countData)
      setAllData(tagsDto);
      setIsLoading(false);
      return {data:tagsDto,totalPages:100}
    }else {
      return {data:[],totalPages:0}
    }
    }
    else{
      return {data:[],totalPages:0}
    }
  }, [businessId,searchTerm]);
 

  const addTag = () => {
    setDelete(false);
    openModal(true);
  }

  const formatCreatedOn = (date: string) => {
    return <span title={moment(date).format("DD/MM/YY HH:mm")}>{moment(date).format("DD MMM")}</span>;
  };

  const getNameFromMember = (cell: any, row: any) => {
    if (row.memberDTO) {
      const Name = row.memberDTO.name.charAt(0).toUpperCase() + row.memberDTO.name.slice(1);

      return (
        <div className='d-flex logomargin'>
          <div className="circlelogo ms-2">
            {row.memberDTO.name[0].toUpperCase()}
          </div>
          <p className='mt-2 mb-0'>{Name}</p>
        </div>
      );
    }
    return '';
  };

  const closeModal = () => {
    openModal(false)
  }

  const validation = Yup.object({
    name: Yup.string()
      .trim()
      .required('Tag name is required')
  });

  const handleSubmit = (value: any) => {
    const payload = {
      "businessUid": business.business?.uid,
      "memberId": business.uid,
      "name": value.name
    }
    createTag(payload).then(() => {
      getAllData(key);
    })
    openModal(false);
  }

  const delTag = (id: number, name: string) => {
    sweetalert(
      "warning",
      "NOTE: Contacts linked to this tag will be deleted. Are you sure you want to continue?",
      () => {
    const payload = {
      "id": id,
      "name": name
    }
    deleteTag(payload).then(() => {
      getAllData(key);
    })
    openModal(false);
  },
  () => { }
 );
}

  const deletefun = (row: any) => {
    const data = {
      "id": row.id, "name": row.name
    }
    setRowDelete(data);
    delTag(row?.id, row?.name)
    // setDelete(true);
    // openModal(true);
  }

  const deletealert = (cell: any, row: any) => {
    return (
      <span className={`${isInvalidAccess ? "notAllowed" : ""}`}>
        <svg
          width="16"
          height="16"
          className={`me-3 ${isInvalidAccess ? "isDisabled" : ""}`}
          onClick={() => { deletefun(row) }}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer' }}
        >
          <path
            d="M2 4H3.33333H14"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.66602 7.3335V11.3335"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.33398 7.3335V11.3335"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </ span>
    )
  }

  const  columns = [
    {
      Header: (
        <input
          type="checkbox"
          hidden={true}
          // checked={this.state.allChecked}
          // onChange={this.toggleAllCheckboxes}
        />
      ),
      accessor: "checkbox",
      // Cell: this.checkboxCellRenderer,
      sticky: "left",
    },
      {
        Header: "Name",
        accessor: "name",
        sticky: "left",
        Cell: ({ cell, row }: any) => <p title={cell.value}>{cell.value?.length > 20? cell.value.slice(0,19) + '...' : cell.value }</p>
      },
      {
        Header: "Created By",
        accessor: "createdby",
        Cell: ({ cell, row }: any) => getNameFromMember(cell.value, row.original),
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ cell, row }: any) => <p title={moment(cell.value).format("MMM YYYY h:mm A")}>{dateSplit(cell.value)}</p>,
      },
      {
        Header: "Actions",
        accessor: "edit",
        Cell: ({ cell, row }: any) => deletealert(cell.value, row.original),
      }
    ] as any;
    
  

  // useEffect(() => {
  //   getAllData('');
  // }, [businessId])
  const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    setSearchTerm(searchTermCheck);
    setKey(searchTerm);
    getAllData(searchTerm)
  }
  return (
    <div className="name-tag mb-2">
      <h5 className='mt-3'>Tags</h5>
      <Row className='pt-3 pb-2'>
        <Col md={3}>
        <SearchInput component="tag" onSearchChange={onSearchChange} 
          placeHolder='Search Tags'
          />
        </Col>
        <Col></Col>
        <Col md={2}>
      <Button disabled={isInvalidAccess} className='sendButton' onClick={() => addTag()}>+ Add Tag</Button>
      </Col>
      </Row>
      <div className=''>
          {isLoading ? (
          <div className="centerItemsWithWidth offset-md-3">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
          <BizTable
          columns={columns}
          fetchData={fetchData}
          counts={tagCount}
          refetchData={allData}
          tableName='TAGS'
          />
          </>
        // <BootstrapTable data={allData} pagination={allData.length > 10 ? true : false} version="4" striped hover
        //   containerStyle={{ textAlign: 'left' }}
        //   options={{
        //     headerAlign: "center",
        //     noDataText: "No records found",
        //     sortIndicator: true,
        //     hidePageListOnlyOnePage: true,
        //     clearSearch: false,
        //     alwaysShowAllBtns: true,
        //     withFirstAndLast: true,
        //     sizePerPage: 10,
        //     paginationPosition: "bottom",
        //   } as any}>
        //   <TableHeaderColumn dataField='id' isKey hidden>#</TableHeaderColumn>
        //   <TableHeaderColumn dataField='name' columnClassName="text-wrap" dataAlign='left' >Tag name</TableHeaderColumn>
        //   <TableHeaderColumn dataAlign='center' columnClassName="text-wrap" dataFormat={getNameFromMember}>Created by</TableHeaderColumn>
        //   <TableHeaderColumn dataField='createdAt' dataAlign='center' dataFormat={formatCreatedOn}>Created at</TableHeaderColumn>
        //   <TableHeaderColumn dataAlign='center' dataFormat={deletealert}>Actions</TableHeaderColumn>
        // </BootstrapTable>
        )}
      </div>
      <Modall isOpen={open} onClose={closeModal} title={delAlert ? `Delete Tag - ${rowDelete.name}` : "Create New Tag"} size="md">
        <Formik
          initialValues={{
            name: ''
          }}
          validationSchema={validation}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {delAlert ?
                <>
                  <h6>Do you want to delete the tag?</h6>
                  <p> NOTE: "Contacts linked to this tag will be deleted.</p>
                  <div className='d-flex justify-content-end mt-4'>
                    <Button type='button' onClick={closeModal} className='cancelButton w-25 p-1 me-2'>No</Button>
                    <Button type='button' onClick={() => delTag(rowDelete.id, rowDelete.name)} className='sendButton w-25 p-1'>Yes</Button>
                  </div>
                </> :
                <div>
                  <label>Name<span className="required" /></label>
                  <Field name='name' className='form-control'></Field>
                  <ErrorMessage name='name' component='div' className="errorMsg"></ErrorMessage>
                  <div className='d-flex justify-content-end mt-3'>
                    <Button type='button' onClick={closeModal} className='cancelButton w-25 p-1 me-2'>Cancel</Button>
                    <Button type='submit' onClick={() => handleSubmit} className='sendButton w-25 p-1'>Save</Button>
                  </div>
                </div>
              }
            </Form>
          )}
        </Formik>
      </Modall>

    </div>
  );
}

export default Tag;
