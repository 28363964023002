import { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Offcanvas, Button, Overlay, Popover } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import CreatableSelect from 'react-select/creatable';
import { BsTrash } from 'react-icons/bs';
import { MdAdd, MdCalendarToday, MdCheckBox, MdDataArray, MdDataObject, MdNumbers, MdTitle } from 'react-icons/md';
import { IEditorProps } from './types';
import { useElmentEditor } from './hooks';
import styles from './setVariable.module.css';
import EditorCaption from './editorCaption';
import VariableInputLabel from './variableInputLabel';
import { uuidv4 } from '../../../utils/uuid';
import { variableIcon } from '../../../icons';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { createVariable } from '../../../store/graphSlice';

interface IOption {
    id: string;
    name: string;
    value: string;
    type: 'TEXT';
}

interface FormData {
    variables: IOption[];
};

type IVarFormat = "TEXT" | "NUMBER" | "BOOLEAN" | "ARRAY" | "OBJECT" | "DATE";

interface OptionsInputProps {
    options: IOption[];
    placeholder?: string;
    addLabel?: string;
    isInvalid: boolean;
    isValid: boolean;
    error: any; // Type: string | string[] | FormikErrors<IOption>[] | undefined
    touched: any;
}

interface CreateVariableForm {
    name: string;
    format: IVarFormat;
}


interface SelectVariableProps {
    name: string;
    value: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    type?: IVarFormat | null;
    paymentId?: String;
    elementType?: string;
    varType?: string;
    isDisabled?: boolean;
}


export const SelectVariable: React.FC<SelectVariableProps> = props => {
    const [variableName, setVariableName] = useState("");
    const [show, setShow] = useState(false);
    const containerRef = useRef(null);
    const target = useRef(null);
    const formSubmitBtnRef = useRef<HTMLButtonElement>(null);
    const variables = useAppSelector((state) => state.graph.variables);
    const formik = useFormikContext();
    const dispatch = useAppDispatch();
    const varTypeHashmap = useMemo(() => {
        const hashMap: { [key: string]: string } = {};
        variables.forEach((item: any) => {
            hashMap[item.name] = item.type;
        });
        return hashMap;
    }, [variables])

    const variableNameSchema = yup.string().required("Variable name is required")
        .matches(/^[A-Za-z][A-Za-z0-9_]*$/, 'Variable name should start with alphabet; Cannot have special characters except underscore (_)')

    const schema = yup.object().shape({
        name: variableNameSchema,
        format: yup.string().required("Variable format is required")
            .oneOf(['TEXT', 'NUMBER', 'BOOLEAN', 'ARRAY', 'OBJECT', 'DATE'], 'Variable format is invalid'),
    });

    const createVariableHandler = async (data: CreateVariableForm) => {
        const variable = {
            name: data.name,
            value: props.value,
            type: data.format,
            paymentId: props.paymentId,
            id: uuidv4()
        };
        try {
            await variableNameSchema.validate(data.name);
            dispatch(createVariable(variable));
            props?.name === "payment_id" ? formik.setFieldValue("storageVariable.paymentId", data.name) : formik.setFieldValue(props.name, variable);
            setShow(false);
        } catch (validationError) {
            setShow(true);
            setTimeout(() => formSubmitBtnRef.current?.click(), 300);
        }
    }

    const isCreatable = (inputValue: string) => {
        return props.elementType !== 'condition' && inputValue.trim() !== '';
    };

    return (
        <div ref={containerRef}>
            <div ref={target} className='create' >

                <CreatableSelect
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 1100 }),
                        menu: base => ({ ...base, width: 370, zIndex: 100, position: "relative" })
                    }}

                    placeholder={props.placeholder}
                    value={props.value ? { value: props.value, label: props.value } : ''}
                    onChange={({ value }: any) => {
                        formik.setFieldValue(props.name + ".name", value);
                        if (props.onChange) {
                            props.onChange(value);
                        }
                    }}
                    formatOptionLabel={({ value }: any) => (
                        <>
                            {varTypeHashmap[value] === "TEXT" ? <MdTitle /> : null}
                            {varTypeHashmap[value] === "NUMBER" ? <MdNumbers /> : null}
                            {varTypeHashmap[value] === "OBJECT" ? <MdDataObject /> : null}
                            {varTypeHashmap[value] === "ARRAY" ? <MdDataArray /> : null}
                            {varTypeHashmap[value] === "BOOLEAN" ? <MdCheckBox /> : null}
                            {varTypeHashmap[value] === "DATE" ? <MdCalendarToday /> : null}
                            <span className="ml-2">{value}</span>
                        </>
                    )}
                    options={
                        variables
                            .filter((variable: any) => {
                                if (props?.varType === 'appointment_confirmation' && (variable?.specialType !== 'appointment_Flow' || !variable?.awaited)) return false;
                                if (!props.type) return true;
                                return variable.type === props.type
                            })
                            .map((variable: any) => {
                                return { value: variable.name, label: variable.name }
                            })
                    }
                    onCreateOption={(varName) => {
                        setVariableName(varName);
                        if (!props.type) {
                            setShow(true);
                        } else {
                            createVariableHandler({ name: varName, format: props.type });
                        }
                    }}
                    isDisabled={props?.isDisabled ? true : false}
                    isValidNewOption={isCreatable}
                />
            </div>
            <Overlay container={containerRef} target={target.current} show={show} placement="bottom">
                <Popover id="popover-createvariable" style={{ zIndex: 1500 }}>
                    <Popover.Header as="h3">Create variable</Popover.Header>
                    <Popover.Body>
                        <Formik
                            validationSchema={schema}
                            onSubmit={createVariableHandler}
                            initialValues={{ name: "", format: props.type || "TEXT" }}
                        >
                            {({ handleSubmit, values, touched, errors, handleChange, setFieldValue }) => {
                                useEffect(() => {
                                    if (variableName !== values.name) {
                                        setFieldValue("name", variableName);
                                    }
                                }, [variableName])
                                return (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Form.Group className='mb-3'>
                                                <Form.Label>Name <span className='required'></span></Form.Label>
                                                <Form.Control
                                                    name="name"
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    isInvalid={(touched.name && errors.name) ? true : false}
                                                    isValid={touched.name && !errors.name}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className='mb-3'>
                                                <Form.Label>Format <span className='required'></span></Form.Label>
                                                <Form.Select
                                                    name="format"
                                                    onChange={handleChange}
                                                    disabled={props.type ? true : false}
                                                    value={values.format}
                                                    isInvalid={(touched.format && errors.format) ? true : false}
                                                    isValid={touched.format && !errors.format}
                                                >
                                                    <option value="TEXT">Text</option>
                                                    <option value="NUMBER">Number</option>
                                                    <option value="BOOLEAN">Boolean</option>
                                                    <option value="ARRAY">Array</option>
                                                    <option value="OBJECT">Object</option>
                                                    <option value="DATE">Date</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors.format}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form>
                                        <div className="d-flex justify-content-center" >
                                            <Button
                                                ref={formSubmitBtnRef}
                                                type="submit"
                                                className="sendButton mr-2"
                                            > Create </Button>
                                            <Button
                                                onClick={() => {
                                                    setShow(false);
                                                }}
                                                className={`cancelButton`}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    );
};

SelectVariable.defaultProps = {
    placeholder: "Select Variable...",
    type: null
};

const OptionsInput: React.FC<OptionsInputProps> = ({ options, addLabel, ...otherProps }) => {
    const formik = useFormikContext();
    const handleChange = (index: number, key: 'value' | 'name', text: string) => {
        const newOptions: IOption[] = JSON.parse(JSON.stringify(options));
        newOptions[index] = { ...newOptions[index], [key]: text };
        formik.setFieldValue('variables', newOptions);
    };

    const handleDelete = (index: number) => {
        const newOptions: IOption[] = JSON.parse(JSON.stringify(options));
        newOptions.splice(index, 1);
        formik.setFieldValue('variables', newOptions);
    };

    const handleAddMatch = () => {
        const newOptions: IOption[] = JSON.parse(JSON.stringify(options));
        const uniqueId = Date.now().toString();
        newOptions.push({ id: uniqueId, name: '', value: '', type: 'TEXT' });
        formik.setFieldValue('variables', newOptions);
    };

    return (
        <>
            {options.map((variable, index) => {
                const getIsTouched = (key: string): boolean => {
                    // return (otherProps.touched && otherProps.touched[index] && otherProps.touched[index][key]) ? otherProps.touched[index][key] : false;
                    return otherProps.touched;
                }
                const getError = (key: string): null | string => {
                    if (otherProps.error && otherProps.error[index]) {
                        return otherProps.error[index][key];
                    }
                    return null;
                }
                return (
                    <div className={styles.optionBox} key={variable.id}>
                        <div className={styles.deleteButton}>
                            <Button
                                disabled={index === 0 && options.length === 1}
                                onClick={() => handleDelete(index)}
                                size='sm'
                                className='deleteSmallButton'
                            >
                                <BsTrash />
                            </Button>
                        </div>
                        <div className={styles.form}>
                            <Form.Group className='mb-3'>
                                <VariableInputLabel
                                    label='Variable'
                                    required
                                    error={getIsTouched('name') ? getError('name') : null}
                                >
                                    \
                                    <SelectVariable
                                        name={`variables[${index}]`}
                                        value={variable.name}
                                    />

                                </VariableInputLabel>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Value</Form.Label>
                                <Form.Control
                                    as='input'
                                    name={`variables[${index}].value`}
                                    placeholder='Enter a value'
                                    value={variable.value}
                                    isValid={!getError('value') && getIsTouched('value')}
                                    onChange={(e: any) => handleChange(index, 'value', e.target.value)}
                                />
                                {getError('value') !== null && getIsTouched('value') ? (
                                    <div className='invalid-feedback' style={{ display: "block" }}>
                                        {getError('value')}
                                    </div>
                                ) : null}
                            </Form.Group>
                        </div>
                    </div>
                );
            })}
            <Button onClick={handleAddMatch} size='sm' className='addButton'><MdAdd /></Button>
            {(otherProps.touched && otherProps.error && typeof otherProps.error === 'string') ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {otherProps.error}
                </div>
            ) : null}
        </>
    );
};

const SetVariableEditor: React.FC<IEditorProps> = props => {
    const [formData, setFormData] = useState<FormData>({
        variables: [
            { id: uuidv4(), name: '', value: '', type: 'TEXT' }
        ]
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'set_variable',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const variableSchema = yup.object().shape({
        name: yup.string().required('Variable name required'),
        value: yup.string().required('Variable value required'),
        type: yup.string(),
    });
    const schema = yup.object().shape({
        variables: yup.array().of(variableSchema).min(1, 'At least one variable is required')
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, values, touched, errors, setValues }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Set Variable' icon={<img alt='' style={{ width: 20 }} src={variableIcon} />} />
                        <Offcanvas.Body>
                            <Form.Group className="mb-5">
                                <OptionsInput
                                    options={values.variables}
                                    error={errors.variables}
                                    isInvalid={(touched.variables && errors.variables) ? true : false}
                                    isValid={(touched.variables && !errors.variables) ? true : false}
                                    touched={touched.variables ? true : false}

                                />
                            </Form.Group>

                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' disabled={props?.isInvalidAccess}>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default SetVariableEditor;