import React, { Component, MouseEvent } from "react";
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { Button, Form as FormBootstrap } from "react-bootstrap";
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import axios from './../../utils/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { connect, useSelector } from 'react-redux';
import { sweetalert, toast } from "../../common/alert";
import './QuickReply.scss';
import Toolbar from "./Toolbar";
import moment from 'moment';
import RightSidebar from "../../common/rightSidebar";
import { sliceText } from '../../common/sliceText';
import { Dispatch } from 'redux';
import BizTable from '../../common/BizTable';
import SearchInput from "../../common/searchComponent";
import { roleRules } from "../../redux/actions/action";
// import { ErrorImage } from "../../common/ErrorMessage";

interface SelectBtnOption {
  value: string;
  label: string;
}

interface MembersState {
  showContent: boolean;
  showVariable: boolean;
  showEdit: boolean;
  MessageLists: any;
  MessageEdit: any;
  count: any,
  message: any,
  selectedoption: string,
  selectedVariable: string,
  mouseText: string,
  textstyle: string,
  content: string,
  rows: any;
  toolbarMessage: any;
  isLoading: boolean;
  messageCount:number;
  searchTerm: string | null;
  nameCount: number;
  media:any;
  paginationPaload: {
    pageIndex: number;
    pageSize: number;
    searchText: string;
    currentPage: number;
  };
  isInvalidAccess: boolean;
  roleRules: any;
}
export interface FormValues {
  firstname: string;
  message: string;
}

interface Props {
  channelUid: { id: number } | null;
  roleRules: any; 
  quickReplies: any;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  quickReplies: (data: any) => dispatch({ type: 'QUICK_REPLIES', payload: data })
});

class QuickReply extends Component<Props, MembersState> {
  wrapperRef: HTMLDivElement | null = null;
  columns: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      showContent: false,
      showVariable: false,
      showEdit: false,
      MessageLists: [],
      MessageEdit: null,
      count: 0,
      message: '',
      selectedoption: '',
      selectedVariable: '',
      mouseText: '',
      content: '',
      textstyle: '',
      rows: [],
      toolbarMessage: '',
      isLoading: false,
      messageCount:0,
      searchTerm: null,
      nameCount: 0,
      media: null,
      paginationPaload: {
        pageIndex: 0,
        pageSize: 10,
        searchText: "",
        currentPage: 0,
      },
      isInvalidAccess: false,
      roleRules: []
    };
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }
  handleOutsideClick(event: any) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showContent: false });
    }
  }

  setWrapperRef(node: any) {
    this.wrapperRef = node;
  }

  handleClickAdd = (event: MouseEvent<HTMLButtonElement>) => {
    this.setState({ message: '', count: 0, rows: [] })
    this.setState((prevState) => ({
      showContent: !prevState.showContent,
      showEdit: false
    }));
  };

  handleClickEdit = (event: MouseEvent<HTMLButtonElement>, rowData: any) => {
    if ('uid' in rowData && 'name' in rowData && 'content' in rowData && 'id' in rowData) {
      this.setState({ MessageEdit: rowData });
       this.setState((prevState) => ({
        showContent: !prevState.showContent,
        showEdit: true,
      })) 
      this.setState({nameCount: rowData?.name ? rowData?.name.length : 0});
      // this.setState({media : rowData?.media ? rowData?.media : null});     
    }

  };
  
  MessageData = (data: any) => {
    this.setState({ message: data });
  }

  setMediaObj = (data : any) => {
    this.setState({ media: data });
  }

  renderEditColumn = (cell: any, rowData: object) => {
    return (
      <div className="d-flex justify-content-center">
        <div className={`mr-1`} onClick={
          () => this.handleClickEdit(cell, rowData)}>
          {/* <FontAwesomeIcon icon={faEdit} /> */}
          <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        </div>

        <div className={`${this.state.isInvalidAccess ? "notAllowed" : ""}`}>
        <div onClick={
          () => this.DeleteSelect(cell, rowData)} className={` ml-1 ${this.state.isInvalidAccess ? "disabledState" : ""}`}>
          {/* <FontAwesomeIcon icon={faTrashCan} /> */}
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 4H3.33333H14"
              stroke="#666E7D"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
              stroke="#666E7D"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.66602 7.3335V11.3335"
              stroke="#666E7D"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.33398 7.3335V11.3335"
              stroke="#666E7D"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        </div>

      </div>
    );
  };
  handleCancel = () => {
    this.setState({ showContent: false });
  };

  checkInvalidAccess() {
    const { roleRules } = this.props;
    if (roleRules) {
      const isInvalidAccess = roleRules.canViewQuickReplies === true && roleRules.canManageQuickReplies === false;
      this.setState({ isInvalidAccess });
      this.setState({roleRules});
    }
  }

  componentDidMount(): void {
    const { channelUid } = this.props;
    if (channelUid)
      // this.fetchMembers()
    document.addEventListener('mousedown', this.handleOutsideClick);
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<MembersState>, snapshot?: any): void {

    const { channelUid } = this.props;
    if (channelUid && channelUid !== prevProps.channelUid) {
      // this.fetchMembers()
      this.fetchData(0, 10, '', 0)
    }
    const { paginationPaload,searchTerm } = this.state;
    if(searchTerm !== prevState.searchTerm){
      const { pageIndex, pageSize, searchText, currentPage } = paginationPaload;
    this.fetchData(pageIndex, pageSize, searchText, currentPage )
    }
    if (this.state.roleRules !== this.props.roleRules) {
      this.checkInvalidAccess();
    }
  }

  handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { channelUid } = this.props;
    const addInvitePayload = {
      name: values.firstname,
      channelUid: channelUid,
      content: this.state.message,
      media: this.state.media ? this.state.media : null,
    };
    if (this.state.showEdit) {

      const Payload = {
        "id": this.state.MessageEdit.id,
        "uid": this.state.MessageEdit.uid,
        "name": values.firstname,
        "content": this.state.message,
        "channelUid": channelUid,
        "media": this.state.media ? this.state.media : null,
      }
      axios.patch('canned/update', Payload)
        .then(() => {
          toast('success', 'Updated successfully');
          // this.fetchMembers()
          this.fetchData(0, 20, '', 0)
        })
        .catch((error) => {
          toast('error', error.response.data.message);
        });

      if (values.firstname && values.message) {
        this.handleCancel();
      }
      setSubmitting(false);

    } else {

      // if (data.file.image) {
      //   const apiUrl = `media/saveMedia?uid=${uid}&name=${data.templateName}`;
      //   const formData = new FormData();
      //   formData.append('content', data.file.image);
      //   axios.post(apiUrl, formData, {
      //       headers: {
      //           'Content-Type': 'multipart/form-data'
      //       }
      //   })

      axios.post('canned/create', addInvitePayload)
        .then(() => {
          toast('success', 'Added Successfully');
          // this.fetchMembers()
          this.fetchData(0, 20, '', 0)
        })
        .catch((error) => {
          toast('error', error.response.data.message);
        });

      if (values.firstname && values.message) {
        this.handleCancel();
      }
      setSubmitting(false);
    };
  }
  DeleteSelect = (cell: any, rowData: any) => {
    if (rowData && rowData.uid) {
      const delpayload = {
        data: {
          uid: rowData.uid,
        }
      };
      sweetalert(
        'warning',
        'Are you sure you want to continue?',
        () => {
          axios.delete(`canned/${rowData.uid}/delete`)
            .then(() => {
              toast('success', 'Deleted Successfully');
              // this.fetchMembers();
              this.fetchData(0, 20, '', 0)
            })
            .catch((error) => {
              toast('error', error.response.data.message);
            });
        },
        () => {

        }
      );
      // axios.delete(`canned/${rowData.uid}/delete`)
      //   .then(() => {
      //     showAlert('success', 'Deleted Successfully');
      //     this.fetchMembers();
      //   })
      //   .catch((error) => {
      //     showAlert('error', error.response.data.message);
      //   });
    }
  };

  fetchData = async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any[]; totalPages: number }> => {
    const { channelUid } = this.props;
    const { searchTerm } = this.state;
    
    const pageData =
      this.state.paginationPaload.pageSize !== pageSize ? 0 : currentPage;
  
    const currentData = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchText: searchText,
      currentPage: pageData,
    };
  
    this.setState({ paginationPaload: currentData });
  
    const MessagePayload = {
      // params: {
        channelId: channelUid,
        page: searchTerm ? 0 : currentPage,
        limit: pageSize,
        search: searchTerm,
      // }
    };
  
    try {
      const response = await axios.post('canned/getAllCannedResponse', MessagePayload);
  
      if (response.data && response.data.cannedReponseList) {
        const { cannedReponseList, count } = response.data;
        const countData = searchTerm ? cannedReponseList.length : count;
        this.setState({ MessageLists: cannedReponseList, messageCount: countData });
        this.setState({ isLoading: false });
        return { data: cannedReponseList, totalPages: 100 }; // Assuming totalPages logic
      } else {
        console.error('No data or unexpected response structure:', response);
        this.setState({ MessageLists: [], messageCount: 0 });
        return { data: [], totalPages: 0 };
      }
    } catch (error) {
      console.error('Error fetching canned responses:', error);
      this.setState({ MessageLists: [], messageCount: 0 });
      return { data: [], totalPages: 0 };
    }
  };
  
  onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    this.setState({ searchTerm: searchTermCheck });
  };

  // fetchMembers = () => {
  //   const { channelUid} = this.props;
  //   const MessageList = {
  //     params: {
  //       channelId: channelUid,
  //     }
  //   };
  //   this.setState({isLoading : true});
  //   axios.get('canned/getAllCannedResponse', MessageList)
  //     .then((res) => {
  //       if (channelUid) {
  //         this.setState({ MessageLists: res.data });
  //         this.props.quickReplies(res.data);    
  //         this.setState({isLoading : false});  
  //       }
  //     })
  //     .catch((error) => {
  //       if (channelUid) {
  //         if( error.response.data.message !== "FAILURE"){
  //         toast('error', error.response.data.message);
  //         this.setState({isLoading : false});
  //         }
  //       }
  //     });
  // };

  addVariable = (setFieldValue: any, variable: any) => {
    this.setState((prevState) => {
      return {
        selectedoption: `{{${variable}}}`,
      };
    }, () => {
      setFieldValue('selectedoption', this.state.message);
    });
  };
  handleMouseUp = () => {
    const selection = window.getSelection();
    if (selection) {
      const selectedText = selection.toString();
      if (selectedText) {
        this.setState({ mouseText: selectedText });
      }

    }
  };

  toolbarValue = (toolbar: any) => {
    this.setState({ toolbarMessage: toolbar.toString('html') })
  }
  showVariablefun = () => {
    this.setState((prevState) => ({
      showVariable: !prevState.showVariable,
    }));
  }

  renderMessage = (cell: any, rowData: any) => {
    let trimmedText
    if ('uid' in rowData && 'name' in rowData && 'content' in rowData && 'id' in rowData) {
      trimmedText = rowData?.content?.trim();
    }
    let renderedText = trimmedText;
    if(renderedText){
    if (renderedText.includes('*')) {
      const boldWords = this.wordsExtractedFromSymbols('*', renderedText);
      boldWords.forEach(b => {
        const replaceAsBold = `<span class="fontWeight">${b}</span>`;
        renderedText = renderedText.replace(`*${b}*`, replaceAsBold);
      })
    }
    if (renderedText.includes('_')) {
      const italicWords = this.wordsExtractedFromSymbols('_', renderedText);
      italicWords.forEach(i => {
        const replaceAsItalic = `<i>${i}</i>`;
        renderedText = renderedText.replace(`_${i}_`, replaceAsItalic);
      })
    }
    if (renderedText.includes('~')) {
      const tildeWords = this.wordsExtractedFromSymbols('~', renderedText);
      tildeWords.forEach(t => {
        const replaceAsTilde = `<span class="strikeThrough">${t}</span>`;
        renderedText = renderedText.replace(`~${t}~`, replaceAsTilde);
      })
    }
   }
    // const titletext = <div dangerouslySetInnerHTML={{ __html: renderedText }} />;
    return (
      <div
        dangerouslySetInnerHTML={{ __html: renderedText }} />
    );
  };
  wordsExtractedFromSymbols = (symbol: string, text: string) => {
    var regex;
    if (symbol === '*') {
      regex = /\*(.*?)\*/g;
    }
    else if (symbol === '_') {
      regex = /_([^_]+)_/g;
    }
    else {
      regex = /~([^~]+)~/g;
    }
    const extractedWords = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      extractedWords.push(match[1]);
    }
    return extractedWords;
  }

  dateSplit = (date: any) => {
    const inputTimestamp = date;
    const momentObject = moment(inputTimestamp);
    const outputFormat = "DD MMM, YY hh:mm A";
    const formattedTimestamp = momentObject.format(outputFormat);
    return formattedTimestamp;
  }
  nameslice = (name: any) => {
    return (<div title={name}>{sliceText(name, 22)}</div>);
  }

  render() {
    const { messageCount } = this.state;
    const { message } = this.state;
    const { selectedoption } = this.state;
    const { selectedVariable } = this.state;
    const variable: SelectBtnOption[] = [
      { value: 'option1', label: 'Customer Id' },
      { value: 'option2', label: 'User name' },
    ];

    const validationSchema = Yup.object().shape({
      firstname: Yup.string().required('Name is required').max(60, 'Name should be less than 60 characters'),
      message: Yup.string().test('is-editor-value', 'Message is required', (value: any) => {
        return value && value.trim() !== '';
      }),
    });

    this.columns = [
      {
        Header: (
          <input
            type="checkbox"
            hidden={true}
            // checked={this.state.allChecked}
            // onChange={this.toggleAllCheckboxes}
          />
        ),
        accessor: "checkbox",
        // Cell: this.checkboxCellRenderer,
        sticky: "left",
      },
      {
        Header: "Name",
        accessor: "name",
        sticky: "left",
        Cell: ({ cell, row }: any) => this.nameslice(cell.value),
        width: 400,
      },
      {
        Header: "Message",
        accessor: "content",
        Cell: ({ cell, row }: any) => this.renderMessage(cell, row.original),
        width: 200,
      },
      // {
      //   Header: "Attachment",
      //   accessor: "attachment",
      // },
      {
        Header: "Created on",
        accessor: "createdAt",
        Cell: ({ cell, row }: any) => this.dateSplit(cell.value),
        width: 200,
      },
      {
        Header: "Actions",
        accessor: "edit",
        Cell: ({ cell, row }: any) => this.renderEditColumn(cell, row.original),
        width: 200,
      },
    
    ] as any;

    const { showContent, showEdit, MessageLists, MessageEdit } = this.state;
    const MemberDatas = MessageLists;
    const sidebarStyle = {
      backgroundColor: "white",
      width: "50%",
    };

    const options = {
      headerAlign: 'center',
      noDataText: 'No data found',
      sortIndicator: true,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      clearSearch: false,
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
      prePage: 'Prev',
      nextPage: 'Next',
      firstPage: 'First',
      lastPage: 'Last',
      sizePerPage: 5,
      paginationPosition: 'bottom'
    }
    return (
      <>
        <div className="m-2 quickcontainer">
              <h5 className="pt-2">Quick Replies</h5>
              <p className="mt-3 text-dark"> Create quick replies to reuse frequently sent messages.</p>
              {showContent && (
                <RightSidebar handleCancel={this.handleCancel} title={!showEdit ? "Add Quick Reply" : "Edit Quick Reply"} width="435px">
                  <Formik
                    initialValues={{
                      firstname: showEdit ? MessageEdit.name : '',
                      message: showEdit ? MessageEdit.content : '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={this.handleSubmit}
                    validateOnMount={true}
                  >
                    {({ isSubmitting, setFieldValue }) => (
                      <Form className="pl-3 pr-3 mt-5 ml-1">
                        {/* ref={this.setWrapperRef} */}
                        <FormBootstrap.Group className="form-group">
                          <FormBootstrap.Label className="text-dark">Name <span className="required"></span>
                          </FormBootstrap.Label>
                          <Field type="text" name="firstname" id="firstname"
                            placeholder="Enter your name" className="form-control" onChange={(event: any)=>{
                              const valueSpace = event.target.value.replace(/^\s+/, '');
                              setFieldValue("firstname", valueSpace);
                              this.setState({ nameCount: valueSpace.length }); 
                            }}/>
                            <p className='float-right text-muted'>{this.state.nameCount}/60</p>
                          <ErrorMessage name="firstname" component="div" className="text-danger" />

                          <FormBootstrap.Label htmlFor="useroption" className="text-dark pt-4">Message Content<span className="required"></span></FormBootstrap.Label>
                          <Toolbar name="message" showEdit={this.state.showEdit} toolbarValue={this.toolbarValue} returnMessage={this.state.MessageEdit} MessageData={this.MessageData}  setMediaObj={this.setMediaObj}/>
                          {/* <ErrorMessage name="message" component="div" className="text-danger" /> */}
                        </FormBootstrap.Group>
                        <div className={`d-flex justify-content-end ${this.state.isInvalidAccess ? "notAllowed" : ""} `}>
                          <Button
                            variant=""
                            type="button"
                            className="cancelButton w-25 mr-2 mt-3 rounded"
                            onClick={this.handleCancel}
                          >Cancel</Button>
                          <Button
                            variant=""
                            type="submit"
                            className="signUpBtn mt-3 w-25"
                             disabled={isSubmitting ||  this.state.message?.length <= 0 || this.state.message?.length > 1024 || this.state.isInvalidAccess}
                          > Save</Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </RightSidebar>
              )}            
        </div>
        <div>
          <div className="row pb-2">
            <div className="col">
        <SearchInput
                      component="community"
                      onSearchChange={this.onSearchChange}
                      placeHolder="Search"
                    />
            </div>
            <div className="col"></div>
                      <div className="col button-global-align">
            <Button
                onClick={this.handleClickAdd}
                variant=""
                type="button"
                className="sendButton paddingBtn"
                disabled={this.state.isInvalidAccess}
              >
                <FontAwesomeIcon icon={faPlus} className="me-1" /> Add Reply
              </Button>
            </div>
          </div>
              </div>
              {this.state.isLoading ? (
          <div className="centerItemsWithWidth">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
        // <div className="TQuick ml-3 mr-3">
        //   <BootstrapTable
        //     data={MemberDatas}
        //     search
        //     pagination={MemberDatas.length > 10}
        //     version="4"
        //     striped
        //     hover
        //   >
        //     <TableHeaderColumn dataField='id' isKey hidden>#</TableHeaderColumn>
        //     <TableHeaderColumn dataField='name' columnClassName="text-wrap" dataAlign='left' dataFormat={this.nameslice}>Template Name</TableHeaderColumn>
        //     {/* <TableHeaderColumn dataField='vamo system' dataFormat={() => 'vamo system'} dataAlign='center' >Status</TableHeaderColumn> */}
        //     <TableHeaderColumn dataField='content' columnClassName="text-wrap" dataFormat={this.renderMessage} dataAlign='left' >Message</TableHeaderColumn>
        //     <TableHeaderColumn dataField='name' columnClassName="text-wrap" dataAlign='left' >Attachment</TableHeaderColumn>
        //     <TableHeaderColumn dataField='createdAt' dataFormat={this.dateSplit} dataAlign='left' >Created on</TableHeaderColumn>
        //     <TableHeaderColumn dataField='edit' dataFormat={this.renderEditColumn}
        //       dataAlign='center'>
        //       Actions
        //     </TableHeaderColumn>
        //   </BootstrapTable>
       <> <BizTable
                  columns={this.columns}
                  fetchData={this.fetchData}
                  counts={messageCount}
                  refetchData={MemberDatas}
                  tableName='QUICKREPLY'
                />
             {/* {MemberDatas.length > 0? '' : <ErrorImage/>} */}
           </>
        // </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    channelUid: state.cartreducer.channelUid?.value,
    roleRules: state.cartreducer.roleRules,
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(QuickReply);
