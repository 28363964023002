import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Avatar from "react-avatar";
import DeleteBot from "./deleteBot";
import CreateBot from "./createBot";
import NoDataFallback from "../../../common/noDataFallback";
import { sliceText } from "../../../common/sliceText";
import TooltipReference from "../../../common/tooltip";
import BizTable from "../../../common/BizTable";
import styles from "./createBot.module.scss";

interface IBot {
  uid: string;
  name: string;
  sessionTTL: string;
  sessionTimeoutMessage: string;
  updatedAt: string;
  member?: {
    name: string;
  };
}

interface BotsListProps {
  bots: IBot[];
  refetch: () => void;
  isLoading: boolean;
  isInvalidAccess: boolean;
}

const BotsList: React.FC<BotsListProps> = ({ bots, refetch, isLoading, isInvalidAccess }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const navigate = useNavigate();

  const goToFlowListHandler = (botUid: string, name: string) => {
    localStorage.setItem("botName", name);
    navigate("/bots/" + botUid);
  };

  const TimeFormatterData = (cell: string) => {
    return <TimeFormatter milliseconds={parseInt(cell)} />;
  };

  const CreatedByData = (cell: string, row: IBot) => {
    const { member } = row;
    return (
      <>
        {member && (
          <>
            <Avatar
              fgColor="#fff"
              name={member.name}
              round
              size="25"
              textSizeRatio={3.5}
            />
            <span style={{ marginLeft: "5px", color: "#2E3967", marginTop: "3px" }}>

              {member.name}
            </span>
          </>
        )}
      </>
    );
  };
  const [editBotData, setEditBotData] = useState<any>(null);
  const [dropBotData, setDropBotData] = useState<any>(null);
  const [isLoad, setIsLoad] = useState<any>(true);
  const handleDrop = async (event: any, rowData: any) => {
    setShowDelete(true);
    setDropBotData(rowData);
    if (bots.length === 1) {
      setIsLoad(false);
    }
    event.stopPropagation();
  };

  const handleClickEdit = async (event: any, rowData: any) => {
    setEditBotData(rowData); // Store edit bot data in state
    setShowEdit(true);
    event.stopPropagation();
  };

  const ActionData = (cell: string, row: any) => {
    const { uid } = row;
    return (
      <div className={`${isInvalidAccess ? "notAllowed" : ""}`} >
        <svg
          width="16"
          height="16"
          onClick={(event: any) => handleClickEdit(event, row)}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${isInvalidAccess ? styles.isDisabled : ''}`}
        >
          <path
            d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          width="16"
          height="16"
          className={` ms-4 ${isInvalidAccess ? styles.isDisabled : ''}`}
          onClick={(event: any) => handleDrop(event, row)}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4H3.33333H14"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.66602 7.3335V11.3335"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.33398 7.3335V11.3335"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    );
  };

  const updateList = () => {
    setShowEdit(false);
    setShowDelete(false);
    refetch();
  };

  const onRowClick = (row: any) => {
    const { uid, name } = row;
    goToFlowListHandler(uid, name);
  };

  // const options = {
  //   headerAlign: "center",
  //   noDataText: "No records found",
  //   sortIndicator: true,
  //   hidePageListOnlyOnePage: true,
  //   clearSearch: false,
  //   alwaysShowAllBtns: true,
  //   withFirstAndLast: true,
  //   sizePerPage: 10,
  //   paginationPosition: "bottom",
  //   rowStyle: { cursor: "pointer" },
  //   onRowClick: onRowClick,
  // };

  const columns = [
    // {
    //   dataField: "uid",
    //   text: "#",
    //   hidden: true,
    // },
    {
      Header: (
        <input
          type="checkbox"
          hidden={true}
        // checked={this.state.allChecked}
        // onChange={this.toggleAllCheckboxes}
        />
      ),
      accessor: "checkbox",
      sticky: "left",
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell, row }: any) => (
        <TooltipReference
          placement="right"
          tooltipId={`bot-${row.id}`}
          content={cell.value}
        >
          <p>{sliceText(cell.value, 40)}</p>
        </TooltipReference>
      ),
    },
    {
      Header: "Timed Out",
      accessor: "sessionTTL",
      Cell: ({ cell }: any) => TimeFormatterData(cell.value),
    },
    {
      Header: "Created By",
      accessor: "createdByBot",
      Cell: ({ cell, row }: any) => CreatedByData(cell.value, row.original),
    },
    {
      Header: "Modified On",
      accessor: "modifiedOn",
      Cell: ({ cell, row }: any) => moment(row?.original?.updatedAt).format("DD MMM hh.mm A")
    },
    {
      Header: "Actions",
      accessor: "uid",
      Cell: ({ cell, row }: any) => ActionData(cell.value, row.original),
    },
  ] as any;

  return (
    <>
      {showEdit && (
        <CreateBot
          id={editBotData.uid}
          show={showEdit}
          onHide={() => setShowEdit(false)}
          onCreate={updateList}
          bot={editBotData}
          editData={{
            nameInput: editBotData.name,
            timeoutMsgInput: editBotData.sessionTimeoutMessage,
            sessionTTL: editBotData.sessionTTL,
          }}
          showDelete={() => setShowDelete(true)}
        />
      )}
      {showDelete && (
        <DeleteBot
          name={dropBotData.name}
          uid={dropBotData.uid}
          show={showDelete}
          onHide={() => setShowDelete(false)}
          onDelete={updateList}
        />
      )}

      {/* <BootstrapTable
        data={bots}
        pagination={bots?.length > 10}
        version="4"
        striped
        hover
        options={options as any}
        containerStyle={{ textAlign: "left" }}
      >
        <TableHeaderColumn dataField="uid" isKey hidden>
          #
        </TableHeaderColumn>
        <TableHeaderColumn dataField="name" dataFormat={(name, row) => (
          <TooltipReference placement="right" tooltipId={`bot-${row.id}`} content={name}>
            <p>{sliceText(name, 40)}</p>
          </TooltipReference>
        )} dataAlign="left" width="100">
          Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="sessionTTL"
          dataAlign="center"
          width="100"
          dataFormat={TimeFormatterData}
        >
          Timed out
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="member.name"
          dataAlign="left"
          width="100"
          dataFormat={CreatedByData}
        >
          Created by
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="updatedAt"
          dataAlign="center"
          width="100"
          dataFormat={(cell: string) => moment(cell).format("DD MMM hh.mm A")}
        >
          Modified on
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="uid"
          dataAlign="center"
          width="100"
          dataFormat={ActionData}
        >
          Actions
        </TableHeaderColumn>
      </BootstrapTable> */}
      {/* <Loader/> */}
      <>
        {/* { bots?.length === 0 && !isLoading && isLoad ? (
          <Loader />
        ) : ( */}
        <div className="mt-2">
          <BizTable
            columns={columns}
            // fetchData={fetchData}
            counts={0}
            refetchData={bots}
            tableName="BOT"
            // searchTerm={searchTerm ? true : false}
            handleRowClick={onRowClick}
          />
        </div>
        {/* )} */}
      </>
      {bots.length <= 0 ? (
        <div className="mt-3">
          <NoDataFallback
            caption="No Bots found"
            message='Click on "Create New Bot" to create your first bot!'
          />
        </div>
      ) : null}
    </>
  );
};

interface TimeFormatterProps {
  milliseconds: number;
}

const TimeFormatter: React.FC<TimeFormatterProps> = ({ milliseconds }) => {
  const formatTime = (milliseconds: number): string => {
    if (milliseconds >= 120000) {
      const minutes = Math.floor(milliseconds / 60000);
      return `${minutes} Min`;
    } else {
      const seconds = Math.floor(milliseconds / 1000);
      return `${seconds} Sec`;
    }
  };

  let formattedTime = formatTime(milliseconds);

  if (formattedTime === "60 Sec") formattedTime = "1 Min";

  return <span>{formattedTime}</span>;
};

export default BotsList;
