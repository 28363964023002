
const INIT_STATE: any = {
    carts: [],
    registerData: {},
    business: {},
    flagData: {},
    memberData: [],
    channelData: [],
    groupData: [],
    channelUid: {},
    roleData: [],
    mappedMemberData: new Map(),
    mappedMemberDataWithUid: new Map(),
    quickReplies: [],
    subscriptionData: [],
    accountData: [],
    leadData: [],
    allMessagesCount: {},
    rolePermissions: [],
    roleRules: {},
    zohoData: [],
    moduleName: "",
    numberMasking: false
};

export const cartreducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case "ADD_CART":

            const IteamIndex = state.carts.findIndex((iteam: any) => iteam.id === action.payload.id);

            if (IteamIndex >= 0) {
                state.carts[IteamIndex].qnty += 1
                return {
                    ...state,
                    carts: [...state.carts]
                }
            } else {
                const temp = { ...action.payload, qnty: 1 }
                return {
                    ...state,
                    carts: [...state.carts, temp]
                }
            }

        case "RMV_CART":
            const data = state.carts.filter((el: any) => el.id !== action.payload);
            // console.log(data);
            return {
                ...state,
                carts: data
            }

        case "RMV_ONE":
            const IteamIndex_dec = state.carts.findIndex((iteam: any) => iteam.id === action.payload.id);

            if (state.carts[IteamIndex_dec].qnty >= 1) {
                const deleteiteams = state.carts[IteamIndex_dec].qnty -= 1
                // console.log([...state.carts, deleteiteams]);

                return {
                    ...state,
                    carts: [...state.carts]
                }
            } else if (state.carts[IteamIndex_dec].qnty === 1) {
                const data = state.carts.filter((el: any) => el.id !== action.payload);

                return {
                    ...state,
                    carts: data
                }
            }
            break;
        case "REGISTER_DATA":
            return {
                ...state,
                registerData: action.payload
            }
        case "BUSINESS_ID":
            return {
                ...state,
                business: action.payload
            }
        case "EXIST_COMMUNITY":
            return {
                ...state,
                existCommunity: action.payload
            }
        case "FLAG_DATA":
            return {
                ...state,
                flagData: action.payload
            }
        case "TIMEZONE_DATA":
            return {
                ...state,
                timeZone: action.payload
            }
        case "MEMBER_DATA":
            return {
                ...state,
                memberData: action.payload
            }
        case "CHANNEL_DATA":
            return {
                ...state,
                channelData: action.payload
            }
        case "CHANNEL_UID":
            return {
                ...state,
                channelUid: action.payload
            }
        case "TABLE_LIST":
            return {
                ...state,
                TableList: action.payload
            }
        case "ROLE_DATA":
            return {
                ...state,
                roleData: action.payload
            }
        case "MAPPED_MEMBER_DATA":
            return {
                ...state,
                mappedMemberData: action.payload
            }
        case "MAPPED_MEMBER_DATA_WITH_UID":
            return {
                ...state,
                mappedMemberDataWithUid: action.payload
            }
        case "QUICK_REPLIES":
            return {
                ...state,
                quickReplies: action.payload
            }
        case "RESET_STATE":
            return INIT_STATE;
        case "SUBSCRIPTION_DATA":
            return {
                ...state,
                subscriptionData: action.payload
            }
        case "ACCOUNT_DATA":
            return {
                ...state,
                accountData: action.payload
            }
        case "LEAD_DATA":
            return {
                ...state,
                leadData: action.payload
            }
        case "ALL_MESSAGES_DATA":
            return {
                ...state,
                allMessagesCount: action.payload
            }

        case "GROUP_DATA":
            return {
                ...state,
                groupData: action.payload
            }
        default:
            return state

        case "ROLE_PERMISSIONS":
            return {
                ...state,
                rolePermissions: action.payload
            }

        case "ROLE_RULES":
            return {
                ...state,
                roleRules: action.payload
            }
        case "ZOHO_DATA": {
            return {
                ...state,
                zohoData: action.payload
            }
        }
        case "ROLE_MODULE": {
            return {
                ...state,
                roleModule: action.payload
            }
        }
        case "NUMBER_MASKING": {
            return {
                ...state,
                numberMasking: action.payload
            }
        }

    }
}
