import React, { useEffect, useState, useCallback } from 'react';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useDispatch, useSelector } from 'react-redux';
import { getAllTeam, createTeam, updateTeam, teamDelete, getTeam, getRoles, createRole, getRole, updateRole, deleteRole } from "../../services/contactMemberService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormGroup, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import { dateSplit } from "../../common/commonfns";
import RightSidebar from "../../common/rightSidebar";
import Avatar from "react-avatar";
import { Label } from "reactstrap";
import BizTable from "../../common/BizTable";
import { Row, Col } from 'reactstrap';
import SearchInput from "../../common/searchComponent";
import { ErrorImage } from "../../common/ErrorMessage";
import axios from '../../utils/axios';
import './Member.scss';
import { showAlertYesNo } from '../../common/alertYesNo';
import { BsInfoCircleFill } from 'react-icons/bs';



interface ToggleSwitchProps extends FieldProps {
  label: string;
  isDisabled: boolean;
  viewName: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ field, form, label, isDisabled, viewName }) => {
  return (
    <div className={`toggle-switch ${isDisabled ? "notAllowed" : ""} `}>
      <span className="toggle-label">{label}</span>
      <label className={`switch ${isDisabled ? "isDisabled" : ""}`}>
        <input
          type="checkbox"
          checked={field.value}
          onChange={() => {
            form.setFieldValue(field.name, !field.value);
            if (field?.name?.includes("canCreateAndManageCatalogue") && !field.value) {
              form.setFieldValue("moduleAccessRules[15].rules.canViewCatalogue", true);
            }
            else if (field?.name?.includes("canViewAndManageOrders") && !field.value) {
              form.setFieldValue("moduleAccessRules[15].rules.canViewOrders", true);
            }
            else if (field?.name?.includes("canCreateViewAndManagePayments") && !field.value) {
              form.setFieldValue("moduleAccessRules[15].rules.canViewPayments", true);
            }
            else if (viewName !== field.name && !field.value) {
              form.setFieldValue(viewName, true);
            }
          }}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

const ModuleAccessRules = [
  {
    rules: {
      editMasking: false
    },
    module: "masking"
  },
  {
    rules: {
      canViewConversations: false,
      canManageConversations: false
    },
    module: "salesInbox"
  },
  {
    rules: {
      canViewConversations: false,
      canManageConversations: false
    },
    module: "supportInbox"
  },
  {
    rules: {
      canViewContacts: false,
      canManageContactsAndDeleteContactList: false
    },
    module: "contacts"
  },
  {
    rules: {
      canViewCampaignsAndAnalytics: false,
      CanAddViewScheduleAndManageCampaigns: false
    },
    module: "campaign"
  },
  {
    rules: {
      canViewSequences: false,
      canManageSequences: false
    },
    module: "sequence"
  },
  {
    rules: {
      canViewBots: false,
      canManageBots: false
    },
    module: "bot"
  },
  {
    rules: {
      canViewWhatsappTemplates: false,
      canManageWhatsappTemplates: false
    },
    module: "whatsappTemplate"
  },
  {
    rules: {
      canViewWhatsappFlows: false,
      canManageWhatsappFlows: false
    },
    module: "whatsappFlows"
  },
  {
    rules: {
      canViewWhatsappChannels: false,
      canManageWhatsappChannels: false
    },
    module: "whatsappChannels"
  },
  {
    rules: {
      canViewWhatsappOTPs: false,
      canManageWhatsappOTPs: false
    },
    module: "WhatsappOtp"
  },
  {
    rules: {
      canViewTags: false,
      canManageTags: false
    },
    module: "tags"
  },
  {
    rules: {
      canViewQuickReplies: false,
      canManageQuickReplies: false
    },
    module: "quickReplies"
  },
  {
    rules: {
      canViewAutomation: false,
      canManageAutomation: false
    },
    module: "automation"
  },
  {
    rules: {
      canViewIntegrations: false,
      canManageIntegrations: false
    },
    module: "integration"
  },
  {
    rules: {
      canViewAnalyticsAndReport: false,
      canManageAnalyticsAndReport: false
    },
    module: "analytics"
  },
  {
    rules: {
      canViewCatalogue: false,
      canCreateAndManageCatalogue: false,
      canViewOrders: false,
      canViewAndManageOrders: false,
      canViewPayments: false,
      canCreateViewAndManagePayments: false,
    },
    module: "commerce"
  },
  {
    rules: {
      canViewCTWAs: false,
      canManageCTWAs: false
    },
    module: "ctwa"
  },
  {
    rules: {
      canViewTicketsAndAnalytics: false,
      canManageTicketsAndAnalytics: false
    },
    module: "ticketing"
  },
  {
    rules: {
      canViewAppointmentsConfigurationAndAppoitmentsBooked: false,
      canManageAppointmentsConfigurationAndAppoitmentsBooked: false
    },
    module: "appointment"
  },
  {
    rules: {
      canViewMemberTeamsAndRoles: false,
      canManageMemberTeamsAndRoles: false
    },
    module: "rolesAndPermissions"
  },
  {
    rules: {
      canViewKnowledgeBase: false,
      canManageKnowledgeBase: false,
    },
    module: "knowledgeBase"
  },
  {
    rules: {
      canViewWebhooksAndApiKeys: false,
      canManageWebhooksAndApiKeys: false
    },
    module: "developerModules"
  },
  {
    rules: {
      canViewFieldForceTracking: false,
      canManageFieldForceTracking: false
    },
    module: "fieldForceTracking"
  }
]

interface moduleAccess {
  rules: {}
  module: string
}


interface FormData {
  name: string,
  type: string,
  createdBy: string,
  description: string | null,
  moduleAccessRules: moduleAccess[]
}

interface Props {
  isInvalidAccess: boolean;
}

const Roles = (props: Props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [descCount, setDesc] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [open, openModal] = useState<boolean>(false);
  const [teamCount, setTeamCnt] = useState(0);
  const [typeCount, setTypeCount] = useState(0);
  const [teamEditCount, setTeamEdit] = useState(0);
  const [descEditCount, setEditDesc] = useState(0);
  const [allRoles, setRoles] = useState([]);
  const [sideBar, setSideBar] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [editManage, setEditManage] = useState<boolean>(false);
  const [modules, setModules] = useState<moduleAccess[]>([
    {
      rules: {
        editMasking: "Hide mobile numbers"
      },
      module: "Mobile Number Masking"
    },
    {
      rules: {
        canViewConversations: "Can view conversations",
        canManageConversations: "Can manage all conversations"
      },
      module: "Sales Inbox"
    },
    {
      rules: {
        canViewConversations: "Can view conversations",
        canManageConversations: "Can manage all conversations"
      },
      module: "Support Inbox"
    },
    {
      rules: {
        canViewContacts: "Can view and update contact list",
        canManageContactsAndDeleteContactList: "Can manage contact list"
      },
      module: "Contacts"
    },
    {
      rules: {
        canViewCampaignsAndAnalytics: "Can view campaigns and analytics",
        CanAddViewScheduleAndManageCampaigns: "Can add, view, schedule and manage campaigns"
      },
      module: "Campaign"
    },
    {
      rules: {
        canViewSequences: "Can view sequences",
        canManageSequences: "Can manage sequences"
      },
      module: "Sequence"
    },
    {
      rules: {
        canViewBots: "Can view bots",
        canManageBots: "Can manage bots"
      },
      module: "Bot Flows"
    },
    {
      rules: {
        canViewWhatsappTemplates: "Can view WhatsApp templates",
        canManageWhatsappTemplates: "Can manage WhatsApp templates"
      },
      module: "WhatsApp Templates"
    },
    {
      rules: {
        canViewWhatsappFlows: "Can view WhatsApp flows",
        canManageWhatsappFlows: "Can manage WhatsApp flows"
      },
      module: "WhatsApp Flows"
    },
    {
      rules: {
        canViewWhatsappChannels: "Can view WhatsApp channels",
        canManageWhatsappChannels: "Can manage WhatsApp channels"
      },
      module: "WhatsApp Channels"
    },
    {
      rules: {
        canViewWhatsappOTPs: "Can view WhatsApp OTP",
        canManageWhatsappOTPs: "Can manage WhatsApp OTP"
      },
      module: "WhatsApp OTP"
    },
    {
      rules: {
        canViewTags: "Can view tags",
        canManageTags: "Can manage tags"
      },
      module: "Tags"
    },
    {
      rules: {
        canViewQuickReplies: "Can view quick replies",
        canManageQuickReplies: "Can manage quick replies"
      },
      module: "Quick Replies"
    },
    {
      rules: {
        canViewAutomation: "Can view automation",
        canManageAutomation: "Can manage automation"
      },
      module: "Automation"
    },
    {
      rules: {
        canViewIntegrations: "Can view integrations",
        canManageIntegrations: "Can manage integrations"
      },
      module: "Integration"
    },
    {
      rules: {
        canViewAnalyticsAndReport: "Can view analytics and report",
        canManageAnalyticsAndReport: "Can manage analytics and report"
      },
      module: "Analytics"
    },
    {
      rules: {
        canViewOrders: "Can view orders",
        canViewCatalogue: "Can view catalogue",
        canViewPayments: "Can view payments",
        canViewAndManageOrders: "Can view and manage orders",
        canCreateAndManageCatalogue: "Can create and manage catalogue",
        canCreateViewAndManagePayments: "Can create, view, and manage payments",
      },
      module: "Commerce"
    },
    {
      rules: {
        canViewCTWAs: "Can view CTWAs",
        canManageCTWAs: "Can manage CTWAs"
      },
      module: "CTWA"
    },
    {
      rules: {
        canViewTicketsAndAnalytics: "Can view tickets and analytics",
        canManageTicketsAndAnalytics: "Can manage tickets and analytics"
      },
      module: "Ticketing"
    },
    {
      rules: {
        canViewAppointmentsConfigurationAndAppoitmentsBooked: "Can view appointments configuration and booked appointments",
        canManageAppointmentsConfigurationAndAppoitmentsBooked: "Can manage appointments configuration and booked appointments"
      },
      module: "Appointment"
    },
    {
      rules: {
        canViewMemberTeamsAndRoles: "Can view member, teams and roles",
        canManageMemberTeamsAndRoles: "Can manage member, teams and roles"
      },
      module: "Member, Teams & Roles"
    },
    {
      rules: {
        canViewKnowledgeBase: "Can view knowledge base",
        canManageKnowledgeBase: "Can manage knowledge base",
      },
      module: "Knowledge Base"
    },
    {
      rules: {
        canViewWebhooksAndApiKeys: "Can view webhooks and API keys",
        canManageWebhooksAndApiKeys: "Can manage webhooks and API keys"
      },
      module: "Developer Modules"
    },
    {
      rules: {
        canViewFieldForceTracking: "Can view field force tracking",
        canManageFieldForceTracking: "Can manage field force tracking"
      },
      module: "Field Force Tracking"
    }
  ]);
  const [createFormData, setCreateFormData] = useState<FormData | null>({
    name: '',
    type: '',
    createdBy: '',
    description: '',
    moduleAccessRules: ModuleAccessRules
  });
  const business = useSelector((state: any) => state.cartreducer.business);


  const handleUpdate = async () => {
    try {
      // await updateTeam(rowData.uid, payload);
      // getSingleTeam(0, 20, '', 0);
      // getAll();
      fetchData(0, 20, '', 0)
    } catch (error) {
      console.error('Error:', error);
    }
    handleCancel();
  };

  const deleteRoleById = async (uid: any) => {
    try {
      const alertResponse = await showAlertYesNo(
        "question",
        "Are you sure you want to delete this role ?"
      );
      if (alertResponse.isConfirmed) {
        await deleteRole(uid);
      }
      fetchData(0, 20, '', 0)
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const getRolebyId = async (uid: any) => {
    try {
      const response = await getRole(uid);
      let updatedModules = [...response?.moduleAccessRules];

      if (updatedModules[0].module !== "masking") {
        const maskingIndex = updatedModules.findIndex(
          (module) => module.module === "masking"
        );
        if (maskingIndex !== -1) {
          const maskingModule = updatedModules.splice(maskingIndex, 1)[0];
          updatedModules.unshift(maskingModule);
        }
      }
      const updatedResponse = {
        ...response,
        moduleAccessRules: updatedModules
      };
      setCreateFormData(updatedResponse);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const renderEditColumn = (cell: any, row: any) => {
    const isInvalid = row?.name.toLowerCase() === "member" || row?.name.toLowerCase() === "owner" || row?.name.toLowerCase() === "admin" || props?.isInvalidAccess;
    return (
      <div className='d-flex justify-content-center'>
        <svg
          onClick={
            () => {
              setSideBar(true);
              getRolebyId(row.uid);
            }}
          className='me-3'
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer' }}
        >
          <path
            d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          onClick={
            () => {
              deleteRoleById(row.uid);
            }}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer' }}
          className={`${isInvalid ? "isDisabled" : ""}`}
        >
          <path
            d="M2 4H3.33333H14"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.66602 7.3335V11.3335"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.33398 7.3335V11.3335"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    );
  };

  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );

  useEffect(() => {
    if (searchTerm) {
      const filteredData = allRoles.filter((item: any) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setRoles(filteredData);
    }
    else if (!searchTerm) {
      fetchData(0, 20, '', 0)
    }
  }, [searchTerm])

  const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    setSearchTerm(searchTermCheck);
  };

  const handleCancel = () => {
    setSideBar(false);
    openModal(false);
    setSelectedRows([]);
    setCreateFormData(null);
  }

  const columns = [
    {
      Header: "Role",
      accessor: "type",
      Cell: ({ cell, row }: any) => cell.value ? <p title={cell.value}>{cell.value?.length > 30 ? cell.value?.slice(0, 20) + '...' : cell.value}</p> : '',
      width: '200',
    },
    {
      Header: "Created By",
      accessor: "createdBy",
      Cell: ({ cell, row }: any) => cell.value ? <p>{cell.value}</p> : <>Default</>
      ,
      width: '30',
    },
    {
      Header: "Actions",
      accessor: "edit",
      Cell: ({ cell, row }: any) => renderEditColumn(cell.value, row.original),
    }
  ] as any;

  const fetchData = useCallback(async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {

    const listPayload = {
      businessUid: businessId,
      page: currentPage,
      limit: pageSize,
      search: searchTerm
    };


    const response = await getRoles(businessId);
    if (response) {
      const data = response;
      setRoles(data);
      const countData = data?.length;
      setIsLoading(false);
      return { data: data, totalPages: countData }
    }
    else {
      return { data: [], totalPages: 0 }
    }
  }, [businessId]);


  const closeModal = () => {
    openModal(false);
    setSideBar(false);
  }

  const validation = Yup.object({
    name: Yup.string().trim().required('Role name is required').max(60, "Role name must not exceed 60 characters"),
    type: Yup.string(),
    description: Yup.string().max(60, "Description must not exceed 60 characters"),
  });


  const onSubmitHandler = async (values: any, isCreate: boolean) => {
    if (isCreate) {
      try {
        values.createdBy = business?.name;
        await createRole(values, businessId);
        fetchData(0, 20, '', 0);
        openModal(false);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    else {
      try {
        await updateRole(values, values?.uid);
        fetchData(0, 20, '', 0);
        setSideBar(false);
        openModal(false);
        setCreateFormData(null);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }

  return (

    <>
      {isLoading ? (
        <div className="centerItemsWithWidth">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <Row className='pt-3 pb-2'>
              <Col md={3}>
                <SearchInput
                  component="Team"
                  onSearchChange={onSearchChange}
                  placeHolder="Search Role"
                />
              </Col>
              <Col></Col>
              <Col md={2}><Button
                onClick={() => {
                  openModal(true)
                  setTeamCnt(0);
                  setTeamEdit(0);
                  setDesc(0);
                  setEditDesc(0);
                }}
                variant=""
                type="button"
                className="sendButton w-100"
                disabled={props?.isInvalidAccess}
              ><FontAwesomeIcon icon={faPlus} />  Add Role
              </Button>
              </Col>
            </Row>
          </div>

          {(sideBar || open) && (

            <RightSidebar className="header" handleCancel={handleCancel} title={open ? 'Create Role' : 'Edit Role'} width={"500px"}>
              <>
                <Formik
                  initialValues={createFormData || {
                    name: '',
                    type: '',
                    createdBy: '',
                    description: '',
                    moduleAccessRules: ModuleAccessRules,
                  }}
                  validationSchema={validation}
                  enableReinitialize={true}
                  onSubmit={(values) => onSubmitHandler(values, open)}
                >
                  {({ values, setFieldValue }) => {
                    const enableAll = () => {
                      const moduleAccessRules = values?.moduleAccessRules || [];
                      const updatedModuleAccessRules = moduleAccessRules?.map((module) => {
                        const updatedRules = Object.keys(module.rules).reduce<{ [key: string]: boolean }>(
                          (acc, ruleKey) => {
                            acc[ruleKey] = ((values?.moduleAccessRules[0]?.rules as any)?.editMasking === true && ruleKey.toLowerCase().includes("manage")) ? false : true;
                            return acc;
                          },
                          {}
                        );
                        return {
                          ...module,
                          rules: updatedRules,
                        };
                      });
                      setFieldValue('moduleAccessRules', updatedModuleAccessRules);
                    }

                    useEffect(() => {
                      if ((values?.moduleAccessRules[0]?.rules as any)?.editMasking) {
                        const updatedModuleAccessRules = values?.moduleAccessRules.map((item: any) => {
                          const updatedRules = Object.keys(item.rules).reduce((acc, key) => {
                            acc[key] = key.toLowerCase().includes("manage") ? false : item?.rules[key];
                            return acc;
                          }, {} as { [key: string]: boolean });
                          return {
                            ...item,
                            rules: updatedRules,
                          };
                        });
                        setEditManage(true);
                        setFieldValue('moduleAccessRules', updatedModuleAccessRules);
                      }
                      else {
                        setEditManage(false);
                      }
                    }, [(values?.moduleAccessRules[0]?.rules as any)?.editMasking])

                    const isViewAndManage = (modIndex: any, ruleIndex: any) => {
                      if (Object.values(values?.moduleAccessRules[modIndex]?.rules)[0] && Object.values(values?.moduleAccessRules[modIndex]?.rules)[1] && ruleIndex === 0 && values?.moduleAccessRules[modIndex]?.module !== "commerce") {
                        return true;
                      }
                      else if (values?.moduleAccessRules[modIndex]?.module === "commerce") {
                        if (Object.keys(values?.moduleAccessRules[modIndex]?.rules)[ruleIndex] === "canViewCatalogue" && Object.values(values?.moduleAccessRules[modIndex]?.rules)[5]) {
                          return true;
                        }
                        else if (Object.keys(values?.moduleAccessRules[modIndex]?.rules)[ruleIndex] === "canViewOrders" && Object.values(values?.moduleAccessRules[modIndex]?.rules)[3]) {
                          return true;
                        }
                        else if (Object.keys(values?.moduleAccessRules[modIndex]?.rules)[ruleIndex] === "canViewPayments" && Object.values(values?.moduleAccessRules[modIndex]?.rules)[4]) {
                          return true;
                        }
                      }
                      return false;
                    }

                    return (
                      <Form className='ml-3 mr-2'>
                        <Offcanvas.Body className="formContainer">
                          <FormGroup className='mb-3'>
                            <Label>Role Name<span className='required'></span></Label>
                            <Field
                              type='text'
                              id='name'
                              className='form-control'
                              name='name'
                              placeholder='Enter role name'
                              onChange={(event: any) => {
                                let text = event.target.value;
                                setFieldValue('name', text);
                                setFieldValue('type', text);
                                setTeamCnt(text.length);
                              }}
                              disabled={(values?.name?.toLowerCase() === "owner" && !open)}
                            />
                            <div className='float-right text-muted small'>{values?.name?.length} / 60</div>
                            <ErrorMessage name='name' className='errorMsg' component='div' />
                          </FormGroup>

                          <FormGroup className='mb-3'>
                            <Label className='mt-3'>Description</Label>
                            <Field
                              type='text'
                              id='description'
                              className='form-control'
                              name='description'
                              placeholder='Enter description'
                              onChange={(event: any) => {
                                let text = event.target.value;
                                setFieldValue('description', text);
                                setDesc(text.length);
                              }}
                              disabled={(values?.name?.toLowerCase() === "owner" && !open)}
                            />
                            <div className='float-right text-muted small'>{values?.description?.length} / 60</div>
                            <ErrorMessage name='description' className='errorMsg' component='div' />
                          </FormGroup>

                          <FormGroup className='mb-3'>
                            <p><em>Note :</em> Any new feature added in the future will be disabled by default, but you can modify in the settings anytime.</p>
                          </FormGroup>

                          <FormGroup className='mb-1 d-flex justify-content-between align-items-center'>
                            <Label style={{ fontSize: "15px" }}>
                              <b>Role Permissions</b>
                            </Label>
                            <a onClick={enableAll} id='customLink'  >Enable All </a>
                          </FormGroup>

                          {modules?.map((mod: moduleAccess, modIndex: number) => (
                            <FormGroup className='mb-3' key={modIndex}>
                              <Label style={{ fontSize: "14px", fontWeight: 600, color: "GrayText" }}>
                                {mod?.module}
                                {mod?.module === "Mobile Number Masking" && <OverlayTrigger
                                  placement='bottom'
                                  overlay={<Tooltip> Show only part of a mobile number to keep details private. By enabling this feature, members will not be able to edit or manage the modules.</Tooltip>}
                                >
                                  <Button size='sm' variant='default'>
                                    <BsInfoCircleFill />
                                  </Button>
                                </OverlayTrigger>
                                }
                              </Label>

                              {Object.entries(mod?.rules).map(([ruleKey, ruleValue], ruleIndex) => (
                                <Field
                                  className='mb-2'
                                  key={ruleIndex}
                                  name={`moduleAccessRules[${modIndex}].rules.${ruleKey}`}
                                  component={ToggleSwitch}
                                  label={ruleValue}
                                  isDisabled={(values?.name?.toLowerCase() === "owner" && !open) || (isViewAndManage(modIndex, ruleIndex)) || (ruleKey?.toLowerCase()?.includes("manage") && editManage)}
                                  viewName={`moduleAccessRules[${modIndex}].rules.${Object.keys(mod?.rules)[0]}`}
                                />
                              ))}
                            </FormGroup>
                          ))}
                        </Offcanvas.Body>
                        <div className="editorFooter">
                          <Button type="button" className="cancelButton mr-3" onClick={closeModal}>
                            Cancel
                          </Button>
                          <Button className='sendButton mb-1' type='submit' disabled={(values?.name?.toLowerCase() === "owner" && !open) || props?.isInvalidAccess}>
                            {open ? 'Create' : 'Update'}
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>

              </>
            </RightSidebar>
          )}

          <BizTable
            columns={columns}
            fetchData={fetchData}
            counts={0}
            refetchData={allRoles}
            tableName='ROLES'
          />
        </div>
      )}
    </>
  )
}


export default Roles;