import React, { ReactNode, useState, useEffect, useRef } from 'react';
import Editor, { EditorValue, ToolbarConfig } from 'react-rte';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { any } from 'prop-types';
import './QuickReply.scss';
import { useFormikContext } from 'formik';
import { FormValues } from './QuickReply';
import Select from 'react-select';
import { Button, Col, Row, InputGroup, Form as FormBootstrap } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { FaFileImage, FaFilePdf, FaUpload } from 'react-icons/fa';
import customData from '@emoji-mart/data';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { EditorState, Modifier } from 'draft-js';
import TooltipReference from '../../common/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrin, faGrinAlt, faInfoCircle, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import AxiosInstance from '../../utils/axios';
import { useSelector } from "react-redux";
import { toast } from "../../common/alert";
import { BsFillTrashFill } from 'react-icons/bs';
import * as mediaService from '../../botFlows/services/media';

export interface ToolbarClasses<T> {
  component: T;
}

export interface ToolbarProps {
  toolbarValue: (toolbar: any) => void;
  returnMessage: any;
  MessageData: (item: any) => void;
  name: any;
  showEdit: any;
  setMediaObj: (item: any) => void;
}
interface FileItem {
  file: File;
  imgUrl: string;
}
function Toolbar(props: ToolbarProps) {
  const formik = useFormikContext<FormValues>();
  const channel: any = useSelector((state: any) => state.cartreducer.channelUid);
  // const [files, setFiles] = useState<FileItem[]>([]);
  // const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [popovertarget, setpopovertarget] = useState<null | HTMLButtonElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const pickerRef = useRef<HTMLDivElement>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState<any[]>([]);
  const [Var, setvariable] = useState(false);
  const [counter, setCounter] = useState(0);
  const [media, setMedia] = useState<any>(props.returnMessage?.media || null);

  const { values } = formik;
  const convertText = (rowData: any) => {
    let trimmedText
    if (rowData) {
      trimmedText = rowData.trim();
    }
    let renderedText = trimmedText;
    if (renderedText.includes('*')) {
      const boldWords = wordsExtractedFromSymbols('*', renderedText);
      boldWords.forEach(b => {
        renderedText = renderedText.replace(`*${b}*`, `**${b}**`);
      })
    }
    if (renderedText.includes('~')) {
      const tildeWords = wordsExtractedFromSymbols('~', renderedText);
      tildeWords.forEach(t => {
        renderedText = renderedText.replace(`~${t}~`, `~~${t}~~`);
      })
    }
    return (
      renderedText
    );
  };
  const wordsExtractedFromSymbols = (symbol: any, text: any) => {
    var regex;
    if (symbol === '*') {
      regex = /\*(.*?)\*/g;
    }
    else if (symbol === '_') {
      regex = /_([^_]+)_/g;
    }
    else {
      regex = /~([^~]+)~/g;
    }
    const extractedWords = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      extractedWords.push(match[1]);
    }
    return extractedWords;
  }


  const [editorValue, setEditor] = useState<EditorValue>(
    props.showEdit
      ? EditorValue.createFromString(convertText(props.returnMessage?.content.toString()), 'markdown')
      : EditorValue.createEmpty()
  );

  const handleEmojiClick = (e: any) => {
    const emoji = e.native;
    const editorState = editorValue.getEditorState();
    const selection = editorState.getSelection();
    const contentStateWithEmoji = Modifier.insertText(
      editorState.getCurrentContent(),
      selection,
      emoji
    );
    const newEditorState = EditorState.push(
      editorState,
      contentStateWithEmoji,
      'insert-characters'
    );

    const newEditorValue = editorValue.setEditorState(newEditorState);
    setEditorValue(newEditorValue);
    setPopoverOpen(false);
    setpopovertarget(null);
  };


  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node;
    if (popoverOpen &&
      popovertarget &&
      popovertarget !== target &&
      !popovertarget.contains(target) &&
      pickerRef.current &&
      !pickerRef.current.contains(target)) {
      setPopoverOpen(false);
      setpopovertarget(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [popovertarget, popoverOpen]);

  const toolbarConfig: ToolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-b' },
      { label: 'Italic', style: 'ITALIC', className: 'custom-css-i' },
      {
        label: "Strikethrough", style: "STRIKETHROUGH", className: 'custom-css-s'
      },
    ],
    BLOCK_TYPE_DROPDOWN: [],
    BLOCK_TYPE_BUTTONS: [

    ],
    BLOCK_ALIGNMENT_BUTTONS: [
    ]
  };

  const showVariable = () => {
    setvariable(prevState => !prevState);
  };
  const setEditorValue = (value: EditorValue) => {
    const html = value.toString('html');
    const htmlValue = html.replace(/<p[^>]*>(.*?)<\/p>/g, '$1');
    const whatsappValue = convertHtmlToWhatsApp(htmlValue);
    props.MessageData(whatsappValue)
    props.toolbarValue(value);
    setEditor(value);
    formik.setFieldValue('message', whatsappValue);
    // setCounter(value.length\)
  };
  const convertHtmlToWhatsApp = (html: string): string => {
    let result = html;
    result = result.replace(/&nbsp;/g, ' ');
    result = result.replace(/<br>/g, ' ');
    result = result.replace(/<strong>(.*?)<\/strong>/g, '*$1*'); // bold
    result = result.replace(/<em>(.*?)<\/em>/g, '_$1_').trim(); // italics
    result = result.replace(/<strike>(.*?)<\/strike>/g, '~$1~').trim(); // strikethrough
    result = result.replace(/<del>(.*?)<\/del>/g, '~$1~').trim(); // monospace
    if (result === '* $1 *') {
      result = result.replace(/\s+/g, ''); // Remove spaces within *
      result = '*$1*';
    } else if (result === '_ $1  _') {
      result = result.replace(/\s+/g, ''); // Remove spaces within _
      result = '_$1_';
    } else if (result === '~ $1 ~') {
      result = '~$1~'; // Remove leading/trailing spaces within ``
    }
    return result;
  }
  const options = [
    { value: 'Name', label: '{{Name}}' },
    { value: 'Phone', label: '{{Phone}}' },
    { value: 'Email', label: '{{Email}}' },
  ];

  // const handlemessage = (message: any) => {
  //   const currentEditorValue = editorValue.toString('markdown');
  //   const updatedEditorValue = currentEditorValue + message.label;
  //   const newEditorValue = EditorValue.createFromString(updatedEditorValue, 'markdown');
  //   setEditorValue(newEditorValue);
  //   setShowEmojiPicker(prevData => [...prevData, message.label]);
  // };
  const handlemessage = (message: any) => {
    const editorState = editorValue.getEditorState();
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    if (currentContent.getPlainText().length + message.label.length <= maxCharacterCount && currentContent.getPlainText().length) {
      const contentStateWithMessage = Modifier.insertText(
        currentContent,
        selection,
        message.label
      );
      const newEditorState = EditorState.push(
        editorState,
        contentStateWithMessage,
        'insert-characters'
      );
      const newEditorValue = editorValue.setEditorState(newEditorState);
      setEditorValue(newEditorValue);
      setShowEmojiPicker(prevData => [...prevData, message.label]);
    }
  };


  function getFileIcon(extension: string): JSX.Element {
    if (extension === 'pdf') {
      return <FaFilePdf />;
    } else if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
      return <FaFileImage />;
    }
    return <FaUpload />;
  }

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const selectedFile = event.target.files?.[0];
  //   if (selectedFile) {
  //     const imgUrl = URL.createObjectURL(selectedFile);
  //     setFiles([...files, { file: selectedFile, imgUrl }]);
  //     setSelectedFile(selectedFile);
  //     // insertFilePlaceholder(selectedFile);
  //   }
  // };
  // const insertFilePlaceholder = (file: File) => {
  //   const placeholder = `Uploaded File: ${file.name}`;
  //   const currentEditorValue = editorValue.toString('markdown');
  //   const updatedEditorValue = currentEditorValue + placeholder;  
  //   const newEditorValue = EditorValue.createFromString(updatedEditorValue, 'markdown');
  //   setEditorValue(newEditorValue);
  // };
  const maxCharacterCount = 1024;

  const handleEditorChange = (newEditorValue: any) => {
    const contentHtml = newEditorValue.toString('html');
    const plainTextContent = contentHtml.replace(/<[^>]+>/g, '');
    const currentCharacterCount = plainTextContent.length;
    setCounter(currentCharacterCount);
    // if (currentCharacterCount <= maxCharacterCount) {
    setEditorValue(newEditorValue);
  };

  const uploadFile = async (file: File) => {
    const allowedSize: number = 2;
    if (file.size > allowedSize * 1024 * 1024) {
      toast("error", "File size too large!" + "Please select a file smaller than " + allowedSize + "MB.");
      return;
    }
    const formData = new FormData();
    formData.append('content', file);
    try {
      const response = await AxiosInstance.post('media/saveMessageMedia?uid=' + channel?.channelAllData?.uid, formData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
        }
      });

      setMedia(response.data)
      props.setMediaObj(response.data);
    } catch (error) {
      console.error(error);
      toast("error", "Failed to upload!");
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      await uploadFile(file);
    }
  };

  const removeFileHandler = async (media: any) => {
    try {
      await mediaService.deleteMedia(media);
      setMedia(null);
      props.setMediaObj(null);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="editor">
      <Editor toolbarConfig={toolbarConfig} value={editorValue} autoFocus={true} onChange={handleEditorChange} placeholder="Type your messsage" />
      <p className='float-right text-muted'>characters: {counter}</p>
      {counter >= 1024 ? (
        <p style={{ color: 'red' }}>
          Maximum character count reached.
        </p>
      ) : counter <= 0?  <p style={{ color: 'red' }}>
      Message is required.
    </p> : ''}

      <Button className="signUpBtn addvariable" type="button" onClick={showVariable}>+ Variable</Button>
      <FontAwesomeIcon icon={faInfoCircle} className='infoicon' title='These make the content of the message for personalized users.' />
      {Var ? <div className="dropDown"><Select className="p-0" options={options} onChange={handlemessage} /> </div> : ''}
      <Button className="signUpBtn1 btn btn-secondary" id="popemoji" type="button" onClick={(event: any) => {
        setPopoverOpen(true);
        setpopovertarget(event.currentTarget);
      }}>
        <FontAwesomeIcon icon={faGrinAlt} />
      </Button>

      <div>
        <Popover
          isOpen={popoverOpen}
          target='popemoji'
          toggle={() => {
            setPopoverOpen(false);
            setpopovertarget(null);
          }}
          placement="bottom"
          className="emojipopover emoji-popover"
          trigger="legacy"
        >
          <PopoverBody>
            <div>
              <Picker data={customData} onEmojiSelect={(e: any) => {
                handleEmojiClick(e);
              }}
              />
            </div>
          </PopoverBody>
        </Popover>
      </div>

      <label className='fileattach btn btn-secondary'><span><FontAwesomeIcon icon={faPaperPlane} aria-hidden="true" title=''/></span>
        <input
          type="file"
          accept=".jpeg,.jpg,.png"
          className='none'
          title=''
          onChange={(event) => {
            const file = event.target.files;
            //  field.onChange(event);
            onFileChange(event);
          }
          }
        />
      </label>
      {media && media.url ?
        <div className='mt-5'>
          <label className='text-dark '>File Name: <span className='text-muted'>{media?.fileName?.length > 30? media?.fileName.slice(0,29) : media?.fileName}</span></label>
          <br/>
          <div>
          <img src={media.url} alt='img' width='80' height='80' className='p-2 border'></img>
          <button type='button' onClick={() => {
            removeFileHandler(media);
          }} className="bg-white border-0 ml-3">
            <BsFillTrashFill className="bg-white text-secondary h6" />
          </button>
          </div>
        </div> : ''
      }
      <br />
      {/* {selectedFile && (
        <div className='border'>        
          {selectedFile.type.includes('image') ? (
            <img src={selectedFile && URL.createObjectURL(selectedFile)} alt="Selected" width={40} />    
          ) : (
            <a href={selectedFile && URL.createObjectURL(selectedFile)} target="_blank" rel="noopener noreferrer">
             <span className='h2'>{getFileIcon(selectedFile.name.split('.').pop() || '')} </span> Open PDF 
            </a>
          )}
          <br/>
          <span>{selectedFile.name}</span>
        </div>
      )} */}
    </div>
  );
}

export default Toolbar;