import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Popover,
  PopoverBody,
  UncontrolledTooltip,
  Row,
  Col,
  Label,
  FormGroup,
  Button,
} from "reactstrap";
import Modall from "../../common/modall";
import TemplateList from "../Template/templateList";
import CommonPopover from "../../common/commonPopover";
import QuickReplies from "../../common/quickReplies";
import { extractVariableName } from "../../common/commonfns";
import { toast } from "../../common/alert";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import EmojiPicker, { EmojiStyle, Theme } from "emoji-picker-react";
import "./conversation.scss";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { faFaceSmile } from "@fortawesome/free-regular-svg-icons";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import quickreplyicon from "../../assets/img/svgs/quick_rep.svg";
import { Quill } from "react-quill";
import conversationSendMessageContext from "./conversationSendMessageContext";
import { getCredit } from "../../services/dashboardService";
import TooltipReference from "../../common/tooltip";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import RightSidebar from "../../common/rightSidebar";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FieldProps,
  FormikProps,
} from "formik";
import { getAll } from "../../services/paymentService";
import * as Yup from "yup";
import Select from "react-select";
// import { getAll as product } from '../../services/catalogueService';

import { getAllCatalogue } from "../../services/catalogueService";
import AxiosInstance from "../../utils/axios";
import * as mediaService from "../../botFlows/services/media";
import { BsFillTrashFill } from "react-icons/bs";
import styles from "../../botFlows/components/designFlow/editor/catalogue/sendOrderDetails.module.scss";
import ValidationSettings from "../../botFlows/components/designFlow/editor/askType/validationSettings";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
interface SelectBtnOption {
  value: string;
  label: string;
}

interface ProductItem {
  saleAmount?: number;
  amount?: number;
  quantity?: number;
}

interface FormContext {
  product?: ProductItem[];
  taxAmt?: string;
  shipAmt?: string;
}

interface InputProps {
  handleInputChange: (e: string) => void;
  setMediaType: (type: string) => void;
  selectedConversation?: any;
  replyMessage?: any;
  setFiles: React.Dispatch<
    React.SetStateAction<
      {
        imgUrl: string;
        imgName: string;
        image: File;
        type: string;
        size: number;
        innerType: "video" | "image" | "document";
      }[]
    >
  >;
  setMediaRes: (type: any) => void;
  socket?: any;
  component: any;
  isInvalidAccess?: boolean;
  credits: any
}
type ReactQuillInstance = {
  focus(): unknown;
  getEditor: () => any;
  makeUnprivilegedEditor: (editor: any) => { root: HTMLElement };
  editingArea: any;
};
const ConversationInput: React.FC<InputProps> = ({
  handleInputChange,
  setMediaType,
  selectedConversation,
  setFiles,
  replyMessage,
  setMediaRes,
  socket,
  component,
  isInvalidAccess, credits
}) => {
  const [selectedTool, setSelectedTool] = React.useState<string | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const togglePopover = () => setPopoverOpen(!popoverOpen);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [notes, setNotes] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [quickPopoverOpen, setQuickPopoverOpen] = useState(false);
  const [convInput, setConvInput] = useState("");
  const [messageType, setMessageType] = useState("");
  const [innerMediatype, setInnerMediaType] = useState("");
  const editableRef = React.useRef<ReactQuillInstance | null>(null);
  const [storedRange, setStoredRange] = useState<Range | null>(null);
  const [modalHead, setModalTiitle] = useState("Select Template");
  const [modalSize, setModalSize] = useState("modal-xxl");
  const business = useSelector((state: any) => state.cartreducer.business);
  const [isFocused, setIsFocused] = useState(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const [emojiPopupOpen, setEmojiPopupOpen] = useState(false);
  const conversationSendMessageCtx = useContext(conversationSendMessageContext);
  const [showContent, setContent] = useState<boolean>(false);
  const channel: any = useSelector(
    (state: any) => state.cartreducer.channelUid
  );
  const [charCount, setCount] = useState(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [payments, setPayment] = React.useState<SelectBtnOption | any>([]);
  const [catalogue, setCatalogue] = React.useState<SelectBtnOption | any>([]);
  const [media, setMedia] = useState<any>([]);
  const [footerCount, setFooterCount] = useState(0);
  const [taxCount, setTax] = useState(0);
  const [shipCount, setShip] = useState(0);
  const [discountCount, setDiscount] = useState(0);
  const [nameCount, setName] = useState(0);
  const [isTaxVisible, setIsTaxVisible] = useState(false);
  const [isShippingVisible, setIsShippingVisible] = useState(false);
  const [isDiscountVisible, setIsDiscountVisible] = useState(false);
  const [isOpen, setValidateOpen] = useState(false);
  const [paginationPayload, setPaginationPayload] = useState({
    pagelimit: 20,
    pageSize: 0,
    currentPage: 0,
  });
  const [quickMedia, setQuickMedia] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const previousConversationRef = useRef(selectedConversation);

  const toggleTaxForm = (value: string) => {
    value === "tax" && setIsTaxVisible(!isTaxVisible);
    value === "shipping" && setIsShippingVisible(!isShippingVisible);
    value === "discount" && setIsDiscountVisible(!isDiscountVisible);
  };
  // const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const time = [
    { value: "Minutes", label: "Minutes" },
    { value: "Hours", label: "Hours" },
  ];

  useEffect(() => {
    if (editableRef.current) {
      editableRef.current.focus();
    }
  }, [selectedConversation]);

  const toggleQuickPopover = () => {
    setQuickPopoverOpen(!quickPopoverOpen);
  };

  const selectItem = (itemId: string) => {
    if (selectedTool === itemId) {
      setSelectedTool(null);
      switch (itemId) {
        case "notes":
          {
            setNotes(false);
            setSelectedTool("");
            setPopoverOpen(false);
          }
          break;
      }
    } else {
      setSelectedTool(itemId);
      switch (itemId) {
        case "sendSvg":
          togglePopover();
          break;
        case "notes":
          {
            setNotes(true);
            if (messageType === "quick") {
              setConvInput("");
              setMessageType("message");
            }
          }
          break;
        case "templates":
          handleOpen();
          break;
        case "B":
          makeBold();
      }
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    const selection = window.getSelection();
    if (!selection || !selection.rangeCount) return;
    const range = selection.getRangeAt(0).cloneRange();
    setStoredRange(range);
  };

  const makeBold = () => {
    if (!storedRange) return;
    const boldElement = document.createElement("strong");
    storedRange.surroundContents(boldElement);
    window.getSelection()?.removeAllRanges();
  };

  useEffect(() => {
    if (previousConversationRef.current?.uid !== selectedConversation?.uid) {
      setConvInput("");
    }
    previousConversationRef.current = selectedConversation;
    setSelectedTool("");
    setPopoverOpen(false);
    setNotes(false);
    (window as any).CONVO_SELECTED = selectedConversation;
  }, [selectedConversation]);

  useEffect(() => {
    if (!popoverOpen) {
      setSelectedTool("");
    }
  }, [popoverOpen]);

  // const handleKeyDown = (e: KeyboardEvent) => {
  //     if ("key" in e && e.key !== "Enter") return;
  //     e.preventDefault();
  //     if (notes) {
  //         setMediaType('notes');
  //     } else {
  //         setMediaType('text');
  //     }
  // };
  // useEffect(() => {
  //     if (editableRef.current && extractTextFromHTML(convInput)) {
  //         const quill = editableRef.current.getEditor();
  //         const handleChange = (delta: any, oldDelta: any, source: any) => {
  //             if (source === 'user') {
  //                 const enterKeyPressed = delta.ops && delta.ops[1] && delta.ops[1].insert === '\n';
  //                 if (enterKeyPressed) {
  //                     const retText = convertHTMLToMarkdown(convInput);
  //                     handleInputChange(retText);
  //                     if (notes) {
  //                         setMediaType('notes');
  //                     } else {
  //                         setMediaType('text');
  //                     }
  //                     quill.deleteText(quill.getLength() - 2, 1);
  //                 }
  //             }
  //         };
  //         quill.on('text-change', handleChange);
  //         return () => {
  //             quill.off('text-change', handleChange);
  //         };
  //     }
  // }, [convInput]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const sample = convertHTMLToMarkdown(convInput).trim();
      if (editableRef.current && convertHTMLToMarkdown(convInput).trim()) {
        const quill = editableRef.current.getEditor();
        // Alt+Enter
        if (event.altKey && event.key === "Enter") {
          event.preventDefault();
          const range = quill.getSelection();
          if (range) {
            quill.insertText(range.index, "\n");
            quill.setSelection(range.index + 1, Quill.sources.SILENT);
          }
        }
        // Only Enter
        else if (!event.altKey && !event.shiftKey && event.key === "Enter") {
          if (extractTextFromHTML(convInput)) {
            setTimeout(() => {
              setConvInput('');
            })
            if (notes) {
              setMediaType("notes");
            } else if (quickMedia) {
              setMediaType("quickMedia");
            } else {
              setMediaType("text");
            }
          }
          setQuickMedia(false)
          quill.deleteText(quill.getLength() - 2, 1);
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [convInput, notes]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedFiles: {
      imgUrl: string;
      imgName: string;
      image: File;
      type: string;
      size: number;
      innerType: "video" | "image" | "document"; // for internal state reference
    }[] = [];
    const allowedFileTypes = [
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/plain",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (event.target.files && event.target.files.length > 0) {
      const newFile = event.target.files[0];
      switch (innerMediatype) {
        case "image": {
          if (
            newFile.type === "image/jpeg" ||
            newFile.type === "image/png" ||
            newFile.type === "image/jpg"
          ) {
            const imgUrl = URL.createObjectURL(newFile);
            updatedFiles.push({
              imgUrl: imgUrl,
              imgName: newFile.name,
              image: newFile,
              type: newFile.type,
              size: newFile.size,
              innerType: "image",
            });
            if (newFile.size > 2 * 1024 * 1024) {
              setFiles([]);
              toast("error", "Image must be less than 2MB");
            } else {
              setFiles(updatedFiles);
            }
          } else {
            toast("error", "Invalid file format");
          }
          break;
        }
        case "video": {
          if (newFile.type === "video/mp4") {
            const imgUrl = URL.createObjectURL(newFile);
            updatedFiles.push({
              imgUrl: imgUrl,
              imgName: newFile.name,
              image: newFile,
              type: newFile.type,
              size: newFile.size,
              innerType: "video",
            });
            if (newFile.size > 16 * 1024 * 1024) {
              setFiles([]);
              toast("error", "Video must be less than 16MB");
            } else {
              setFiles(updatedFiles);
            }
          } else {
            toast("error", "Invalid file format");
          }
          break;
        }
        case "doc": {
          if (allowedFileTypes.includes(newFile.type)) {
            const imgUrl = URL.createObjectURL(newFile);
            updatedFiles.push({
              imgUrl: imgUrl,
              imgName: newFile.name,
              image: newFile,
              type: newFile.type,
              size: newFile.size,
              innerType: "document",
            });
            if (newFile.size > 100 * 1024 * 1024) {
              setFiles([]);
              toast("error", "Document must be less than 100MB");
            } else {
              setFiles(updatedFiles);
            }
          } else {
            toast("error", "Invalid file format");
          }
          break;
        }
      }
      togglePopover();
      setSelectedTool("");
    }
  };
  const handleUploadClick = (type: string) => {
    if (fileInputRef.current) {
      switch (type) {
        case "image":
          fileInputRef.current.accept = "image/*";
          break;
        case "video":
          fileInputRef.current.accept = "video/*";
          break;
        case "doc":
          fileInputRef.current.accept = ".pdf, .xls, .xlsx, .txt, .doc, .docx";
          break;
      }
      fileInputRef.current.click();
    }
    setInnerMediaType(type);
  };

  const handleClose = () => {
    setOpenModal(false);
    setModalSize("modal-xxl");
    setSelectedTool("");
  };
  const modalTitle = (title: string) => {
    setModalTiitle(title);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };
  const getQuickReply = (quickres: any) => {
    const extractedWords = extractVariableName(quickres.content);
    var renderedText = quickres.content;
    extractedWords.forEach((variableName: any, index: number) => {
      const replacedText = renderedText.replace(
        `{{${variableName}}}`,
        variableName === "Name"
          ? selectedConversation.contact.name
          : variableName === "Phone"
            ? selectedConversation.contact.mobileNumber
            : selectedConversation.contact.email
      );
      renderedText = replacedText;
    });
    setQuickPopoverOpen(false);
    setMessageType("quick");
    handleInputChange(renderedText);
    if (quickres?.media && quickres?.media?.url) {
      setQuickMedia(true);
      const newContent = `<p><img src=${quickres?.media?.url
        } width=${40} height=${40}></img></p>` + renderedText;
      setConvInput(newContent);
      quickres.media.caption = renderedText;
      setMediaRes(quickres?.media);
      // Manually set cursor position after setting state
      setTimeout(() => {
        if (editableRef.current) {
          const editor = editableRef.current.getEditor();
          editor.focus();
          editor.setSelection(editor.getLength(), 0);
        }
      }, 0);
    } else {
      setQuickMedia(false);
      setConvInput(renderedText);
      // Manually set cursor position after setting state
      setTimeout(() => {
        if (editableRef.current) {
          const editor = editableRef.current.getEditor();
          editor.focus();
          editor.setSelection(editor.getLength(), 0);
        }
      }, 0);
    }
  };

  const extractTextFromHTML = (htmlString: string): string => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    const paragraphs = doc.body.querySelectorAll("p");
    let text = "";
    paragraphs.forEach((paragraph: Element, index: number) => {
      const paragraphText = paragraph.textContent;
      // if (paragraphText) {
      text += paragraphText;
      if (index < paragraphs.length - 1) {
        text += "\n";
        // }
      }
    });
    return text;
  };

  const convertHTMLToMarkdown = (html: string): string => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    function traverse(node: Node): string {
      // if (node.nodeType === Node.TEXT_NODE) {
      //   return node.nodeValue || "";
      // }
      // if (node.nodeType !== Node.ELEMENT_NODE) {
      //   return "";
      // }
      if (node.nodeType === Node.TEXT_NODE) {
        const content = (node.nodeValue || "").trim();
        if (content === "" && node !== node.parentNode?.lastChild) return "";
        return content + (content.endsWith("\n") ? "" : "\n");
      }
      if (node.nodeType !== Node.ELEMENT_NODE) {
        return "";
      }

      const element = node as Element;
      if (element.nodeName === "BR") {
        return "\n";
      }
      // const element = node as Element;
      const content = Array.from(element.childNodes).map(traverse).join("");
      switch (element.nodeName) {
        case "S":
          const hasItalicInStrike = element.querySelector("i");
          const hasBoldInStrike = element.querySelector("strong");
          if (hasItalicInStrike && hasBoldInStrike) {
            return `~*_${content}_*~`;
          } else if (hasItalicInStrike) {
            return `~_${content}_~`;
          } else if (hasBoldInStrike) {
            return `~*${content}*~`;
          } else {
            return `~${content}~`;
          }
        case "STRONG":
          const hasItalicInBold = element.querySelector("i");
          const hasStrikeInBold = element.querySelector("del");
          if (hasItalicInBold && hasStrikeInBold) {
            return `*~_${content}_~*`;
          } else if (hasItalicInBold) {
            return `*_${content}_*`;
          } else if (hasStrikeInBold) {
            return `*~${content}~*`;
          } else {
            return `*${content}*`;
          }
        case "EM":
          const hasBoldInItalic = element.querySelector("strong");
          const hasStrikeInItalic = element.querySelector("del");
          if (hasBoldInItalic && hasStrikeInItalic) {
            return `_*~${content}~*_`;
          } else if (hasBoldInItalic) {
            return `_*${content}*_`;
          } else if (hasStrikeInItalic) {
            return `_~${content}~_`;
          } else {
            return `_${content}_`;
          }
        default:
          return content;
      }
    }
    return traverse(doc.body);
  };
  
  const handleBoldClick = () => {
    const quill = editableRef.current?.getEditor();
    if (quill) {
      const currentText = quill.getText();
      const currentRange = quill.getSelection();
      const format = quill.getFormat(currentRange ? currentRange.index : 0);
      if (currentText.trim()) {
        if (format.bold) {
          quill.format("bold", false);
        } else {
          quill.format("bold", true);
        }
      } else {
        quill.insertText(0, " ");
        quill.setSelection(0, 1);
        quill.format("bold", true);
        quill.focus();
        if (currentRange || currentText.trim() === "") {
          quill.setSelection(currentRange ? currentRange.index : 1);
        }
      }
    }
  };
  const insertEmojiHandler = (emoji: string) => {
    if (editableRef.current) {
      const quill = editableRef.current.getEditor();
      const lastLineIndex = quill.getLength() - 1;
      quill.insertText(lastLineIndex, emoji, "user");
      quill.setSelection(lastLineIndex + 1, "silent");
      quill.focus();
    }
  };

  const handleCancel = () => {
    setContent(false);

    setIsFirstLoad(true);
    setIsTaxVisible(false);
    setIsShippingVisible(false);
    setIsDiscountVisible(false);
    setPaginationPayload({
      pagelimit: 20,
      pageSize: 0,
      currentPage: 0,
    });
  };

  const handleScrollToBottom = async () => {
    console.log("Scrolled to bottom, loading more options...");
    const nextPage = isFirstLoad ? 0 : paginationPayload.pageSize + 1;
    const nextLimit = paginationPayload.pagelimit;

    const listPayload = {
      page: nextPage,
      limit: nextLimit,
      search: "",
    };

    const catalogueResponse = await getAllCatalogue(
      business?.business?.uid,
      listPayload
    );
    const catalogueData = catalogueResponse.digitalItem;

    setCatalogue((prevCatalogue: any) => [...prevCatalogue, ...catalogueData]);
    setPaginationPayload({
      pagelimit: nextLimit,
      pageSize: nextPage,
      currentPage: paginationPayload.currentPage,
    });
    setIsFirstLoad(false);
  };

  const addPayment = async () => {
    setPaginationPayload({
      pagelimit: 20,
      pageSize: 0,
      currentPage: 0,
    });
    setCatalogue('')
    try {
      const response = await getAll(channel?.channelAllData?.uid);
      const data = response.data;
      setPayment(data);
      handleScrollToBottom();
      setContent(true);
      setMedia(null);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const validation = Yup.object().shape({
    paymentobj: Yup.object()
      .nullable()
      .required("Payment provider is required"),
    duration: Yup.number().when("time", {
      is: (time: { value: string }) => time.value === "Minutes",
      then: Yup.number().min(5, "Duration must be at least 5 minutes"),
    }),
    body: Yup.string()
      .required("Message is required")
      .max(1024, "Message must not exceed 1024 characters"),
    product: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(),
          label: Yup.string().required(),
        })
      )
      .min(1, "Product is required"),
    footer: Yup.string().max(60, "Footer must not exceed 60 characters"),
    taxDesc: Yup.string().max(60, "Description must not exceed 60 characters"),
    shipDec: Yup.string().max(60, "Decription must not exceed 60 characters"),
    discountDesc: Yup.string().max(
      60,
      "Description must not exceed 60 characters"
    ),
    discountName: Yup.string().max(
      60,
      "description must not exceed 60 characters"
    ),
    discountAmt: Yup.number()
      .nullable()
      .test(
        "max-discount",
        "Discount amount must be less than the total amount after adjustments",
        function (value) {
          if (value == null) {
            return true;
          }

          const context = this.options.context as FormContext;
          const { product, taxAmt, shipAmt } = context;
          const totalSalesAmount = product
            ? product.reduce((acc: number, item: ProductItem) => {
              const itemAmount =
                (item?.saleAmount && item?.saleAmount > 0 ? item.saleAmount / 100 : (item?.amount ? item?.amount / 100 : 0)) || 0;
              return acc + itemAmount * (item.quantity || 1);
            }, 0)
            : 0;

          const totalAmount =
            totalSalesAmount +
            (parseFloat(taxAmt || "0")) +
            (parseFloat(shipAmt || "0"));
          const adjustedAmount = totalAmount - (parseFloat(value.toString()) || 0);

          if (adjustedAmount < 1) {
            return this.createError({
              message: "Discount amount must be less than the total amount",
              path: this.path,
            });
          }
          if (value >= totalAmount) {
            return this.createError({
              message: "Discount amount must be less than the total amount",
              path: this.path,
            });
          }
          return true;
        }
      ),

    // discountAmt: Yup.number()
    // .nullable()
    // .test(
    //   "max-discount",
    //   "Discount amount must be less than the total amount",
    //   function (value) {
    //     if (value == null) {
    //       return true; 
    //     }
    //     const { product } = this.parent;
    //     // console.log("pro", product)
    //     const totalSalesAmount = product
    //       ? product.reduce((acc: number, item: any) => acc + (item?.saleAmount? (item?.saleAmount/100) : (item?.amount/100) || 0) * (item.quantity || 1), 0)
    //       : 0;
    //     return value < totalSalesAmount;
    //   }
    // ),
  });

  const selectStyles = {
    menu: (provided: any) => ({
      ...provided,
      width: "102%",
    }),
    control: (provided: any) => ({
      ...provided,
      width: "100%",
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: "inherit",
      ":hover": {
        backgroundColor: "transparent",
        color: "#6f6b6b",
      },
    }),
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      await uploadFile(file);
    }
  };

  const uploadFile = async (file: File) => {
    const allowedSize: number = 2;
    if (file.size > allowedSize * 1024 * 1024) {
      toast(
        "error",
        "File size too large!" +
        "Please select a file smaller than " +
        allowedSize +
        "MB."
      );
      return;
    }
    const formData = new FormData();
    formData.append("content", file);
    try {
      const response = await AxiosInstance.post(
        "media/saveMessageMedia?uid=" + channel?.channelAllData?.uid,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setMedia(response.data);
    } catch (error) {
      console.error(error);
      toast("error", "Failed to upload!");
    }
  };

  const removeFileHandler = async (media: any) => {
    try {
      await mediaService.deleteMedia(media);
      setMedia(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (values: any) => {
    const selectedPayment = payments.find(
      (payment: any) => payment.uid === values.paymentobj.value
    );
    const matchedProducts = catalogue.filter((item: any) => {
      return values.product.some(
        (selectedProduct: any) => selectedProduct.value === item.uid
      );
    });

    matchedProducts.forEach((matchedProduct: any, index: number) => {
      matchedProduct.quantity = values.product[index].quantity;
    });

    const payload = {
      businessId: business?.business?.uid,
      channelId: channel?.channelAllData?.uid,
      conversationUid: selectedConversation?.uid,
      contactId: selectedConversation?.contact?.uid,
      content: values.body,
      type: "text",
      subType: "text",
      note: false,
      mobileNumber: selectedConversation?.contact?.mobileNumber,
      assignId: selectedConversation?.assignId,
      assignTo: selectedConversation?.assignTo,
      paymentConfig: selectedPayment,
      expiringDuration: values.duration
        ? values.time.label === "Hours"
          ? values.duration * 3600000
          : values.duration * 60 * 1000
        : 5 * 60 * 1000,
      digitalItems: matchedProducts,
      header: media
        ? {
          headerType: "image",
        }
        : null,
      footerText: values.footer,
      bodyText: values.body,
      mediaDto: media ? media : null,
      tax: {
        amount: values.taxAmt ? values.taxAmt * 100 : null,
        description: values.taxDesc ? values.taxDesc : null,
      },
      discount: {
        amount: values.discountAmt ? values.discountAmt * 100 : null,
        description: values.discountDesc ? values.discountDesc : null,
        discountProgramName: values.discountName ? values.discountName : null,
      },
      shipping: {
        amount: values.shipAmt ? values.shipAmt * 100 : null,
        description: values.shipDec ? values.shipDec : null,
      },
    };
    socket.emit("orderMessage", payload);
    // setPaginationPayload({
    //   pagelimit: 0,
    //   pageSize: 0,
    //   currentPage: 0,
    // });
    // setContent(false);
    handleCancel();
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    try {
      if (event.key === "Enter" && !event.shiftKey) {
        if (convertHTMLToMarkdown(convInput).trim().length === 0) {
          event.stopPropagation();
          event.preventDefault(); // prevent the default behavior of the Enter key press
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={isInvalidAccess ?
        <Tooltip>
          Access denied !
        </Tooltip> : <></>
      }
    >
      <div className={`${isInvalidAccess ? "notAllowed" : "pointer"}`}>
        <div
          className={`messageInput contactPanel p-0 ${notes ? "notesBgsent" : ""
            }`}
          style={{
            border: component === "sendTicket" ? "1px solid #E9E9E9" : "",
            pointerEvents: isInvalidAccess ? "none" : "all",
            opacity: isInvalidAccess ? "0.8" : "1",
          }}
        >
          {credits?.totalCredits <= 1 ? (
            <div className="timeoutConvoMessage deletedContact mr-4">
              <div>
                <FontAwesomeIcon icon={faBan} className="mr-2" />
                You have reached the maximum conversation limit. Please add credits to continue further.
              </div>
            </div>) : (
            <>
              <div className="w-100">
                {(selectedConversation?.remainingTime > 0 || notes) &&
                  !selectedConversation?.contact?.isDeleted ? (
                  <>
                    <div className="reactQuillInput">
                      <ReactQuill
                        readOnly={isInvalidAccess}
                        ref={editableRef}
                        value={convInput}
                        onChange={(content: string) => {
                          setConvInput(content);
                          handleInputChange(convertHTMLToMarkdown(content));
                        }}
                        autoFocus={true}
                        modules={{
                          toolbar: {
                            container: "#toolbar",
                          },
                        }}
                        selection={{ index: 0, length: 0 }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        placeholder="Enter Message"
                        className={`quillEditor${notes ? "notesBgSent" : ""} 
                    ${selectedConversation?.remainingTime <= 0 && !notes
                            ? "quill-disabled"
                            : ""
                          }`}
                        onKeyDown={(e: any) => handleEnterKeyPress(e)}
                      />
                    </div>
                    <div className="d-flex" id="toolbar">
                      <div id="toolbar">
                        <UncontrolledTooltip placement="top" target="Tooltip-Bold">
                          Bold
                        </UncontrolledTooltip>
                        <button
                          id="Tooltip-Bold"
                          className="ql-bold"
                          disabled={!convertHTMLToMarkdown(convInput)}
                        ></button>
                        <UncontrolledTooltip placement="top" target="Tooltip-Italic">
                          Italic
                        </UncontrolledTooltip>
                        <button
                          id="Tooltip-Italic"
                          className="ql-italic"
                          disabled={!convertHTMLToMarkdown(convInput)}
                        ></button>
                        <UncontrolledTooltip
                          placement="top"
                          target="Tooltip-Strikethrough"
                        >
                          Strikethrough
                        </UncontrolledTooltip>
                        <button
                          id="Tooltip-Strikethrough"
                          className="ql-strike"
                          disabled={!convertHTMLToMarkdown(convInput)}
                        ></button>
                        {/* <button className="ql-underline"></button> */}
                      </div>
                      {!notes && selectedConversation.remainingTime > 0 && (
                        <>
                          <div className="ml-1 mt-1">
                            {component === "conversationInbox" && (
                              <>
                                <UncontrolledTooltip
                                  placement="top"
                                  target="quickRep"
                                >
                                  Quick Replies
                                </UncontrolledTooltip>
                                <img
                                  src={quickreplyicon}
                                  onClick={() => selectItem("quickRes")}
                                  id="quickRep"
                                  className="mb-2"
                                />
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 10 11" fill="none"
                                                        onClick={() => selectItem("quickRes")} id='quickRep'>
                                                        <ellipse cx="5" cy="5.125" rx="5" ry="5.125" fill="#90939F" />
                                                        <path d="M0.549238 10.1115H4.57701L1.93815 8.86483L1.3826 8.72632L0.688127 9.41889C0.132571 9.97294 0.364053 10.1115 0.549238 10.1115Z" fill="#90939F" stroke="#90939F" stroke-width="0.5" />
                                                        <path d="M5.96819 2.07751L5.8293 2.21602L2.91263 5.1248C2.69041 5.45723 2.91263 5.54034 3.05152 5.54034H4.30152L3.60708 8.03359C3.49597 8.58764 3.74597 8.44913 3.88486 8.31061L6.94041 5.26332C7.16263 5.04169 7.033 4.89395 6.94041 4.84778H5.55152L6.24597 2.21602C6.24597 1.9944 6.06078 2.03133 5.96819 2.07751Z" fill="white" stroke="white" stroke-width="0.5" />
                                                    </svg> */}
                                <CommonPopover
                                  isOpen={quickPopoverOpen}
                                  target="quickRep"
                                  toggle={toggleQuickPopover}
                                  placement="top"
                                  component="conversation"
                                >
                                  <QuickReplies
                                    component="conversation"
                                    getQuickReply={getQuickReply}
                                  ></QuickReplies>
                                </CommonPopover>
                              </>
                            )}
                          </div>
                          <div className="mx-1">
                            <UncontrolledTooltip
                              placement="top"
                              target="convAddEmoji"
                            >
                              Pick an Emoji
                            </UncontrolledTooltip>
                            <FontAwesomeIcon
                              icon={faFaceSmile}
                              className="ml-2 mb-2 mt-1"
                              id="convAddEmoji"
                              color="#90939F"
                              fontSize={16}
                            />
                            <Popover
                              placement="top"
                              target="convAddEmoji"
                              trigger="legacy"
                              isOpen={emojiPopupOpen}
                              toggle={() => setEmojiPopupOpen((current) => !current)}
                            >
                              <EmojiPicker
                                skinTonesDisabled
                                theme={Theme.LIGHT}
                                emojiStyle={EmojiStyle.APPLE}
                                onEmojiClick={({ emoji }) => {
                                  insertEmojiHandler(emoji);
                                  setEmojiPopupOpen(false);
                                }}
                              />
                            </Popover>
                          </div>
                          <UncontrolledTooltip placement="top" target="filesPopover">
                            Attachment
                          </UncontrolledTooltip>
                          <FontAwesomeIcon
                            icon={faPaperclip}
                            className="ml-2 mb-2 mt-1"
                            onClick={() => selectItem("sendSvg")}
                            id="filesPopover"
                            color="#90939F"
                            fontSize={16}
                          />
                          <Popover
                            placement="top"
                            isOpen={popoverOpen}
                            target="filesPopover"
                            toggle={togglePopover}
                            trigger="legacy"
                            className="convoUploadPopup"
                          >
                            <PopoverBody>
                              <div
                                className="convoUploadPopup_item hover-red"
                                onClick={() => handleUploadClick("image")}
                              >
                                {/* <FontAwesomeIcon icon={faImage} className='mr-2' /> */}
                                <Row>
                                  <Col className="col-1">
                                    <span className="mr-2">
                                      <svg
                                        width="28"
                                        height="20"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M23.625 4.375H7.875C7.41087 4.375 6.96575 4.55937 6.63756 4.88756C6.30937 5.21575 6.125 5.66087 6.125 6.125V7.875H4.375C3.91087 7.875 3.46575 8.05937 3.13756 8.38756C2.80937 8.71575 2.625 9.16087 2.625 9.625V21.875C2.625 22.3391 2.80937 22.7842 3.13756 23.1124C3.46575 23.4406 3.91087 23.625 4.375 23.625H20.125C20.5891 23.625 21.0342 23.4406 21.3624 23.1124C21.6906 22.7842 21.875 22.3391 21.875 21.875V20.125H23.625C24.0891 20.125 24.5342 19.9406 24.8624 19.6124C25.1906 19.2842 25.375 18.8391 25.375 18.375V6.125C25.375 5.66087 25.1906 5.21575 24.8624 4.88756C24.5342 4.55937 24.0891 4.375 23.625 4.375ZM7.875 6.125H23.625V12.9883L22.5236 11.888C22.3611 11.7254 22.1682 11.5965 21.9558 11.5085C21.7435 11.4205 21.5159 11.3753 21.286 11.3753C21.0562 11.3753 20.8286 11.4205 20.6162 11.5085C20.4039 11.5965 20.2109 11.7254 20.0484 11.888L17.8609 14.0755L13.0484 9.26297C12.7203 8.93503 12.2753 8.75081 11.8114 8.75081C11.3475 8.75081 10.9025 8.93503 10.5744 9.26297L7.875 11.9623V6.125ZM20.125 21.875H4.375V9.625H6.125V18.375C6.125 18.8391 6.30937 19.2842 6.63756 19.6124C6.96575 19.9406 7.41087 20.125 7.875 20.125H20.125V21.875ZM23.625 18.375H7.875V14.4375L11.8125 10.5L17.2441 15.9316C17.4081 16.0955 17.6306 16.1876 17.8626 16.1876C18.0945 16.1876 18.317 16.0955 18.4811 15.9316L21.2877 13.125L23.625 15.4634V18.375ZM17.5 9.1875C17.5 8.92791 17.577 8.67415 17.7212 8.45831C17.8654 8.24247 18.0704 8.07425 18.3102 7.97491C18.5501 7.87557 18.814 7.84958 19.0686 7.90022C19.3232 7.95086 19.557 8.07587 19.7406 8.25942C19.9241 8.44298 20.0491 8.67684 20.0998 8.93144C20.1504 9.18604 20.1244 9.44994 20.0251 9.68977C19.9258 9.9296 19.7575 10.1346 19.5417 10.2788C19.3258 10.423 19.0721 10.5 18.8125 10.5C18.4644 10.5 18.1306 10.3617 17.8844 10.1156C17.6383 9.86944 17.5 9.5356 17.5 9.1875Z"
                                          fill="#666E7D"
                                        />
                                      </svg>
                                    </span>
                                  </Col>
                                  <Col className="ms-2">Upload Image</Col>
                                </Row>
                              </div>
                              <div
                                className="convoUploadPopup_item  "
                                onClick={() => handleUploadClick("video")}
                              >
                                <Row>
                                  <Col className="col-1 ms-2">
                                    <span className="mr-2">
                                      {/* <FontAwesomeIcon icon={faVideo} className='mr-2 ml-2' /> */}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#666E7D"
                                        className="bi bi-camera-video"
                                        viewBox="0 0 16 16"
                                      >
                                        {" "}
                                        <path
                                          fill-rule="evenodd"
                                          d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                                        />{" "}
                                      </svg>
                                    </span>
                                  </Col>
                                  <Col>Upload Video</Col>
                                </Row>
                              </div>
                              <div
                                className="convoUploadPopup_item  hover-purple"
                                onClick={() => handleUploadClick("doc")}
                              >
                                <Row>
                                  <Col className="col-1">
                                    {/* <FontAwesomeIcon icon={faFile} className='mr-2' /> */}
                                    <span className="mr-2">
                                      <svg
                                        width="28"
                                        height="20"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M23.3691 9.00594L17.2441 2.88094C17.1627 2.79971 17.0662 2.7353 16.96 2.69138C16.8538 2.64747 16.7399 2.62491 16.625 2.625H6.125C5.66087 2.625 5.21575 2.80937 4.88756 3.13756C4.55937 3.46575 4.375 3.91087 4.375 4.375V23.625C4.375 24.0891 4.55937 24.5342 4.88756 24.8624C5.21575 25.1906 5.66087 25.375 6.125 25.375H21.875C22.3391 25.375 22.7842 25.1906 23.1124 24.8624C23.4406 24.5342 23.625 24.0891 23.625 23.625V9.625C23.6251 9.51006 23.6025 9.39623 23.5586 9.29001C23.5147 9.18379 23.4503 9.08726 23.3691 9.00594ZM17.5 5.61203L20.638 8.75H17.5V5.61203ZM21.875 23.625H6.125V4.375H15.75V9.625C15.75 9.85706 15.8422 10.0796 16.0063 10.2437C16.1704 10.4078 16.3929 10.5 16.625 10.5H21.875V23.625Z"
                                          fill="#666E7D"
                                        />
                                      </svg>
                                    </span>
                                  </Col>
                                  <Col className="ms-2">Upload Document</Col>
                                </Row>
                              </div>
                              <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                accept="image/*"
                                onChange={handleFileChange}
                              />
                            </PopoverBody>
                          </Popover>
                          <div>
                            {component === "conversationInbox" && (
                              <>
                                <FontAwesomeIcon
                                  icon={faWallet}
                                  className="ml-3 mb-2 mt-1"
                                  color="#90939F"
                                  fontSize={14}
                                  onClick={() => addPayment()}
                                ></FontAwesomeIcon>

                                {showContent && (
                                  <RightSidebar
                                    handleCancel={handleCancel}
                                    title="Send Payment"
                                    width="425px"
                                  >
                                    <Formik
                                      initialValues={{
                                        paymentobj: null,
                                        time: time[0],
                                        duration: 5,
                                        file: null,
                                        body: "",
                                        product: [],
                                        footer: "",
                                        taxAmt: null,
                                        taxDesc: "",
                                        shipAmt: null,
                                        shipDec: "",
                                        discountAmt: null,
                                      }}
                                      validationSchema={validation}
                                      onSubmit={handleSubmit}
                                    >
                                      {({ values, setFieldValue }) => (
                                        <Form className="ml-3 mr-3">
                                          <FormGroup>
                                            <Label>
                                              Payment Provider
                                              <span className="required"></span>
                                            </Label>
                                            <Field name="paymentobj">
                                              {({ field, form }: any) => (
                                                <Select
                                                  options={
                                                    payments &&
                                                    payments.map((e: any) => ({
                                                      value: e.uid,
                                                      label:
                                                        e.method === "upi"
                                                          ? "UPI VPA ID"
                                                          : e.method === "razorpay"
                                                            ? "Razorpay"
                                                            : "PayU",
                                                    }))
                                                  }
                                                  value={values.paymentobj}
                                                  onChange={(event: any) => {
                                                    setFieldValue(
                                                      "paymentobj",
                                                      event
                                                    );
                                                  }}
                                                  styles={selectStyles}
                                                />
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name="paymentobj"
                                              component="div"
                                              className="text-danger"
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label>
                                              Order Products
                                              <span className="required"></span>
                                            </Label>
                                            {/* <Field name="product" as="select" multiple>
                                                {({ field, form }: any) => (
                                                    <Select
                                                    {...field}
                                                    isMulti
                                                    name="product"
                                                    options={catalogue && catalogue.map((e: any) => ({
                                                        value: e.uid,
                                                        label: e.name,
                                                    }))}
                                                    onChange={(selectedOptions: SelectBtnOption[] | null) => {
                                                        form.setFieldValue("product", selectedOptions);
                                                    }}
                                                    styles={selectStyles}
                                                    />
                                                )}
                                                </Field> */}

                                            <Field
                                              name="product"
                                              as="select"
                                              multiple
                                            >
                                              {({ field, form }: any) => (
                                                <Select
                                                  {...field}
                                                  isMulti
                                                  name="product"
                                                  options={
                                                    catalogue &&
                                                    catalogue.map((e: any) => ({
                                                      value: e.uid,
                                                      label: e.name,
                                                      quantity: 0,
                                                      amount: e.amount,
                                                      saleAmount: e.saleAmount,
                                                    }))
                                                  }
                                                  onChange={(
                                                    selectedOptions:
                                                      | SelectBtnOption[]
                                                      | null
                                                  ) => {
                                                    form.setFieldValue(
                                                      "product",
                                                      selectedOptions
                                                    );
                                                  }}
                                                  styles={selectStyles}
                                                  onMenuScrollToBottom={
                                                    handleScrollToBottom
                                                  }
                                                />
                                              )}
                                            </Field>
                                            {values.product.length > 0 && (
                                              <>
                                                <Label className="mt-3">
                                                  Quantity
                                                </Label>
                                                <div className="border rounded p-1">
                                                  {values.product.map(
                                                    (
                                                      selectedProduct:
                                                        | {
                                                          value: string;
                                                          quantity: number;
                                                        }
                                                        | any,
                                                      index: number
                                                    ) => {
                                                      const product = catalogue.find(
                                                        (item: any) =>
                                                          item.uid ===
                                                          selectedProduct.value
                                                      );
                                                      if (
                                                        selectedProduct.quantity === 0
                                                      ) {
                                                        selectedProduct.quantity += 1;
                                                      }
                                                      return (
                                                        <div
                                                          key={selectedProduct.value}
                                                          className="d-flex mt-2 mb-1"
                                                        >
                                                          <div className="text-dark col-8" title={product.name}>
                                                            {product.name.length > 27 ? product.name?.slice(0, 30) + '...' : product.name}
                                                          </div>
                                                          <button
                                                            type="button"
                                                            className="border-0"
                                                            onClick={() => {
                                                              let updatedProducts: any =
                                                                [];
                                                              updatedProducts = [
                                                                ...values.product,
                                                              ];
                                                              if (
                                                                updatedProducts[index]
                                                                  .quantity >= 1
                                                              ) {
                                                                updatedProducts[
                                                                  index
                                                                ].quantity -= 1;
                                                              }
                                                              setFieldValue(
                                                                "product",
                                                                updatedProducts
                                                              );
                                                            }}
                                                          >
                                                            -
                                                          </button>
                                                          <div className="pl-2 pr-2 border">
                                                            {selectedProduct.quantity}
                                                          </div>
                                                          <button
                                                            type="button"
                                                            className="border-0"
                                                            onClick={() => {
                                                              let updatedProducts: any =
                                                                [];
                                                              updatedProducts = [
                                                                ...values.product,
                                                              ];
                                                              updatedProducts[
                                                                index
                                                              ].quantity += 1;
                                                              setFieldValue(
                                                                "product",
                                                                updatedProducts
                                                              );
                                                            }}
                                                          >
                                                            +
                                                          </button>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </>
                                            )}
                                            <ErrorMessage
                                              name="product"
                                              component="div"
                                              className="text-danger"
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label>Expiring Duration</Label>
                                            <div className="d-flex exduration col-12 p-1">
                                              <Field name="time">
                                                {({ field, form }: any) => (
                                                  <Select
                                                    options={time}
                                                    value={values.time}
                                                    className="col-6 mr-1"
                                                    onChange={(event) => {
                                                      setFieldValue("time", event);
                                                    }}
                                                  />
                                                )}
                                              </Field>
                                              <Field
                                                type="text"
                                                name="duration"
                                                id="duration"
                                                placeholder="Enter duration"
                                                className="form-control col-5"
                                                onChange={(
                                                  e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                  let value = e.target.value.replace(
                                                    /[^0-9.]/g,
                                                    ""
                                                  );
                                                  value = value.replace(
                                                    /^(\d*\.\d*)\..*$/,
                                                    "$1"
                                                  );
                                                  setFieldValue("duration", value);
                                                }}
                                              />
                                            </div>
                                            <ErrorMessage
                                              name="duration"
                                              component="div"
                                              className="text-danger"
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label className="m-0">
                                              Header (Optional){" "}
                                              <span className="small">
                                                Max file size: 2 MB
                                              </span>
                                            </Label>
                                            <p className="m-0 small">
                                              File type allowed : image/jpeg,image/png
                                            </p>
                                            {!media ? (
                                              <Field name="file">
                                                {({ field }: any) => (
                                                  <div>
                                                    <Label className="uploadFiles col-3">
                                                      <input
                                                        type="file"
                                                        className="hideFileInput"
                                                        accept=".jpeg,.jpg,.png"
                                                        onChange={(event) => {
                                                          const file =
                                                            event.target.files;
                                                          field.onChange(event);
                                                          onFileChange(event);
                                                        }}
                                                      />{" "}
                                                      Upload File
                                                    </Label>
                                                  </div>
                                                )}
                                              </Field>
                                            ) : (
                                              <div>
                                                <img
                                                  src={media.url}
                                                  alt="img"
                                                  width="80"
                                                  height="80"
                                                ></img>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    removeFileHandler(media);
                                                    setFieldValue("media", null);
                                                  }}
                                                  className="bg-white border-0 ml-3"
                                                >
                                                  <BsFillTrashFill className="bg-white text-secondary h6" />
                                                </button>
                                              </div>
                                            )}
                                          </FormGroup>
                                          <FormGroup>
                                            <Label>
                                              Body Message
                                              <span className="required"></span>
                                            </Label>
                                            <Field name="body">
                                              {({
                                                field,
                                              }: FieldProps & {
                                                form: FormikProps<any>;
                                              }) => (
                                                <>
                                                  <div>
                                                    <textarea
                                                      ref={textareaRef}
                                                      {...field}
                                                      placeholder="Enter message"
                                                      onChange={(event) => {
                                                        let text = event.target.value;
                                                        setFieldValue("body", text);
                                                        setCount(text.length);
                                                      }}
                                                      className="form-control"
                                                    />
                                                  </div>
                                                  <div className="float-right text-muted small">
                                                    {charCount} / 1024
                                                  </div>
                                                </>
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name="body"
                                              component="div"
                                              className="text-danger"
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <Label> Footer (optional)</Label>
                                            <Field
                                              type="text"
                                              name="footer"
                                              id="footer"
                                              placeholder="Enter footer text"
                                              className="form-control"
                                              onChange={(event: any) => {
                                                let text = event.target.value;
                                                setFieldValue("footer", text);
                                                setFooterCount(text.length);
                                              }}
                                            />
                                            <div className="float-right text-muted small">
                                              {footerCount} / 60
                                            </div>
                                            <ErrorMessage
                                              name="footer"
                                              component="div"
                                              className="text-danger"
                                            />
                                          </FormGroup>
                                          <div className="advancecard mb-3">
                                            <ValidationSettings
                                              initiallyOpen={isOpen}
                                              onCancel={() => {
                                                setValidateOpen(false);
                                              }}
                                              caption="Advanced (optional)"
                                              component="salesinbox"
                                            >
                                              <FormGroup className="mb-3">
                                                <div>
                                                  <div className={styles.dropdown}>
                                                    <button
                                                      type="button"
                                                      className={styles.dropbtn}
                                                      onClick={() => {
                                                        setIsTaxVisible(
                                                          !isTaxVisible
                                                        );
                                                      }}
                                                    >
                                                      Tax{" "}
                                                      <span className="float-right">
                                                        {isTaxVisible ? (
                                                          <FontAwesomeIcon
                                                            icon={faChevronDown}
                                                          />
                                                        ) : (
                                                          <FontAwesomeIcon
                                                            icon={faChevronUp}
                                                          />
                                                        )}
                                                      </span>
                                                    </button>
                                                    {isTaxVisible && (
                                                      <div
                                                        className={
                                                          styles.dropdownContent
                                                        }
                                                      >
                                                        <FormGroup>
                                                          <p className="labelSize">
                                                            Tax Amount
                                                          </p>
                                                          <Field
                                                            type="text"
                                                            id="taxAmt"
                                                            className="form-control"
                                                            name="taxAmt"
                                                            onChange={(
                                                              e: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                              let value =
                                                                e.target.value.replace(
                                                                  /[^0-9.]/g,
                                                                  ""
                                                                );
                                                              value = value.replace(
                                                                /^(\d*\.\d*)\..*$/,
                                                                "$1"
                                                              );
                                                              setFieldValue(
                                                                "taxAmt",
                                                                value
                                                              );
                                                            }}
                                                            placeholder="Enter tax amount"
                                                          />
                                                        </FormGroup>
                                                        <FormGroup>
                                                          <p className="labelSize">
                                                            Tax Description
                                                          </p>
                                                          <Field
                                                            type="text"
                                                            id="taxDesc"
                                                            className="form-control"
                                                            name="taxDesc"
                                                            onChange={(
                                                              event: any
                                                            ) => {
                                                              let text =
                                                                event.target.value;
                                                              setFieldValue(
                                                                "taxDesc",
                                                                text
                                                              );
                                                              setTax(text.length);
                                                            }}
                                                            disabled={
                                                              !values.taxAmt ||
                                                              values.taxAmt <= 0
                                                            }
                                                            placeholder="Enter tax description"
                                                          />
                                                          <div className="float-right text-muted small">
                                                            {taxCount} / 60
                                                          </div>
                                                          <ErrorMessage
                                                            name="taxDesc"
                                                            component="div"
                                                            className="text-danger"
                                                          />
                                                        </FormGroup>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </FormGroup>
                                              <FormGroup className="mb-3">
                                                <div>
                                                  <div className={styles.dropdown}>
                                                    <button
                                                      type="button"
                                                      className={styles.dropbtn}
                                                      onClick={() =>
                                                        toggleTaxForm("shipping")
                                                      }
                                                    >
                                                      Shipping{" "}
                                                      <span className="float-right">
                                                        {isShippingVisible ? (
                                                          <FontAwesomeIcon
                                                            icon={faChevronDown}
                                                          />
                                                        ) : (
                                                          <FontAwesomeIcon
                                                            icon={faChevronUp}
                                                          />
                                                        )}
                                                      </span>
                                                    </button>
                                                    {isShippingVisible && (
                                                      <div
                                                        className={
                                                          styles.dropdownContent
                                                        }
                                                      >
                                                        <FormGroup>
                                                          <p className="labelSize">
                                                            Shipping Amount
                                                          </p>
                                                          <Field
                                                            type="text"
                                                            id="shipAmt"
                                                            className="form-control"
                                                            name="shipAmt"
                                                            onChange={(
                                                              e: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                              let value =
                                                                e.target.value.replace(
                                                                  /[^0-9.]/g,
                                                                  ""
                                                                );
                                                              value = value.replace(
                                                                /^(\d*\.\d*)\..*$/,
                                                                "$1"
                                                              );
                                                              setFieldValue(
                                                                "shipAmt",
                                                                value
                                                              );
                                                            }}
                                                            placeholder="Enter shipping amount"
                                                          />
                                                        </FormGroup>
                                                        <FormGroup>
                                                          <p className="labelSize">
                                                            Shipping Description
                                                          </p>
                                                          <Field
                                                            type="text"
                                                            id="shipDec"
                                                            className="form-control"
                                                            name="shipDec"
                                                            onChange={(
                                                              event: any
                                                            ) => {
                                                              let text =
                                                                event.target.value;
                                                              setFieldValue(
                                                                "shipDec",
                                                                text
                                                              );
                                                              setShip(text.length);
                                                            }}
                                                            disabled={
                                                              !values.shipAmt ||
                                                              values.shipAmt <= 0
                                                            }
                                                            placeholder="Enter shipping description"
                                                          />
                                                          <div className="float-right text-muted small">
                                                            {shipCount} / 60
                                                          </div>
                                                          <ErrorMessage
                                                            name="shipDec"
                                                            component="div"
                                                            className="text-danger"
                                                          />
                                                        </FormGroup>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </FormGroup>
                                              <FormGroup className="mb-3">
                                                <div>
                                                  <div className={styles.dropdown}>
                                                    <button
                                                      type="button"
                                                      className={styles.dropbtn}
                                                      onClick={() =>
                                                        toggleTaxForm("discount")
                                                      }
                                                    >
                                                      Discount{" "}
                                                      <span className="float-right">
                                                        {isDiscountVisible ? (
                                                          <FontAwesomeIcon
                                                            icon={faChevronDown}
                                                          />
                                                        ) : (
                                                          <FontAwesomeIcon
                                                            icon={faChevronUp}
                                                          />
                                                        )}
                                                      </span>
                                                    </button>
                                                    {isDiscountVisible && (
                                                      <div
                                                        className={
                                                          styles.dropdownContent
                                                        }
                                                      >
                                                        <FormGroup>
                                                          <p className="labelSize">
                                                            Discount Amount
                                                          </p>
                                                          <Field
                                                            type="text"
                                                            id="discountAmt"
                                                            className="form-control"
                                                            name="discountAmt"
                                                            onChange={(
                                                              e: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                              let value =
                                                                e.target.value.replace(
                                                                  /[^0-9.]/g,
                                                                  ""
                                                                );
                                                              value = value.replace(
                                                                /^(\d*\.\d*)\..*$/,
                                                                "$1"
                                                              );
                                                              setFieldValue(
                                                                "discountAmt",
                                                                value
                                                              );
                                                            }}
                                                            placeholder="Enter discount amount"
                                                          />
                                                          <ErrorMessage name="discountAmt" className="text-danger" component="div" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                          <p className="labelSize">
                                                            Discount Description
                                                          </p>
                                                          <Field
                                                            type="text"
                                                            id="discountDesc"
                                                            className="form-control"
                                                            name="discountDesc"
                                                            onChange={(
                                                              event: any
                                                            ) => {
                                                              let text =
                                                                event.target.value;
                                                              setFieldValue(
                                                                "discountDesc",
                                                                text
                                                              );
                                                              setDiscount(
                                                                text.length
                                                              );
                                                            }}
                                                            disabled={
                                                              !values.discountAmt ||
                                                              values.discountAmt <= 0
                                                            }
                                                            placeholder="Enter discount description"
                                                          />
                                                          <div className="float-right text-muted small">
                                                            {discountCount} / 60
                                                          </div>
                                                          <ErrorMessage
                                                            name="discountDesc"
                                                            component="div"
                                                            className="text-danger"
                                                          />
                                                        </FormGroup>
                                                        <FormGroup>
                                                          <p className="labelSize">
                                                            Discount Program Name
                                                          </p>
                                                          <Field
                                                            type="text"
                                                            id="discountName"
                                                            className="form-control"
                                                            name="discountName"
                                                            onChange={(
                                                              event: any
                                                            ) => {
                                                              let text =
                                                                event.target.value;
                                                              setFieldValue(
                                                                "discountName",
                                                                text
                                                              );
                                                              setName(text.length);
                                                            }}
                                                            disabled={
                                                              !values.discountAmt ||
                                                              values.discountAmt <= 0
                                                            }
                                                            placeholder="Enter discount name"
                                                          />
                                                          <div className="float-right text-muted small">
                                                            {nameCount} / 60
                                                          </div>
                                                          <ErrorMessage
                                                            name="discountName"
                                                            component="div"
                                                            className="text-danger"
                                                          />
                                                        </FormGroup>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </FormGroup>
                                            </ValidationSettings>
                                          </div>
                                          <div className="editor-footer">
                                            <Button
                                              type="button"
                                              className="cancelButton mr-3"
                                              onClick={() => handleCancel()}
                                            >
                                              Cancel
                                            </Button>
                                            <Button
                                              type="submit"
                                              className="sendButton"
                                            >
                                              Send
                                            </Button>
                                          </div>
                                        </Form>
                                      )}
                                    </Formik>
                                  </RightSidebar>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : selectedConversation?.contact?.isDeleted ? (
                  <div className="timeoutConvoMessage deletedContact">
                    <div>
                      <FontAwesomeIcon icon={faBan} className="mr-2" />
                      Contact have been deleted.
                    </div>
                  </div>
                ) : (
                  !notes && (
                    <div className="timeoutConvoMessage">
                      <div className="caption">
                        {/* <span className="title">
                                        You've reached your 24-hour limit
                                    </span> */}
                        <span className="subtitle">
                          WhatsApp allows you to send only pre-approved template
                          messages.
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
              {!selectedConversation?.contact?.isDeleted && (
                <div className="noteTemplate p-1">
                  <div className="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 20 20"
                      fill="none"
                      onClick={() => selectItem("notes")}
                    >
                      <path
                        d="M16.9257 10.5391L17.3566 8.92914C17.8599 7.04997 18.1124 6.11081 17.9232 5.29747C17.7733 4.65539 17.4363 4.07219 16.9549 3.62164C16.3457 3.05081 15.4057 2.79914 13.5266 2.29581C11.6474 1.79164 10.7074 1.53997 9.89491 1.72914C9.25282 1.87907 8.66962 2.21605 8.21908 2.69747C7.72991 3.21914 7.47491 3.98331 7.09575 5.37164L6.89241 6.12581L6.46158 7.73581C5.95741 9.61497 5.70575 10.5541 5.89491 11.3675C6.04484 12.0096 6.38183 12.5928 6.86325 13.0433C7.47241 13.6141 8.41241 13.8658 10.2916 14.37C11.9849 14.8233 12.9157 15.0725 13.6782 14.9783C13.7616 14.9683 13.8432 14.9533 13.9232 14.935C14.5651 14.7855 15.1483 14.4491 15.5991 13.9683C16.1699 13.3583 16.4216 12.4183 16.9257 10.5391Z"
                        stroke={selectedTool === "notes" ? "#007EC3" : "#666E7D"}
                        stroke-width="1.25"
                      />
                      <path
                        d="M13.6769 14.9783C13.5027 15.5119 13.1966 15.9929 12.7869 16.3766C12.1778 16.9475 11.2378 17.1991 9.35861 17.7025C7.47944 18.2058 6.53944 18.4583 5.72694 18.2683C5.08495 18.1186 4.50176 17.7819 4.05111 17.3008C3.48028 16.6916 3.22778 15.7516 2.72444 13.8725L2.29361 12.2625C1.78944 10.3833 1.53778 9.44329 1.72694 8.63079C1.87687 7.9887 2.21386 7.4055 2.69528 6.95495C3.30444 6.38412 4.24444 6.13246 6.12361 5.62829C6.47861 5.53329 6.80111 5.44662 7.09444 5.37079"
                        stroke={selectedTool === "notes" ? "#007EC3" : "#666E7D"}
                        stroke-width="1.25"
                      />
                      <path
                        d="M9.81156 8.33325L13.8366 9.41158M9.16406 10.7483L11.5791 11.3949"
                        stroke={selectedTool === "notes" ? "#007EC3" : "#666E7D"}
                        stroke-width="1.25"
                        stroke-linecap="round"
                      />
                    </svg>
                    <div
                      className={`notesFontSize ${selectedTool === "notes" ? "noteTemplateFont" : ""
                        }`}
                      onClick={() => selectItem("notes")}
                    >
                      Notes
                    </div>
                  </div>
                  <div>
                    {component === "conversationInbox" && (
                      <>
                        {credits.totalCredits <= 1 ? (
                          <TooltipReference
                            placement="bottom"
                            content={
                              credits.totalCredits <= 1 ? "You don't have enough credits" : ""
                            }
                            tooltipId="source"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23"
                              height="23"
                              viewBox="0 0 20 20"
                              fill="none"
                              style={{ cursor: 'default' }}
                            >
                              <path
                                d="M5.625 2.5C4.7962 2.5 4.00134 2.82924 3.41529 3.41529C2.82924 4.00134 2.5 4.7962 2.5 5.625V13.125C2.5 13.9538 2.82924 14.7487 3.41529 15.3347C4.00134 15.9208 4.7962 16.25 5.625 16.25H6.25V15H5.625C5.12772 15 4.65081 14.8025 4.29917 14.4508C3.94754 14.0992 3.75 13.6223 3.75 13.125V5.625C3.75 5.12772 3.94754 4.65081 4.29917 4.29917C4.65081 3.94754 5.12772 3.75 5.625 3.75H13.125C13.6223 3.75 14.0992 3.94754 14.4508 4.29917C14.8025 4.65081 15 5.12772 15 5.625V8.75H16.25V5.625C16.25 4.7962 15.9208 4.00134 15.3347 3.41529C14.7487 2.82924 13.9538 2.5 13.125 2.5H5.625ZM9.375 10C8.87772 10 8.40081 10.1975 8.04917 10.5492C7.69754 10.9008 7.5 11.3777 7.5 11.875V16.875C7.5 17.3723 7.69754 17.8492 8.04917 18.2008C8.40081 18.5525 8.87772 18.75 9.375 18.75H16.875C17.3723 18.75 17.8492 18.5525 18.2008 18.2008C18.5525 17.8492 18.75 17.3723 18.75 16.875V11.875C18.75 11.3777 18.5525 10.9008 18.2008 10.5492C17.8492 10.1975 17.3723 10 16.875 10H9.375ZM8.75 11.875C8.75 11.7092 8.81585 11.5503 8.93306 11.4331C9.05027 11.3158 9.20924 11.25 9.375 11.25H16.875C17.0408 11.25 17.1997 11.3158 17.3169 11.4331C17.4342 11.5503 17.5 11.7092 17.5 11.875V12.48L13.125 14.91L8.75 12.48V11.875ZM8.75 13.9087L12.8212 16.1713C12.9142 16.2229 13.0187 16.25 13.125 16.25C13.2313 16.25 13.3358 16.2229 13.4288 16.1713L17.5 13.9087V16.875C17.5 17.0408 17.4342 17.1997 17.3169 17.3169C17.1997 17.4342 17.0408 17.5 16.875 17.5H9.375C9.20924 17.5 9.05027 17.4342 8.93306 17.3169C8.81585 17.1997 8.75 17.0408 8.75 16.875V13.9087ZM6.25 6.25C6.08424 6.25 5.92527 6.31585 5.80806 6.43306C5.69085 6.55027 5.625 6.70924 5.625 6.875C5.625 7.04076 5.69085 7.19973 5.80806 7.31694C5.92527 7.43415 6.08424 7.5 6.25 7.5H12.5C12.6658 7.5 12.8247 7.43415 12.9419 7.31694C13.0592 7.19973 13.125 7.04076 13.125 6.875C13.125 6.70924 13.0592 6.55027 12.9419 6.43306C12.8247 6.31585 12.6658 6.25 12.5 6.25H6.25Z"
                                fill={
                                  credits.totalCredits <= 1
                                    ? "#cbd5df"
                                    : selectedTool === "templates"
                                      ? "#007EC3"
                                      : "#666E7D"
                                }
                              />
                            </svg>
                            <div
                              className={`notesFontSize ${credits.totalCredits <= 1
                                ? "small"
                                : selectedTool === "templates"
                                  ? "noteTemplateFont"
                                  : ""
                                }`}
                            >
                              Templates
                            </div>
                          </TooltipReference>
                        ) : (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23"
                              height="23"
                              viewBox="0 0 20 20"
                              fill="none"
                              onClick={() => {
                                if (credits.totalCredits > 1) {
                                  selectItem("templates");
                                }
                              }}
                            >
                              <path
                                d="M5.625 2.5C4.7962 2.5 4.00134 2.82924 3.41529 3.41529C2.82924 4.00134 2.5 4.7962 2.5 5.625V13.125C2.5 13.9538 2.82924 14.7487 3.41529 15.3347C4.00134 15.9208 4.7962 16.25 5.625 16.25H6.25V15H5.625C5.12772 15 4.65081 14.8025 4.29917 14.4508C3.94754 14.0992 3.75 13.6223 3.75 13.125V5.625C3.75 5.12772 3.94754 4.65081 4.29917 4.29917C4.65081 3.94754 5.12772 3.75 5.625 3.75H13.125C13.6223 3.75 14.0992 3.94754 14.4508 4.29917C14.8025 4.65081 15 5.12772 15 5.625V8.75H16.25V5.625C16.25 4.7962 15.9208 4.00134 15.3347 3.41529C14.7487 2.82924 13.9538 2.5 13.125 2.5H5.625ZM9.375 10C8.87772 10 8.40081 10.1975 8.04917 10.5492C7.69754 10.9008 7.5 11.3777 7.5 11.875V16.875C7.5 17.3723 7.69754 17.8492 8.04917 18.2008C8.40081 18.5525 8.87772 18.75 9.375 18.75H16.875C17.3723 18.75 17.8492 18.5525 18.2008 18.2008C18.5525 17.8492 18.75 17.3723 18.75 16.875V11.875C18.75 11.3777 18.5525 10.9008 18.2008 10.5492C17.8492 10.1975 17.3723 10 16.875 10H9.375ZM8.75 11.875C8.75 11.7092 8.81585 11.5503 8.93306 11.4331C9.05027 11.3158 9.20924 11.25 9.375 11.25H16.875C17.0408 11.25 17.1997 11.3158 17.3169 11.4331C17.4342 11.5503 17.5 11.7092 17.5 11.875V12.48L13.125 14.91L8.75 12.48V11.875ZM8.75 13.9087L12.8212 16.1713C12.9142 16.2229 13.0187 16.25 13.125 16.25C13.2313 16.25 13.3358 16.2229 13.4288 16.1713L17.5 13.9087V16.875C17.5 17.0408 17.4342 17.1997 17.3169 17.3169C17.1997 17.4342 17.0408 17.5 16.875 17.5H9.375C9.20924 17.5 9.05027 17.4342 8.93306 17.3169C8.81585 17.1997 8.75 17.0408 8.75 16.875V13.9087ZM6.25 6.25C6.08424 6.25 5.92527 6.31585 5.80806 6.43306C5.69085 6.55027 5.625 6.70924 5.625 6.875C5.625 7.04076 5.69085 7.19973 5.80806 7.31694C5.92527 7.43415 6.08424 7.5 6.25 7.5H12.5C12.6658 7.5 12.8247 7.43415 12.9419 7.31694C13.0592 7.19973 13.125 7.04076 13.125 6.875C13.125 6.70924 13.0592 6.55027 12.9419 6.43306C12.8247 6.31585 12.6658 6.25 12.5 6.25H6.25Z"
                                fill={
                                  selectedTool === "templates" ? "#007EC3" : "#666E7D"
                                }
                              />
                            </svg>
                            <div
                              className={`notesFontSize ${selectedTool === "templates" ? "noteTemplateFont" : ""
                                }`}
                              onClick={() => {
                                if (credits.totalCredits > 1) {
                                  selectItem("templates");
                                }
                              }}
                            >
                              Templates
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {openModal && (
                      <Modall
                        isOpen={openModal}
                        onClose={handleClose}
                        title={modalHead}
                        size={modalSize}
                      >
                        <TemplateList
                          component="conversation"
                          handleClose={handleClose}
                          modalTitle={modalTitle}
                          setModalSize={setModalSize}
                          selectedConversation={selectedConversation}
                        />
                      </Modall>
                    )}
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 32 32"
                    fill="none"
                    onClick={() => {
                      if (extractTextFromHTML(convInput).trim() !== "") {
                        if (extractTextFromHTML(convInput)) {
                          if (notes) {
                            setMediaType("notes");
                          } else if (messageType === "quick" && quickMedia) {
                            setMediaType("quickMedia");
                            setConvInput("");
                            // setMediaRes(quickres?.media);
                          } else {
                            setMediaType("text");
                            setConvInput("");
                          }
                        }
                        setQuickMedia(false)
                        setClicked(true);
                        setTimeout(() => {
                          setClicked(false);
                        }, 500);
                      }
                    }}
                    className="mb-2 mr-1 ml-2"
                    style={{
                      cursor:
                        extractTextFromHTML(convInput).trim() !== ""
                          ? "pointer"
                          : "not-allowed",
                    }}
                  >
                    <path
                      d="M16.0013 0.166748C18.0806 0.166748 20.1395 0.576289 22.0605 1.37199C23.9814 2.16769 25.7269 3.33396 27.1972 4.80422C28.6674 6.27448 29.8337 8.01994 30.6294 9.94093C31.4251 11.8619 31.8346 13.9208 31.8346 16.0001C31.8346 20.1993 30.1665 24.2266 27.1972 27.1959C24.2278 30.1653 20.2006 31.8334 16.0013 31.8334C13.922 31.8334 11.8631 31.4239 9.94215 30.6282C8.02116 29.8325 6.27571 28.6662 4.80544 27.1959C1.83612 24.2266 0.167969 20.1993 0.167969 16.0001C0.167969 11.8008 1.83612 7.77355 4.80544 4.80422C7.77477 1.8349 11.802 0.166748 16.0013 0.166748ZM10.0165 9.35711C9.85155 9.28634 9.66797 9.40735 9.66797 9.58687V14.277C9.66797 14.4023 9.76076 14.5083 9.885 14.5248L19.1104 15.7523C19.3998 15.7908 19.3998 16.2094 19.1104 16.2479L9.885 17.4754C9.76076 17.4919 9.66797 17.5979 9.66797 17.7232V22.4133C9.66797 22.5928 9.85155 22.7138 10.0165 22.643L24.9658 16.2298C25.1677 16.1432 25.1677 15.857 24.9658 15.7703L10.0165 9.35711Z"
                      fill={
                        extractTextFromHTML(convInput).trim() !== ""
                          ? "#458d80"
                          : "#ccc"
                      }
                    />
                  </svg>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </ OverlayTrigger>
  );
};
export default ConversationInput;
