import ResolveConversation from './actions/resolveConversation';
import UnassignConversation from './actions/unassignConversation';
import UpdateContactFields from './actions/updateContactFields';
import UpdateConversationStatus from './actions/updateConversationStatus';
import UpdateConversationTag from './actions/updateConversationTag';
import RemoveConversationTag from './actions/removeConversationTag';
import ApiCalls from './apiCalls';
import AskButtonOption from './askButtonOption';
import AskAddress from './askType/askAddress';
import AskDate from './askType/askDate';
import AskEmail from './askType/askEmail';
import AskFile from './askType/askFile';
import AskKeyword from './askType/askKeyword';
import AskLocation from './askType/askLocation';
import AskNumber from './askType/askNumber';
import AskText from './askType/askText';
import AskUrl from './askType/askUrl';
import AssignConversation from './assignConversation';
import SendOrderDetails from './catalogue/sendOrderDetails';
import Condition from './condition';
import Delay from './delay';
import Flow from './flow';
import RazorPay from './integration/razorPay';
import ZohoCRM from './integration/zohoCRM';
import JumpTo from './jumpTo';
import OpenAi from './openAi';
import SendButtonOption from './sendButtonOption';
import SendList from './sendList';
import SendMedia from './sendMedia';
import SendTemplate from './sendTemplate';
import SendText from './sendText';
import SetVariable from './setVariable';
import StartNode from './startNode';
import SwitchCondition from './switchCondition';
import AskLocationButton from './askType/askLocationButton';
import AskCtaUrl from './askType/askCtaUrl';
import SendFlow from './sendFlow'
import TriggerSequence from './actions/triggerSequence';
import SendLocation from './sendLocation';
import AddNotes from './actions/addNotes';
import SendProduct from './catalogue/sendProducts';
import SetMember from './actions/setMember';
import Shopify from './integration/shopify';
import Ticketing from './ticketing/ticketing';
import GoogleSheets from './integration/googleSheets';
import AskCurrentLocation from './askType/askCurrentLocation';
import AppointmentFlow from './appointment/appointmentFlow';
import AppointmentConfirmation from './appointment/appointmentConfirmation';
import CreateTicketNode from './ticketing/createTicket';
import SangamCRM from './integration/sangamCRM';
import WooCommerce from './integration/wooCommerce';
import openAI from './integration/openAI';
import ZohoBigin from './integration/zohoBigin';
import ReplaceText from './replaceText';
import Zoom from './integration/zoom';
import Hubspot from './integration/hubspot';



export type INodeType =
    | 'startNode'
    | 'send_text'
    | 'ask_type/ask_text'
    | 'ask_address'
    | 'send_Flow'
    | 'ask_type/ask_number'
    | 'ask_type/ask_email'
    | 'ask_type/ask_date'
    | 'ask_type/ask_url'
    | 'ask_type/ask_file'
    | 'ask_type/ask_location'
    | 'ask_CtaUrl'
    | 'askButtonOption'
    | 'ask_location'
    | 'send_reply_buttons'
    | 'send_list'
    | 'delay'
    | 'send_media'
    | 'ask_keyword_options'
    | 'switchCondition'
    | 'set_variable'
    | 'actions/assign_conversation'
    | 'actions/unAssign_converation'
    | 'actions/resolve_conversation'
    | "actions/update_contactTags"
    | "actions/remove_contactTags"
    | "actions/update_contact"
    | "actions/update_conversationStatus"
    | "actions/trigger_Sequence"
    | "actions/set_member"
    | "actions/notes"
    | "actions/set_member"
    | 'condition'
    | 'jump_to'
    | 'api_call'
    | 'send_wa_template'
    | 'open_ai'
    | 'integration/ZohoCRM'
    | 'integration/Shopify'
    | 'flow'
    | 'integration/RazorPay'
    | 'send_OrderDetails'
    | 'send_location'
    | 'send_product'
    | 'ticketIt'
    | 'integration/GoogleSheets'
    | 'ask_currentLocation'
    | 'appointment_Flow'
    | 'appointment_confirmation'
    | 'create_Ticket'
    | 'integration/SangamCRM' 
    | 'integration/Woocommerce'
    | 'integration/OpenAI'
    | 'integration/ZohoBigin'
    | 'replaceText'
    | 'integration/Zoom'
    | 'integration/Hubspot'
    | 'send_wa_templateV2'



const nodeTypes: { [key in INodeType]: React.ComponentType<any> } = {
    startNode: StartNode,
    'send_text': SendText,
    'ask_type/ask_text': AskText,
    'ask_address': AskAddress,
    'ask_type/ask_number': AskNumber,
    'ask_type/ask_email': AskEmail,
    'ask_type/ask_date': AskDate,
    'ask_type/ask_url': AskUrl,
    'ask_type/ask_file': AskFile,
    'ask_type/ask_location': AskLocation,
    'ask_CtaUrl': AskCtaUrl,
    'ask_location':AskLocationButton,
    askButtonOption: AskButtonOption,
    'send_reply_buttons': SendButtonOption,
    'send_list': SendList,
    'send_Flow': SendFlow,
    'delay': Delay,
    'send_media': SendMedia,
    'ask_keyword_options': AskKeyword,
    'switchCondition': SwitchCondition,
    'set_variable': SetVariable,
    'actions/assign_conversation': AssignConversation,
    'actions/unAssign_converation': UnassignConversation,
    'actions/resolve_conversation': ResolveConversation,
    "actions/update_contactTags": UpdateConversationTag,
    "actions/remove_contactTags": RemoveConversationTag,
    "actions/update_contact": UpdateContactFields,
    "actions/update_conversationStatus": UpdateConversationStatus,
    "actions/trigger_Sequence": TriggerSequence,
    "actions/set_member": SetMember, 
    "actions/notes": AddNotes,
    'condition': Condition,
    'jump_to': JumpTo,
    'api_call': ApiCalls,
    'send_wa_template': SendTemplate,
    'send_wa_templateV2': SendTemplate,
    'open_ai': OpenAi,
    'integration/ZohoCRM': ZohoCRM,
    'integration/Shopify': Shopify,
    'flow': Flow,
    'integration/RazorPay': RazorPay,
    'send_OrderDetails': SendOrderDetails,
    'send_location': SendLocation,
    'send_product': SendProduct,
    'ticketIt': Ticketing,
    'integration/GoogleSheets': GoogleSheets,
    'ask_currentLocation': AskCurrentLocation,
    'appointment_Flow': AppointmentFlow,
    'appointment_confirmation': AppointmentConfirmation,
    'create_Ticket': CreateTicketNode,
    'integration/SangamCRM': SangamCRM,
    'integration/Woocommerce': WooCommerce,
    'integration/OpenAI': openAI,
    'integration/ZohoBigin': ZohoBigin,
    'replaceText': ReplaceText,
    'integration/Zoom': Zoom,
    'integration/Hubspot': Hubspot
};

export default nodeTypes;