import React, { Component } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { Button, Row, Col , InputGroup } from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers } from "formik";
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTrashCan, faXmark , faSearch ,faTimes } from "@fortawesome/free-solid-svg-icons";
import { connect, useSelector } from "react-redux";
import { Label } from "reactstrap";
import {
  CreateCommunity,
  UpdateCommunity,
  CreateContactList,
  ContactsGetAll,
  deleteContactsInCommunity,
  getCommunityContacts
} from "../../services/campaignService";
import { sweetalert, confirmAlert, toast } from "./../../common/alert";
import { sliceText } from "../../common/sliceText";
import TooltipReference from "../../common/tooltip";
import Modall from "../../common/modall";
import Contacts from "../Conversations/contact";
import Campaign from "./Campaign";
import AddContact from "../Contacts/addContacts";
import Select from 'react-select';
import { getTag } from '../Tag/tagApi'
import SearchInput from "../../common/searchComponent";
import moment, { isDate } from "moment";
import BizTable from "../../common/BizTable";
import { tableReset } from "../../redux/actions/action";
import { contactFilters } from "../../services/contactMemberService";

interface RowData {
  uid: string;
  id: string;
  name: string;
}

interface ContactsState {
  checkBoxShow: boolean;
  inputField: boolean;
  ContactLists: any;
  ContactEdit: any;
  isSelected: boolean;
  selectedUids: any;
  newGroup: string;
  MemberName: string;
  selectedRow: any;
  EditCommunityList: any;
  EditCommunityField: any;
  ContactCount: number;
  ContactListExcel: any;
  isLoading: boolean;
  checkboxSelected: any;
  openModal: boolean;
  createAlert: boolean;
  isLoadingNewCommunity: boolean;
  newFieldCommunity: boolean;
  tagsList: [{ label: string, value: string, tagAll: any }],
  getTag: any,
  getContactData: [],
  // currentPage: number, // Initialize currentPage in state
  // paginationLimit: number,
  searchTerm: any;
  allChecked: boolean;
  searchExcel:any;
  numberMasking: boolean;
}

interface FormValues {
  newGroup: string;
}

const validationSchema = Yup.object().shape({
  newGroup: Yup.string().required("Community name is required"),
});
interface Props {
  EditCommunityData?: any;
  backToExist?: () => void;
  backToCampaign?: () => void;
  showNewCampaign?: (showNewcampaign: any, status: string) => void;
  excelContactList: any;
  // checkedData?: any;
  businessId: any;
  memberData: { name: string } | null;
  channelData: { value: string } | null;
  memberId: any;
  callbackChecked: any;
  selectedContactData: any;
  checkboxExistCommunity: any;
  updatedContact?: (contactUpdate: any) => void;
  // handleClose: () => void;
  iscreateCampaign: boolean;
  updateExcelData?: any;
  selectedTableData:any;
  tableReset?:any;
  numberMasking: boolean;
}

class NewCommunity extends Component<Props, ContactsState> {
  paginationLimit = 10;
  currentPage = 0;
  columns: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      checkBoxShow: false,
      inputField: false,
      ContactLists: [],
      ContactEdit: null,
      isSelected: false,
      selectedUids: [],
      newGroup: "",
      MemberName: "",
      selectedRow: [],
      EditCommunityList: null,
      EditCommunityField: this.props?.EditCommunityData?.name,
      ContactCount: 0,
      ContactListExcel: [],
      isLoading: true,
      checkboxSelected: [],
      openModal: false,
      createAlert: false,
      isLoadingNewCommunity: true,
      newFieldCommunity: false,
      tagsList: [{ label: '', value: '', tagAll: [] }],
      getTag: '',
      getContactData: [],
      // currentPage: 0, // Initialize currentPage in state
      // paginationLimit: 10,
      searchTerm: null,
      allChecked: false,
      searchExcel: null,
      numberMasking: false
    };
    // this.toggleAllCheckboxes = this.toggleAllCheckboxes.bind(this);
  }

  onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    this.setState({ searchTerm: searchTermCheck,getTag:'' });
  };
  searchExcelData = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm.toLowerCase() : null;
    const { excelContactList } = this.props;
    
    if (searchTermCheck) {
        const sort = excelContactList?.filter((contact: any) => {
            return contact.name.toLowerCase().includes(searchTermCheck) ||
                   contact.mobileNumber.toString().toLowerCase().includes(searchTermCheck);
        });
        this.setState({ searchExcel: searchTermCheck, ContactLists: sort });
    } else {
        this.setState({ searchExcel: null, ContactLists: excelContactList });
    }
}


  // fetchData = async (
  //   pageIndex: number,
  //   pageSize: number,
  //   searchText: string,
  //   currentPage: number
  // ): Promise<{ data: any; totalPages: number }> => {

  //   const {EditCommunityData,excelContactList,businessId} = this.props;
  //   const {searchTerm} = this.state;
  //   const {uid} = EditCommunityData;

  //   const communityPayload = {
  //     limit: pageSize,
  //     page: searchTerm ? 0 : currentPage,
  //     search: searchTerm
  //   }
  //   if(EditCommunityData){
  //   const response =  await getCommunityContacts(uid,communityPayload);
   
  //   const {list,count} = response?.data;
  //   const countData = searchTerm ? list.length : count;
  //   this.setState({EditCommunityList:list,ContactCount:countData})
  //     return { data: list, totalPages:count  };      
  //   }
  // else if (excelContactList?.length > 0){
  //   return { data: excelContactList, totalPages:100  };        
  // }
  // else{
  //   const listPayload = {
  //     uid: businessId,
  //     page: searchTerm ? 0 : currentPage,
  //     limit: pageSize,
  //     search: searchTerm
  //   };
  //   const response = await ContactsGetAll(listPayload);
  //   const {list,count} = response?.data;
  //   if (response) {
  //     this.setState({
  //       ContactLists:list,
  //       ContactCount: searchTerm !== null ? response.data.list.length : response.data.count
  //     });
  //   }
  //   if(response){
  //     return { data: list, totalPages: count};
  //   }
  //   else{
  //     return { data: [], totalPages: 0 };
  //   }
  // }
  // };
  fetchData = async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {
    console.log('searchText', searchText);
  
    const { EditCommunityData, excelContactList, businessId } = this.props;
    const { searchTerm, getTag } = this.state;
  
    // If EditCommunityData is present
    if (EditCommunityData) {
      const { uid } = EditCommunityData;
      const communityPayload = {
        limit: pageSize,
        page: searchTerm ? 0 : currentPage,
        search: searchTerm,
      };
  
      const response = await getCommunityContacts(uid, communityPayload);
      const { list, count } = response?.data || { list: [], count: 0 };
      this.setState({ EditCommunityList: list, ContactCount: count });
      return { data: list, totalPages: count };
    }
  
    // If excelContactList has data
    if (excelContactList?.length > 0) {
      return { data: excelContactList, totalPages: 100 };
    }
  
    // For other cases
    const listPayload = {
      uid: businessId,
      page: searchTerm ? 0 : currentPage,
      limit: pageSize,
      search: searchTerm,
    };
  
    // If there is a getTag state
    if (getTag &&  getTag.tagAll) {
      const filterPayload = {
        uid: businessId,
        tags: [getTag?.tagAll],
        page: currentPage,
        limit: pageSize,
      };
  
      const response = await contactFilters(filterPayload);
      if (response) {
        const { list, count } = response;
        this.setState({ ContactLists: list, ContactCount: count });
        return { data: list, totalPages: count };
      }
    }
  
    // Default case: Fetch all contacts
    const response = await ContactsGetAll(listPayload);
    const { list, count } = response?.data || { list: [], count: 0 };
    this.setState({
      ContactLists: list,
      ContactCount: searchTerm ? list.length : count,
    });
  
    return { data: list, totalPages: count };
  };
  

  // fetchContacts = async () => {
  //   const { searchTerm } = this.state;
  //   const page = this.currentPage > 0 ? this.currentPage - 1 : 0;
  //   const ContactList = {
  //     uid: this.props.businessId,
  //     limit: this.paginationLimit,
  //     page: searchTerm ? 0 : page,
  //     search: searchTerm,
  //   };
  //   const response = await ContactsGetAll(ContactList);
  //   if (response) {
  //     this.setState({
  //       ContactLists: response.data.list,
  //       ContactCount: searchTerm !== null ? response.data.list.length : response.data.count
  //     });
  //   }
  // }

  // pageChange = (page: any, sizePerPage: any) => {
    // const newPaginationLimit = sizePerPage !== this.paginationLimit ? sizePerPage : this.paginationLimit;
    // const newCurrentPage = sizePerPage !== this.paginationLimit ? 0 : (page !== this.currentPage ? page : this.currentPage);

    // this.paginationLimit = newPaginationLimit;
    // this.currentPage = newCurrentPage;

    // Call fetchContacts or any other method that needs these values
    // this.fetchContacts();
  // };


  getTags = async (businessId: number) => {
    const payload = {
      "businessUid": businessId,
      "limit": 1000,
      "page": 0,
      "search": null
    }

    const tagsData = await getTag(payload, 'contact')
    const tags = tagsData?.data?.tagsDto.map((tag: any) => ({
      label: tag.name,
      value: tag.name,
      tagAll: tag
    }));

    this.setState({ tagsList: tags });
  }

  checkInvalidAccess() {
    const { numberMasking } = this.props;
    this.setState({ numberMasking });
  }

  componentDidMount = async () => {
    const { businessId, EditCommunityData, excelContactList } = this.props;
    const UploadContact = excelContactList;
    if (UploadContact.length > 0) {
      this.setState({
        ContactLists: excelContactList,
        ContactListExcel: excelContactList,
      });
    } else if (EditCommunityData) {
      // this.setState({ EditCommunityField: EditCommunityData.name });
      // this.setState({ EditCommunityList: EditCommunityData.contacts });
      this.setState({ EditCommunityField: EditCommunityData });
      // this.setState({ EditCommunityList: EditCommunityData });
    } else {
      // const ContactList = {
      //   uid: businessId,
      //   limit: 500,
      //   page: 0,
      // };
      // const response = await ContactsGetAll(ContactList as any);
      //
      // const list = response?.data?.list;
      // this.setState({
      //   ContactLists: list,
      //   ContactCount: response?.data?.count,
      // });
      this.getTags(businessId as any);
      // this.fetchContacts()
    }

    setTimeout(() => {
      this.setState({ isLoading: false, isLoadingNewCommunity: false });
    });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<ContactsState>,
    snapshot?: any
  ): void {
    const { businessId, EditCommunityData } = this.props;
    const { EditCommunityList, checkboxSelected } = this.state;
    if (businessId !== prevProps.businessId) {
      this.setState({
        newFieldCommunity: true,
        EditCommunityField: EditCommunityData.count,
        // EditCommunityList: EditCommunityData.list,
      });
    }
    // if (EditCommunityList !== prevState.EditCommunityList) {
    //   // old
    //   const contact_id = EditCommunityList?.flatMap(
    //     (contactList: any) => contactList.id
    //   );
    //   const contact_uid = EditCommunityList?.flatMap(
    //     (contactList: any) => contactList.uid
    //   );
    //   if (contact_id) {
    //     this.setState({
    //       // checkboxSelected: contact_id,
    //       // selectedRow: EditCommunityList,
    //       // selectedUids: contact_uid,
    //     });
    //   }
    // }
    if(prevProps.selectedTableData !== this.props.selectedTableData){
      const {selectedTableData} = this.props;
    
      const totalcount = Object.values(selectedTableData).reduce((acc, arr:any) => acc + arr?.length, 0);
      const initialSelected = Object.values(selectedTableData).flat();
        this.setState({selectedRow: initialSelected})
      }
    if (prevState.searchTerm !== this.state.searchTerm) {
      // this.fetchContacts()
      this.fetchData(0, 20, '', 0);
    }
    if (prevState.getTag !== this.state.getTag) {
      // this.fetchContacts()
      this.fetchData(0, 20, '', 0);
    }
    if(this.state.numberMasking !== this.props.numberMasking) {
      this.checkInvalidAccess();
    }
  }

  handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      const { selectedRow, EditCommunityList } = this.state;
      if (EditCommunityList) {
        // if (selectedRow.length >= 0) {
        //   showAlert("error", "Atleast one contact should be selected");
        // } else {
        const { channelData } = this.props;
        const channelId = channelData?.value;

        const newGroup = values?.newGroup;
        const communityName = newGroup
          ? newGroup
          : this.props?.EditCommunityData?.name;
        const defaultValue = this.props?.EditCommunityData;
        const updateCommunityPayload = {
          name: communityName,
          contacts: EditCommunityList,
        }
        const communityPayload = {
          ...defaultValue, ...updateCommunityPayload
        }
        if (channelId) {
          const response = await UpdateCommunity(
            communityPayload,
            channelId
          );
          if (response) {
            const contactList = response.data;
            if (this.props.backToExist && this.props.updatedContact) {
              this.props.updatedContact(contactList);
              this.props.backToExist();
            }
          }
        }
        // }
      } else {
        const { newGroup, ContactListExcel } = this.state;
        const UploadContact = ContactListExcel;
        const { businessId, channelData, memberId } = this.props;
        const channelId = channelData?.value as any;
        if (UploadContact.length > 0) {
          const response = await CreateContactList(
            businessId,
            ContactListExcel,
            memberId,
            channelId
          );
          if (response) {
            if (channelId) {
              const status = "EXCEL";
              const responseData = await CreateCommunity(
                newGroup,
                response,
                status,
                channelId,
                memberId
              );
              if (
                responseData &&
                this.props.backToCampaign &&
                this.props.showNewCampaign
              ) {
                this.props.showNewCampaign(responseData, status);
                this.props.backToCampaign();
              }
            }
          }
        } else {
          const { selectedRow, newGroup } = this.state;
          if (channelId) {
            if (selectedRow.length <= 0) {
              toast("error", "Atleast one contact should be selected");
            } else {
              const status = "MANUAL";
              const response = await CreateCommunity(
                newGroup,
                selectedRow,
                status,
                channelId,
                memberId
              );
              if (response) {
                if (
                  response.success === "MANUAL" &&
                  this.props.showNewCampaign
                ) {
                  this.props.showNewCampaign(response, status);
                  // this.back_existCommunity();
                }
              }
            }
          }
        }
      }
    } catch (error: any) { }
    setSubmitting(false);
  };

  // handleRowSelect = (row: RowData, isSelected: any) => {
  //   if (isSelected) {
  //     const uid = row.uid;
  //     this.setState((prevState) => ({
  //       selectedUids: [...prevState.selectedUids, uid],
  //       selectedRow: [...prevState.selectedRow, row],
  //       // checkboxSelected: [...prevState.checkboxSelected, row.id],
  //     }));
  //   } else {
  //     this.setState((prevState: any) => ({
  //       selectedUids: prevState.selectedUids.filter(
  //         (uid: string) => uid !== row.uid
  //       ),
  //       selectedRow: prevState.selectedRow.filter(
  //         (selectedRow: any) => selectedRow !== row
  //       ),
  //       // checkboxSelected: prevState.checkboxSelected.filter(
  //       //   (id: string) => id !== row.id // Remove the unselected row's id
  //       // ),
  //     }));
  //   }
  // };

  // handleRowSelectAll = (isSelected: boolean, rows: any) => {

  //   const newUids = isSelected ? rows.map((row: any) => row.uid) : [];
  //   const newSelectedRows = isSelected ? rows : [];
  //   // const newCheckboxSelected = isSelected ? rows.map((row: any) => row.id) : [];

  //   this.setState({
  //     selectedUids: newUids,
  //     selectedRow: newSelectedRows,
  //     // checkboxSelected: newCheckboxSelected,
  //   });

  //   return true; // Or some other appropriate boolean or array value
  // };

  clearSelect = () => {
    const { tableReset } = this.props;
      if(tableReset){
        tableReset();
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"]'
    );
    checkboxes?.forEach((checkbox) => {
      if (checkbox.checked) {
        checkbox.click();
      }
    });
  }
    // this.setState({ selectedUids: [], checkBoxShow: false });
  };

  arraysHaveSameElements(array1: [], array2: []) {
    if (array1.length !== array2.length) {
      return false;
    }

    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();

    return sortedArray1.every((value, index) => value === sortedArray2[index]);
  }

  back_existCommunity = () => {
    if (this.props.EditCommunityData !== "") {
      // const { checkboxSelected } = this.state;
      // let contactArrayEqual;
      // let filteredContact = [];
      // const checkboxContact = this.props.EditCommunityData?.contacts;
      // if (checkboxSelected?.length >= 0) {
      //   if (this.props.EditCommunityData) {
      //     filteredContact =
      //       checkboxSelected &&
      //       checkboxSelected?.filter(
      //         (id: any) => !checkboxContact?.some((item: any) => item.id === id)
      //       );
      //     const changeContact = checkboxContact?.flatMap(
      //       (contactList: any) => contactList.id
      //     );
      //     contactArrayEqual = this.arraysHaveSameElements(
      //       checkboxSelected,
      //       changeContact
      //     );
      //   } else {
      //     if (checkboxSelected.length > 0) {
      //       contactArrayEqual = false;
      //     }
      //   }

      //    new 
      const { selectedRow, newGroup } = this.state;

      if (selectedRow?.length > 0 || newGroup !== '') {
        confirmAlert(
          "warning",
          "Your changes will be lost. Are you sure you want to continue?",
          () => {
            if (this.props.backToExist) {
              this.props.backToExist();
            }
          },
          () => { }
        );
      } else {
        if (this.props.backToExist) {
          this.props.backToExist();
        }
      }



      // if (selectedRow?.length >= 0) {
      //     if (this.props.EditCommunityData) {
      //       filteredContact =
      //         checkboxSelected &&
      //         checkboxSelected?.filter(
      //           (id: any) => !checkboxContact?.some((item: any) => item.id === id)
      //         );
      //       const changeContact = checkboxContact?.flatMap(
      //         (contactList: any) => contactList.id
      //       );
      //       contactArrayEqual = this.arraysHaveSameElements(
      //         checkboxSelected,
      //         changeContact
      //       );



      //     } else {
      //       if (checkboxSelected.length > 0) {
      //         contactArrayEqual = false;
      //       }

      //     }
      //   }



      // } else if (this.props.backToExist) {
      //   this.props.backToExist();
      // }
    } else {
      if (this.props.backToCampaign) {
        this.props.backToCampaign();
      }
    }
  };

  backToUploadExcel = () => {
    const { createAlert } = this.state;
    if (createAlert === true) {
      sweetalert(
        "warning",
        "Your changes will be lost. Are you sure you want to continue?",
        () => {
          if (this.props.updateExcelData && this.props.backToCampaign) {
            const { excelContactList } = this.props;
            this.props.backToCampaign();
            this.props.updateExcelData(excelContactList);
          }
        },
        () => { }
      );
    } else {
      if (this.props.updateExcelData && this.props.backToCampaign) {
        const { excelContactList, callbackChecked } = this.props;
        // if(excelContactList.length > 0){
        //   const {check} = callbackChecked
        //   const updatedChecked = {
        //     ...this.props.callbackChecked, // Spread the existing checked object
        //     check: {
        //       ...check, // Spread the existing 'check' object
        //       selectContact: 'upload'  
        //     }

        //   };
        //   if (this.props.checkedData) {
        //     this.props.checkedData(updatedChecked);
        //  
        //   }      
        // }
        this.props.backToCampaign();
        this.props.updateExcelData(excelContactList);
      }
    }
  };

  backValidCampain = (event: any) => {
    if (this.state.selectedRow.length > 0 && event === "cancel") {
      sweetalert(
        "warning",
        "Your changes will be lost. Are you sure you want to continue?",
        () => {
          if (event === "cancel" && this.props.backToCampaign) {
            this.props.backToCampaign();
          } else {
            this.clearSelect();
          }
        },
        () => { }
      );
    } else {
      if (event === "cancel" && this.props.backToCampaign) {
        this.props.backToCampaign();
      } else {
        this.clearSelect();
      }
    }
  };

  handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault(); // Prevent space key from having its default behavior
    }
    const { ContactListExcel } = this.state;
    if (ContactListExcel?.length > 0) {
      this.setState({ createAlert: true });
    }
  };
  emailData = (cell: any, row: any) => {
    return (
      <div>
        {cell && cell.length > 30 ? (
          <TooltipReference
            placement="right"
            content={cell}
            tooltipId={`emailData_${row.id}`}
          >
            {sliceText(cell, 28)}
          </TooltipReference>
        ) : (
          <span className="email-data">{cell ? sliceText(cell, 25) : "-"}</span>
        )}
      </div>
    );
  };

  nameData = (cell: any, row: any) => {
    return (
      <div>
        {cell.length > 11 ? (
          <TooltipReference
            placement="right"
            content={cell}
            tooltipId={`nameData_${row.id}`}
          >
            {sliceText(cell, 12)}
          </TooltipReference>
        ) : (
          <span>{sliceText(cell, 12)}</span>
        )}
      </div>
    );
  };

  mobileData = (cell: any, rowData: any) => {
    const { countryCode } = rowData;
    const countryCodeString = countryCode?.toString(); // Ensure countryCode is a string
    const numberMaskString = localStorage.getItem('numberMask');
    const numberMask = numberMaskString === 'true' || this.state.numberMasking;
    return (
      <div className={`${numberMask ? "numberMasking_Blur" :''}`}>
        {
          this.state?.ContactListExcel.length > 0 ?
            (
              <>
                {'+' + countryCode + ' ' + cell}
              </>
            ) : (
              countryCodeString?.length > 0
                ? `+${countryCodeString} ${cell.substring(countryCodeString.length)}`
                : cell
            )

        }

      </div>
    );
  };


  // tagContacts = async (tagList: any) => {
  //   const { businessId } = this.props
  //   const ContactList = {
  //     uid: businessId,
  //     tags: [tagList],
  //     limit: 1000,
  //     page: 0,
  //   };
  //   const filterPayload = {
  //     uid: businessId,
  //     tags: [tagList] ?? "",
  //     page: 0,
  //     limit: 1000
  //     //tagname: name ?? '',
  //   };

  //   const response = await contactFilters(filterPayload);
  //   console.log('ssdds',response)
  //   // const response = await ContactsGetAll(ContactList as any);
  //   const {list,count} = response;
  //   this.setState({
  //     ContactLists: list,
  //     ContactCount: count,
  //   });
  // }

  tagsChange = async (Tag: any) => {
    if (Tag !== null) {
      this.setState({ getTag: Tag })
      const searchTag = Tag?.tagAll
      // this.tagContacts(searchTag)
    }
    else {
      this.setState({ getTag: [] })
      // this.fetchContacts()
      // this.fetchData(0, 20, '', 0);
    }
  }



  tagsData = (cell: any, row: any) => {
    let title;
    if (!cell || cell.length === 0) {
      return <span className="">-</span>;
    } else {
      if (this.state?.ContactListExcel.length > 0) {
        let tagNames;
        if (cell.length > 1) {
          tagNames = cell.map((tag: any) => String(tag)).join(', ');
        } else {
          tagNames = String(cell);
          if (isDate(cell)) {
            tagNames = moment(new Date(cell.getFullYear(), cell.getMonth(), cell.getDate()+1)).format('MMM DD YYYY');
          }
        }
        title = [tagNames].join(', ');
      }
      else {
        const tagNames = cell.map((tag: any) => tag.name);
        title = tagNames.join(', ');
      }
    }

    return (
      <div className="d-flex">
        <div className="d-flex justify-content-end p-0">
          {/* {cell}
       */}
          {/* {cell.slice(0, 1).map((item: any) => (
            <span key={item} className="ms-1 tag-list-view">
              {item.name ? sliceText(item.name, 10) : "-"}
            </span>
          ))} */}
          {cell && Array.isArray(cell) && cell?.slice(0, 1).map((item: any) => (
            <span key={item} className="ms-1 tag-list-view">
              {Array.isArray(item) ? (
                item.join(', ')
              ) : (
                typeof item === 'string' ? item : (item && item.name ? sliceText(item.name, 10) : "-")
              )}
            </span>
          ))}
          {!Array.isArray(cell) && (
            <span className="ms-1 tag-list-view">
              {title}
            </span>

          )}
        </div>
        <div className="d-flex justify-content-start tag-count">
          {/* {cell.length > 1 && <span className=""> +{cell.length - 1}</span>} */}
          {cell.length > 1 &&
            <TooltipReference
              placement='bottom'
              content={title}
              tooltipId={`tagData_${row.id}`}>
              +{cell.length - 1}
            </TooltipReference>
          }
        </div>
      </div>
    );
  };

  handleShow = () => {
    const numberMask = localStorage.getItem('numberMask') === 'true' || this.state.numberMasking;
    const {ContactLists,EditCommunityList} = this.state;
    if(numberMask && ContactLists){
      this.setState({ openModal: false });
    }
     if(EditCommunityList ||(!numberMask && ContactLists)){
      this.setState({ openModal: true });
    }
  };
  

  handleClose = () => {
    this.setState({ openModal: false });
  };

  addNewContact = async (addContact: any) => {
    this.setState({ EditCommunityList: addContact });
    const { channelData } = this.props;
    const channelId = channelData?.value;
    const { newGroup } = this.state
    const communityName = newGroup
      ? newGroup
      : this.props?.EditCommunityData?.name;
    // const defaultValue = this.props?.EditCommunityData;
    // const defaultValue = this.state.EditCommunityList;
    const updateCommunityPayload = {
      uid: this.props?.EditCommunityData?.uid,
      name: communityName,
      // contacts: addContact,
      creatingContacts: addContact
    }
    // const communityPayload = {
    //   ...defaultValue, ...updateCommunityPayload
    // }
    if (channelId) {
      const response = await UpdateCommunity(
        updateCommunityPayload,
        channelId
      );
      if(response){
        this.fetchData(0, 20, '', 0);
      }
    }
  };

  // fetchContacts = async () => {
  //   const { businessId } = this.props
  //   const ContactList = {
  //     uid: businessId,
  //     limit: 500,
  //     page: 0,
  //   };
  //   const response = await ContactsGetAll(ContactList as any);
  //   const list = response?.data?.list;
  //   this.setState({
  //     ContactLists: list,
  //     ContactCount: response?.data?.count,
  //   });
  // }

  dropContacts = async () => {
    const { channelData, EditCommunityData } = this.props;
    const { uid } = EditCommunityData
    const channelId = channelData?.value;
    const { selectedRow } = this.state;
    const uids = selectedRow.map((row: any) => row.uid)
    const dropPayload = {
      uid: uid,
      name: EditCommunityData.name,
      removingContacts: uids
    }
    if (channelId) {
      confirmAlert(
        "warning",
        "Are you sure you want to delete this contact?",
        async () => {
          const response = await deleteContactsInCommunity(channelId, dropPayload);
          if (response) {
            const channelId = channelData?.value;
            this.fetchData(0, 20, '', 0);
            this.setState({selectedUids: [],selectedRow:[] })
            // const res = await GetCommunity(uid, channelId)
            // const contactsData = res?.data?.contacts
            // if (res) {
            //   this.setState({ EditCommunityList: contactsData, selectedUids: [] });
            // }
          }
        },
        () => { }
      );
    }
  }

  // checkboxCellRenderer = (cell: any, row: any) => {
  //   return (
  //     <input
  //       type="checkbox"
  //       checked={this.state.selectedUids.includes(row.uid)}
  //       onChange={(e) => this.handleRowSelect(row, e.target.checked)}
  //     />
  //   );
  // }

  
  // toggleAllCheckboxes = () => {
  //   const { allChecked } = this.state;
  //   const newAllChecked = !allChecked;
  //   this.setState({ allChecked: newAllChecked });

  //   const checkboxes = document.querySelectorAll<HTMLInputElement>(
  //     'input[type="checkbox"]'
  //   );
  //   checkboxes?.forEach((checkbox) => {
  //     if (checkbox.checked !== newAllChecked) {
  //       checkbox.click();
  //     }
  //   });
  // }


  render() {
    const {
      selectedUids,
      checkBoxShow,
      EditCommunityList,
      // checkboxSelected,
      selectedRow,
      isLoadingNewCommunity,
      ContactCount,
    } = this.state;
    const totalPages = Math.ceil(ContactCount / this.paginationLimit);
    const numberMaskString = localStorage.getItem('numberMask');
    const numberMask = numberMaskString === 'true' || this.state.numberMasking;
    const style = {
      background: numberMask ? '#e9e9e9' : '', 
      cursor: numberMask ? 'not-allowed' : '',
    };
    // if (isLoadingNewCommunity) {
    //   return (
    //     <>
    //       <Loader />
    //     </>
    //   )
    // }
  
    const { ContactLists, EditCommunityField, ContactListExcel } = this.state;
    const EditCommunity = EditCommunityList;
    const UploadContact = ContactListExcel;
    const ContactData = ContactLists;
 
    const ContactDatas = EditCommunity ? EditCommunityList : ContactData;
    // const selectRowProp: SelectRow = {
    //   mode: "checkbox",
    //   clickToSelectAndEditCell: false,
    //   clickToExpand: false,
    //   onSelect: this.handleRowSelect,
    //   onSelectAll: this.handleRowSelectAll,
    //   // selected: checkboxSelected,
    // };

    this.columns = [
     
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       checked={this.state.allChecked}
      //       onChange={this.toggleAllCheckboxes}
      //       hidden={this.state?.ContactListExcel?.length > 0 ? true : false} 
      //     />
      //   ),
      //   accessor: "checkbox",
      //   Cell:({cell,row}:any)=> this.checkboxCellRenderer(cell.value, row.original),
      //   sticky: "left",
      // },
      {
        id: "selection",
        Header: ({
          getToggleAllRowsSelectedProps,
        }: {
          getToggleAllRowsSelectedProps: any;
        }) => (
          <div>
            <input type="checkbox"
            hidden={this.state?.ContactListExcel?.length > 0 ? true : false}
            {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }: { row: any }) => (
          <div>
            <input type="checkbox" 
              hidden={this.state?.ContactListExcel?.length > 0 ? true : false}
            {...row.getToggleRowSelectedProps()} />
          </div>
        ),
        accessor: "checkbox",
      },
      {
        Header: "Name",
        accessor: "name",
        sticky: "left",
      },
      {
        Header: "Mobile Number",
        accessor: "mobileNumber",
        Cell: ({ cell, row }: any) => this.mobileData(cell.value, row.original),
      },
      {
        Header: "Contact Type",
        accessor: "contactType",
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ cell, row }: any) => this.tagsData(cell.value, row.original),
      },
      // this.state.ContactListExcel.length === 0 && (
      //   {
      //     Header: "Email",
      //     accessor: "email",
      //     Cell: ({ cell, row }: any) => this.emailData(cell.value, row.original),
      //     grid: "third-column",
      //   }
      // ),
            // {
      //   Header: "Source",
      //   accessor: "source",
      //   Cell: ({ cell, row }: any) => this.sourceData(cell.value, row.original),
      // },
      // {
      //   Header: "Last Update",
      //   accessor: "updatedAt",
      //   Cell: ({ cell, row }: any) =>
      //     moment(cell.value).format("DD MMM hh.mm A"),
      // },
      // {
      //   Header: "Action",
      //   accessor: "actions",
      //   Cell: ({ cell, row }: any) => (
      //     <div>
      //       <svg
      //         width="16"
      //         height="16"
      //         onClick={() => this.handleClickEdit(cell, row.original)}
      //         viewBox="0 0 16 16"
      //         fill="none"
      //         xmlns="http://www.w3.org/2000/svg"
      //       >
      //         <path
      //           d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
      //           stroke="#666E7D"
      //           stroke-width="1.25"
      //           stroke-linecap="round"
      //           stroke-linejoin="round"
      //         />
      //         <path
      //           d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
      //           stroke="#666E7D"
      //           stroke-width="1.25"
      //           stroke-linecap="round"
      //           stroke-linejoin="round"
      //         />
      //       </svg>
      //     </div>
      //   ),
      //   grid: "col-md-1",
      // },
    ] as any;

    const sidebarStyle = {
      backgroundColor: "white",
      width: "380px", // Set the desired background color here
    };
    // const options = {
    //   headerAlign: "center",
    //   noDataText: "No records found",
    //   sortIndicator: true,
    //   hidePageListOnlyOnePage: true,
    //   clearSearch: false,
    //   alwaysShowAllBtns: true,
    //   withFirstAndLast: true,
    //   // sizePerPage: 25,
    //   pagination: false,
    //   onPageChange: this.pageChange,
    //   sizePerPage: this.paginationLimit,
    //   sizePerPageList: [10, 25, 50, 100, { text: 'All', value: EditCommunity ? EditCommunity.length : this.state.ContactCount }],
    //   paginationPosition: "bottom",
    // };

    return (
      <div className="new-community-container">
        {this.state.newFieldCommunity ? (
          <>
            <Campaign
            // checkboxExistCommunity={checkboxSelected}
            />
          </>
        ) : (<>
          <div className="global-table-title">
            <h5>
              {EditCommunity ? (
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  onClick={this.back_existCommunity}
                />
              ) : UploadContact?.length > 0 ? (
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  onClick={() => this.backToUploadExcel()}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  onClick={() => this.backValidCampain("cancel")}
                />
              )}
              {"  "}
              {EditCommunity
                ? "Edit Community"
                : "Create New Community from Contacts"}
            </h5>
          </div>
          <div className="">
            <Formik
              initialValues={{
                newGroup: EditCommunityField,
              }}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Row className="">
                  <Label className="community-name  pt-3">Community Name</Label>
                  <Form className="">
                    <Row>
                      <Col md="4" lg="4" xl="4" className="community-name">
                        <Field
                          type="text"
                          name="newGroup"
                          className="form-control "
                          placeholder="Enter your Community name"
                          onKeyDown={this.handleKeyDown}
                          maxLength={60}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = e.target.value;
                            this.setState({ newGroup: value });
                            setFieldValue("newGroup", e.target.value);
                          }}
                        />
                      </Col>
                      <Col md="1" lg="1" xl="1"></Col>
                      <Col md="7" lg="7" xl="7" className="new-community-buttons">
                        <>
                          {
                            ((ContactLists && ContactLists.length > 0 && ContactListExcel?.length === 0) || !this.props.excelContactList) && (
                              <>
                                <div
                                  className="border rounded pl-2 pt-1 pr-2 mt-2 mr-2"
                                  onClick={this.handleShow}
                                  style={style}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"

                                  >
                                    <path
                                      d="M16 20V18.3333C16 17.4493 15.6348 16.6014 14.9846 15.9763C14.3345 15.3512 13.4528 15 12.5333 15H6.46667C5.54725 15 4.66549 15.3512 4.01536 15.9763C3.36524 16.6014 3 17.4493 3 18.3333V20"
                                      stroke="#666E7D"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9.5 11C11.433 11 13 9.433 13 7.5C13 5.567 11.433 4 9.5 4C7.567 4 6 5.567 6 7.5C6 9.433 7.567 11 9.5 11Z"
                                      stroke="#666E7D"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M19 8V14"
                                      stroke="#666E7D"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M22 11H16"
                                      stroke="#666E7D"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  {this.state.openModal && (
                                    <Modall
                                      isOpen={this.state.openModal}
                                      onClose={this.handleClose}
                                      title="Add Contact"
                                      size="md"
                                      component="conversation"
                                    >
                                      <div>
                                        <div className="d-flex justify-content-between">
                                          <h5 className="ps-3">
                                            {/* <FontAwesomeIcon
                              icon={faArrowLeft}
                              onClick={() => this.backValidCampain("cancel")}
                              className="me-2"
                            /> */}
                                            Add Contact
                                          </h5>
                                          <div>
                                            <FontAwesomeIcon icon={faXmark}
                                              onClick={this.handleClose} />
                                          </div>
                                        </div>
                                      </div>
                                      <AddContact
                                        fetchContacts={this.fetchData}
                                        handleClose={this.handleClose}
                                      />
                                    </Modall>
                                  )}
                                </div>
                                <div className="w-25 mt-2 mr-2">
                                  <Select
                                    value={this.state.getTag}
                                    onChange={this.tagsChange}
                                    options={this.state.tagsList as any}
                                    isClearable={true}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                  />
                                </div>
                              </>
                            )
                          }
                          {EditCommunity && (
                            <>
                              <div
                                className="border rounded pt-1 pl-1 pr-1 me-2 mt-2"
                                onClick={this.handleShow}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M16 20V18.3333C16 17.4493 15.6348 16.6014 14.9846 15.9763C14.3345 15.3512 13.4528 15 12.5333 15H6.46667C5.54725 15 4.66549 15.3512 4.01536 15.9763C3.36524 16.6014 3 17.4493 3 18.3333V20"
                                    stroke="#666E7D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M9.5 11C11.433 11 13 9.433 13 7.5C13 5.567 11.433 4 9.5 4C7.567 4 6 5.567 6 7.5C6 9.433 7.567 11 9.5 11Z"
                                    stroke="#666E7D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M19 8V14"
                                    stroke="#666E7D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M22 11H16"
                                    stroke="#666E7D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                {this.state.openModal && (
                                  <Modall
                                    isOpen={this.state.openModal}
                                    onClose={this.handleClose}
                                    title="New Conversation"
                                    size="md"
                                    component="conversation"
                                  >
                                    <Contacts
                                      handleClose={this.handleClose}
                                      status="CAMPAIGN"
                                      EditCommunityList={EditCommunityList}
                                      addNewContact={this.addNewContact}
                                    ></Contacts>
                                  </Modall>
                                )}
                              </div>
                            </>
                          )}
                          <Button
                            variant=""
                            type="button"
                            onClick={() =>
                              EditCommunity
                                ? this.back_existCommunity()
                                : UploadContact?.length > 0
                                  ? this.backToUploadExcel()
                                  : this.backValidCampain("cancel")
                            }
                            className="cancelButton me-2 mt-2"
                          >
                            Cancel
                          </Button>
                          {selectedRow.length > 0 ? (
                            <Button
                              variant=""
                              type="button"
                              onClick={this.clearSelect}
                              className="cancelButton me-2 mt-2"
                            >
                              Clear Selection
                            </Button>
                          ) : null}
                          {(EditCommunity && selectedRow.length > 0)
                            ? (<>
                              <Button
                                variant=""
                                type="button"
                                onClick={this.dropContacts}
                                className="sendButton w-25  mt-2 "
                              >
                                <FontAwesomeIcon icon={faTrashCan} className="me-2" /> Delete selected  ({selectedRow.length})
                              </Button>
                            </>) : (<>
                              <Button
                                variant=""
                                type="submit"
                                disabled={isSubmitting}
                                className={`
                                sendButton w-25 mt-2 p-0 d-flex 
                                ${isSubmitting  ? 'justify-content-evenly' :'justify-content-center'} 
                                align-items-center
                                `}
                              >
                                <div>
                                {EditCommunity
                                  ? "Update Community"
                                  : "Create Community"}{" "}
                                </div>
                                <div>
                                  {isSubmitting&&(<div className="loadingVia"></div>)}
                                </div>
                              </Button>
                            </>)}
                        </>
                      </Col>
                    </Row>
                    <ErrorMessage
                      className="text-danger ps-3"
                      name="newGroup"
                      component="div"
                    />
                  </Form>
                </Row>
              )}
            </Formik>
            {UploadContact.length > 0 ? (
              <div className="row  d-flex align-items-center pt-2">
              <div className="selected-contacts col-md-8">
                Contact list: {"  "} <span>{UploadContact.length}</span>
              </div>
              <div className="col-md-4 d-flex justify-content-end">
              <InputGroup className="searchDesign rounded ">
            <InputGroup.Text className="searchIcon hideBorder iconsColor">
                <FontAwesomeIcon icon={faSearch} aria-hidden="true" />
            </InputGroup.Text>
            <input
                className="form-control hideBorder"
                type="search"
                placeholder="Search Contacts" // You can replace this with a static placeholder if needed
                value={this.state.searchExcel || ""}
                onChange={(e) => this.searchExcelData(e.target.value)}
                // readOnly
            />
            {this.state.searchExcel &&(
            <InputGroup.Text className="searchIcon hideBorder iconsColor">
                <FontAwesomeIcon
                    icon={faTimes}
                    aria-hidden="true"
                    onClick={() => this.setState({ searchExcel: null })}
                />
            </InputGroup.Text>
            )}
        </InputGroup>
                </div>
                </div>
            ) : (
              // <Row>
              //   <Col md={9}>
              // <div className={`selected-contacts ${EditCommunity ? 'mb-5' : ''}`}>
              //   Selected :{" "}
              //   <span>
              //     {selectedUids.length} of{" "}
              //     {EditCommunity ? EditCommunityList.length : ContactCount}{" "}
              //     Contacts{" "}
              //   </span>

              // </div>
              // </Col>
              // <Col className="pe-0">
              //           <SearchInput
              //             component="community"
              //             onSearchChange={this.onSearchChange}
              //           />
              //         </Col>
              // </Row>
              <Row className="ms-2 mt-1">
                <Col md="8" className="mt-4">
                  <div>
                    Selected :{" "}
                    <span style={{ color: "#007EC3" }}>
                      {selectedRow?.length} of{" "}
                      {ContactCount}{" "}
                      Contacts{" "}
                    </span>
                  </div>
                </Col>
                {/* {!EditCommunity && ( */}
                  <Col className="pe-0 mt-3">
                    <SearchInput
                      component="community"
                      onSearchChange={this.onSearchChange}
                      placeHolder="Search Contacts"
                    />
                  </Col>
                {/* )} */}
              </Row>
            )}
            <div className="underline"></div>
            <div className={`mt-1 ${!EditCommunity ? 'global-check' : ''}`}>
              {/* <BootstrapTable
                data={ContactDatas}
                selectRow={
                  UploadContact.length > 0 ? { mode: "none" } : selectRowProp
                }
                search={EditCommunity || UploadContact.length > 0 ? true : false}
                pagination
                version="4"
                striped
                hover
                remote
                fetchInfo={{
                  dataTotalSize: this.paginationLimit * totalPages,
                }}
                options={options as any}
                containerStyle={{ textAlign: "left" }}
              >
                <TableHeaderColumn dataField="id" isKey hidden >
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="name"
                  dataAlign="left"
                  dataFormat={this.nameData}
                  width="25%"
                >
                  Name
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="mobileNumber"
                  dataAlign="left"
                  width="25%"
                  dataFormat={this.mobileData}>
                  Mobile number
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="tags"
                  dataAlign="left"
                  width="25%"
                  dataFormat={this.tagsData}
                >
                  Tags
                </TableHeaderColumn>

                {this.state.ContactListExcel.length === 0 && (
                  <TableHeaderColumn
                    dataField="email"
                    dataAlign="left"
                    dataFormat={this.emailData}
                  >
                    Email
                  </TableHeaderColumn>
                )}
              </BootstrapTable> */}
                 <BizTable
                  columns={this.columns}
                  fetchData={this.fetchData}
                  counts={this.state.ContactCount}
                  refetchData={ContactDatas}
                  tableName="NEWCOMMUNITY"
                />
            </div>
          </div>
        </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    businessId: state.cartreducer.business?.business?.uid,
    memberId: state.cartreducer.business?.uid,
    memberData: state.cartreducer.memberData,
    channelData: state.cartreducer.channelUid,
    selectedTableData: state.tableReducer.selectedUids,
    numberMasking: state.cartreducer.numberMasking,
  };
};

const mapDispatchToProps = {
  tableReset,
}


export default connect(mapStateToProps,mapDispatchToProps)(NewCommunity);
