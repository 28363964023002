import React, { useContext, useEffect, useMemo, useRef, useState, useCallback } from "react";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { Alert } from "react-bootstrap";
import {
  Row,
  Col,
  NavLink,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Table,
  Button,
  List,
  Badge,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircleInfo,
  faPenToSquare,
  faMobile,
  faLocationDot,
  faClock,
  faDownload,
  faWallet,
  faUser,
  faProjectDiagram,
  faMicrochip,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faIdCard } from "@fortawesome/free-regular-svg-icons";
import { useSelector } from "react-redux";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { CSSTransition } from "react-transition-group";
import Swal from "sweetalert2";
import moment from "moment";
import SwitchButton from "../Contacts/SwitchButton";

import styles from "./SubscriptionPage.module.scss";
import Loader from "../../shade/Loaders/Loaders";
import SyncButton from "../../common/syncButton";
import * as paymentGatwayService from "../../services/PaymentGatwayService";
import * as subscriptionService from "../../services/SubscriptionService";
import SidebarToggleContext from "../../contexts/sidebarToggleContext";
import RightSidebar from "../../common/rightSidebar";
//import StripeIndex from "../PaymentGateway/StripeIndex";
import { useQuery } from "@tanstack/react-query";
import RefetchGlobalDataContext from "../../contexts/refetchGlobalData";
import { sweetalert, toast } from "../../common/alert";
import BillingInformation from "./BillingInformation";
import { useNavigate, useLocation } from "react-router-dom";
import { freeActivation } from "../../services/SubscriptionService";
import { planCodes } from "../../common/commonfns";

import { HiOutlineUserGroup } from "react-icons/hi2";
import { BsBuildings } from "react-icons/bs";
import TooltipReference from "../../common/tooltip";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { resetState } from '../../redux/actions/action';
import { useDispatch } from 'react-redux';
import { clearWindowCache } from '../../common/commonfns';
import { Link } from 'react-router-dom';
import { auth } from "../../Firebase/firebase";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import ZohoSub from "./zohoSubscription";
import { socket } from "../../common/socketconfig";
import BizTable from "../../common/BizTable";

export function formatCurrency(amount: number, currency = "USD"): string {
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
  return formatted.replace(/\s+/g, '');
}
interface ISubscriptionPlan {
  expireAt: string;
  createdAt: string;
  description: string;
  name: string;
  members: number;
  duration: number;
  subscriptions: {
    id: number;
    createdAt: string;
    updatedAt: string;
    amount: number;
    period: "WEEK" | "MONTH";
    duration: number;
  };
}
// Returns the end date string of a given subscription plan
export function subscriptionPlanEnd(subscription: any): string {
  return moment(subscription.expireAt)
    .add(
      subscription.subscriptions.duration,
      subscription.subscriptions.period === "WEEK" ? "weeks" : "months"
    )
    .format("DD MMM, YYYY");
}

const SubscriptionPage: React.FC = () => {
  const MySwal = withReactContent(Swal);
  const isFreeTrail = useSelector(
    (state: any) => state.cartreducer.business?.business?.freeTrial
  )
    ? true
    : false;
  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.id
  );
  const currentPlan = useSelector(
    (state: any) => state.cartreducer.business?.business?.plan
  );

  const memberUid = useSelector((state: any) => state.cartreducer.business.uid);
  const [planTab, setPlanTab] = useState("2");
  const [isYearly, setIsYearly] = useState(false);
  const [isBasicYearly, setIsBasicYearly] = useState(false);
  const [showBillingInfo, setShowBillingInfo] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [addonsInfo, setAddonsInfo] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const active = searchParams.get("active");
  const [checkoutData, setCheckoutData] = useState<null | {
    amount: number;
    currency: "INR" | "USD";
  }>(null);
  const selectedPlanRef = useRef(null);
  const { isSidebarToggled } = useContext(SidebarToggleContext);
  const [stripeClientKey, setStripeClientKey] = useState("");
  const [showCheckout, setShowCheckout] = useState(false);
  const [subscription, setSubscription] = useState<null | ISubscriptionPlan>(
    null
  );
  const isDisabled = true;
  const expireAtString = currentPlan?.expireAt;
  const expireAtDate = new Date(expireAtString);
  const navigate = useNavigate();
  const zohoSubscriptions = useSelector(
    (state: any) => state.cartreducer.subscriptionData
  );
  const [zohoPlan, setZohoPlan] = useState<any>(null);
  const [activePlan, setActivePlan] = useState<any>(null);
  const [basicPlan, setBasicPlan] = useState<any>(null);
  const [selectedPlanMode, setSelectedPlanMode] = useState('monthly'); // Default to monthly
  const [addons, setAddons] = useState([]);
  const planSocket = socket;
  const [currency, setCurrency] = useState<string | null>(null);

  const handleSelectPlan = (plan: any) => {
    setSelectedPlanMode(plan);
  };

  const [selectedIntervals, setSelectedIntervals] = useState<{
    [planCode: string]: string;
  }>({});
  const business = useSelector(
    (state: any) => state.cartreducer.business?.business
  );
  const pendingRowRef = useRef<HTMLHeadingElement>(null);
  const dispatch = useDispatch();
  const trial: boolean =
    business?.business?.freeTrial ||
      (!business?.business?.freeTrial && !business?.business?.plan?.code)
      ? true
      : false;

  const handleIntervalChange = (planCode: string, interval: string) => {
    setSelectedIntervals((prevIntervals) => ({
      ...prevIntervals,
      [planCode]: interval,
    }));
  };

  // Format the date to DD MMM YYYY format
  const formattedDate = expireAtDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  const [subscriptionHistoryQuery, setSubscriptionHistoryQuery] = useState<any>([]);
  const [count, setCount] = useState(0)
  const fetchData = useCallback(async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {

    const payload = {
      businessId: businessId,
      paymentType: 0,
      limit: pageSize,
      page: currentPage
    }
    const response = await subscriptionService.getSubscriptionHistory(
      payload
    );
    const { paymentHistory, counts } = response
    if (response) {
      setSubscriptionHistoryQuery(paymentHistory)
      setCount(counts)
      return { data: paymentHistory, totalPages: 100 }
    }
    else {
      return { data: [], totalPages: 0 }
    }
  }, [businessId]);

  // const subscriptionHistoryQuery = useQuery<any[], Error>({
  //   queryKey: ["subscription-history", businessId],
  //   queryFn: async () => {
  //     try {
  //       if (!businessId) {
  //         return new Promise(() => { });
  //       }
  //       const payload = {
  //         businessId: businessId,
  //         paymentType: 0,
  //         limit: 100,
  //         page: 0
  //       }
  //       const response = await subscriptionService.getSubscriptionHistory(
  //         payload
  //       );
  //       return response?.paymentHistory;
  //     } catch (error) {
  //       console.error(error);
  //       return [];
  //     }
  //   },
  // });


  const columns = [
    {
      Header: (
        <input
          type="checkbox"
          hidden={true}
        // checked={this.state.allChecked}
        // onChange={this.toggleAllCheckboxes}
        />
      ),
      accessor: "checkbox",
      // Cell: this.checkboxCellRenderer,
      sticky: "left",
    },
    {
      Header: "Created On",
      accessor: "createdAt",
      Cell: ({ cell, row }: any) => moment(cell.value).format("DD MMM, YYYY"),
      sticky: "left",
    },
    // {
    //   Header: "Mobile Number",
    //   accessor: "mobileNumber",
    // },
    {
      Header: "Amount",
      accessor: "amount",
      grid: "third-column",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell, row }: any) => statusData(cell.value, row.original),
      grid: "col-md-1",
    },
    {
      Header: "Action",
      accessor: "edit",
      Cell: ({ cell, row }: any) => downloadInvoice(cell.value, row.original),
      grid: "col-md-1",
    },
  ] as any;


  useEffect(() => {
    if (active) {
      if (moment(expireAtString).diff(moment(), "days") < 0) setPlanTab("2");
      else setPlanTab("1");
    }
    //setPlanTab('1');
  }, [active]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch(`https://ipapi.co/json/`);
        if (!response.ok) {
          throw new Error('Failed to fetch country information');
        }
        const data = await response.json();
        if (data)
          setCurrency(data.currency);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCountry();
  }, []);

  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = "#F6F6F6";
    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);
  useEffect(() => {
    if (currentPlan) {
      setSubscription(currentPlan);
    }
  }, [currentPlan]);

  useEffect(() => {
    getAllAddons();
  }, [])

  const getAllAddons = async () => {
    try {
      const addon = await subscriptionService.getAddons();
      if (addon)
        setAddons(addon);
    }
    catch (err) {
      setAddons([]);
    }
  }

  // Pending Transaction Alert
  const [pendingAlertDisplayed, setPendingAlertDisplayed] = useState(false);
  const isTransactionPending = useMemo(() => {
    if (
      subscriptionHistoryQuery.isFetched &&
      !subscriptionHistoryQuery.isError &&
      !subscriptionHistoryQuery.isError
    ) {
      return (subscriptionHistoryQuery.data as any).find(
        (transition: any) => transition.status === "PENDING"
      );
    }
    return false;
  }, [subscriptionHistoryQuery]);
  // useEffect(() => {
  //   if (!pendingAlertDisplayed && isTransactionPending) {
  //     const pendingTransation = (subscriptionHistoryQuery.data as any).find((transition: any) => transition.status === "PENDING");
  //     setPendingAlertDisplayed(true);
  //     MySwal.fire({
  //       title: (
  //         <PendingTransation
  //           amount={pendingTransation.amount / 100}
  //           createdAt={pendingTransation.createdAt}
  //           onPay={() => {
  //             MySwal.close();
  //             payNowHandler(pendingTransation.paymentId, pendingTransation.amount);
  //           }}
  //         />
  //       ),
  //       showConfirmButton: false,
  //       showCloseButton: true
  //     });

  //   }
  // }, [isTransactionPending])

  useEffect(() => {
    let activePlan: any = null;
    if (zohoSubscriptions.length) {
      if (isFreeTrail) {
        setActivePlan(business.freeTrial);
      } else if (!isFreeTrail && !business?.plan?.code) {
        setActivePlan(currentPlan);
      } else {
        [activePlan] = zohoSubscriptions.filter(
          (sub: any) => sub.plan_code === currentPlan?.code
        );
        setActivePlan(activePlan);
      }
    }
  }, [zohoSubscriptions]);

  const { refetch } = useContext(RefetchGlobalDataContext);

  const checkForPaymentHandler = async () => {
    const paymentIntent = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    const status = new URLSearchParams(window.location.search).get(
      "redirect_status"
    );
    if (!paymentIntent && !status) {
      return;
    }
    const data = {
      payment_intent: paymentIntent,
    };

    if (data) {
      const response = await paymentGatwayService.retrievePaymentIntent(data);
      if (response) {
        setTimeout(() => {
          refetch();
          fetchData(0, 20, '', 0)
          // subscriptionHistoryQuery.refetch();
        }, 1000);
        Swal.fire({
          icon: "success",
          title: "Payment success",
          html: "Your subscription is activated.<br/>You can now proceed to the dashboard.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`${process.env.PUBLIC_URL}/dashboard`);
            window.location.reload();
          }
        });
      }
    }
  };

  useEffect(() => {
    checkForPaymentHandler();
  }, []);
  useEffect(() => {
    if (!isFreeTrail && selectedPlan !== null) {
      setSelectedPlan(null);
      setAddonsInfo(false);
    }
  }, [planTab]);
  const subscriptionPlansQuery = useQuery({
    queryKey: ["subscription-plans"],
    queryFn: subscriptionService.getSubscriptionPlans,
  });
  const billingInfoQuery = useQuery({
    queryKey: ["billing-info"],
    queryFn: () => subscriptionService.getBillingDetail(businessId),
  });

  const onSubscribeHandler = async () => {
    // const clientKey = await paymentGatwayService.createPaymentStatus({
    //   businessUid: businessUid,
    //   memberUid: memberUid,
    //   amount: amount,
    //   currency,
    // });
    // if (clientKey && subscriptionPlansQuery.data) {
    //   setStripeClientKey(clientKey);
    //   setCheckoutData({
    //     currency: (subscriptionPlansQuery.data.find(plan => plan.id === selectedPlan)?.currency as "USD" | "INR"),
    //     amount: (subscriptionPlansQuery.data.find(plan => plan.id === selectedPlan)?.amount as number) * (isYearly ? 10 : 1)
    //   });
    //   setShowCheckout(true);
    // }
    const queryString = selectedPlan?.custom_fields[0]?.value;
    // const parts = queryString.split('&');
    // const encodedParts = parts.map((part: any) => {
    //   const keyValue = part.split('=');
    //   const encodedKey = encodeURIComponent(keyValue[0]);
    //   const encodedValue = encodeURIComponent(keyValue[1]);
    //   return `${encodedKey}=${encodedValue}`;
    // });
    // const encodedQueryString = encodedParts.join('&');
    window.open(
      `${selectedPlan.url}?${queryString}&cf_business_id=${businessUid}`,
      "_blank"
    );
    //window.open(`${selectedPlan.url}?cf_business_id=${businessUid}`, "_blank");
  };

  const payNowHandler = async (paymentId: string, amount: number) => {
    const response = await paymentGatwayService.retrievePaymentIntent({
      payment_intent: paymentId,
    });
    if (response) {
      const { client_secret } = response;
      setStripeClientKey(client_secret);
      setCheckoutData({
        currency: "INR", // Todo: Make this dynamic for USD supportation
        amount: amount,
      });
      setShowCheckout(true);
    }
  };

  const saveBillingDetails = () => {
    if (selectedPlan)
      setAddonsInfo(true);
    else
      setAddonsInfo(false);
  }

  const cancelPaymentHandler = (paymentId: number) => {
    sweetalert(
      "warning",
      "Are you sure you want to delete this transaction?",
      async () => {
        if (paymentId) {
          try {
            await paymentGatwayService.cancelTransactionPending(paymentId);
            toast("success", "Transaction deleted successfully");
            // subscriptionHistoryQuery.refetch();
            fetchData(0, 20, '', 0)
          } catch (error) {
            toast("error", "Transaction failed to cancel");
          }
        }
      },
      () => { }
    );
  };

  useEffect(() => {
    // Scroll to the pending row when the component mounts
    if (pendingRowRef.current) {
      pendingRowRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [subscriptionHistoryQuery]);

  let subscriptionPlans = <Loader />;

  if (zohoSubscriptions.length === undefined || null) {
    subscriptionPlans = (
      <Alert color="danger">Someting went wrong. Try again later.</Alert>
    );
  }

  if (zohoSubscriptions.length) {
    const desiredOrder: string[] = [
      planCodes("freeMonthly"),
      planCodes("freeYearly"),
      planCodes("proMonthly"),
      planCodes("proYearly"),
      planCodes("ultimateMonthly"),
      planCodes("ultimateYearly"),
    ];
    const replacementCode: Record<string, string> = {
      [planCodes("proMonthly")]: planCodes("proYearly"),
      [planCodes("ultimateMonthly")]: planCodes("ultimateYearly"),
      [planCodes("freeMonthly")]: planCodes("freeYearly"),
    };

    const replacementCodeQuarterly: Record<string, string> = {
      [planCodes("proMonthly")]: planCodes("proQuarterly"),
      [planCodes("ultimateMonthly")]: planCodes("ultimateQuarterly"),
      [planCodes("freeMonthly")]: planCodes("freeQuarterly"),
    };

    const filteredSubscriptions = zohoSubscriptions.filter((plan: any) =>
      [
        planCodes("freeMonthly"),
        planCodes("proMonthly"),
        planCodes("ultimateMonthly"),
      ].includes(plan.plan_code)
    );

    const showUpgradeOrDowngradeText = (plancode: string): string => {
      if ((activePlan?.plan_code === planCodes('ultimateQuarterly') || activePlan?.plan_code === planCodes('ultimateMonthly') || activePlan?.plan_code === planCodes('ultimateYearly'))
        && (plancode === planCodes('proQuarterly') || plancode === planCodes('proMonthly') || plancode === planCodes('proYearly')))
        return 'Downgrade';
      else if ((activePlan?.plan_code === planCodes('ultimateQuarterly') || activePlan?.plan_code === planCodes('ultimateMonthly') || activePlan?.plan_code === planCodes('ultimateYearly'))
        && (plancode === planCodes('freeQuarterly') || plancode === planCodes('freeYearly')))
        return 'Downgrade';
      else if ((activePlan?.plan_code === planCodes('proQuarterly') || activePlan?.plan_code === planCodes('proMonthly') || activePlan?.plan_code === planCodes('proYearly'))
        && (plancode === planCodes('freeQuarterly') || plancode === planCodes('freeYearly')))
        return 'Downgrade';
      else
        return 'Upgrade';
    }

    const FeatureComponent = ({ adjustedPlan }: any) => {
      let content;
      const description = adjustedPlan.store_markup_description;

      try {
        // Try to parse the description as JSON
        const parsed = JSON.parse(description);
        if (parsed && parsed.features) {
          // Render as list if JSON parsing is successful and 'features' key exists
          content = (
            <List className="mb-0 mt-0">
              {parsed?.features?.map((feature: any, index: any) => (
                <li key={index}>{feature.name}</li>
              ))}
            </List>
          );
        }
      } catch (error) {
        // Fallback if JSON parsing fails, treat as plain text
        const parts = description.split("|");
        if (parts.length > 1) {
          // Render as list if there are multiple parts split by "|"
          content = (
            <List className="mb-0 mt-0">
              {parts.map((point: any, index: any) => (
                <li key={index}>{point.trim()}</li> // Trim to clean up any leading/trailing whitespace
              ))}
            </List>
          );
        } else {
          // Render as simple text if there's only one part
          content = (
            <CardSubtitle className="mt-2">{description}</CardSubtitle>
          );
        }
      }

      return <div>{content}</div>;
    };
    subscriptionPlans = (
      <>
        {filteredSubscriptions
          .sort(
            (a: { plan_code: string }, b: { plan_code: string }) =>
              desiredOrder.indexOf(a.plan_code) -
              desiredOrder.indexOf(b.plan_code)
          )
          .map((plan: any, index: number) => {

            const adjustedPlanCode =
              selectedPlanMode === "yearly" && replacementCode[plan.plan_code]
                ? replacementCode[plan.plan_code]
                : selectedPlanMode === "quarterly" && replacementCodeQuarterly[plan.plan_code]
                  ? replacementCodeQuarterly[plan.plan_code]
                  : plan.plan_code;

            const adjustedPlan =
              (selectedPlanMode === "yearly" && replacementCode[plan.plan_code]) ||
                (selectedPlanMode === "quarterly" && replacementCodeQuarterly[plan.plan_code])
                ? zohoSubscriptions.find((p: any) => p.plan_code === adjustedPlanCode)
                : plan;


            const cssClasses = [styles.planCard];
            if (selectedPlan?.plan_code === adjustedPlan.plan_code)
              cssClasses.push(
                // selectedPlan?.plan_code === planCodes("proMonthly") ||
                //   selectedPlan?.plan_code === planCodes("proYearly")
                //   ? styles.selected
                //   :
                styles.ultimatePlan
              );
            const isCurrentlyActive = (adjustedPlan.plan_code === currentPlan?.code && moment(expireAtString).diff(moment(), "days") < 0) ?
              isTransactionPending ? true : false :
              adjustedPlan.plan_code === currentPlan?.code;
            let buttonBackground = filteredSubscriptions.map((el: any) =>
              el.plan_code === "01010" ? "#458d80" : "#458d80"
            );
            let cardIcon = filteredSubscriptions.map((el: any) =>
              el.plan_code === "01010" ? (
                <HiOutlineUserGroup size={40} color="#9c9ea2" />
              ) : (
                <BsBuildings size={40} color="#9c9ea2" />
              )
            );

            let description = filteredSubscriptions.map(
              (el: any) => el.description
            );

            const internationalPrice = adjustedPlan.pricebooks.
              filter((pr: any) => pr.pricebook_name === 'International M USD');

            const indianPrice = adjustedPlan.pricebooks.filter((pr: any) => pr.pricebook_name === 'Bizmagnets M INR');

            const indianStrikedPrice = adjustedPlan.pricebooks.filter((pr: any) => pr.pricebook_name === 'Strike Out INR');

            const internationalStrikedPrice = adjustedPlan.pricebooks.filter((pr: any) => pr.pricebook_name === 'Strike Out USD');

            return (
              <Col
                sm={index === 0 && selectedPlanMode === 'monthly' ? '2' : '4'}
                key={adjustedPlan.plan_code}
                className="d-flex justify-content-center m-0"
              >
                {/* {selectedPlanMode === 'monthly' && adjustedPlan.plan_code} */}
                <Card
                  className={cssClasses.join(" ")}
                  onClick={() => {
                    if (!isCurrentlyActive) {
                      if (selectedPlan?.plan_code === adjustedPlan.plan_code) {
                        setSelectedPlan(null);
                      } else {
                        setSelectedPlan(adjustedPlan);
                      }
                      if (billingInfoQuery.data?.name && billingInfoQuery.data?.address && billingInfoQuery.data?.phoneNumber &&
                        billingInfoQuery.data?.email
                      ) {
                        setAddonsInfo(true);
                        setShowBillingInfo(false);
                      }
                      else {
                        //toast('warning', 'Fill billing details first to pick a plan');
                        setShowBillingInfo(true);
                        setAddonsInfo(false);
                      }
                    }
                  }}
                  style={{ display: index === 0 && selectedPlanMode === 'monthly' ? 'none ' : '' }}
                >
                  <div
                    style={{
                      background: buttonBackground[index],
                      height: "4px",
                      width: "100%",
                      borderRadius: "3px 3px 0 0",
                    }}
                  ></div>
                  <CardBody>
                    <CardSubtitle className={!indianStrikedPrice?.length ? styles.notStrikedSubtitile : ''}>
                      <div>
                        <div>{cardIcon[index]}</div>
                        <b>{adjustedPlan.name}</b>
                      </div>
                    </CardSubtitle>
                    {indianStrikedPrice?.length ?
                      <div className={`${styles.strikedPrices} fontchange`}>{formatCurrency(currency === 'INR' && (billingInfoQuery.data?.country?.toLowerCase() === 'india' || !billingInfoQuery.data?.country) ?
                        indianStrikedPrice[0]?.pricebook_rate : internationalStrikedPrice[0]?.pricebook_rate,
                        currency === "INR" && (billingInfoQuery.data?.country?.toLowerCase() === 'india' || !billingInfoQuery.data?.country) ? "INR" : "USD")}</div> : ''
                    }
                    <CardTitle tag="h5" className={`fontchange ${!indianStrikedPrice?.length ? styles.upgradeBtn : ''}`}>
                      {formatCurrency(currency === 'INR' && (billingInfoQuery.data?.country?.toLowerCase() === 'india' || !billingInfoQuery.data?.country) ?
                        indianPrice[0]?.pricebook_rate : internationalPrice[0]?.pricebook_rate,
                        currency === "INR" && (billingInfoQuery.data?.country?.toLowerCase() === 'india' || !billingInfoQuery.data?.country) ? "INR" : "USD")}
                    </CardTitle>
                    {/* {adjustedPlan.recurring_price > 0 ? (
                      <CardSubtitle>
                        Per {adjustedPlan.interval_unit === 'years' ? "Year" : "Month"}
                      </CardSubtitle>
                    ) : null} */}
                    <div className={styles.perMonthGST}>per month
                      {currency === 'INR' && (billingInfoQuery.data?.country?.toLowerCase() === 'india' || !billingInfoQuery.data?.country) &&
                        <span> + GST</span>}
                    </div>
                    <Button
                      // color="primary"
                      style={{
                        backgroundColor: buttonBackground[index],
                        color: "white",
                        border: "none",
                      }}
                      className={[styles.cta, "my-2"].join(" ")}
                      disabled={isCurrentlyActive}
                      outline={
                        selectedPlan?.plan_code !== adjustedPlan.plan_code
                      }
                    >
                      {isCurrentlyActive ? (
                        <div style={{ color: "white" }}>
                          <FontAwesomeIcon icon={faCheck} className="mr-2" />
                          Currently Active
                        </div>
                      ) : adjustedPlan.plan_code === selectedPlan?.plan_code ? (
                        <>
                          <FontAwesomeIcon icon={faCheck} className="mr-2" />
                          Selected
                        </>
                      ) : (<>
                        {moment(expireAtString).diff(moment(), "days") < 0 ? 'Upgrade' : showUpgradeOrDowngradeText(adjustedPlan.plan_code)}
                      </>
                      )}
                    </Button>

                    <h5 className="d-flex justify-content-center align-items-center">
                      {description[index]}
                    </h5>
                    {/* {adjustedPlan.store_markup_description.split("|").length >
                      1 ? (
                      <List className="mb-0 mt-0">
                        {adjustedPlan.store_markup_description
                          .split("|")
                          .map((point: any) => {
                            return <li key={point}>{point}</li>;
                          })}
                      </List>
                    ) : (
                      <CardSubtitle className="mt-2">
                        {adjustedPlan.store_markup_description}
                      </CardSubtitle>
                    )} */}
                    <FeatureComponent adjustedPlan={adjustedPlan} />
                  </CardBody>
                  <div
                    style={{
                      background: buttonBackground[index],
                      height: "4px",
                      width: "100%",
                      borderRadius: "0 0 3px 3px",
                    }}
                  ></div>
                </Card>
              </Col>
            );
          })}
      </>
    );
  }
  const statusData = (cell: any, row: any) => {
    switch (cell) {
      case "PENDING":
        return (
          <>
            <h5 ref={pendingRowRef}>
              <Badge>Pending</Badge>
            </h5>
          </>
        );
      case "PAID":
        return (
          <h5>
            <Badge color="success">Paid</Badge>
          </h5>
        );
      default:
        return (
          <h5>
            <Badge>{cell}</Badge>
          </h5>
        );
    }
  }

  const downloadInvoice = (cell: any, row: any) => {
    const isPaymentPending = row.status === "PENDING" ? true : false;

    return (
      <div>
        {row?.metadata?.invoiceUrl ? (
          <>
            {/* <Button className={`btn btn-sm ${styles.payNowInvoice}`} onClick={() => {
              window.open(row.metadata.invoiceUrl, "_blank");
            }}>Pay Now</Button> */}
            {isPaymentPending ?
              <Button className={`btn btn-sm ${styles.payNowInvoice}`} onClick={() => {
                window.open(row.metadata.invoiceUrl, "_blank");
              }}>Renew</Button> :
              <TooltipReference
                content={isPaymentPending ? "Renew" : "Download Invoice"}
                tooltipId={`downloadInvoice-${row.id}`}
                placement="bottom"
              >
                <FontAwesomeIcon
                  className="invoiceDownload"
                  icon={faDownload}
                  onClick={() => {
                    window.open(row.metadata.invoiceUrl.replace(
                      "/secure",
                      "/api/v1/clientinvoices/secure"
                    ) + "&accept=pdf", "_blank");
                  }}
                /></TooltipReference>}
          </>
        ) : (
          "-"
        )}
      </div>
    );
  };

  let subscriptionInvoice = <Loader />;

  if (
    !subscriptionHistoryQuery.isError &&
    !subscriptionHistoryQuery.isLoading
  ) {
    subscriptionInvoice = (
      <div className="mb-4">
        {/* <BootstrapTable
          data={subscriptionHistoryQuery.data as any}
          pagination
          version="4"
          striped
          hover
          containerStyle={{ textAlign: "left" }}
          options={
            {
              headerAlign: "center",
              noDataText: "No records found",
              sortIndicator: true,
              hidePageListOnlyOnePage: true,
              clearSearch: false,
              alwaysShowAllBtns: true,
              withFirstAndLast: true,
              sizePerPage: 10,
              paginationPosition: "bottom",
            } as any
          }
        >
          <TableHeaderColumn dataField="paymentId" isKey hidden>
            Invoice No.
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="createdAt"
            dataFormat={(createdAt) => moment(createdAt).format("DD MMM, YYYY")}
            width="70"
          >
            Created On
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="amount"
            dataFormat={(amount, row) => (
              <p className="fontchange ml-3">{formatCurrency(amount, row.currency)}</p>
            )}
            width="70"
          >
            Amount
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="status"
            dataAlign="center"
            width="70"
            dataFormat={(status) => {
              switch (status) {
                case "PENDING":
                  return (
                    <>
                      <h5 ref={pendingRowRef}>
                        <Badge>Pending</Badge>
                      </h5>
                    </>
                  );
                case "PAID":
                  return (
                    <h5>
                      <Badge color="success">Paid</Badge>
                    </h5>
                  );
                default:
                  return (
                    <h5>
                      <Badge>{status}</Badge>
                    </h5>
                  );
              }
            }}
          >
            Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="edit"
            dataFormat={downloadInvoice}
            dataAlign="center"
            width="70"
          >
            Actions
          </TableHeaderColumn>
        </BootstrapTable>  */}
        <BizTable
          columns={columns}
          fetchData={fetchData}
          counts={count}
          refetchData={subscriptionHistoryQuery.data}
          tableName='SUBSCRIPTION'
        />
      </div>
    );
  }

  const contactSupportHandler = () => {
    Swal.fire({
      icon: "info",
      title: "To upgrade your plan, Please contact support",
    });
    setSelectedPlan(null);
  };

  let planDetailsTable = <></>;

  if (
    (!subscriptionPlansQuery.isError &&
      !subscriptionPlansQuery.isLoading &&
      subscription &&
      isFreeTrail) ||
    (!isFreeTrail && !currentPlan?.code)
  ) {
    // const proPlan = (subscriptionPlansQuery.data as any).find(
    //   (plan: any) => plan.id === 2
    // );

    // // If for some reason, plan with ID 2 is not found fallback hardcorded prices
    // let monthlyPrice = (proPlan ? proPlan.amount : 2500) / 100;
    // let yearlyPrice = (proPlan ? proPlan.amount * 10 : 25000) / 100;

    planDetailsTable = (
      <div className={styles.subscriptionPlanDetails}>
        <h2>
          Get started at no cost, <br /> then pay as you go.
        </h2>
        <div className={styles.switch}>
          <div className={styles.switch_label}>Monthly</div>
          <SwitchButton
            whatsappOptState={() => setIsYearly(selectedPlanMode !== 'yearly')}
            checked={selectedPlanMode === 'yearly'}
            menuName="subscription"
          />
          <div className={styles.switch_label}>Yearly</div>
        </div>
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>Free Plan</th>
              <th>Premium Plan</th>
              <th>Enterprise Plan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Price</td>
              <td>{formatCurrency(0, "INR")}</td>
              <td>
                {formatCurrency(zohoPlan?.price_brackets[0].price, "INR")}
                <small>/{selectedPlanMode === 'yearly' ? "year" : "month"}</small>
              </td>
              <td>Custom</td>
            </tr>
            <tr>
              <td>Members</td>
              <td>1</td>
              <td>
                6+
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className={styles.tooltip}>
                      Additional {formatCurrency(12, "USD")}
                      <small>/month</small> per member
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className={["ml-2", styles.infoButton].join(" ")}
                  />
                </OverlayTrigger>
              </td>
              <td>Custom</td>
            </tr>
            <tr>
              <td>WhatsApp Channels</td>
              <td>1</td>
              <td>2</td>
              <td>Custom</td>
            </tr>
            <tr>
              <td>ChatBot</td>
              <td>1</td>
              <td>6</td>
              <td>Custom</td>
            </tr>
            <tr>
              <td>Campaigns and Scheduling</td>
              <td>❌</td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>Campaign Analytics</td>
              <td>❌</td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>Quick Replies</td>
              <td>✔️</td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>Chat Automation</td>
              <td>✔️</td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>WhatsApp Ad Insights (CTWA)</td>
              <td>❌</td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>Catalogues</td>
              <td>❌</td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>Business app Integration</td>
              <td>❌</td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>Greentick Verfication & Assistance</td>
              <td>❌</td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>ChatGPT Customization</td>
              <td>✔️</td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td>Support (Email & WhatsApp)</td>
              <td>❌</td>
              <td>✔️</td>
              <td>✔️</td>
            </tr>
            <tr>
              <td></td>
              <td>
                {" "}
                <FontAwesomeIcon icon={faCheck} className="mr-2" /> Currently
                Active{" "}
              </td>
              <td>
                <Button
                  color="primary"
                  className="signUpBtn"
                  size="sm"
                  onClick={() => {
                    setIsYearly(
                      zohoPlan.interval_unit === "months" ? false : true
                    );
                    setPlanTab("2");
                  }}
                >
                  Upgrade
                </Button>
              </td>
              <td>
                <Button
                  color="primary"
                  className="signUpBtn"
                  size="sm"
                  onClick={contactSupportHandler}
                >
                  Upgrade
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
  const currentUrl = window.location.href;

  let billingInfoCard = <Loader />;

  if (!billingInfoQuery.isLoading && !billingInfoQuery.isError) {
    billingInfoCard = (
      <Card className={styles.billingInfoCard}>
        <CardBody>
          <Button color="link" className={styles.editButton} onClick={() => setShowBillingInfo(true)}>
            <FontAwesomeIcon
              icon={faPenToSquare}
              className="mr-2"
            />
          </Button>
          <CardTitle tag="h5">
            {billingInfoQuery.data.name || (
              <>
                BizMagnets Billing{" "}
                <small className={styles.notProvidedColor}>
                  (Not Provided)
                </small>
              </>
            )}
          </CardTitle>
          <Table borderless className="mt-3">
            <tbody>
              <tr>
                <th scope="row">
                  <FontAwesomeIcon
                    inverse
                    icon={faMobile}
                    className={styles.infoIcon}
                  />
                  Mobile
                </th>
                <td>
                  {billingInfoQuery.data.phoneNumber || (
                    <small className={styles.notProvidedColor}>
                      (Not Provided)
                    </small>
                  )}
                </td>
                <td></td>
                <th scope="row">
                  <FontAwesomeIcon
                    inverse
                    icon={faLocationDot}
                    className={styles.infoIcon}
                  />
                  Address
                </th>
                <td>
                  {billingInfoQuery.data.address || (
                    <small className={styles.notProvidedColor}>
                      (Not Provided)
                    </small>
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <FontAwesomeIcon
                    inverse
                    icon={faEnvelope}
                    className={styles.infoIcon}
                  />
                  Email
                </th>
                <td>
                  {billingInfoQuery.data.email || (
                    <small className={styles.notProvidedColor}>
                      (Not Provided)
                    </small>
                  )}
                </td>
                <td></td>
                <th scope="row">
                  <FontAwesomeIcon
                    inverse
                    icon={faIdCard}
                    className={styles.infoIcon}
                  />
                  Tax ID
                </th>
                <td>
                  {billingInfoQuery.data.taxID || (
                    <small className={styles.notProvidedColor}>
                      (Not Provided)
                    </small>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  } else if (billingInfoQuery.isError)
    billingInfoCard = (
      <Alert variant="danger">Failed to fetch billing Information</Alert>
    );

  const getAddonCount = (addoncode: string) => {
    const addoncount = currentPlan?.data?.find((a: any) => a.addon_code === addoncode);
    return addoncount?.quantity;
  }


  const planAddons = (
    <>
      {activePlan?.addons?.map((addon: any) => {
        switch (addon.addon_code) {
          case '03':
            return (
              <TooltipReference content={addon.name} tooltipId={'addon' + addon.addon_code} placement="bottom">
                <span className={styles.addonsDesign}> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <path d="M15.0508 11.5032C16.1277 11.5032 17 12.3132 17 13.3131V14.0021H16.9946C16.958 14.7821 16.7275 15.7591 15.7852 16.557C14.7557 17.43 12.9777 18 10 18C7.02123 18 5.24431 17.43 4.21477 16.557C3.27246 15.7591 3.042 14.7811 3.00538 14.0021H3V13.3121C3 12.3132 3.87231 11.5032 4.94923 11.5032H15.0508ZM6.23077 3.00041C5.80234 3.00041 5.39146 3.15844 5.08852 3.43974C4.78558 3.72103 4.61538 4.10255 4.61538 4.50037V8.50026C4.61538 8.89807 4.78558 9.27959 5.08852 9.56089C5.39146 9.84219 5.80234 10.0002 6.23077 10.0002H13.7692C14.1977 10.0002 14.6085 9.84219 14.9115 9.56089C15.2144 9.27959 15.3846 8.89807 15.3846 8.50026V4.50037C15.3846 4.10255 15.2144 3.72103 14.9115 3.43974C14.6085 3.15844 14.1977 3.00041 13.7692 3.00041H10.5385V2.50042C10.5415 2.43401 10.5297 2.36773 10.5037 2.30579C10.4777 2.24385 10.4381 2.18759 10.3875 2.14059C10.3369 2.09358 10.2763 2.05685 10.2096 2.03273C10.1429 2.0086 10.0715 1.99761 10 2.00044C9.70277 2.00044 9.46154 2.23043 9.46154 2.50042V3.00041H6.23077ZM6.76923 6.50031C6.76923 6.2351 6.88269 5.98076 7.08465 5.79323C7.28662 5.60569 7.56054 5.50034 7.84615 5.50034C8.13177 5.50034 8.40569 5.60569 8.60765 5.79323C8.80962 5.98076 8.92308 6.2351 8.92308 6.50031C8.92308 6.76552 8.80962 7.01987 8.60765 7.2074C8.40569 7.39493 8.13177 7.50029 7.84615 7.50029C7.56054 7.50029 7.28662 7.39493 7.08465 7.2074C6.88269 7.01987 6.76923 6.76552 6.76923 6.50031ZM11.0769 6.50031C11.0769 6.2351 11.1904 5.98076 11.3923 5.79323C11.5943 5.60569 11.8682 5.50034 12.1538 5.50034C12.4395 5.50034 12.7134 5.60569 12.9153 5.79323C13.1173 5.98076 13.2308 6.2351 13.2308 6.50031C13.2308 6.76552 13.1173 7.01987 12.9153 7.2074C12.7134 7.39493 12.4395 7.50029 12.1538 7.50029C11.8682 7.50029 11.5943 7.39493 11.3923 7.2074C11.1904 7.01987 11.0769 6.76552 11.0769 6.50031Z"
                    fill="#0085ff" />
                </svg>
                  {getAddonCount(addon.addon_code) && (
                    <span className={styles.addonCount}>{parseInt(getAddonCount(addon.addon_code)) < 100 ?
                      getAddonCount(addon.addon_code) : '99+'}</span>
                  )}
                </span>
              </TooltipReference>
            )
          case '02':
            return (
              <TooltipReference content={addon.name} tooltipId={'addon' + addon.addon_code} placement="bottom">
                <span className={styles.addonsDesign}><FontAwesomeIcon icon={faProjectDiagram} />
                  {getAddonCount(addon.addon_code) && (
                    <span className={styles.addonCount}>{parseInt(getAddonCount(addon.addon_code)) < 100 ?
                      getAddonCount(addon.addon_code) : '99+'}</span>
                  )}
                </span>
              </TooltipReference>
            )
          case '04':
            return (
              <TooltipReference content={addon.name} tooltipId={'addon' + addon.addon_code} placement="bottom">
                <span className={styles.addonsDesign}><FontAwesomeIcon icon={faUser} />
                  {getAddonCount(addon.addon_code) && (
                    <span className={styles.addonCount}>{parseInt(getAddonCount(addon.addon_code)) < 100 ?
                      getAddonCount(addon.addon_code) : '99+'}</span>
                  )}
                </span>
              </TooltipReference>
            )
          case '05':
            return (
              <TooltipReference content={addon.name} tooltipId={'addon' + addon.addon_code} placement="bottom">
                <span className={styles.addonsDesign}><FontAwesomeIcon icon={faWhatsapp} />
                  {getAddonCount(addon.addon_code) && (
                    <span className={styles.addonCount}>{parseInt(getAddonCount(addon.addon_code)) < 100 ?
                      getAddonCount(addon.addon_code) : '99+'}</span>
                  )}
                </span>
              </TooltipReference>
            )
          case '06':
            return (
              <TooltipReference content={addon.name} tooltipId={'addon' + addon.addon_code} placement="bottom">
                <span className={styles.addonsDesign}><FontAwesomeIcon icon={faMicrochip} />
                  {getAddonCount(addon.addon_code) && (
                    <span className={styles.addonCount}>{parseInt(getAddonCount(addon.addon_code)) < 100 ?
                      getAddonCount(addon.addon_code) : '99+'}</span>
                  )}
                </span>
              </TooltipReference>
            )
          default:
            return null;
        }
      })
      }
    </>
  )

  return (
    <main
      className={`${currentUrl.includes("/subscription?plan=subscribe") &&
        moment(expireAtString).diff(moment(), "days") < 0
        ? `${styles.hidesidebar} ${"col-12"}`
        : ""
        }`}
      style={{ paddingBottom: "5rem" }}
    >
      {/* {isTransactionPending ? (
        <div className="mt-4">
          <Alert variant="warning">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            You cannot create a new transaction while there is an existing pending transaction. To initiate a new transaction, please cancel the pending transaction.
          </Alert>
        </div>
      ) : null} */}
      <Nav tabs className="hideBorder">
        {moment(expireAtString).diff(moment(), "days") < 0 ? (
          ""
        ) : (
          <NavItem>
            <NavLink
              className={planTab === "1" ? "tabActive" : undefined}
              onClick={() => {
                setSelectedPlan(null);
                setAddonsInfo(false);
                setPlanTab("1");
              }}
            >
              {isFreeTrail ? "Trial Plan" : "Active Plan"}
            </NavLink>
          </NavItem>
        )}
        <NavItem>
          <NavLink
            className={planTab === "2" ? "tabActive" : undefined}
            onClick={() => setPlanTab("2")}
          >
            Upgrade Plan
          </NavLink>
        </NavItem>
        {planTab === "2" && (
          <>
            <b className="m-auto col-md-4 h5">Choose Your Plan</b>
          </>
        )}
      </Nav>
      {currentUrl.includes('/subscription?plan=subscribe') && <>
        <Link to='/login' className={styles.logOut} onClick={() => {
          //dispatch(resetState());
          clearWindowCache();
          auth.signOut();
          localStorage.clear();
          if (planSocket)
            planSocket.disconnect();
        }}> <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-3 fa-lg ml-1" />Logout</Link>
      </>}
      <TabContent activeTab={planTab}>
        {moment(expireAtString).diff(moment(), "days") < 0 ? (
          ""
        ) : (
          console.log('activePlanCard', activePlan, subscription),
          <TabPane tabId="1">
            {subscription && activePlan ? (
              <Card className={styles.activePlanCard}>
                <CardBody>
                  {isFreeTrail || (!isFreeTrail && !currentPlan?.code) ? (
                    <div>
                      <CardTitle tag="h5">
                        {!isFreeTrail && !currentPlan?.code
                          ? "Bizmagnets Pro Plan"
                          : "Bizmagnets Trial Plan"}
                      </CardTitle>
                      <CardSubtitle
                        className={`${moment(subscription.expireAt).diff(moment(), "days") <
                          0
                          ? "text-danger"
                          : ""
                          }`}
                      >
                        {moment(subscription.expireAt).diff(moment(), "days") <
                          0
                          ? "Your plan is expired"
                          : (!isFreeTrail && !currentPlan?.code
                            ? "Your plan ends on"
                            : "Demo account ends on") +
                          " - " +
                          formattedDate +
                          " (" +
                          moment(subscription.expireAt).diff(
                            moment(),
                            "days"
                          ) +
                          " days left)"}
                      </CardSubtitle>
                      {!isFreeTrail && !currentPlan?.code ? (
                        <p>
                          We should have Connect your dedicated WhatsApp
                          business number, ensuring approval and enabling the
                          delivery of a top-notch customer experience
                        </p>
                      ) : (
                        <p>
                          Subscribe now to receive a dedicated WhatsApp business
                          number, ensuring approval and enabling the delivery of
                          a top-notch customer experience.
                        </p>
                      )}
                      {/* <Button
                     color="primary"
                     className="signUpBtn"
                     onClick={() => setPlanTab("2")}
                   >
                     Subscribe Now
                   </Button> */}
                    </div>
                  ) : (
                    <div>
                      <CardTitle tag="h5">{activePlan.name}</CardTitle>
                      <div className={styles.planDetails}>
                        <div className={styles.item}>
                          <span className={styles.caption}>
                            Number Of Members
                          </span>
                          <span className={styles.data}>
                            {subscription.members}
                          </span>
                        </div>
                        <div className={styles.item}>
                          <span className={styles.caption}>Plan Mode</span>
                          <span className={styles.data}>
                            {activePlan.interval_unit === "months" && activePlan.interval === 1
                              ? "Monthly"
                              : activePlan.interval_unit === "months" && activePlan.interval === 3
                                ? "Quarterly"
                                : "Yearly"}
                          </span>
                        </div>
                        <div className={styles.item}>
                          <span className={styles.caption}>
                            Plan Start Date
                          </span>
                          <span className={styles.data}>
                            {moment(subscription.createdAt).format(
                              "DD MMM, YYYY"
                            )}
                          </span>
                        </div>
                        <div className={styles.item}>
                          <span className={styles.caption}>Plan End Date</span>
                          <span className={styles.data}>
                            {/* {subscriptionPlanEnd(subscription)} */}

                            {moment(subscription.expireAt).format(
                              "DD MMM, YYYY"
                            )}
                          </span>
                        </div>
                        {currentPlan?.data?.length > 0 ? (
                          <div className={styles.item}>
                            <div className={styles.caption}>Addons</div>
                            <div className="mt-3">{planAddons}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                  <div>
                    <img
                      className={styles.background}
                      src={`${process.env.PUBLIC_URL}/images/subscription-background.svg`}
                      alt=""
                    />
                  </div>
                </CardBody>
              </Card>
            ) : (
              <Loader />
            )}
            {(isFreeTrail || (!isFreeTrail && !currentPlan?.code)) && (
              <div className="pb-4 pt-2">
                <div className={styles.trialPlan}>
                  <div>
                    Unlock the full potential of our application by exploring
                    the Upgrade Plans tab and unleashing a myriad of powerful
                    features beyond the limitations of the trial plan.
                  </div>
                  <div>
                    <Button
                      color="primary"
                      className="signUpBtn mb-1 mt-4"
                      onClick={() => setPlanTab("2")}
                    >
                      Upgrade Now
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </TabPane>
        )}
        <TabPane tabId="2">
          {(isFreeTrail || (!isFreeTrail && !currentPlan?.code)) &&
            moment(expireAtString).diff(moment().add(7, "days"), "days") >
            0 && (
              <div className="subscription_status_info">
                <div>
                  <FontAwesomeIcon
                    icon={faClock}
                    style={{ color: "#c29f45", marginLeft: "3px" }}
                  />
                </div>
                <div className="plan_notification">
                  You are trialing pro plan. Select the plan that fits your
                  needs.
                </div>
              </div>
            )}
          {/* <h5 className="text-center pt-2"> */}
          {/* <b>Choose Your Plan</b> */}
          {/* </h5> */}
          {/* <div className="my-2 text-center">
            <FormGroup check inline>
              <Input
                type="radio"
                checked={!isYearly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedPlan(null);
                  setIsYearly(!event.target.checked);
                }}
                style={{
                  outline: "none",
                  borderColor: "initial",
                  boxShadow: "none",
                  marginTop: "2.5px",
                }}
              />
              <Label check>Monthly</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input
                type="radio"
                checked={isYearly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedPlan(null);
                  setIsYearly(event.target.checked);
                }}
                style={{
                  outline: "none",
                  borderColor: "initial",
                  boxShadow: "none",
                  marginTop: "2.5px",
                }}
              />
              <Label check>Yearly</Label>
            </FormGroup>
          </div> */}
          <div className={`${styles.planSelector} mb-2`} >
            <div
              className={`${styles.planOption} ${selectedPlanMode === 'monthly' ? styles[selectedPlanMode] : 'default'}`}
              onClick={() => handleSelectPlan('monthly')}
            >
              Monthly
            </div>
            <div
              className={`${styles.planOption} ${selectedPlanMode === 'quarterly' ? styles[selectedPlanMode] : 'default'}`}
              onClick={() => handleSelectPlan('quarterly')}
            >
              Quarterly <span> ▼8%</span>
            </div>
            <div
              className={`${styles.planOption} ${selectedPlanMode === 'yearly' ? styles[selectedPlanMode] : 'default'}`}
              onClick={() => handleSelectPlan('yearly')}
            >
              Yearly <span> ▼20%</span>
            </div>
          </div>

          <Row className="mr-3">{subscriptionPlans}</Row>
        </TabPane>
      </TabContent>
      {isFreeTrail || (!currentPlan?.code && !isFreeTrail) ? (
        ""
      ) : (
        <section className={`${planTab === "2" ? "my-3" : "my-2"}`}>
          <div className={styles.billingInfoCaption}>
            <h5>Billing Information</h5>
            <SyncButton
              onClick={() => {
                refetch();
                billingInfoQuery.refetch();
                setTimeout(
                  () => { toast("success", "Billing Information Synced Successfully") }, 1000
                )
              }}
            />
          </div>
          {billingInfoCard}
        </section>
      )}
      {/* {
        (isFreeTrail || (!currentPlan?.code && !isFreeTrail) || moment(expireAtString).diff(moment(), "days") < 0) ? '' :
          subscriptionHistoryQuery.data?.some(
            (item) => item.status === "PENDING"
          ) ? (
            <>
              <div className={[styles.billingInfoCaption, "mt-4"].join(" ")}>
                <h5>
                  <b>Invoice</b>
                </h5>
                <SyncButton
                  onClick={() => {
                    subscriptionHistoryQuery.refetch();
                  }}
                />
              </div>
              {subscriptionInvoice}
            </>
          ) : null
      } */}
      {isFreeTrail || (!currentPlan?.code && !isFreeTrail) ? (
        ""
      ) : (
        <>
          <div className={[styles.billingInfoCaption, "mt-1"].join(" ")}>
            <h5>
              <b>Invoice</b>
            </h5>
            <SyncButton
              onClick={() => {
                fetchData(0, 20, '', 0)
                // subscriptionHistoryQuery.refetch();
                setTimeout(
                  () => { toast("success", "Invoice Synced Successfully") }, 1000
                );
              }}
            />
          </div>
          {subscriptionInvoice}
        </>
      )}
      {/* {zohoSubscriptions.length ? (
        <>
          <CSSTransition
            in={selectedPlan !== null}
            timeout={300}
            nodeRef={selectedPlanRef}
            unmountOnExit
            mountOnEnter
            classNames={{
              enter: styles.enter,
              enterActive: styles.enterActive,
              exit: styles.exit,
              exitActive: styles.exitActive,
            }}
          >
            <div
              ref={selectedPlanRef}
              className={`${styles.selectedPlan}${isSidebarToggled ? " " + styles.sidebarToggled : ""
                }`}
            >
              <div style={{ fontSize: 14 }}>
                <span className="dimmed mr-1">Total Amount:</span>
                {selectedPlan ? (
                  <>
                    {formatCurrency(selectedPlan.recurring_price, "INR")}
                    <span className="dimmed ml-1">
                      ({selectedPlanMode === 'yearly' ? "Yearly" : "Monthly"})
                    </span>
                  </>
                ) : (
                  "-"
                )}
              </div>

              {selectedPlan ? (
                <>
                  <div style={{ flex: 1 }}></div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="mr-4" style={{ fontSize: 14 }}>
                      No. of users: 6
                    </div>
                    <div>
                      <Button
                        className="cancelButton mr-2"
                        onClick={() => setSelectedPlan(null)}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{
                          backgroundColor:
                            selectedPlan?.name ===
                              ("Pro - Monthly" || "Pro - Yearly")
                              ? "#ff7342"
                              : "#007ec3",
                          color: "white",
                          border: "none",
                        }}
                        onClick={() => {
                          onSubscribeHandler();
                        }}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </CSSTransition>
          {showCheckout && checkoutData && (
            <RightSidebar
              handleCancel={() => setShowCheckout(false)}
              title="Subscription Details"
              width="30%"
            >
            </RightSidebar>
          )}
        </>
      ) : null} */}
      {selectedPlan && addonsInfo && (
        <RightSidebar handleCancel={() => {
          setSelectedPlan(null)
          setAddonsInfo(false);
        }}
          title="Subscription Details"
          width="30%">
          <ZohoSub planDetails={selectedPlan} formatCurrency={formatCurrency} addons={addons} currency={currency}
            billingData={billingInfoQuery.data} />
        </RightSidebar>
      )}
      {showBillingInfo ? (
        <RightSidebar
          handleCancel={() => {
            setShowBillingInfo(false)
            setSelectedPlan(null);
          }}
          title="Billing Information"
          width="35%"
        >
          {billingInfoQuery.isFetched && billingInfoQuery.data ? (
            <BillingInformation
              onCancel={() => setShowBillingInfo(false)}
              refetch={() => billingInfoQuery.refetch()}
              data={billingInfoQuery.data}
              saveBillingDetails={saveBillingDetails}
              plan={currentPlan}
            />
          ) : (
            <Spinner />
          )}
        </RightSidebar>
      ) : null}
    </main>
  );
};

export default SubscriptionPage;
